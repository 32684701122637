import { VFC, useState, ReactNode, MouseEventHandler } from "react";
import ReactModal from "react-modal";
import {
  TreeView,
  TreeItem,
  TreeViewThemeContext,
} from "../../common/treeview";
import { Folder } from "app/javascript/types/folder";
import {
  toTreeFolderFromTeamFolders,
  toTreeFolderFromUserFolders,
  TreeFolder,
} from "../../../lib/tree-folder";
import {
  ApiVideoApi,
  Configuration,
  ApiVideoIdDuplicatePost422Response,
} from "../../../generated/api";
import { notify } from "../../notification";
import { isResponseError } from "../../../lib/isResponseError";

function requestMoveVideo(
  videoId: number,
  newParentFolderId: number,
  csrfToken: string
) {
  const api = new ApiVideoApi(
    new Configuration({
      basePath: location.origin,
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiVideoIdMovePatch({
    id: videoId,
    apiVideoIdMovePatchRequest: { folderId: newParentFolderId },
  });
}

type Props = {
  videoId: number;
  currentParentFolderId: number;
  userFolders: Folder[];
  teamFolders: Folder[];
  csrfToken: string;
  onAfterClose(): void;
};

export const MoveVideoModal: VFC<Props> = (props) => {
  const userTreeFolder = toTreeFolderFromUserFolders(props.userFolders);
  const teamTreeFolder = toTreeFolderFromTeamFolders(props.teamFolders);

  const [visible, setVisible] = useState(true);
  const [errMsg, setErrMsg] = useState<ReactNode>(null);
  const [parentFolderId, setParentFolderId] = useState(
    props.currentParentFolderId
  );

  const moveVideo: MouseEventHandler = (event) => {
    event.preventDefault();
    setErrMsg(null);
    (async function () {
      try {
        await requestMoveVideo(props.videoId, parentFolderId, props.csrfToken);
        location.reload();
      } catch (reason) {
        if (isResponseError(reason) && reason.response.status === 422) {
          const res: ApiVideoIdDuplicatePost422Response =
            await reason.response.json();
          const msg = Object.values(res.messages)
            .map((msgs) =>
              msgs instanceof Array ? msgs.join("\n") : `${msgs}`
            )
            .join("\n");
          setErrMsg(msg);
        } else {
          setVisible(false);
          notify(
            "エラーが発生しました。画面をリロードしてもう一度試してください。"
          );
          throw reason;
        }
      }
    })();
  };

  return (
    <ReactModal
      onAfterClose={props.onAfterClose}
      isOpen={visible}
      overlayClassName="c-dialog__overlay"
      className="c-dialog__container"
    >
      <div className="c-dialog__header">
        <h2 className="c-dialog__header__title">ビデオの移動</h2>
        <button
          className="material-icons-round c-dialog__header__close-icon"
          onClick={() => setVisible(false)}
        >
          close
        </button>
      </div>
      {errMsg && <p className="c-dialog__err-msgs">{errMsg}</p>}
      <div className="c-dialog__content">
        <TreeView
          selectedItemId={parentFolderId}
          onItemSelected={(itemId: number) => setParentFolderId(itemId)}
        >
          <TreeViewThemeContext.Provider
            value={{
              icon: <span className="material-icons-round">folder</span>,
            }}
          >
            <TreeItem
              itemId={userTreeFolder.id}
              label={userTreeFolder.name}
              icon={<span className="material-icons-round">perm_identity</span>}
            >
              {buildTreeView(userTreeFolder.children)}
            </TreeItem>
            <TreeItem
              itemId={teamTreeFolder.id}
              label={teamTreeFolder.name}
              icon={<span className="material-icons-round">business</span>}
            >
              {buildTreeView(teamTreeFolder.children)}
            </TreeItem>
          </TreeViewThemeContext.Provider>
        </TreeView>
      </div>
      <div className="c-dialog__footer">
        <button
          className="c-button--outlined narrow-padding"
          type="button"
          onClick={() => setVisible(false)}
        >
          キャンセル
        </button>
        <button
          className="c-button--primary narrow-padding"
          type="submit"
          onClick={moveVideo}
        >
          ここに移動
        </button>
      </div>
    </ReactModal>
  );
};

function buildTreeView(folders: TreeFolder[]): ReactNode {
  if (folders) {
    return folders.map((folder) => (
      <TreeItem key={folder.id} itemId={folder.id} label={folder.name}>
        {buildTreeView(folder.children)}
      </TreeItem>
    ));
  }
}
