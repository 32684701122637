import { VFC, useState, useEffect } from "react";

interface Props {
  className?: string;
  url: string;
}

export const LinkCopyButton: VFC<Props> = (props) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      // コピーボタン表示の戻し
      const timeoutId = setTimeout(() => setCopied(false), 3000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [copied]);

  const copyUrl = () => {
    navigator.clipboard.writeText(props.url).then(() => {
      setCopied(true);
    });
  };

  return (
    <button
      className={`c-button--text-link-primary no-decoration ${
        props.className ?? ""
      }`}
      onClick={copyUrl}
    >
      <span className="material-icons-round c-button__icon">
        {copied ? "done" : "link"}
      </span>
      {copied ? "コピーしました" : "リンクをコピー"}
    </button>
  );
};
export default LinkCopyButton;
