/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner } from './ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner';
import {
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerFromJSON,
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerFromJSONTyped,
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerToJSON,
} from './ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner';

/**
 * 
 * @export
 * @interface ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner
 */
export interface ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner {
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner
     */
    track?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner
     */
    fit?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner
     */
    time?: number;
    /**
     * 
     * @type {Array<ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner>}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner
     */
    animations?: Array<ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner>;
}

/**
 * Check if a given object implements the ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner interface.
 */
export function instanceOfApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerFromJSON(json: any): ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner {
    return ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerFromJSONTyped(json, false);
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'track': !exists(json, 'track') ? undefined : json['track'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'fit': !exists(json, 'fit') ? undefined : json['fit'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'animations': !exists(json, 'animations') ? undefined : ((json['animations'] as Array<any>).map(ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerFromJSON)),
    };
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerToJSON(value?: ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'track': value.track,
        'type': value.type,
        'source': value.source,
        'fit': value.fit,
        'duration': value.duration,
        'time': value.time,
        'animations': value.animations === undefined ? undefined : ((value.animations as Array<any>).map(ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerToJSON)),
    };
}

