import loadingImg from "images/loading.svg";
import {
  SubtitlesEditView,
  SubtitlesEditViewProps,
} from "./edit/SubtitlesEditView";
import { useSubtitleView } from "./useSubtitleView";

type Props = SubtitlesEditViewProps & {
  canRequestGenerateJob: boolean;
  subtitles: {
    generateJobState: string;
    size: number;
  };
};

export function SubtitlesView(props: Props) {
  const { viewState, isRequesting, requestGenerateJob, startEditView } =
    useSubtitleView(
      props.videoId,
      props.csrfToken,
      props.subtitles.generateJobState,
      props.subtitles.size
    );

  if (viewState === "initial") {
    // 字幕生成方法選択
    return (
      <div className="p-subtitles__main">
        <div className="p-subtitles__prepare-container">
          <button
            className="c-button--outlined align-start p-subtitles__prepare-button"
            disabled={isRequesting || !props.canRequestGenerateJob}
            style={isRequesting ? { cursor: "progress" } : null}
            onClick={() => requestGenerateJob()}
            title={
              props.canRequestGenerateJob
                ? "AI字幕(自動生成)"
                : "音声トラックのないビデオでは自動生成できません"
            }
          >
            <span className="material-icons-round c-button__icon">
              auto_awesome
            </span>
            AI字幕(自動生成)
          </button>

          <button
            className="c-button--outlined align-start p-subtitles__prepare-button"
            disabled={isRequesting}
            onClick={startEditView}
            title="字幕を手動入力"
          >
            <span className="material-icons-round c-button__icon">
              edit_note
            </span>
            字幕を手動入力
          </button>
        </div>
        {isRequesting && <style>{`html { cursor: progress; }`}</style>}
      </div>
    );
  }

  if (viewState === "processing") {
    // 字幕生成中
    return (
      <div className="p-subtitles__main">
        <div className="p-subtitles__processing-container">
          <h3 className="p-subtitles__processing-title">
            字幕を生成しています...
          </h3>
          <p className="p-subtitles__processing-text">
            この画面から移動しても字幕の生成は継続されます。
          </p>
          <img className="p-subtitles__processing-loading" src={loadingImg} />
        </div>
      </div>
    );
  }

  if (viewState === "editable") {
    return (
      <SubtitlesEditView
        csrfToken={props.csrfToken}
        videoId={props.videoId}
        src={props.src}
        type={props.type}
      />
    );
  }

  // エラー
  return (
    <div className="p-subtitles__main">
      <div className="p-subtitles__prepare-container">
        エラーが発生しました。
        <br />
        画面をリロードしてもう一度試してください。
      </div>
    </div>
  );
}
export default SubtitlesView;
