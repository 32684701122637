/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource } from './ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource';
import {
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceFromJSON,
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceFromJSONTyped,
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceToJSON,
} from './ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource';

/**
 * 
 * @export
 * @interface ApiDesignIdAddCreatomateJobPostRequestCreatomateParam
 */
export interface ApiDesignIdAddCreatomateJobPostRequestCreatomateParam {
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParam
     */
    multipart?: number;
    /**
     * 
     * @type {ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParam
     */
    source?: ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource;
}

/**
 * Check if a given object implements the ApiDesignIdAddCreatomateJobPostRequestCreatomateParam interface.
 */
export function instanceOfApiDesignIdAddCreatomateJobPostRequestCreatomateParam(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamFromJSON(json: any): ApiDesignIdAddCreatomateJobPostRequestCreatomateParam {
    return ApiDesignIdAddCreatomateJobPostRequestCreatomateParamFromJSONTyped(json, false);
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDesignIdAddCreatomateJobPostRequestCreatomateParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'multipart': !exists(json, 'multipart') ? undefined : json['multipart'],
        'source': !exists(json, 'source') ? undefined : ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceFromJSON(json['source']),
    };
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamToJSON(value?: ApiDesignIdAddCreatomateJobPostRequestCreatomateParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'multipart': value.multipart,
        'source': ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceToJSON(value.source),
    };
}

