import { VFC, useState, useRef, ChangeEventHandler, useEffect } from "react";
import ReactModal from "react-modal";
import loadingImg from "images/loading.svg";
import { ApiVideoApi, Configuration } from "../../generated/api";
import { BlockBlobClient } from "@azure/storage-blob";
import { loadVideoDuration } from "../../lib/loadVideoDuration";

interface Props {
  csrfToken: string;
  folderId?: number;
}

const acceptMimeTypes = ["video/mp4", "video/quicktime"];
type ErrorType = "fileSize" | "fileType" | "unknown";

async function uploadVideo(props: Props, file: File): Promise<void> {
  const duration = await loadVideoDuration(file);

  const api = new ApiVideoApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": props.csrfToken,
      },
    })
  );

  const res = await api.apiVideoPreparePost();

  const blobClient = new BlockBlobClient(res.uploadUrl);
  await blobClient.uploadData(file, {
    blobHTTPHeaders: { blobContentType: file.type },
  });

  const title = file.name.replace(/\.[^/.]+$/, "");

  const assetName = res.assetName;
  await api.apiVideoUploadedPost({
    apiVideoUploadedPostRequest: {
      assetName: assetName,
      title: title,
      folderId: props.folderId,
      inputVideoDuration: duration,
    },
  });
}

function validateFile(file: File) {
  const errors: ErrorType[] = [];
  if (file.size > 30 * 1024 * 1024 * 1024) {
    errors.push("fileSize");
  }
  if (!acceptMimeTypes.includes(file.type)) {
    errors.push("fileType");
  }
  return errors;
}

export const VideoUploader: VFC<Props> = (props) => {
  const inputFileRef = useRef<HTMLInputElement>();
  const shouldConfirmBeforeUnload = useRef(false);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [uploading, setUploading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const handleCloseButton = () => {
    setModalVisible(false);
    setErrors([]);
  };

  // 保存前、保存中は画面離脱確認をする。
  shouldConfirmBeforeUnload.current ||= uploading;
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      if (shouldConfirmBeforeUnload.current) {
        event.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
  }, []);

  const fileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setErrors([]);
    const file = event.target.files[0];

    if (!file) {
      return;
    }
    const errors = validateFile(file);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    // アップロード
    setUploading(true);
    uploadVideo(props, file)
      .then(() => {
        setUploading(false);
        setModalVisible(false);
        shouldConfirmBeforeUnload.current = false;
        location.reload();
      })
      .catch((reason) => {
        console.error(reason);
        setErrors(["unknown"]);
        setUploading(false);
      });
    shouldConfirmBeforeUnload.current = false;
  };
  return (
    <>
      <button
        className="p-video-list__header-button"
        onClick={() => setModalVisible(!modalVisible)}
      >
        <i className="material-icons-round p-video-list__header-button-icon">
          videocam
        </i>
        ビデオアップロード
      </button>
      <ReactModal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        overlayClassName="c-dialog__overlay"
        className="c-dialog__container"
      >
        <div className="p-video-uploader__container">
          {uploading && (
            <>
              <div className="p-video-uploader__uploading">
                <img
                  src={loadingImg}
                  className="p-video-uploader__uploading-progress"
                />
              </div>
              <style>{`body { cursor: progress; }`}</style>
            </>
          )}
          <div className="c-dialog__header">
            <h2 className="c-dialog__header__title">ビデオをアップロード</h2>
            <button
              className="material-icons-round c-dialog__header__close-icon"
              onClick={() => handleCloseButton()}
            >
              close
            </button>
          </div>
          {errors.length > 0 && (
            <div className="c-dialog__err-msgs">
              アップロードできませんでした。
              <br />
              {errors
                .map((errorType) => {
                  const errMsgs: Record<ErrorType, string> = {
                    fileSize:
                      "ファイルサイズを30GB以下にして再度お試しください。",
                    fileType:
                      "MP4またはMOV形式のファイルをアップロードしてください。",
                    unknown: "画面をリロードしてもう一度お試しください。",
                  };
                  return errMsgs[errorType] ?? errMsgs.unknown;
                })
                .join("\n")}
            </div>
          )}
          <div className="p-video-uploader__msgs">
            <i className="material-icons-round icn_videocam">videocam</i>
            <br />
            ファイル形式：MP4, MOV <br />
            最大アップロードサイズ：30GB
            <br />
          </div>
          <div className="c-dialog__footer">
            <button
              className="c-button--outlined narrow-padding"
              type="button"
              onClick={() => handleCloseButton()}
            >
              キャンセル
            </button>
            <button
              className="c-button--primary narrow-padding"
              disabled={uploading}
              onClick={() => inputFileRef.current.click()}
            >
              ファイルを選択
            </button>
            <input
              ref={inputFileRef}
              type="file"
              accept={acceptMimeTypes.join(",")}
              onChange={fileChange}
              disabled={uploading}
              className="p-video-uploader__input"
            />
          </div>
        </div>
      </ReactModal>
    </>
  );
};
export default VideoUploader;
