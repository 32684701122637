/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAcademyAcademyIdWatchLinkPatchRequest
 */
export interface ApiAcademyAcademyIdWatchLinkPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyAcademyIdWatchLinkPatchRequest
     */
    type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiAcademyAcademyIdWatchLinkPatchRequest
     */
    emails?: Array<string>;
}

/**
 * Check if a given object implements the ApiAcademyAcademyIdWatchLinkPatchRequest interface.
 */
export function instanceOfApiAcademyAcademyIdWatchLinkPatchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyAcademyIdWatchLinkPatchRequestFromJSON(json: any): ApiAcademyAcademyIdWatchLinkPatchRequest {
    return ApiAcademyAcademyIdWatchLinkPatchRequestFromJSONTyped(json, false);
}

export function ApiAcademyAcademyIdWatchLinkPatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyAcademyIdWatchLinkPatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
    };
}

export function ApiAcademyAcademyIdWatchLinkPatchRequestToJSON(value?: ApiAcademyAcademyIdWatchLinkPatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'emails': value.emails,
    };
}

