/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoVideoIdExamQuestionsGet200ResponseInner,
  ApiVideoVideoIdExamQuestionsPutRequest,
} from '../models';
import {
    ApiVideoVideoIdExamQuestionsGet200ResponseInnerFromJSON,
    ApiVideoVideoIdExamQuestionsGet200ResponseInnerToJSON,
    ApiVideoVideoIdExamQuestionsPutRequestFromJSON,
    ApiVideoVideoIdExamQuestionsPutRequestToJSON,
} from '../models';

export interface ApiVideoVideoIdExamQuestionsGetRequest {
    videoId: number;
}

export interface ApiVideoVideoIdExamQuestionsPutOperationRequest {
    videoId: number;
    apiVideoVideoIdExamQuestionsPutRequest?: ApiVideoVideoIdExamQuestionsPutRequest;
}

/**
 * 
 */
export class ApiExamQuestionApi extends runtime.BaseAPI {

    /**
     * show
     */
    async apiVideoVideoIdExamQuestionsGetRaw(requestParameters: ApiVideoVideoIdExamQuestionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiVideoVideoIdExamQuestionsGet200ResponseInner>>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdExamQuestionsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/exam/questions`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiVideoVideoIdExamQuestionsGet200ResponseInnerFromJSON));
    }

    /**
     * show
     */
    async apiVideoVideoIdExamQuestionsGet(requestParameters: ApiVideoVideoIdExamQuestionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiVideoVideoIdExamQuestionsGet200ResponseInner>> {
        const response = await this.apiVideoVideoIdExamQuestionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiVideoVideoIdExamQuestionsPutRaw(requestParameters: ApiVideoVideoIdExamQuestionsPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiVideoVideoIdExamQuestionsGet200ResponseInner>>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdExamQuestionsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/exam/questions`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdExamQuestionsPutRequestToJSON(requestParameters.apiVideoVideoIdExamQuestionsPutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiVideoVideoIdExamQuestionsGet200ResponseInnerFromJSON));
    }

    /**
     * update
     */
    async apiVideoVideoIdExamQuestionsPut(requestParameters: ApiVideoVideoIdExamQuestionsPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiVideoVideoIdExamQuestionsGet200ResponseInner>> {
        const response = await this.apiVideoVideoIdExamQuestionsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
