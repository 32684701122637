import {
  useCallback,
  useRef,
  useState,
  useEffect,
  VFC,
  SelectHTMLAttributes,
  FormEvent,
} from "react";
import { flushSync } from "react-dom";
import { BlockBlobClient } from "@azure/storage-blob";

import {
  ApiVideoApi,
  ApiNarrationApi,
  ApiSubtitleApi,
  Configuration,
} from "../../generated/api";
import { useSubMenuVisible } from "../common/submenus/useSubMenuVisible";
import { useNarrationsView, NarrationState } from "./useNarrationsView";
import { TimeMsInput } from "../common/TimeMsInput";
import { NarrationPreviewPlayer } from "./NarrationPreviewPlayer";
import { AzureCognitiveService } from "../../lib/AzureCognitiveService";
import { notify } from "../notification";
import { videoPath } from "../../generated/routes";
import { formatTimeMs } from "../../lib/formatTimeMs";
import { isResponseError } from "../../lib/isResponseError";

type Video = Awaited<ReturnType<ApiVideoApi["apiVideoIdGet"]>>;
type VideoNarration = Awaited<
  ReturnType<ApiNarrationApi["apiVideoVideoIdNarrationsGet"]>
>[number];

interface Props {
  csrfToken: string;
  video: Video;
  videoNarrations: VideoNarration[];
  src: string;
  type: string;
  subtitlesUrl?: string;
}

// https://learn.microsoft.com/ja-jp/azure/cognitive-services/speech-service/language-support?tabs=stt-tts#supported-languages
const VOICE_NAME_LIST = [
  { label: "女性", value: "ja-JP-NanamiNeural" },
  { label: "男性", value: "ja-JP-KeitaNeural" },
];
const SPEECH_RATE_LIST = [
  { label: "少しゆっくり", value: "1" },
  { label: "ふつう", value: "1.05" },
  { label: "少し速い", value: "1.25" },
  { label: "速い", value: "1.5" },
];
const MAX_NARRATION_ITEM_COUNT = 200;
const FORM_ID = "narrations-form";

export const VideoNarrationsEdit: VFC<Props> = (props) => {
  const shouldConfirmBeforeUnload = useRef(false);
  const acsRef = useRef<AzureCognitiveService>();
  const formRef = useRef<HTMLFormElement>();
  const [loading, setLoading] = useState(false);
  const [viewState, dispatch] = useNarrationsView(
    props.video,
    props.videoNarrations,
    VOICE_NAME_LIST[0].value,
    SPEECH_RATE_LIST[1].value
  );
  const hasError = viewState.narrations.some((n) => !!n.error);

  shouldConfirmBeforeUnload.current ||= viewState.narrations.length > 0;
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      if (shouldConfirmBeforeUnload.current) {
        event.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
  }, []);

  useEffect(() => {
    return () => acsRef.current?.disposeAudioCaches();
  }, []);

  const prepareSubmit = async () => {
    setLoading(true);
    dispatch({ type: "set-playing", playing: false });

    try {
      acsRef.current ??= new AzureCognitiveService(props.csrfToken);
      await acsRef.current.ensureToken();
      const narrations = await Promise.all(
        convertNarrationAudios(acsRef, viewState)
      );
      // 音声データの変換をstateに即時反映し、後続のrequestSubmit時点で確実に値が存在するようにする
      flushSync(() => dispatch({ type: "set-narrations", narrations }));
    } catch (reason) {
      notify(
        "音声の生成に失敗しました。\n誤った内容のナレーションボックスがないか確認し、しばらく待ってもう一度お試しください。"
      );
      throw reason;
    } finally {
      setLoading(false);
    }

    // Submitイベントを発火し、Formバリデーションとsubmitイベントに処理を引き継ぐ
    formRef.current.requestSubmit();
  };

  const submit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      !confirm(
        "現在のビデオの音声をAIナレーションで上書きします。\n" +
          "この操作は取り消せません。よろしいですか？"
      )
    ) {
      return;
    }
    setLoading(true);

    try {
      await saveNarrations(
        props.csrfToken,
        props.video.id,
        viewState.voiceName,
        viewState.speechRate,
        viewState.narrations
      );
    } catch (reason) {
      let msg =
        "AIナレーションの生成開始に失敗しました。しばらく待ってもう一度お試しください。";
      if (isResponseError(reason) && reason.response.status === 422) {
        // e.g. {"messages":{"foo":["fooは不正な値です"]}}
        const resBody: { messages?: Record<string, [string]> } =
          await reason.response.json().catch(() => null);
        msg =
          Object.values(resBody?.messages || {})
            .flat()
            .join("\n")
            .trim() || msg;
      }
      notify(msg);
      setLoading(false);
      throw reason;
    }

    shouldConfirmBeforeUnload.current = false;
    location.assign(videoPath(props.video.hashid));
  };

  const importSubtitles = () => {
    dispatch({ type: "set-playing", playing: false });
    if (viewState.narrations.length > 0) {
      if (
        !confirm(
          "現在のナレーションボックスを字幕データで上書きします。\n" +
            "よろしいですか？"
        )
      ) {
        return;
      }
    }
    fetchSubtitles(props.video.id).then((subtitles) => {
      if (subtitles?.length > 0) {
        if (subtitles.length > MAX_NARRATION_ITEM_COUNT) {
          subtitles.splice(MAX_NARRATION_ITEM_COUNT);
          notify(
            `ナレーションを生成できるのは${MAX_NARRATION_ITEM_COUNT}までのため、末尾の字幕は省かれました。`
          );
        }
        const narrations = subtitles.map((s) => ({
          startTimeMs: Math.floor(s.startTimeMs / 100) * 100, // 100ms未満を切り捨て
          text: s.value,
        }));
        dispatch({ type: "set-narrations", narrations });
      }
    });
  };

  const convertNarrationsToAudios = async () => {
    setLoading(true);

    try {
      acsRef.current ??= new AzureCognitiveService(props.csrfToken);
      await acsRef.current.ensureToken();
      const promises = convertNarrationAudios(acsRef, viewState).map(
        (convertingNarrationAudio) =>
          convertingNarrationAudio.then((narration) =>
            dispatch({ type: "set-narration-item", narration })
          )
      );
      await Promise.all(promises);
      formRef.current?.reportValidity();
    } catch (reason) {
      notify(
        "音声の生成に失敗しました。\n誤った内容のナレーションボックスがないか確認し、しばらく待ってもう一度お試しください。"
      );
      throw reason;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="p-narrations__header">
        <a href={videoPath(props.video.hashid)} className="c-button--text">
          キャンセル
        </a>

        <button
          type="button"
          className="c-button--primary"
          disabled={!viewState.narrations.length || loading}
          onClick={prepareSubmit}
        >
          AIナレーションを生成
        </button>

        <EditSubmenu
          onClickClear={() => {
            if (confirm("ナレーションボックスをすべてクリアします。")) {
              dispatch({ type: "set-narrations", narrations: [] });
            }
          }}
        />
      </div>
      <div className="p-narrations__main">
        <div className="p-narrations__edit-content-container">
          <div className="p-narrations__edit-content-header-controls">
            {viewState.narrations.length === 0 && (
              <button
                type="button"
                className="c-button--text-link-primary no-decoration"
                title="ナレーションを追加"
                onClick={() =>
                  dispatch({ type: "add-new-narration-after", key: null })
                }
              >
                <span className="material-icons-round c-button__icon">add</span>
                ナレーションを追加
              </button>
            )}
            <div>{/* spacer */}</div>
            <button
              type="button"
              className="c-button--outlined"
              disabled={!(viewState.video.subtitles.size > 0)}
              onClick={importSubtitles}
            >
              <span className="material-icons-round c-button__icon">
                subtitles
              </span>
              字幕を取り込む
            </button>
          </div>
          {viewState.narrations.length > 0 && (
            <form id={FORM_ID} ref={formRef} onSubmit={submit}>
              {viewState.narrations.map((narration) => (
                <NarrationEditItem
                  key={narration.key}
                  isCurrent={
                    !hasError && narration.key === viewState.playingNarrationKey
                  }
                  value={narration}
                  onChange={(newValue) => {
                    if (narration.text != newValue.text) {
                      // テキスト変更時は音声をリセットして、プレビューも一旦止める。
                      newValue.audio = null;
                      dispatch({ type: "set-playing", playing: false });
                    }
                    dispatch({
                      type: "set-narration-item",
                      narration: newValue,
                    });
                  }}
                  onDelete={() =>
                    dispatch({ type: "delete-narration", key: narration.key })
                  }
                  onAddAfter={
                    viewState.narrations.length >= MAX_NARRATION_ITEM_COUNT
                      ? null
                      : () =>
                          dispatch({
                            type: "add-new-narration-after",
                            key: narration.key,
                          })
                  }
                />
              ))}
            </form>
          )}
        </div>
        <div className="p-narrations__player-container">
          <NarrationPreviewPlayer
            src={props.src}
            type={props.type}
            subtitlesUrl={props.subtitlesUrl}
            narrations={viewState.narrations}
            playing={viewState.playing}
            onChangePlaying={useCallback(
              (playing) => dispatch({ type: "set-playing", playing }),
              [dispatch]
            )}
            onPlay={convertNarrationsToAudios}
            onTimeUpdate={useCallback(
              (currentTimeMs) =>
                dispatch({ type: "set-current-time", currentTimeMs }),
              [dispatch]
            )}
          />
          <div className="p-narrations__voice-selector">
            <label
              className="p-narrations__voice-selector-label"
              htmlFor="voice-name-select"
            >
              音声
            </label>
            <VoiceNameSelect
              id="voice-name-select"
              className="p-narrations__voice-selector-select"
              form={FORM_ID}
              required
              value={viewState.voiceName}
              onChange={(e) =>
                dispatch({ type: "set-voice-name", voiceName: e.target.value })
              }
            />
          </div>
          <div className="p-narrations__voice-selector">
            <label className="p-narrations__voice-selector-label">
              読み上げ速度
            </label>
            {SPEECH_RATE_LIST.map((item) => (
              <label
                className="p-narrations__speech-rate-label"
                key={item.value}
              >
                <input
                  className="p-narrations__speech-rate-check"
                  type="radio"
                  name="narrations-speech-rate"
                  value={item.value}
                  checked={item.value === viewState.speechRate}
                  onChange={(e) =>
                    dispatch({
                      type: "set-speech-rate",
                      speechRate: e.target.value,
                    })
                  }
                />
                {item.label}
              </label>
            ))}
          </div>
        </div>
      </div>
      {loading && ( // モーダル制御
        <div
          style={{
            cursor: "progress",
            opacity: 1,
            width: "100%",
            height: "100%",
            position: "absolute",
            transform: "translateY(-100%)",
          }}
        ></div>
      )}
    </div>
  );
};
export default VideoNarrationsEdit;

const NarrationEditItem: VFC<{
  isCurrent: boolean;
  value: NarrationState;
  onChange: (newValue: NarrationState) => void;
  onDelete: () => void;
  onAddAfter: () => void | null;
}> = ({ isCurrent, value, onChange, onDelete, onAddAfter }) => {
  const textareaRef = useRef<HTMLTextAreaElement>();
  const scrollIntoView = useCallback((elem: HTMLDivElement) => {
    elem?.scrollIntoView?.({ behavior: "smooth", block: "center" });
  }, []);
  const endTimeMs = Number.isSafeInteger(value.audio?.durationMs)
    ? value.startTimeMs + value.audio.durationMs
    : null;

  useEffect(() => {
    textareaRef.current.setCustomValidity(value.error || "");
  }, [value.error]);

  const update = (newValue: Partial<NarrationState>) => {
    onChange({ ...value, ...newValue });
  };

  /** 100ms単位未満を切り上げる */
  function ceil100ms(ms: number) {
    return Math.ceil(ms / 100) * 100;
  }

  return (
    <div
      className={`p-narrations__item p-narrations__show-on-hover-container ${
        isCurrent ? "is-current" : ""
      }`}
      ref={isCurrent ? scrollIntoView : null}
    >
      <div className="p-narrations__item-times">
        <TimeMsInput
          required
          className={`p-narrations__item-time-input ${
            value.error ? "has-error" : ""
          }`}
          value={value.startTimeMs}
          onChange={(startTimeMs) => update({ startTimeMs })}
        />
        {endTimeMs && (
          <time
            className="p-narrations__item-time-display"
            dateTime={`T${endTimeMs / 1000}S`}
          >
            {formatTimeMs(ceil100ms(endTimeMs))}
          </time>
        )}
      </div>
      <div className="p-narrations__item-controls p-narrations__show-on-hover-item">
        <button
          type="button"
          className="material-icons-round p-narrations__item-controls-middle-button"
          title="このナレーションを削除"
          onClick={onDelete}
        >
          delete_outline
        </button>
        <button
          type="button"
          className="material-icons-round p-narrations__item-controls-bottom-button"
          title="後にナレーションを追加"
          onClick={onAddAfter}
          disabled={!onAddAfter}
        >
          add_circle
        </button>
      </div>
      <textarea
        ref={textareaRef}
        className={`p-narrations__item-textarea ${
          value.error ? "has-error" : ""
        }`}
        value={value.text}
        maxLength={200}
        required
        onChange={(e) => update({ text: e.target.value })}
      ></textarea>
    </div>
  );
};

const EditSubmenu: VFC<{ onClickClear: () => void }> = ({ onClickClear }) => {
  const [submenu, toggleSubmenu] = useSubMenuVisible();
  return (
    <div>
      <button
        type="button"
        className="material-icons-round c-submenu__button"
        onClick={() => toggleSubmenu()}
      >
        more_vert
      </button>
      <ul
        className="c-submenu__list"
        hidden={!submenu}
        style={{ wordBreak: "keep-all", transform: "translateX(-100%)" }}
      >
        <li className="c-submenu__list-item danger">
          <button onClick={onClickClear} type="reset">
            <i className="material-icons-round c-submenu__list-item-icon">
              clear_all
            </i>
            ナレーションボックスをクリア
          </button>
        </li>
      </ul>
    </div>
  );
};

const VoiceNameSelect: VFC<SelectHTMLAttributes<HTMLSelectElement>> = (
  props
) => {
  return (
    <select {...props}>
      {VOICE_NAME_LIST.map((voice) => (
        <option key={voice.value} value={voice.value}>
          {voice.label}
        </option>
      ))}
    </select>
  );
};

function convertNarrationAudios(
  acsRef: { readonly current: AzureCognitiveService },
  viewState: {
    narrations: NarrationState[];
    voiceName: string;
    speechRate: string;
  }
) {
  const { narrations, voiceName, speechRate } = viewState;
  return narrations.map((narration) =>
    Promise.all([
      narration,
      narration.text.trim()
        ? acsRef.current.convert({
            text: narration.text,
            voiceName,
            speechRate,
          })
        : null,
    ]).then(([narration, audio]) => ({ ...narration, audio }))
  );
}

function fetchSubtitles(videoId: number) {
  const api = new ApiSubtitleApi(
    new Configuration({
      basePath: "",
      headers: { Accept: "application/json", "x-hopper-api-version": "1.0" },
    })
  );
  return api.apiVideoVideoIdSubtitlesGet({ videoId });
}

/**
 * ナレーションの保存操作を行う。
 * - 音声のアップロード
 * - ナレーションの保存(及び、音声生成ジョブのリクエスト)
 */
async function saveNarrations(
  csrfToken: string,
  videoId: number,
  voiceName: string,
  speechRate: string,
  viewNarrations: NarrationState[]
) {
  const narrationsAndBlobNames = await Promise.all(
    viewNarrations.map((narration) =>
      Promise.all([
        narration,
        uploadAudio(videoId, narration.audio).then((uploadedBlobUrl) => {
          // e.g. https://${accountName}.blob.core.windows.net/${container}/${blobName}
          return new URL(uploadedBlobUrl).pathname.split("/").at(-1);
        }),
      ])
    )
  );
  const api = new ApiNarrationApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  type ParamNarration = Required<
    Parameters<
      typeof api.apiVideoVideoIdNarrationsPut
    >[0]["apiVideoVideoIdNarrationsPutRequest"]["narrations"][number]
  >;
  const narrations: ParamNarration[] = narrationsAndBlobNames.map(
    ([narration, blobName]) => ({
      startTimeMs: narration.startTimeMs,
      text: narration.text,
      audio: {
        voiceName,
        blobName,
        speechRate: speechRate,
        durationMs: narration.audio.durationMs,
      },
    })
  );
  return api.apiVideoVideoIdNarrationsPut({
    videoId,
    apiVideoVideoIdNarrationsPutRequest: { narrations },
  });
}

async function uploadAudio(videoId: number, audio: NarrationState["audio"]) {
  const fileBlob = await fetch(audio.blobUrl).then((v) => v.blob());
  const api = new ApiNarrationApi(
    new Configuration({
      basePath: "",
      headers: { "x-hopper-api-version": "1.0" },
    })
  );
  const presign = await api.apiVideoVideoIdNarrationsPresignGet({ videoId });
  const blobClient = new BlockBlobClient(presign.endpoint);
  await blobClient.uploadData(fileBlob, {
    blobHTTPHeaders: { blobContentType: fileBlob.type },
  });
  return presign.endpoint;
}
