import { VFC, useState, FormEvent, MouseEventHandler } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  ApiAcademyCourseLessonApi,
  ApiAcademyCourseApi,
  Configuration,
} from "../../generated/api";
import { notify } from "../notification";
import { RichTextEditor } from "../common/editor/RichTextEditor";
import { editAcademiesCoursePath } from "../../generated/routes";

type AcademyCourse = Awaited<
  ReturnType<ApiAcademyCourseApi["apiAcademyCoursesIdGet"]>
>;
type AcademyCourseLesson = Awaited<
  ReturnType<ApiAcademyCourseLessonApi["apiAcademyCourseLessonsIdGet"]>
>;

interface Props {
  csrfToken: string;
  actionName: "new" | "edit";
  course: AcademyCourse;
  lesson?: AcademyCourseLesson; // 編集モードの時だけ受け取る
}

export const AcademyCourseLessonTextEdit: VFC<Props> = (props) => {
  const [title, setTitle] = useState(props.lesson?.title ?? "");
  const [textHtml, setTextHtml] = useState(props.lesson?.textHtml ?? "");
  const submitable: boolean =
    props.actionName == "new"
      ? textHtml.length > 0 && title.length > 0
      : textHtml.length > 0 &&
        title.length > 0 &&
        (props.lesson.textHtml != textHtml || props.lesson.title != title);

  const handleInputTitle = (title: string) => {
    setTitle(title);
  };

  const handleInputTextHtml = (textHtml: string) => {
    setTextHtml(textHtml);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (textHtml.length > 20000) {
      notify(
        "データ量が大きすぎるため保存できません。コンテンツの量を減らしてください。"
      );
      return;
    }

    const api = new ApiAcademyCourseLessonApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );

    switch (props.actionName) {
      case "new": {
        const params = {
          courseLesson: {
            academyCourseId: props.course.id,
            title,
            textHtml,
          },
        };
        api
          .apiAcademyCourseLessonsPostRaw({
            apiAcademyCourseLessonsPostRequest: params,
          })
          .then(() => {
            location.assign(editAcademiesCoursePath(props.course.hashid));
          })
          .catch((reason) => {
            notify(
              "エラーが発生しました。画面をリロードしてもう一度試してください。"
            );
            throw reason;
          });
        break;
      }
      case "edit": {
        const params = {
          courseLesson: {
            title,
            textHtml,
          },
        };
        api
          .apiAcademyCourseLessonsIdPatch({
            id: props.lesson.id,
            apiAcademyCourseLessonsIdPatchRequest: params,
          })
          .then(() => {
            location.assign(editAcademiesCoursePath(props.course.hashid));
          })
          .catch((reason) => {
            notify(
              "エラーが発生しました。画面をリロードしてもう一度試してください。"
            );
            throw reason;
          });
        break;
      }
    }
  };

  const resetForm: MouseEventHandler = (event) => {
    event.preventDefault();
    if (confirm("編集内容を破棄します。よろしいですか？")) {
      location.reload();
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div>
        <label
          className="p-academy-edit__input-field-label"
          htmlFor="lesson_title"
        >
          レッスンタイトル
        </label>
        <TextareaAutosize
          id="lesson_title"
          className="p-academy-edit__input-field-textarea"
          rows={1}
          value={title}
          required={true}
          maxLength={100}
          placeholder="レッスンタイトルを入力してください"
          onChange={(e) => handleInputTitle(e.target.value)}
        />

        <div className="p-academy-edit__menubar">
          <button
            className="c-button--outlined narrow-padding"
            type="button"
            onClick={resetForm}
            disabled={!submitable}
          >
            変更を破棄
          </button>
          <input
            className="c-button--primary narrow-padding"
            type="submit"
            value="保存"
            disabled={!submitable}
          />
        </div>

        <label
          className="p-academy-edit__input-field-label"
          htmlFor="lesson_text"
        >
          コンテンツ
        </label>
        <RichTextEditor
          className="p-academy-edit__description-edit"
          placeholder="内容を入力"
          toolbarStickyOffset={56}
          value={textHtml}
          onChangeValue={(e) => handleInputTextHtml(e)}
        />
      </div>
    </form>
  );
};
export default AcademyCourseLessonTextEdit;
