/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiScenesIdPutRequestAudioData
 */
export interface ApiScenesIdPutRequestAudioData {
    /**
     * 
     * @type {string}
     * @memberof ApiScenesIdPutRequestAudioData
     */
    voiceName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiScenesIdPutRequestAudioData
     */
    speechRate?: string;
}

/**
 * Check if a given object implements the ApiScenesIdPutRequestAudioData interface.
 */
export function instanceOfApiScenesIdPutRequestAudioData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiScenesIdPutRequestAudioDataFromJSON(json: any): ApiScenesIdPutRequestAudioData {
    return ApiScenesIdPutRequestAudioDataFromJSONTyped(json, false);
}

export function ApiScenesIdPutRequestAudioDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiScenesIdPutRequestAudioData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'voiceName': !exists(json, 'voice_name') ? undefined : json['voice_name'],
        'speechRate': !exists(json, 'speech_rate') ? undefined : json['speech_rate'],
    };
}

export function ApiScenesIdPutRequestAudioDataToJSON(value?: ApiScenesIdPutRequestAudioData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'voice_name': value.voiceName,
        'speech_rate': value.speechRate,
    };
}

