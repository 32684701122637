import { VFC, useState, FormEventHandler } from "react";
import ReactModal from "react-modal";
import { notify } from "../../notification";
import {
  ApiFolderApi,
  Configuration,
  ApiFoldersPost422Response,
} from "../../../generated/api";
import { isResponseError } from "../../../lib/isResponseError";

type Props = {
  folderId: number;
  folderName: string;
  csrfToken: string;
  onAfterClose(): void;
};

function requestFolderRename(
  folderId: number,
  name: string,
  csrfToken: string
) {
  const api = new ApiFolderApi(
    new Configuration({
      basePath: location.origin,
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiFoldersIdRenamePatch({
    id: folderId,
    apiFoldersIdRenamePatchRequest: { folder: { name } },
  });
}

export const RenameFolderModal: VFC<Props> = ({
  folderId,
  folderName,
  csrfToken,
  onAfterClose,
}) => {
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState(folderName);
  const [errMsg, setErrMsg] = useState("");

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    setErrMsg("");
    (async function () {
      try {
        await requestFolderRename(folderId, name, csrfToken);
        location.reload();
        setVisible(false);
      } catch (reason) {
        if (isResponseError(reason) && reason.response.status === 422) {
          const res: ApiFoldersPost422Response = await reason.response.json();
          setErrMsg(res.messages.name.join("\n"));
        } else {
          setVisible(false);
          notify(
            "エラーが発生しました。画面をリロードしてもう一度試してください。"
          );
          throw reason;
        }
      }
    })();
  };

  return (
    <ReactModal
      onAfterClose={onAfterClose}
      isOpen={visible}
      overlayClassName="c-dialog__overlay"
      className="c-dialog__container"
    >
      <div className="c-dialog__header">
        <h2 className="c-dialog__header__title">名称変更</h2>
        <button
          className="material-icons-round c-dialog__header__close-icon"
          onClick={() => setVisible(false)}
        >
          close
        </button>
      </div>
      {errMsg && <p className="c-dialog__err-msgs">{errMsg}</p>}
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="c-dialog__content">
          <input
            type="text"
            name="name"
            className="c-dialog__text-field-input"
            value={name}
            onChange={(e) => setName(e.target.value.trim())}
            autoFocus={true}
            autoComplete="off"
          />
        </div>
        <div className="c-dialog__footer">
          <button
            className="c-button--outlined narrow-padding"
            type="button"
            onClick={() => setVisible(false)}
          >
            キャンセル
          </button>
          <input
            className="c-button--primary narrow-padding"
            type="submit"
            value="変更"
            disabled={name.length <= 0 || name === folderName}
          />
        </div>
      </form>
    </ReactModal>
  );
};
