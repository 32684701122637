import {
  VFC,
  useState,
  useEffect,
  useRef,
  ChangeEventHandler,
  CSSProperties,
  FormEvent,
} from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  ApiExamApi,
  ApiExamQuestionApi,
  Configuration,
} from "../../generated/api";
import { videoPath } from "../../generated/routes";
import { notify } from "../notification";
import { useSubMenuVisible } from "../common/submenus/useSubMenuVisible";
import { isResponseError } from "../../lib/isResponseError";
import { SaveButton, SaveButtonHandler } from "../common/SaveButton";

type Exam = Awaited<ReturnType<ApiExamApi["apiVideoVideoIdExamGet"]>>;
type ExamQuestion = Awaited<
  ReturnType<ApiExamQuestionApi["apiVideoVideoIdExamQuestionsGet"]>
>[number];
type ExamQuestionWithKey = {
  key: string;
  id?: ExamQuestion["id"];
  text?: ExamQuestion["text"];
  options?: {
    key: string;
    text?: ExamQuestion["options"][number]["text"];
    correct?: ExamQuestion["options"][number]["correct"];
  }[];
};

interface Props {
  csrfToken: string;
  videoId: number;
  videoHashId: string;
  exam: Exam;
  examQuestions: ExamQuestion[];
}

export const ExamEdit: VFC<Props> = (props) => {
  const shouldConfirmBeforeUnload = useRef(false);
  const saveButtonRef = useRef<SaveButtonHandler>();
  const [published, setPublished] = useState(props.exam.published);
  const [inputName, setInputName] = useState(props.exam.inputName);
  const [examQuestions, setExamQuestions] = useState<ExamQuestionWithKey[]>(
    () => withKey(props.examQuestions)
  );

  shouldConfirmBeforeUnload.current ||= examQuestions.length > 0;
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      if (shouldConfirmBeforeUnload.current) {
        event.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
  }, []);

  const submit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    saveButtonRef.current.startSaving();
    try {
      const api = new ApiExamQuestionApi(
        new Configuration({
          basePath: "",
          headers: {
            "x-hopper-api-version": "1.0",
            "X-CSRF-Token": props.csrfToken,
          },
        })
      );
      const res = await api.apiVideoVideoIdExamQuestionsPut({
        videoId: props.videoId,
        apiVideoVideoIdExamQuestionsPutRequest: {
          examQuestions: withoutKey(examQuestions),
        },
      });
      setExamQuestions(withKey(res));
      shouldConfirmBeforeUnload.current = false;
      await saveButtonRef.current.finishSaving();
    } catch (reason) {
      let msg =
        "エラーが発生しました。画面をリロードしてもう一度試してください。";
      if (isResponseError(reason) && reason.response.status === 422) {
        // e.g. {"messages":{"foo":["fooは不正な値です"]}}
        const resBody: { messages?: Record<string, [string]> } =
          await reason.response.json().catch(() => null);
        msg =
          Object.values(resBody?.messages || {})
            .flat()
            .join("\n")
            .trim() || msg;
      }
      notify(msg);
      Rollbar.info(reason, msg);
    } finally {
      saveButtonRef.current.reset();
    }
  };

  const changePublished = (published: boolean) => {
    setPublished(published);
    const api = new ApiExamApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiVideoVideoIdExamPut({
        videoId: props.videoId,
        apiVideoVideoIdExamPutRequest: { published },
      })
      .then((exam) => {
        setPublished(exam.published);
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  const changeInputName = (inputName: boolean) => {
    setInputName(inputName);
    const api = new ApiExamApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiVideoVideoIdExamPut({
        videoId: props.videoId,
        apiVideoVideoIdExamPutRequest: { inputName },
      })
      .then((exam) => {
        setInputName(exam.inputName);
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  const addExamQuestion = () => {
    if (examQuestions.length >= 10) {
      Rollbar.info("テスト問題に10以上追加しようとした", {
        videoId: props.videoId,
        videoHashId: props.videoHashId,
      });
      notify("ひとつのテストに追加できる問題は10個までです。");
      return;
    }
    setExamQuestions((prev) => [
      ...prev,
      ...withKey([
        {
          text: "",
          options: [
            { text: "", correct: true },
            { text: "", correct: false },
            { text: "", correct: false },
          ],
        },
      ]),
    ]);
  };

  return (
    <form className="p-exam__container" onSubmit={submit}>
      <div className="p-exam__header">
        <a
          href={videoPath(props.videoHashId)}
          className="c-button--text p-exam__text--button"
        >
          キャンセル
        </a>

        <SaveButton ref={saveButtonRef} type="submit" />

        <PublishSwitch
          checked={published}
          onChange={(event) => changePublished(event.target.checked)}
        />
        <EditSubmenu
          onClickClear={() => {
            if (confirm("問題をすべてクリアします。")) {
              setExamQuestions([]);
            }
          }}
        />
      </div>
      <div className="p-exam__main">
        <label className="p-exam__input-name-control">
          <input
            className="p-exam__checkbox-input"
            type="checkbox"
            checked={inputName}
            onChange={(e) => changeInputName(e.target.checked)}
          />
          回答時に名前入力を求める
        </label>

        {examQuestions.length === 0 ? (
          <>「問題を追加」ボタンで問題を追加してください。</>
        ) : null}

        {examQuestions.map((examQuestion, index) => (
          <ExamQuestionItem
            key={examQuestion.key}
            questionNumber={index + 1}
            value={examQuestion}
            onChange={(newValue) => {
              setExamQuestions((prev) => {
                prev[index] = newValue;
                return [...prev];
              });
            }}
            onDelete={() => {
              setExamQuestions((prev) => {
                prev.splice(index, 1);
                return [...prev];
              });
            }}
            onMoveUp={
              index > 0
                ? () => {
                    setExamQuestions(
                      arrayMove(examQuestions, index, index - 1)
                    );
                  }
                : null
            }
            onMoveDown={
              index < examQuestions.length - 1
                ? () => {
                    setExamQuestions(
                      arrayMove(examQuestions, index, index + 1)
                    );
                  }
                : null
            }
          />
        ))}
      </div>

      <div className="p-exam__side-panel">
        <button
          type="button"
          className="c-button--outlined"
          onClick={addExamQuestion}
        >
          <i className="material-icons-round c-button__icon p-exam__icon--gray">
            add
          </i>
          問題を追加
        </button>
      </div>
    </form>
  );
};
export default ExamEdit;

/**
 * Reactコンポーネント内での各種制御のために、個々の値にkeyを付与する
 */
function withKey(examQuestions: ExamQuestion[]): ExamQuestionWithKey[] {
  return examQuestions.map((eq) => ({
    ...eq,
    key: crypto.randomUUID(),
    options: eq.options.map((o) => ({ ...o, key: crypto.randomUUID() })),
  }));
}

/**
 * リクエストパラメータとしては不要な key を除去する
 */
function withoutKey(examQuestions: ExamQuestionWithKey[]): ExamQuestion[] {
  return examQuestions.map((eq) => {
    // シャローコピーしつつ不要なフィールドを除去するために、未使用変数のlint警告を抑制する
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { key, ...rest } = eq;
    return {
      ...rest,
      options: eq.options.map((o) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { key, ...rest } = o;
        return rest;
      }),
    };
  });
}

const PublishSwitch: VFC<{
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}> = ({ checked, onChange }) => {
  return (
    <div>
      <input
        id="publish_switch"
        type="checkbox"
        className="c-toggle-switch__input"
        checked={checked}
        onChange={onChange}
      />
      <label
        htmlFor="publish_switch"
        className="c-toggle-switch__label"
        data-switch-on="公開"
        data-switch-off="非公開"
      />
    </div>
  );
};

const EditSubmenu: VFC<{ onClickClear: () => void }> = ({ onClickClear }) => {
  const [submenu, toggleSubmenu] = useSubMenuVisible();
  return (
    <div>
      <button
        type="button"
        className="material-icons-round c-submenu__button"
        onClick={() => toggleSubmenu()}
      >
        more_vert
      </button>
      <ul
        className="c-submenu__list c-submenu__list--align-left"
        hidden={!submenu}
      >
        <li className="c-submenu__list-item danger">
          <button type="reset" onClick={onClickClear}>
            <i className="material-icons-round c-submenu__list-item-icon">
              clear_all
            </i>
            すべての問題をクリア
          </button>
        </li>
      </ul>
    </div>
  );
};

const ExamQuestionItem: VFC<{
  questionNumber: number;
  value: ExamQuestionWithKey;
  onChange: (newValue: ExamQuestionWithKey) => void;
  onDelete: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}> = (props) => {
  const addOptionButtonRef = useRef<HTMLButtonElement>();

  useEffect(() => {
    addOptionButtonRef.current.setCustomValidity(
      props.value.options.length >= 2 ? "" : "回答は2つ以上必要です。"
    );
  }, [props.value.options.length]);

  return (
    <div className="p-exam__question-container">
      <div className="p-exam__question-inner">
        <p className="p-exam__question-heading">問題 {props.questionNumber}</p>
        <label className="p-exam__question-label">問題文</label>
        <textarea
          required
          maxLength={1000}
          rows={3}
          value={props.value.text}
          autoFocus={!props.value.text} // 新規追加時に自動フォーカス
          onChange={(event) => {
            props.onChange({ ...props.value, text: event.target.value });
          }}
        />

        <label className="p-exam__question-label">回答</label>
        <ExamQuestionOptions
          value={props.value.options}
          onChange={(newOptions) => {
            props.value.options = newOptions;
            props.onChange({ ...props.value });
          }}
        />
        <div className="p-exam__question-option-add-button-outer">
          <button
            className="c-button--text narrow-padding"
            ref={addOptionButtonRef}
            disabled={props.value.options.length >= 10}
            onClick={(e) => {
              // 意図しないサブミット回避のため type=button にしたいが、カスタムバリデーションを使うためそのままにする。
              // 代わりに preventDefault() でサブミットを回避している。
              e.preventDefault();
              props.onChange({
                ...props.value,
                options: [
                  ...props.value.options,
                  { text: null, correct: false, key: crypto.randomUUID() },
                ],
              });
            }}
          >
            <i className="material-icons-round c-button__icon p-exam__icon--gray">
              add
            </i>
            回答を追加
          </button>
        </div>
      </div>

      <div className="p-exam__question-side-button-container">
        <button
          type="button"
          className="material-icons-round p-exam__icon-button"
          title="上と入れ替え"
          onClick={props.onMoveUp}
          disabled={!props.onMoveUp}
        >
          arrow_drop_up
        </button>
        <button
          type="button"
          className="material-icons-round p-exam__icon-button"
          title="下と入れ替え"
          onClick={props.onMoveDown}
          disabled={!props.onMoveDown}
        >
          arrow_drop_down
        </button>
        <button
          type="button"
          className="material-icons-round p-exam__icon-button"
          title="削除"
          onClick={props.onDelete}
        >
          delete_outline
        </button>
      </div>
    </div>
  );
};

const ExamQuestionOptions: VFC<{
  value: ExamQuestionWithKey["options"];
  onChange(newValue: ExamQuestionWithKey["options"]): void;
}> = (props) => {
  const [radioButtonName] = useState(
    () => `exam-question-options-${crypto.randomUUID()}`
  );
  const dndSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = props.value.findIndex((o) => o.key === active.id);
      const newIndex = props.value.findIndex((o) => o.key === over.id);
      props.onChange(arrayMove(props.value, oldIndex, newIndex));
    }
  }

  return (
    <DndContext
      sensors={dndSensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={props.value.map((o) => o.key)}
        strategy={verticalListSortingStrategy}
      >
        {props.value.map((option, index) => (
          <ExamQuestionOptionsItem
            key={option.key}
            radioButtonName={radioButtonName}
            value={option}
            onChangeText={(newText) => {
              props.value[index].text = newText;
              props.onChange([...props.value]);
            }}
            onChangeCorrect={(newCorrect) => {
              props.value.forEach((o) => (o.correct = false));
              props.value[index].correct = newCorrect;
              props.onChange([...props.value]);
            }}
            onDelete={() => {
              props.value.splice(index, 1);
              props.onChange([...props.value]);
            }}
            isTextDuplicated={props.value
              .slice(0, index) // ダブっているうち後の方にバリデーションエラーを表示する
              .some((o) => o.text === option.text)}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

const ExamQuestionOptionsItem: VFC<{
  radioButtonName: string;
  value: ExamQuestionWithKey["options"][number];
  onChangeText(newText: string): void;
  onChangeCorrect(newCorrect: boolean): void;
  onDelete(): void;
  isTextDuplicated: boolean;
}> = (props) => {
  const textfieldRef = useRef<HTMLInputElement>();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.value.key });
  const rowStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    textfieldRef.current.setCustomValidity(
      props.isTextDuplicated ? "回答が重複しています。" : ""
    );
  }, [props.isTextDuplicated]);

  return (
    <div
      className="p-exam__question-option-item"
      ref={setNodeRef}
      style={rowStyle}
    >
      <button
        type="button"
        className="material-icons-round p-exam__icon-button"
        {...attributes}
        {...listeners}
        style={{ cursor: transform ? "grabbing" : "grab" }}
      >
        drag_handle
      </button>
      <input
        ref={textfieldRef}
        className="p-exam__question-option-item-textfield"
        type="text"
        required
        maxLength={200}
        // 簡易なautoFocus実装のため、ややhackyな実装をしている。
        // 問題の追加と衝突しないよう、回答の追加でのみ text:null を設定させ区別する。
        // autoFocus制御は必須な非機能要件ではないので、リファクタなどでこの方法が使えなくなったら諦めてよい。
        value={props.value.text ?? ""}
        autoFocus={props.value.text == null}
        onChange={(event) => {
          props.onChangeText(event.target.value);
        }}
      />
      <span
        className="p-exam__question-option-item-correct-label"
        role="none"
        style={{ visibility: props.value.correct ? null : "hidden" }}
      >
        正解
      </span>
      <input
        className="p-exam__question-option-item-checkbox"
        type="radio"
        required
        name={props.radioButtonName}
        checked={props.value.correct}
        onChange={(e) => {
          props.onChangeCorrect(e.target.checked);
        }}
      />
      <button
        type="button"
        className="material-icons-round p-exam__icon-button"
        onClick={() => {
          props.onDelete();
        }}
      >
        delete_outline
      </button>
    </div>
  );
};
