/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio
 */
export interface ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio
     */
    blobName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio
     */
    voiceName?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio
     */
    durationMs?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio
     */
    speechRate?: string;
}

/**
 * Check if a given object implements the ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio interface.
 */
export function instanceOfApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudioFromJSON(json: any): ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio {
    return ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudioFromJSONTyped(json, false);
}

export function ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudioFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blobName': !exists(json, 'blob_name') ? undefined : json['blob_name'],
        'voiceName': !exists(json, 'voice_name') ? undefined : json['voice_name'],
        'durationMs': !exists(json, 'duration_ms') ? undefined : json['duration_ms'],
        'speechRate': !exists(json, 'speech_rate') ? undefined : json['speech_rate'],
    };
}

export function ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudioToJSON(value?: ApiVideoVideoIdNarrationsPutRequestNarrationsInnerAudio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blob_name': value.blobName,
        'voice_name': value.voiceName,
        'duration_ms': value.durationMs,
        'speech_rate': value.speechRate,
    };
}

