import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { SubtitlesCue } from "./useSubtitlesCues";

type CurrentSubtitlesCue = {
  currentCue: SubtitlesCue;
  setCurrentTimeMs: Dispatch<SetStateAction<number>>;
};

export function useCurrentSubtitlesCue(
  cues: SubtitlesCue[]
): CurrentSubtitlesCue {
  const [currentCue, setCurrentCue] = useState<SubtitlesCue>(null);
  const [currentTimeMs, setCurrentTimeMs] = useState(0);
  useEffect(() => {
    setCurrentCue(
      cues.find(
        (cue) =>
          cue.startTimeMs <= currentTimeMs && currentTimeMs <= cue.endTimeMs
      )
    );
  }, [currentTimeMs, cues]);
  return {
    currentCue,
    setCurrentTimeMs,
  };
}
