/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner
 */
export interface ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner {
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner
     */
    videoId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner
     */
    startMs?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner
     */
    durationMs?: number;
}

/**
 * Check if a given object implements the ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner interface.
 */
export function instanceOfApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInnerFromJSON(json: any): ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner {
    return ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInnerFromJSONTyped(json, false);
}

export function ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'videoId': !exists(json, 'video_id') ? undefined : json['video_id'],
        'startMs': !exists(json, 'start_ms') ? undefined : json['start_ms'],
        'durationMs': !exists(json, 'duration_ms') ? undefined : json['duration_ms'],
    };
}

export function ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInnerToJSON(value?: ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'video_id': value.videoId,
        'start_ms': value.startMs,
        'duration_ms': value.durationMs,
    };
}

