/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiAcademyCourseLessonsIdPatchRequestCourseLesson } from './ApiAcademyCourseLessonsIdPatchRequestCourseLesson';
import {
    ApiAcademyCourseLessonsIdPatchRequestCourseLessonFromJSON,
    ApiAcademyCourseLessonsIdPatchRequestCourseLessonFromJSONTyped,
    ApiAcademyCourseLessonsIdPatchRequestCourseLessonToJSON,
} from './ApiAcademyCourseLessonsIdPatchRequestCourseLesson';

/**
 * 
 * @export
 * @interface ApiAcademyCourseLessonsIdPatchRequest
 */
export interface ApiAcademyCourseLessonsIdPatchRequest {
    /**
     * 
     * @type {ApiAcademyCourseLessonsIdPatchRequestCourseLesson}
     * @memberof ApiAcademyCourseLessonsIdPatchRequest
     */
    courseLesson?: ApiAcademyCourseLessonsIdPatchRequestCourseLesson;
}

/**
 * Check if a given object implements the ApiAcademyCourseLessonsIdPatchRequest interface.
 */
export function instanceOfApiAcademyCourseLessonsIdPatchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCourseLessonsIdPatchRequestFromJSON(json: any): ApiAcademyCourseLessonsIdPatchRequest {
    return ApiAcademyCourseLessonsIdPatchRequestFromJSONTyped(json, false);
}

export function ApiAcademyCourseLessonsIdPatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCourseLessonsIdPatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'courseLesson': !exists(json, 'course_lesson') ? undefined : ApiAcademyCourseLessonsIdPatchRequestCourseLessonFromJSON(json['course_lesson']),
    };
}

export function ApiAcademyCourseLessonsIdPatchRequestToJSON(value?: ApiAcademyCourseLessonsIdPatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'course_lesson': ApiAcademyCourseLessonsIdPatchRequestCourseLessonToJSON(value.courseLesson),
    };
}

