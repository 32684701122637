/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInner } from './ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInner';
import {
    ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInnerFromJSON,
    ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInnerFromJSONTyped,
    ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInnerToJSON,
} from './ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInner';

/**
 * 
 * @export
 * @interface ApiVideoVideoIdExamQuestionsPutRequest
 */
export interface ApiVideoVideoIdExamQuestionsPutRequest {
    /**
     * 
     * @type {Array<ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInner>}
     * @memberof ApiVideoVideoIdExamQuestionsPutRequest
     */
    examQuestions?: Array<ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInner>;
}

/**
 * Check if a given object implements the ApiVideoVideoIdExamQuestionsPutRequest interface.
 */
export function instanceOfApiVideoVideoIdExamQuestionsPutRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdExamQuestionsPutRequestFromJSON(json: any): ApiVideoVideoIdExamQuestionsPutRequest {
    return ApiVideoVideoIdExamQuestionsPutRequestFromJSONTyped(json, false);
}

export function ApiVideoVideoIdExamQuestionsPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdExamQuestionsPutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'examQuestions': !exists(json, 'exam_questions') ? undefined : ((json['exam_questions'] as Array<any>).map(ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInnerFromJSON)),
    };
}

export function ApiVideoVideoIdExamQuestionsPutRequestToJSON(value?: ApiVideoVideoIdExamQuestionsPutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exam_questions': value.examQuestions === undefined ? undefined : ((value.examQuestions as Array<any>).map(ApiVideoVideoIdExamQuestionsPutRequestExamQuestionsInnerToJSON)),
    };
}

