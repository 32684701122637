import { FC, useContext, useState, useEffect, ReactNode } from "react";
import { TreeViewThemeContext } from "./TreeThemeContext";
import { TreeViewContext } from "./TreeViewContext";
import { DescendantsContext } from "./DescendantsContext";
import { ITEM_ID } from "./TreeViewTypes";

type Props = {
  itemId: ITEM_ID;
  label: string;
  icon?: ReactNode;
  children?: ReactNode;
};

export const TreeItem: FC<Props> = ({ label, itemId, icon, children }) => {
  const { icon: themeIcon } = useContext(TreeViewThemeContext);
  const { depth, expandIfChildSelected } = useContext(DescendantsContext);
  const { selectedItemId, selectItem } = useContext(TreeViewContext);
  const [expanded, setExpanded] = useState(false);

  icon ??= themeIcon; // 個別指定がなければThemeContextのアイコンを使う。
  const selected = selectedItemId === itemId;
  const selectedClassName = selected ? "is-selected" : "";
  const expandedClassName = expanded ? "is-expanded" : "";

  useEffect(() => {
    if (selected) {
      expandIfChildSelected();
    }
    // 初期表示時のみ子が選択状態であることを親に通知してツリーを展開させる。
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li className="c-treeview__list-item">
      <div
        className={`c-treeview__content ${selectedClassName}`}
        onClick={() => selectItem(itemId)}
        style={{ paddingInlineStart: `${depth * 24}px` }}
      >
        <button
          type="button"
          className={`material-icons-round c-treeview__handle ${expandedClassName}`}
          style={children ? null : { visibility: "hidden" }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          arrow_right
        </button>
        {icon && <span className="c-treeview__icon">{icon}</span>}
        <span className="c-treeview__label" title={label}>
          {label}
        </span>
      </div>
      {children && (
        <ul
          className="c-treeview__list"
          style={expanded ? null : { display: "none" }}
        >
          <DescendantsContext.Provider
            value={{
              depth: depth + 1,
              expandIfChildSelected() {
                expandIfChildSelected();
                setExpanded(true);
              },
            }}
          >
            {children}
          </DescendantsContext.Provider>
        </ul>
      )}
    </li>
  );
};
