import { VFC } from "react";

type ExamAnswer = { examQuestionId: number; text: string };

interface Props {
  questionText: string;
  questionNumber: number;
  examQuestionId: number;
  options: { text: string }[];
  selectedAnswer: ExamAnswer;
  onSelect: (answer: ExamAnswer) => void;
}

export const ExamQuestion: VFC<Props> = (props) => {
  return (
    <section className="p-exam-answer-question">
      <p className="p-exam-answer-question__heading">
        問題{props.questionNumber}
      </p>
      <p className="p-exam-answer-question__text">{props.questionText}</p>
      {props.options.map(({ text }, index) => (
        <div key={index} className="p-exam-answer-option">
          <input
            type="radio"
            value={text}
            name={`${props.examQuestionId}`}
            id={`question-${props.examQuestionId}-${index}`}
            checked={text === props.selectedAnswer?.text}
            className="p-exam-answer-option__input"
            onChange={() =>
              props.onSelect({ examQuestionId: props.examQuestionId, text })
            }
          />
          <label
            htmlFor={`question-${props.examQuestionId}-${index}`}
            className="p-exam-answer-option__label"
          >
            {text}
          </label>
        </div>
      ))}
    </section>
  );
};
