/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiWordPronounciationsIdPutRequest
 */
export interface ApiWordPronounciationsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsIdPutRequest
     */
    phoneme?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsIdPutRequest
     */
    literal?: string;
}

/**
 * Check if a given object implements the ApiWordPronounciationsIdPutRequest interface.
 */
export function instanceOfApiWordPronounciationsIdPutRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiWordPronounciationsIdPutRequestFromJSON(json: any): ApiWordPronounciationsIdPutRequest {
    return ApiWordPronounciationsIdPutRequestFromJSONTyped(json, false);
}

export function ApiWordPronounciationsIdPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWordPronounciationsIdPutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneme': !exists(json, 'phoneme') ? undefined : json['phoneme'],
        'literal': !exists(json, 'literal') ? undefined : json['literal'],
    };
}

export function ApiWordPronounciationsIdPutRequestToJSON(value?: ApiWordPronounciationsIdPutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phoneme': value.phoneme,
        'literal': value.literal,
    };
}

