/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner
 */
export interface ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner {
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner
     */
    time?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner
     */
    easing?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner
     */
    reversed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner
     */
    type?: string;
}

/**
 * Check if a given object implements the ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner interface.
 */
export function instanceOfApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerFromJSON(json: any): ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner {
    return ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerFromJSONTyped(json, false);
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : json['time'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'easing': !exists(json, 'easing') ? undefined : json['easing'],
        'reversed': !exists(json, 'reversed') ? undefined : json['reversed'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInnerToJSON(value?: ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerAnimationsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'duration': value.duration,
        'easing': value.easing,
        'reversed': value.reversed,
        'type': value.type,
    };
}

