/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiWordPronounciationsPostRequest
 */
export interface ApiWordPronounciationsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsPostRequest
     */
    literal?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsPostRequest
     */
    phoneme?: string;
}

/**
 * Check if a given object implements the ApiWordPronounciationsPostRequest interface.
 */
export function instanceOfApiWordPronounciationsPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiWordPronounciationsPostRequestFromJSON(json: any): ApiWordPronounciationsPostRequest {
    return ApiWordPronounciationsPostRequestFromJSONTyped(json, false);
}

export function ApiWordPronounciationsPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWordPronounciationsPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'literal': !exists(json, 'literal') ? undefined : json['literal'],
        'phoneme': !exists(json, 'phoneme') ? undefined : json['phoneme'],
    };
}

export function ApiWordPronounciationsPostRequestToJSON(value?: ApiWordPronounciationsPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'literal': value.literal,
        'phoneme': value.phoneme,
    };
}

