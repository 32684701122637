import { VFC, useState, ChangeEventHandler } from "react";
import loadingImg from "images/loading.svg";
import { ApiAcademyLogoApi, Configuration } from "../../generated/api";
import { BlockBlobClient } from "@azure/storage-blob";

interface Props {
  csrfToken: string;
  academyId: number;
  imgUrl?: string;
}

const acceptMimeTypes = ["image/jpeg", "image/png"];
type ErrorType = "fileSize" | "fileType" | "unknown";

export const AcademyLogoEdit: VFC<Props> = (props) => {
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [uploading, setUploading] = useState(false);

  const fileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setErrors([]);
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setUploading(true);
    const errors = validateFile(file);
    if (errors.length > 0) {
      setErrors(errors);
      setUploading(false);
      return;
    }

    // アップロード
    uploadImage(props, file)
      .then(() => {
        location.reload();
      })
      .catch((reason) => {
        console.error(reason);
        setErrors(["unknown"]);
        setUploading(false);
      });
  };

  return (
    <div className="p-academy-edit__logo">
      <p className="p-academy-edit__logo-notes">
        ロゴ画像（jpeg, png / 1MB以下 / 推奨サイズ：縦72px以上）
      </p>
      {errors.length > 0 && (
        <div className="p-academy-edit__logo-error">
          アップロードできませんでした。
          <br />
          {errors
            .map((errorType) => {
              const errMsgs: Record<ErrorType, string> = {
                fileSize: "ファイルサイズを1MB以下にして再度お試しください。",
                fileType:
                  "JPEGまたはPNG形式のファイルをアップロードしてください。",
                unknown: "画面をリロードしてもう一度お試しください。",
              };
              return errMsgs[errorType] ?? errMsgs.unknown;
            })
            .join("\n")}
        </div>
      )}
      <label
        className="p-academy-edit__logo-img-label"
        title="クリックしてロゴ画像をアップロードする"
      >
        {props.imgUrl ? (
          <img src={props.imgUrl} />
        ) : (
          <p>ロゴ画像が選択されていません。</p>
        )}
        <input
          type="file"
          accept={acceptMimeTypes.join(",")}
          onChange={fileChange}
          disabled={uploading}
        />

        {uploading && (
          <>
            <div className="p-academy-edit__logo-uploading">
              <img
                src={loadingImg}
                className="p-academy-edit__logo-uploading-progress"
              />
            </div>
            <style>{`body { cursor: progress; }`}</style>
          </>
        )}
      </label>
    </div>
  );
};
export default AcademyLogoEdit;

async function uploadImage(props: Props, file: File): Promise<void> {
  const api = new ApiAcademyLogoApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": props.csrfToken,
      },
    })
  );

  if (props.imgUrl) {
    await api.apiAcademyLogoDelete();
  }

  const res = await api.apiAcademyLogoPresignGet();

  const blobClient = new BlockBlobClient(res.endpoint);
  await blobClient.uploadData(file, {
    blobHTTPHeaders: { blobContentType: file.type },
  });

  const url = new URL(res.endpoint);
  const [, assetContainer, assetName] = url.pathname.split("/");
  await api.apiAcademyLogoPost({
    assetContainer,
    assetName,
  });
}

function validateFile(file: File) {
  const errors: ErrorType[] = [];
  if (file.size > 1024 * 1024) {
    errors.push("fileSize");
  }
  if (!acceptMimeTypes.includes(file.type)) {
    errors.push("fileType");
  }
  return errors;
}
