/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiAcademyCoursesIdPatchRequestAcademyCourse } from './ApiAcademyCoursesIdPatchRequestAcademyCourse';
import {
    ApiAcademyCoursesIdPatchRequestAcademyCourseFromJSON,
    ApiAcademyCoursesIdPatchRequestAcademyCourseFromJSONTyped,
    ApiAcademyCoursesIdPatchRequestAcademyCourseToJSON,
} from './ApiAcademyCoursesIdPatchRequestAcademyCourse';

/**
 * 
 * @export
 * @interface ApiAcademyCoursesIdPatchRequest
 */
export interface ApiAcademyCoursesIdPatchRequest {
    /**
     * 
     * @type {ApiAcademyCoursesIdPatchRequestAcademyCourse}
     * @memberof ApiAcademyCoursesIdPatchRequest
     */
    academyCourse?: ApiAcademyCoursesIdPatchRequestAcademyCourse;
}

/**
 * Check if a given object implements the ApiAcademyCoursesIdPatchRequest interface.
 */
export function instanceOfApiAcademyCoursesIdPatchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCoursesIdPatchRequestFromJSON(json: any): ApiAcademyCoursesIdPatchRequest {
    return ApiAcademyCoursesIdPatchRequestFromJSONTyped(json, false);
}

export function ApiAcademyCoursesIdPatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCoursesIdPatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'academyCourse': !exists(json, 'academy_course') ? undefined : ApiAcademyCoursesIdPatchRequestAcademyCourseFromJSON(json['academy_course']),
    };
}

export function ApiAcademyCoursesIdPatchRequestToJSON(value?: ApiAcademyCoursesIdPatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'academy_course': ApiAcademyCoursesIdPatchRequestAcademyCourseToJSON(value.academyCourse),
    };
}

