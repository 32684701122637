/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAcademyCourseLessonsIdGet200Response,
  ApiAcademyCourseLessonsIdPatch202Response,
  ApiAcademyCourseLessonsIdPatchRequest,
  ApiAcademyCourseLessonsPost201Response,
  ApiAcademyCourseLessonsPost422Response,
  ApiAcademyCourseLessonsPostRequest,
} from '../models';
import {
    ApiAcademyCourseLessonsIdGet200ResponseFromJSON,
    ApiAcademyCourseLessonsIdGet200ResponseToJSON,
    ApiAcademyCourseLessonsIdPatch202ResponseFromJSON,
    ApiAcademyCourseLessonsIdPatch202ResponseToJSON,
    ApiAcademyCourseLessonsIdPatchRequestFromJSON,
    ApiAcademyCourseLessonsIdPatchRequestToJSON,
    ApiAcademyCourseLessonsPost201ResponseFromJSON,
    ApiAcademyCourseLessonsPost201ResponseToJSON,
    ApiAcademyCourseLessonsPost422ResponseFromJSON,
    ApiAcademyCourseLessonsPost422ResponseToJSON,
    ApiAcademyCourseLessonsPostRequestFromJSON,
    ApiAcademyCourseLessonsPostRequestToJSON,
} from '../models';

export interface ApiAcademyCourseLessonsIdDeleteRequest {
    id: number;
}

export interface ApiAcademyCourseLessonsIdGetRequest {
    id: number;
}

export interface ApiAcademyCourseLessonsIdPatchOperationRequest {
    id: number;
    apiAcademyCourseLessonsIdPatchRequest?: ApiAcademyCourseLessonsIdPatchRequest;
}

export interface ApiAcademyCourseLessonsPostOperationRequest {
    apiAcademyCourseLessonsPostRequest?: ApiAcademyCourseLessonsPostRequest;
}

/**
 * 
 */
export class ApiAcademyCourseLessonApi extends runtime.BaseAPI {

    /**
     * destroy
     */
    async apiAcademyCourseLessonsIdDeleteRaw(requestParameters: ApiAcademyCourseLessonsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCourseLessonsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/course_lessons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiAcademyCourseLessonsIdDelete(requestParameters: ApiAcademyCourseLessonsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiAcademyCourseLessonsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * show
     */
    async apiAcademyCourseLessonsIdGetRaw(requestParameters: ApiAcademyCourseLessonsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCourseLessonsIdGet200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCourseLessonsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/course_lessons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCourseLessonsIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiAcademyCourseLessonsIdGet(requestParameters: ApiAcademyCourseLessonsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCourseLessonsIdGet200Response> {
        const response = await this.apiAcademyCourseLessonsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiAcademyCourseLessonsIdPatchRaw(requestParameters: ApiAcademyCourseLessonsIdPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCourseLessonsIdPatch202Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCourseLessonsIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/academy/course_lessons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAcademyCourseLessonsIdPatchRequestToJSON(requestParameters.apiAcademyCourseLessonsIdPatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCourseLessonsIdPatch202ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiAcademyCourseLessonsIdPatch(requestParameters: ApiAcademyCourseLessonsIdPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCourseLessonsIdPatch202Response> {
        const response = await this.apiAcademyCourseLessonsIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create
     */
    async apiAcademyCourseLessonsPostRaw(requestParameters: ApiAcademyCourseLessonsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCourseLessonsPost201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/academy/course_lessons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAcademyCourseLessonsPostRequestToJSON(requestParameters.apiAcademyCourseLessonsPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCourseLessonsPost201ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiAcademyCourseLessonsPost(requestParameters: ApiAcademyCourseLessonsPostOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCourseLessonsPost201Response> {
        const response = await this.apiAcademyCourseLessonsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
