/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiAcademyCourseLessonsPostRequestCourseLesson } from './ApiAcademyCourseLessonsPostRequestCourseLesson';
import {
    ApiAcademyCourseLessonsPostRequestCourseLessonFromJSON,
    ApiAcademyCourseLessonsPostRequestCourseLessonFromJSONTyped,
    ApiAcademyCourseLessonsPostRequestCourseLessonToJSON,
} from './ApiAcademyCourseLessonsPostRequestCourseLesson';

/**
 * 
 * @export
 * @interface ApiAcademyCourseLessonsPostRequest
 */
export interface ApiAcademyCourseLessonsPostRequest {
    /**
     * 
     * @type {ApiAcademyCourseLessonsPostRequestCourseLesson}
     * @memberof ApiAcademyCourseLessonsPostRequest
     */
    courseLesson?: ApiAcademyCourseLessonsPostRequestCourseLesson;
}

/**
 * Check if a given object implements the ApiAcademyCourseLessonsPostRequest interface.
 */
export function instanceOfApiAcademyCourseLessonsPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCourseLessonsPostRequestFromJSON(json: any): ApiAcademyCourseLessonsPostRequest {
    return ApiAcademyCourseLessonsPostRequestFromJSONTyped(json, false);
}

export function ApiAcademyCourseLessonsPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCourseLessonsPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'courseLesson': !exists(json, 'course_lesson') ? undefined : ApiAcademyCourseLessonsPostRequestCourseLessonFromJSON(json['course_lesson']),
    };
}

export function ApiAcademyCourseLessonsPostRequestToJSON(value?: ApiAcademyCourseLessonsPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'course_lesson': ApiAcademyCourseLessonsPostRequestCourseLessonToJSON(value.courseLesson),
    };
}

