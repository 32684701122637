import { useEffect, useRef, useState } from "react";
import { Muxer, ArrayBufferTarget } from "mp4-muxer";
import { ApiVideoApi, Configuration } from "../../../generated/api";
import { BlockBlobClient } from "@azure/storage-blob";
import loadingImg from "images/loading.svg";
import { loadVideoDuration } from "../../../lib/loadVideoDuration";

const VIDEO_ENCODER_CONFIG: VideoEncoderConfig = {
  codec: "avc1.4d002a", // H.264
  hardwareAcceleration: "prefer-hardware",
  width: 1920,
  height: 1080,
  bitrate: 6_000_000, // 6Mbps
  framerate: 30,
};
const AUDIO_ENCODER_CONFIG: AudioEncoderConfig = {
  codec: "mp4a.40.2", // AAC-LC
  sampleRate: 48000,
  numberOfChannels: 1,
};

interface Props {
  csrfToken: string;
  folderId?: number;
}

function RecordingContainer(props: Props) {
  const [encodable, setEncodable] = useState(null);
  const [micAllowed, setMicAllowed] = useState(null);

  useEffect(() => {
    Promise.all([
      window.VideoEncoder?.isConfigSupported(VIDEO_ENCODER_CONFIG).then(
        (result) => result.supported
      ),
      window.AudioEncoder?.isConfigSupported(AUDIO_ENCODER_CONFIG).then(
        (result) => result.supported
      ),
    ]).then(([videoSupported, audioSupported]) => {
      setEncodable(!!videoSupported && !!audioSupported);
    });
  }, []);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setMicAllowed(true);
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch((error) => {
        console.error(error);
        setMicAllowed(false);
      });
  }, []);

  if (encodable === null && micAllowed === null) {
    return (
      <div className="p-recording">
        <img src={loadingImg} width={24} height={24} />
      </div>
    );
  }

  if (encodable === false) {
    return (
      <div className="p-recording">
        <p>収録できません。最新版Google Chromeをお試しください。</p>
      </div>
    );
  }

  if (micAllowed === false) {
    return (
      <div className="p-recording">
        <p>マイクの権限を許可してください。</p>
      </div>
    );
  }

  return <Recording {...props} />;
}
export default RecordingContainer;

type RecordingState = null | "recording" | "uploading";

export function Recording(props: Props) {
  const shouldConfirmBeforeUnload = useRef(false);
  const recordingRef = useRef<{
    startTimestamp: Date;
    title: string;
    muxer: Muxer<ArrayBufferTarget>;
    videoEncoder: VideoEncoder;
    audioEncoder: AudioEncoder;
    videoStream: MediaStream;
    audioStream: MediaStream;
    timeoutId: ReturnType<typeof setTimeout>;
  }>(null);
  const [state, setState] = useState<RecordingState>(null);
  const [withAudio, setWithAudio] = useState(true);

  // ページ離脱確認
  shouldConfirmBeforeUnload.current = state != null;
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      if (shouldConfirmBeforeUnload.current) {
        event.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
  }, []);

  async function startRecording(withAudio: boolean) {
    const { videoStream, width, height } = await getVideoStream();
    if (!videoStream) {
      setState(null);
      return;
    }

    setState("recording");

    const audioStream = withAudio
      ? await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
      : null;

    // Muxerの設定
    const muxer = new Muxer({
      target: new ArrayBufferTarget(),
      video: {
        codec: "avc",
        width,
        height,
      },
      audio: withAudio
        ? {
            codec: "aac",
            sampleRate: AUDIO_ENCODER_CONFIG.sampleRate,
            numberOfChannels: AUDIO_ENCODER_CONFIG.numberOfChannels,
          }
        : null,
      fastStart: false,
      firstTimestampBehavior: "offset",
    });

    // エンコーダーの設定
    const videoEncoder = new VideoEncoder({
      output: (chunk, metadata) => {
        muxer.addVideoChunk(chunk, metadata);
      },
      error: (error) => {
        console.error("VideoEncoder", error);
      },
    });
    videoEncoder.configure({
      ...VIDEO_ENCODER_CONFIG,
      width,
      height,
    });
    const audioEncoder = withAudio
      ? new AudioEncoder({
          output: (chunk, metadata) => {
            muxer.addAudioChunk(chunk, metadata);
          },
          error: (error) => {
            console.error("AudioEncoder", error);
          },
        })
      : null;
    audioEncoder?.configure(AUDIO_ENCODER_CONFIG);

    // 2時間後に自動停止
    const timeoutId = setTimeout(() => {
      stopRecording();
    }, 2 * 3600 * 1000);

    recordingRef.current = {
      startTimestamp: new Date(),
      title: currentTimestampStr(), // 収録開始時点の日時をタイトルにする
      muxer,
      videoEncoder,
      audioEncoder,
      videoStream,
      audioStream,
      timeoutId,
    };

    // エンコーディング開始。並列処理して欲しいのでawaitしない。
    processVideoEncoding(
      videoEncoder,
      videoStream.getVideoTracks()[0] as MediaStreamVideoTrack
    );
    if (audioEncoder && audioStream) {
      processAudioEncoding(
        audioEncoder,
        audioStream.getAudioTracks()[0] as MediaStreamAudioTrack
      );
    }

    // 「画面共有の停止」が行われた時のイベントを拾う。
    videoStream.getTracks().forEach((track) => {
      track.addEventListener("ended", () => {
        console.debug("Video track ended", track.id, track.label);
        stopRecording();
      });
    });
  }

  async function stopRecording() {
    setState("uploading");
    clearTimeout(recordingRef.current.timeoutId);

    const { muxer, videoEncoder, audioEncoder, videoStream, audioStream } =
      recordingRef.current;

    console.debug("Flushing encoders...");
    await Promise.all([videoEncoder.flush(), audioEncoder?.flush()]);
    muxer.finalize();
    videoStream.getTracks().forEach((track) => track.stop());
    audioStream?.getTracks().forEach((track) => track.stop());
    console.debug("Recording stopped");

    const recordedDurationSec =
      (new Date().getTime() - recordingRef.current.startTimestamp.getTime()) /
      1000;
    console.log("Recorded duration:", recordedDurationSec, "sec");

    const blob = new Blob([muxer.target.buffer], { type: "video/mp4" });

    // ダウンロードするデバッグ用コード
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.setAttribute("href", url);
    // a.setAttribute("download", "video.mp4");
    // a.click();
    // URL.revokeObjectURL(url);

    const res = await uploadVideo(props, recordingRef.current.title, blob);
    const path = res.raw.headers.get("Location");
    console.debug("Uploaded to", path);

    setState(null);
    recordingRef.current = null;
    shouldConfirmBeforeUnload.current = false;
    location.assign(path);
  }

  return (
    <div className="p-recording">
      <h2 className="p-recording__title">新規収録</h2>

      <button
        className="c-button--primary"
        onClick={() => startRecording(withAudio)}
        disabled={state != null}
      >
        <i className="material-icons-round c-button__icon">videocam</i>
        収録開始
      </button>

      <details className="p-recording-settings" open={true}>
        <summary className="p-recording-settings__title">収録設定</summary>

        <label className="p-recording-settings__item">
          <input
            className="p-recording-settings__item-checkbox"
            type="checkbox"
            checked={withAudio}
            onChange={(e) => setWithAudio(e.target.checked)}
            disabled={state != null}
          />
          音声あり
        </label>
      </details>

      {state != null && (
        <div className="p-recording-progress">
          <div className="p-recording-progress__box">
            {state === "recording" && (
              <>
                <p className="p-recording-progress__title">収録中…</p>
                <button
                  className="c-button--outlined"
                  onClick={() => stopRecording()}
                  disabled={!state}
                >
                  <i className="material-icons-round c-button__icon">stop</i>
                  収録停止
                </button>
              </>
            )}
            {state === "uploading" && (
              <>
                <p className="p-recording-progress__title">アップロード中…</p>
                <img
                  src={loadingImg}
                  className="p-recording-progress__loading"
                />
                <style>{`html { cursor: progress; }`}</style>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

async function getVideoStream(): Promise<{
  videoStream: MediaStream;
  width: number;
  height: number;
}> {
  const videoStream: MediaStream = await navigator.mediaDevices
    .getDisplayMedia({
      video: {
        width: { max: 1920 },
        height: { max: 1080 },
        frameRate: { ideal: 30, max: 30 },
      },
    })
    .catch((e) => {
      if (e.name === "NotAllowedError" && e.message === "Permission denied") {
        console.debug(e);
        return null; // ユーザーがキャンセル操作した場合なので未選択扱い。
      }
      // システムで画面収録を許可していない場合は「Permission denied by system」になる
      if (e.message === "Permission denied by system") {
        console.debug(e);
        return null;
      }
      throw e;
    });
  if (!videoStream) {
    return { videoStream: null, width: void 0, height: void 0 };
  }
  const { width, height } = await new Promise<{
    width: number;
    height: number;
  }>((resolve) => {
    const elem = document.createElement("video");
    elem.onloadedmetadata = () => {
      const width = elem.videoWidth;
      const height = elem.videoHeight;
      elem.remove();
      resolve({ width, height });
    };
    elem.preload = "auto";
    elem.muted = true;
    elem.srcObject = videoStream;
  });
  console.debug("Video resolution:", width, height);

  return { videoStream, width, height };
}

async function processVideoEncoding(
  encoder: VideoEncoder,
  track: MediaStreamVideoTrack
): Promise<void> {
  // ストリームから読み出して順次エンコーダーに渡す
  const videoReader = new MediaStreamTrackProcessor({
    track,
  }).readable.getReader();

  let lastKeyDate: Date = new Date(0);
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value: frame } = await videoReader.read();
    if (done) {
      return;
    }
    if (encoder.encodeQueueSize > 2) {
      // エンコーダーにデータを渡しすぎないよう間引く。
      frame.close();
      console.warn("dropped frame");
    } else {
      // 少なくとも5秒ごとにキーフレームを出力
      const keyFrame = new Date().getTime() - lastKeyDate.getTime() > 5_000;
      if (keyFrame) {
        lastKeyDate = new Date();
        console.debug("Key frame");
      }
      encoder.encode(frame, { keyFrame });
      frame.close();
    }
  }
}

async function processAudioEncoding(
  encoder: AudioEncoder,
  track: MediaStreamAudioTrack
) {
  const audioReader = new MediaStreamTrackProcessor({
    track,
  }).readable.getReader();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await audioReader.read();
    if (done) {
      return;
    }
    encoder.encode(value);
  }
}

async function uploadVideo(props: Props, title: string, file: Blob) {
  const duration = await loadVideoDuration(file);

  const api = new ApiVideoApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": props.csrfToken,
      },
    })
  );

  const res = await api.apiVideoPreparePost();

  const blobClient = new BlockBlobClient(res.uploadUrl);
  await blobClient.uploadData(file, {
    blobHTTPHeaders: { blobContentType: file.type },
  });

  const assetName = res.assetName;
  return await api.apiVideoUploadedPostRaw({
    apiVideoUploadedPostRequest: {
      assetName: assetName,
      title: title,
      folderId: props.folderId,
      inputVideoDuration: duration,
    },
  });
}

function currentTimestampStr(): string {
  // e.g. "2020/07/06 20:15"
  const IDTFOpts: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  return Intl.DateTimeFormat("ja-JP", IDTFOpts).format(Date.now());
}
