import { useState, ChangeEventHandler, VFC } from "react";
import {
  ApiAcademyCourseCategoryApi,
  Configuration,
} from "../../generated/api";
import { notify } from "../notification";

type AcademyCourseCategoryStateType = "published" | "draft";

interface Props {
  csrfToken: string;
  academyCourseCategoryId: number;
  state: AcademyCourseCategoryStateType;
}

export const ChangeAcademyCourseCategoryStateSwitch: VFC<Props> = (props) => {
  const [courseState, setCourseState] = useState(props.state === "published");

  const handleOnChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCourseState(e.target.checked);
    const state: AcademyCourseCategoryStateType = e.target.checked
      ? "published"
      : "draft";
    const api = new ApiAcademyCourseCategoryApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiAcademyCourseCategoriesIdPatch({
        id: props.academyCourseCategoryId,
        apiAcademyCourseCategoriesIdPatchRequest: {
          academyCourseCategory: { state },
        },
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  return (
    <>
      <input
        id="publish_switch"
        type="checkbox"
        className="c-toggle-switch__input"
        checked={courseState}
        onChange={handleOnChanged}
      />
      <label
        htmlFor="publish_switch"
        className="c-toggle-switch__label"
        data-switch-on="公開"
        data-switch-off="非公開"
      />
    </>
  );
};
export default ChangeAcademyCourseCategoryStateSwitch;
