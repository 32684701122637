import { useState, useCallback } from "react";
import { useStack } from "../../lib/useStack";

import type {
  ApiFolderApi,
  ApiVideoApi,
  ApiThumbnailSpriteApi,
} from "../../generated/api";
export type VideoSummary = Awaited<
  ReturnType<ApiFolderApi["apiFoldersIdVideosGet"]>
>[number];
export type Video = Awaited<ReturnType<ApiVideoApi["apiVideoIdGet"]>>;

export type VideoThumbnailSprite = Awaited<
  ReturnType<ApiThumbnailSpriteApi["apiVideoVideoIdThumbnailSpritesGet"]>
>;

// ※AMPのClipとは異なる
export interface Clip {
  readonly id: string; // 画面制御の都合上必要なだけなので、ユニークならなんでもいい。
  readonly video: Video;
  readonly thumbnailSpritesUrl: string;
  /** クリップの開始位置(クリップ内の絶対位置) */
  readonly startMs: number;
  /** クリップの終了位置(クリップ内の絶対位置) */
  readonly endMs: number;
  /** クリップの長さ(終了位置 - 開始位置)) */
  readonly durationMs: number;
  /** 元のビデオの長さ */
  readonly videoDurationMs: number;
}

const MIN_DURATION_MS = 100;

export function useTimelineClips(
  initialVideo: Video,
  initialVideoThumbnailSprite: VideoThumbnailSprite,
  defaultClips?: Clip[]
) {
  const [clips, setClips] = useState<Clip[]>(() => {
    if (defaultClips) {
      return defaultClips;
    }
    const endMs = floorLT100ms(initialVideo.playTimeMs);
    return [
      {
        id: generateId(),
        video: initialVideo,
        thumbnailSpritesUrl: initialVideoThumbnailSprite.signedUrl,
        startMs: 0,
        endMs,
        durationMs: endMs,
        videoDurationMs: endMs,
      },
    ];
  });
  const [totalDurationMs, setTotalDurationMs] = useState(() =>
    defaultClips
      ? sumDurationMs(defaultClips)
      : floorLT100ms(initialVideo.playTimeMs)
  );
  const [undoStack, pushUndoStack, popUndoStack] = useStack<Clip[]>();
  const [redoStack, pushRedoStack, popRedoStack, clearRedoStack] =
    useStack<Clip[]>();

  const updateUndoRedoStack = useCallback(
    (newItem: Clip[]) => {
      if (newItem?.length > 0) {
        clearRedoStack();
        pushUndoStack([...newItem]);
      }
    },
    [clearRedoStack, pushUndoStack]
  );

  function addClips(
    newOnes: { video: Video; thumbnailSprite: VideoThumbnailSprite }[],
    timePositionMs: number
  ) {
    setClips((clips) => {
      const { index } = findClipFromPositionMs(clips, timePositionMs);
      const newClips = [...clips];
      const newItems: Clip[] = newOnes.map(({ video, thumbnailSprite }) => {
        const endMs = floorLT100ms(video.playTimeMs);
        return {
          id: generateId(),
          video: video,
          thumbnailSpritesUrl: thumbnailSprite.signedUrl,
          startMs: 0,
          endMs,
          durationMs: endMs,
          videoDurationMs: endMs,
        };
      });
      newClips.splice(index + 1, 0, ...newItems);
      setTotalDurationMs(sumDurationMs(newClips));
      updateUndoRedoStack(clips);
      return newClips;
    });
  }

  function updateClips(newClips: Clip[]) {
    setClips((clips) => {
      setTotalDurationMs(sumDurationMs(newClips));
      updateUndoRedoStack(clips);
      return newClips;
    });
  }

  function splitClip(timePositionMs: number) {
    setClips((clips) => {
      const { index, clip, positionMsInClip } = findClipFromPositionMs(
        clips,
        timePositionMs
      );
      const clip1: Clip = {
        ...clip,
        id: generateId(),
        endMs: positionMsInClip + clip.startMs,
        durationMs: positionMsInClip,
      };
      const clip2: Clip = {
        ...clip,
        id: generateId(),
        startMs: clip.startMs + positionMsInClip,
        durationMs: clip.durationMs - positionMsInClip,
      };
      if (
        clip1.durationMs < MIN_DURATION_MS ||
        clip2.durationMs < MIN_DURATION_MS
      ) {
        return clips;
      }
      const newClips = [...clips];
      newClips.splice(index, 1, clip1, clip2);
      updateUndoRedoStack(clips);
      return newClips;
    });
  }

  function delClip(timePositionMs: number) {
    setClips((clips) => {
      if (clips.length === 1) {
        return clips;
      }
      const { index } = findClipFromPositionMs(clips, timePositionMs);
      const newClips = [...clips];
      newClips.splice(index, 1);
      setTotalDurationMs(sumDurationMs(newClips));
      updateUndoRedoStack(clips);
      return newClips;
    });
  }

  function changeClipStart(clip: Clip, startMs: number) {
    if (clip.startMs === startMs) {
      return;
    }
    if (startMs < 0) {
      return;
    }
    const durationMs = clip.endMs - startMs;
    if (durationMs < MIN_DURATION_MS) {
      return;
    }
    setClips((clips) => {
      const index = clips.findIndex((c) => c.id === clip.id);
      const newClips = [...clips];
      newClips.splice(index, 1, { ...clip, startMs, durationMs });
      setTotalDurationMs(sumDurationMs(newClips));
      updateUndoRedoStack(clips);
      return newClips;
    });
  }

  function changeClipEnd(clip: Clip, endMs: number) {
    if (clip.endMs === endMs) {
      return;
    }
    if (clip.videoDurationMs < endMs) {
      return;
    }
    const durationMs = endMs - clip.startMs;
    if (durationMs < MIN_DURATION_MS) {
      return;
    }
    setClips((clips) => {
      const index = clips.findIndex((c) => c.id === clip.id);
      const newClips = [...clips];
      newClips.splice(index, 1, { ...clip, endMs, durationMs });
      setTotalDurationMs(sumDurationMs(newClips));
      updateUndoRedoStack(clips);
      return newClips;
    });
  }

  function undo() {
    popUndoStack((prevClips) => {
      setClips((currentClips) => {
        pushRedoStack(currentClips);
        setTotalDurationMs(sumDurationMs(prevClips));
        return prevClips;
      });
    });
  }

  function redo() {
    popRedoStack((nextClips) => {
      setClips((currentClips) => {
        pushUndoStack(currentClips);
        setTotalDurationMs(sumDurationMs(nextClips));
        return nextClips;
      });
    });
  }

  return {
    clips,
    totalDurationMs,

    updateClips: useCallback(updateClips, [updateUndoRedoStack]),
    addClips: useCallback(addClips, [updateUndoRedoStack]),
    splitClip: useCallback(splitClip, [updateUndoRedoStack]),
    delClip: useCallback(delClip, [updateUndoRedoStack]),
    changeClipStart: useCallback(changeClipStart, [updateUndoRedoStack]),
    changeClipEnd: useCallback(changeClipEnd, [updateUndoRedoStack]),

    hasUndoStack: undoStack.length > 0,
    hasRedoStack: redoStack.length > 0,
    undo: useCallback(undo, [popUndoStack, pushRedoStack]),
    redo: useCallback(redo, [popRedoStack, pushUndoStack]),
  };
}

function generateId(): string {
  return crypto.randomUUID();
}

function sumDurationMs(clips: Clip[]): number {
  return clips.reduce((sum, clip) => sum + clip.durationMs, 0);
}

/** 100ms未満を切り捨てる */
function floorLT100ms(ms: number) {
  return Math.floor(ms / 100) * 100;
}

export function findClipFromPositionMs(
  clips: Clip[],
  position: number
): { index?: number; clip?: Clip; positionMsInClip?: number } {
  let searchingOffset = 0;
  for (let index = 0; index < clips.length; index++) {
    const clip = clips[index];
    const currentClipEnd = searchingOffset + clip.durationMs;
    if (searchingOffset <= position && position <= currentClipEnd) {
      const positionMsInClip = position - searchingOffset;
      return { index, clip, positionMsInClip };
    }
    searchingOffset = currentClipEnd;
  }
  return {};
}
