import { useState, FormEventHandler } from "react";
import ReactModal from "react-modal";
import {
  ApiWatchLinkConditionResponseApi,
  Configuration,
} from "../../generated/api";
import { notify } from "../notification";

interface Props {
  videoId: number;
  csrfToken: string;
  description?: string;
}

export const WatchLinkConditionFormModal = (props: Props) => {
  const [open, setOpen] = useState(true);
  const [email, setEmail] = useState("");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const api = new ApiWatchLinkConditionResponseApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiVideoVideoIdWatchLinkConditionResponsePost({
        videoId: props.videoId,
        apiVideoVideoIdWatchLinkConditionResponsePostRequest: {
          response: { email },
        },
      })
      .then(() => {
        setOpen(false);
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  return (
    <ReactModal
      isOpen={open}
      overlayClassName="c-dialog__overlay"
      className="c-dialog__container"
    >
      <form
        className="c-dialog__content no-fixed-height p-video-condition-response__container"
        onSubmit={handleSubmit}
      >
        <img src="/logo.png" alt="" width={140} />
        <p className="p-video-condition-response__description">
          {props.description}
        </p>
        <input
          className="p-video-condition-response__textfield"
          name="email"
          type="email"
          placeholder="メールアドレス"
          required
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <p className="p-video-condition-response__terms">
          ビデオを視聴するにあたり、メールアドレスをVideoTouchに登録します。
          VideoTouchの
          <a
            href="https://videotouch.co.jp/privacy"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
          をご確認ください。
        </p>
        <button className="c-button--primary match-parent" type="submit">
          上記に同意し、ビデオを視聴する
        </button>
      </form>
    </ReactModal>
  );
};
export default WatchLinkConditionFormModal;
