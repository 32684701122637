import { VFC, useState, FormEvent } from "react";
import ReactModal from "react-modal";
import {
  ApiAcademyCourseCategoryApi,
  Configuration,
} from "../../generated/api";
import { notify } from "../notification";

interface Props {
  csrfToken: string;
  academyId: number;
}

export const CreateAcademyCourseCategoryModal: VFC<Props> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [title, setTitle] = useState("");

  const handleCloseButton = () => {
    setModalVisible(false);
    setTitle("");
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const api = new ApiAcademyCourseCategoryApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiAcademyCourseCategoriesPost({
        apiAcademyCourseCategoriesPostRequest: {
          academyCourseCategory: {
            title,
          },
        },
      })
      .then(() => {
        location.reload();
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  return (
    <>
      <button
        className="c-button--outlined p-academy-edit__create-button"
        onClick={() => setModalVisible(!modalVisible)}
      >
        <i className="material-icons-round c-button__icon">add</i>
        カテゴリーを作成
      </button>

      <ReactModal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        onAfterClose={() => handleCloseButton()}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        overlayClassName="c-dialog__overlay vertical-center"
        className="c-dialog__container wide"
      >
        <div className="c-dialog__header">
          <h2 className="c-dialog__header__title">カテゴリーを作成</h2>
          <button
            className="material-icons-round c-dialog__header__close-icon"
            onClick={() => handleCloseButton()}
          >
            close
          </button>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="c-dialog__content no-fixed-height">
            <label
              htmlFor="academy-course-title"
              className="c-dialog__text-field-label"
            >
              カテゴリー名
            </label>
            <input
              id="academy-course-title"
              required={true}
              autoFocus={true}
              type="text"
              name="title"
              autoComplete="off"
              maxLength={100}
              className="c-dialog__text-field-input"
              onChange={(e) => setTitle(e.target.value?.trim())}
              value={title}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </div>
          <div className="c-dialog__footer">
            <input
              className="c-button--primary narrow-padding"
              type="submit"
              value="作成する"
            />
          </div>
        </form>
      </ReactModal>
    </>
  );
};

export default CreateAcademyCourseCategoryModal;
