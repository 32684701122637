/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoVideoIdExamAnswersPostRequestAnswersInner } from './ApiVideoVideoIdExamAnswersPostRequestAnswersInner';
import {
    ApiVideoVideoIdExamAnswersPostRequestAnswersInnerFromJSON,
    ApiVideoVideoIdExamAnswersPostRequestAnswersInnerFromJSONTyped,
    ApiVideoVideoIdExamAnswersPostRequestAnswersInnerToJSON,
} from './ApiVideoVideoIdExamAnswersPostRequestAnswersInner';

/**
 * 
 * @export
 * @interface ApiVideoVideoIdExamAnswersPostRequest
 */
export interface ApiVideoVideoIdExamAnswersPostRequest {
    /**
     * 
     * @type {Array<ApiVideoVideoIdExamAnswersPostRequestAnswersInner>}
     * @memberof ApiVideoVideoIdExamAnswersPostRequest
     */
    answers?: Array<ApiVideoVideoIdExamAnswersPostRequestAnswersInner>;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdExamAnswersPostRequest
     */
    userName?: string;
}

/**
 * Check if a given object implements the ApiVideoVideoIdExamAnswersPostRequest interface.
 */
export function instanceOfApiVideoVideoIdExamAnswersPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdExamAnswersPostRequestFromJSON(json: any): ApiVideoVideoIdExamAnswersPostRequest {
    return ApiVideoVideoIdExamAnswersPostRequestFromJSONTyped(json, false);
}

export function ApiVideoVideoIdExamAnswersPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdExamAnswersPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answers': !exists(json, 'answers') ? undefined : ((json['answers'] as Array<any>).map(ApiVideoVideoIdExamAnswersPostRequestAnswersInnerFromJSON)),
        'userName': !exists(json, 'user_name') ? undefined : json['user_name'],
    };
}

export function ApiVideoVideoIdExamAnswersPostRequestToJSON(value?: ApiVideoVideoIdExamAnswersPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answers': value.answers === undefined ? undefined : ((value.answers as Array<any>).map(ApiVideoVideoIdExamAnswersPostRequestAnswersInnerToJSON)),
        'user_name': value.userName,
    };
}

