/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAcademyCourseCategoriesIdDelete422Response,
  ApiAcademyCourseCategoriesIdGet200Response,
  ApiAcademyCourseCategoriesIdPatch202Response,
  ApiAcademyCourseCategoriesIdPatchRequest,
  ApiAcademyCourseCategoriesPost201Response,
  ApiAcademyCourseCategoriesPostRequest,
} from '../models';
import {
    ApiAcademyCourseCategoriesIdDelete422ResponseFromJSON,
    ApiAcademyCourseCategoriesIdDelete422ResponseToJSON,
    ApiAcademyCourseCategoriesIdGet200ResponseFromJSON,
    ApiAcademyCourseCategoriesIdGet200ResponseToJSON,
    ApiAcademyCourseCategoriesIdPatch202ResponseFromJSON,
    ApiAcademyCourseCategoriesIdPatch202ResponseToJSON,
    ApiAcademyCourseCategoriesIdPatchRequestFromJSON,
    ApiAcademyCourseCategoriesIdPatchRequestToJSON,
    ApiAcademyCourseCategoriesPost201ResponseFromJSON,
    ApiAcademyCourseCategoriesPost201ResponseToJSON,
    ApiAcademyCourseCategoriesPostRequestFromJSON,
    ApiAcademyCourseCategoriesPostRequestToJSON,
} from '../models';

export interface ApiAcademyCourseCategoriesIdDeleteRequest {
    id: number;
}

export interface ApiAcademyCourseCategoriesIdGetRequest {
    id: number;
}

export interface ApiAcademyCourseCategoriesIdPatchOperationRequest {
    id: number;
    apiAcademyCourseCategoriesIdPatchRequest?: ApiAcademyCourseCategoriesIdPatchRequest;
}

export interface ApiAcademyCourseCategoriesPostOperationRequest {
    apiAcademyCourseCategoriesPostRequest?: ApiAcademyCourseCategoriesPostRequest;
}

/**
 * 
 */
export class ApiAcademyCourseCategoryApi extends runtime.BaseAPI {

    /**
     * destroy
     */
    async apiAcademyCourseCategoriesIdDeleteRaw(requestParameters: ApiAcademyCourseCategoriesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCourseCategoriesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/course_categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiAcademyCourseCategoriesIdDelete(requestParameters: ApiAcademyCourseCategoriesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiAcademyCourseCategoriesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * show
     */
    async apiAcademyCourseCategoriesIdGetRaw(requestParameters: ApiAcademyCourseCategoriesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCourseCategoriesIdGet200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCourseCategoriesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/course_categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCourseCategoriesIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiAcademyCourseCategoriesIdGet(requestParameters: ApiAcademyCourseCategoriesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCourseCategoriesIdGet200Response> {
        const response = await this.apiAcademyCourseCategoriesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiAcademyCourseCategoriesIdPatchRaw(requestParameters: ApiAcademyCourseCategoriesIdPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCourseCategoriesIdPatch202Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCourseCategoriesIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/academy/course_categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAcademyCourseCategoriesIdPatchRequestToJSON(requestParameters.apiAcademyCourseCategoriesIdPatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCourseCategoriesIdPatch202ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiAcademyCourseCategoriesIdPatch(requestParameters: ApiAcademyCourseCategoriesIdPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCourseCategoriesIdPatch202Response> {
        const response = await this.apiAcademyCourseCategoriesIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create
     */
    async apiAcademyCourseCategoriesPostRaw(requestParameters: ApiAcademyCourseCategoriesPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCourseCategoriesPost201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/academy/course_categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAcademyCourseCategoriesPostRequestToJSON(requestParameters.apiAcademyCourseCategoriesPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCourseCategoriesPost201ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiAcademyCourseCategoriesPost(requestParameters: ApiAcademyCourseCategoriesPostOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCourseCategoriesPost201Response> {
        const response = await this.apiAcademyCourseCategoriesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
