/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiWordPronounciationsIdPut200ResponseLastModifiedBy
 */
export interface ApiWordPronounciationsIdPut200ResponseLastModifiedBy {
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsIdPut200ResponseLastModifiedBy
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsIdPut200ResponseLastModifiedBy
     */
    imageUrl?: string;
}

/**
 * Check if a given object implements the ApiWordPronounciationsIdPut200ResponseLastModifiedBy interface.
 */
export function instanceOfApiWordPronounciationsIdPut200ResponseLastModifiedBy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiWordPronounciationsIdPut200ResponseLastModifiedByFromJSON(json: any): ApiWordPronounciationsIdPut200ResponseLastModifiedBy {
    return ApiWordPronounciationsIdPut200ResponseLastModifiedByFromJSONTyped(json, false);
}

export function ApiWordPronounciationsIdPut200ResponseLastModifiedByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWordPronounciationsIdPut200ResponseLastModifiedBy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
    };
}

export function ApiWordPronounciationsIdPut200ResponseLastModifiedByToJSON(value?: ApiWordPronounciationsIdPut200ResponseLastModifiedBy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'imageUrl': value.imageUrl,
    };
}

