import { useEffect, useRef } from "react";
import { Jodit } from "jodit";
import { TimestampLink } from "./TimestampLinkPlugin";

interface Props {
  className?: string;
  placeholder?: string;
  toolbarStickyOffset?: number;
  value?: string;
  onChangeValue?(newValue: string, oldValue: string): void;
  onBlur?(event: FocusEvent): void;
  enableTimestampLink?: boolean;
}

export function RichTextEditor(props: Props) {
  const textarea = useRef<HTMLTextAreaElement>();
  const editor = useRef<Jodit>();

  useEffect(() => {
    if (props.enableTimestampLink) {
      Jodit.plugins.add("TimestampLink", TimestampLink);
    }

    type JoditOptionsValue = string | number | boolean | string[];
    type JoditOptionsItem = {
      [key: string]: JoditOptionsItem | JoditOptionsValue;
    };
    type JoditOptions = Partial<Jodit["OPTIONS"]> & JoditOptionsItem;
    const config: JoditOptions = {
      tabIndex: 0,
      language: "ja",
      theme: "vt",
      placeholder: props.placeholder || "",
      disablePlugins: [
        "mobile", // mobileプラグインがbuttons指定を書き換えてしまうので。
        "inline-popup", // デフォルトでは不要なメニューも表示されるなど問題があるため使用しない。必要性が出たらカスタマイズなど検討する。
      ],
      buttons: [
        "paragraph",
        "brush",
        "bold",
        "italic",
        "underline",
        "eraser",
        "link",
        "ul",
        "ol",
      ],
      // https://github.com/xdan/jodit/blob/3.24.5/src/plugins/clean-html/config.ts
      cleanHTML: {
        denyTags: `script,img,video,audio,iframe,embed,object,button,form,input,select,textarea,details,dialog,summary`,
      },
      statusbar: false,
      toolbarStickyOffset: props.toolbarStickyOffset,
      showTooltipDelay: 100,
      askBeforePasteHTML: false,
      defaultActionOnPaste: Jodit.constants.INSERT_CLEAR_HTML,
      link: { modeClassName: false, noFollowCheckbox: false },
      colorPickerDefaultTab: "color",
    };
    editor.current = Jodit.make(textarea.current, config);

    return () => {
      editor.current.destruct();
    };
  }, [props.placeholder, props.enableTimestampLink, props.toolbarStickyOffset]);

  useEffect(() => {
    if (editor.current.value != props.value) {
      editor.current.value = props.value;
    }
  }, [props.value]);

  useEffect(() => {
    if (props.onChangeValue) {
      editor.current.events.on("change", props.onChangeValue);
      return () => {
        editor.current.events.off("change", props.onChangeValue);
      };
    }
  }, [props.onChangeValue]);

  useEffect(() => {
    if (props.onBlur) {
      editor.current.events.on("blur", props.onBlur);
      return () => {
        editor.current.events.off("blur", props.onBlur);
      };
    }
  }, [props.onBlur]);

  return (
    <div className={`c-rich-text__content ${props.className ?? ""}`}>
      <textarea ref={textarea} />
    </div>
  );
}
