/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiDesignIdAddCreatomateJobPost200ResponseInner
 */
export interface ApiDesignIdAddCreatomateJobPost200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPost200ResponseInner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPost200ResponseInner
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPost200ResponseInner
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPost200ResponseInner
     */
    outputFormat?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPost200ResponseInner
     */
    webhookUrl?: string;
}

/**
 * Check if a given object implements the ApiDesignIdAddCreatomateJobPost200ResponseInner interface.
 */
export function instanceOfApiDesignIdAddCreatomateJobPost200ResponseInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiDesignIdAddCreatomateJobPost200ResponseInnerFromJSON(json: any): ApiDesignIdAddCreatomateJobPost200ResponseInner {
    return ApiDesignIdAddCreatomateJobPost200ResponseInnerFromJSONTyped(json, false);
}

export function ApiDesignIdAddCreatomateJobPost200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDesignIdAddCreatomateJobPost200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'outputFormat': !exists(json, 'output_format') ? undefined : json['output_format'],
        'webhookUrl': !exists(json, 'webhook_url') ? undefined : json['webhook_url'],
    };
}

export function ApiDesignIdAddCreatomateJobPost200ResponseInnerToJSON(value?: ApiDesignIdAddCreatomateJobPost200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'url': value.url,
        'output_format': value.outputFormat,
        'webhook_url': value.webhookUrl,
    };
}

