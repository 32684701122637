/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiScenesIdGet200ResponseAudioData } from './ApiScenesIdGet200ResponseAudioData';
import {
    ApiScenesIdGet200ResponseAudioDataFromJSON,
    ApiScenesIdGet200ResponseAudioDataFromJSONTyped,
    ApiScenesIdGet200ResponseAudioDataToJSON,
} from './ApiScenesIdGet200ResponseAudioData';

/**
 * 
 * @export
 * @interface ApiScenesIdGet200Response
 */
export interface ApiScenesIdGet200Response {
    /**
     * 
     * @type {number}
     * @memberof ApiScenesIdGet200Response
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiScenesIdGet200Response
     */
    designId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiScenesIdGet200Response
     */
    rowOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiScenesIdGet200Response
     */
    textHtml?: string;
    /**
     * 
     * @type {ApiScenesIdGet200ResponseAudioData}
     * @memberof ApiScenesIdGet200Response
     */
    audioData?: ApiScenesIdGet200ResponseAudioData;
    /**
     * 
     * @type {any}
     * @memberof ApiScenesIdGet200Response
     */
    imageUrl?: any | null;
}

/**
 * Check if a given object implements the ApiScenesIdGet200Response interface.
 */
export function instanceOfApiScenesIdGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiScenesIdGet200ResponseFromJSON(json: any): ApiScenesIdGet200Response {
    return ApiScenesIdGet200ResponseFromJSONTyped(json, false);
}

export function ApiScenesIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiScenesIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'designId': !exists(json, 'designId') ? undefined : json['designId'],
        'rowOrder': !exists(json, 'rowOrder') ? undefined : json['rowOrder'],
        'textHtml': !exists(json, 'textHtml') ? undefined : json['textHtml'],
        'audioData': !exists(json, 'audioData') ? undefined : ApiScenesIdGet200ResponseAudioDataFromJSON(json['audioData']),
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
    };
}

export function ApiScenesIdGet200ResponseToJSON(value?: ApiScenesIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'designId': value.designId,
        'rowOrder': value.rowOrder,
        'textHtml': value.textHtml,
        'audioData': ApiScenesIdGet200ResponseAudioDataToJSON(value.audioData),
        'imageUrl': value.imageUrl,
    };
}

