const formatOptions: Intl.DateTimeFormatOptions = {
  minute: "2-digit",
  second: "2-digit",
  fractionalSecondDigits: 1,
  timeZone: "UTC", // 時刻ではなく再生時間のフォーマットに使いたいのでタイムゾーン調整しないように。
};
/** e.g. `12:34.5` */
const formatter = Intl.DateTimeFormat("ja-JP", formatOptions);
/** e.g. `12:34:56.7` */
const formatterWithHour = Intl.DateTimeFormat("ja-JP", {
  ...formatOptions,
  hour: "numeric",
});

/**
 * Format milliseconds to time notation.
 * @param {number} timeMs
 * @returns {string} `12:34.5` or `12:34:56.7` or null
 */
export function formatTimeMs(timeMs: number): string {
  if (Number.isFinite(timeMs)) {
    const f = timeMs >= 3600_000 ? formatterWithHour : formatter;
    return f.format(timeMs);
  }
  return null;
}
