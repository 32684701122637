import {
  useState,
  useMemo,
  useEffect,
  useRef,
  FC,
  VFC,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
} from "react";
import { CSSTransition } from "react-transition-group";
import ReactTooltip from "react-tooltip";
import {
  editVideoExamPath,
  editVideoNarrationsPath,
  editVideoTimelinePath,
  videoSubtitlesPath,
} from "../../generated/routes";
import { VideoThumbnail } from "./VideoThumbnail";
import type { VideoTransformJobHistory } from "../../types/videoTransformJobHistory";

interface Props {
  csrfToken: string;
  videoId: number;
  videoHashId: string; // Videoのhashid
  videoPlayable: boolean;
  subtitlesGenerateJobRunning: boolean;
  thumbnailSpritesShowable: boolean;
  thumbnailResettable: boolean;
  lastVideoTransformJobHistory?: VideoTransformJobHistory;
}

type ViewMode = "thumbnail" | null;

export const VideoEditMenu: VFC<Props> = (props) => {
  const [viewMode, setViewMode] = useState<ViewMode>(null);

  const lastNarrationJobFailed = useMemo(() => {
    const his = props.lastVideoTransformJobHistory;
    return (
      his?.jobTypeName === "narration_overwrite_text2speech" &&
      (his?.state === "fail" || his?.state === "canceled")
    );
  }, [props.lastVideoTransformJobHistory]);

  return (
    <div className="p-video-edit-menu__container">
      <CSSTransition
        classNames={
          viewMode == null
            ? "p-video-edit-menu__transition-back"
            : "p-video-edit-menu__transition"
        }
        timeout={250}
        in={viewMode == null}
        mountOnEnter
        unmountOnExit
      >
        <div className="p-video-edit-menu__view">
          <p className="p-video-edit-menu__title">ビデオ編集</p>

          {/* ビデオを編集 */}
          <MenuAnchor
            href={
              // ※hrefの有無(有効なリンクかどうか)でスタイルを変えている
              props.videoPlayable &&
              !props.subtitlesGenerateJobRunning &&
              props.thumbnailSpritesShowable
                ? editVideoTimelinePath(props.videoHashId)
                : undefined
            }
            data-tip={
              !props.videoPlayable
                ? "エンコード中です。<br />少し待ってリロードしてください。"
                : props.subtitlesGenerateJobRunning
                ? "AI字幕生成中は使えません"
                : !props.thumbnailSpritesShowable
                ? "ビデオ編集の準備中です。<br />少し待ってリロードしてください。"
                : undefined
            }
          >
            <span className="material-icons-round c-button__icon">movie</span>
            ビデオを編集
          </MenuAnchor>

          {/* 「サムネイル」ボタン */}
          <MenuButton
            data-tip={
              props.videoPlayable
                ? undefined
                : "エンコード中です。<br />少し待ってリロードしてください。"
            }
            disabled={!props.videoPlayable}
            onClick={() => setViewMode("thumbnail")}
          >
            <span className="material-icons-round c-button__icon">
              insert_photo
            </span>
            サムネイル
          </MenuButton>

          {/* 「AI字幕」ボタン */}
          <MenuAnchor
            href={
              // ※hrefの有無(有効なリンクかどうか)でスタイルを変えている
              props.videoPlayable
                ? videoSubtitlesPath(props.videoHashId)
                : undefined
            }
            data-tip={
              props.videoPlayable
                ? undefined
                : "エンコード中です。<br />少し待ってリロードしてください。"
            }
          >
            <span className="material-icons-round c-button__icon">
              subtitles
            </span>
            AI字幕
          </MenuAnchor>

          {/* AIナレーション */}
          <MenuAnchor
            href={
              props.videoPlayable
                ? editVideoNarrationsPath(props.videoHashId)
                : undefined
            }
            data-tip={
              !props.videoPlayable
                ? "エンコード中です。<br />少し待ってリロードしてください。"
                : lastNarrationJobFailed
                ? "AIナレーション生成に失敗しました。<br />もう一度生成をお試しください。"
                : null
            }
            forceShowTooltipOnLoad={lastNarrationJobFailed}
          >
            <span className="material-icons-round c-button__icon">
              record_voice_over
            </span>
            AIナレーション
          </MenuAnchor>

          {/* 「テスト」ボタン */}
          <MenuAnchor href={editVideoExamPath(props.videoHashId)}>
            <span className="material-icons-round c-button__icon">article</span>
            テスト
          </MenuAnchor>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames={
          viewMode === "thumbnail"
            ? "p-video-edit-menu__transition"
            : "p-video-edit-menu__transition-back"
        }
        timeout={250}
        in={viewMode === "thumbnail"}
        mountOnEnter
        unmountOnExit
      >
        <div className="p-video-edit-menu__view">
          <p className="p-video-edit-menu__title--secondary">
            <button
              className="p-video-edit-menu__back-button"
              onClick={() => setViewMode(null)}
              title="ビデオ編集に戻る"
            >
              <span className="material-icons-round c-button__icon">
                chevron_left
              </span>
            </button>
            <span>サムネイル</span>
          </p>
          <VideoThumbnail
            csrfToken={props.csrfToken}
            videoId={props.videoId}
            thumbnailResettable={props.thumbnailResettable}
            enableVideoElemCapture={true}
          />
        </div>
      </CSSTransition>
    </div>
  );
};
export default VideoEditMenu;

const MenuAnchor: FC<
  AnchorHTMLAttributes<HTMLAnchorElement> & { forceShowTooltipOnLoad?: boolean }
> = (props) => {
  const { forceShowTooltipOnLoad, ...anchorAttrs } = props;
  const elemRef = useRef<HTMLAnchorElement>();

  useEffect(() => {
    if (forceShowTooltipOnLoad) {
      // 描画処理との兼ね合いで遅延するための setTimeout
      setTimeout(() => ReactTooltip.show(elemRef.current));
    }
  }, [forceShowTooltipOnLoad]);

  return (
    <a
      ref={elemRef}
      className="c-button--text align-start match-parent"
      data-multiline={true}
      {...anchorAttrs}
    >
      {props.children}
    </a>
  );
};

const MenuButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { "data-tip": string }
> = (props) => {
  const { "data-tip": dataTip, ...buttonAttrs } = props;
  return (
    <div data-multiline={true} data-tip={dataTip}>
      <button
        className="c-button--text align-start match-parent"
        {...buttonAttrs}
      >
        {props.children}
      </button>
    </div>
  );
};
