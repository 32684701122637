/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiWordPronounciationsIdGet200Response,
  ApiWordPronounciationsIdPut200Response,
  ApiWordPronounciationsIdPut422Response,
  ApiWordPronounciationsIdPutRequest,
  ApiWordPronounciationsPostRequest,
} from '../models';
import {
    ApiWordPronounciationsIdGet200ResponseFromJSON,
    ApiWordPronounciationsIdGet200ResponseToJSON,
    ApiWordPronounciationsIdPut200ResponseFromJSON,
    ApiWordPronounciationsIdPut200ResponseToJSON,
    ApiWordPronounciationsIdPut422ResponseFromJSON,
    ApiWordPronounciationsIdPut422ResponseToJSON,
    ApiWordPronounciationsIdPutRequestFromJSON,
    ApiWordPronounciationsIdPutRequestToJSON,
    ApiWordPronounciationsPostRequestFromJSON,
    ApiWordPronounciationsPostRequestToJSON,
} from '../models';

export interface ApiWordPronounciationsGetRequest {
    page?: number;
}

export interface ApiWordPronounciationsIdDeleteRequest {
    id: number;
}

export interface ApiWordPronounciationsIdGetRequest {
    id: number;
}

export interface ApiWordPronounciationsIdPutOperationRequest {
    id: number;
    apiWordPronounciationsIdPutRequest?: ApiWordPronounciationsIdPutRequest;
}

export interface ApiWordPronounciationsPostOperationRequest {
    apiWordPronounciationsPostRequest?: ApiWordPronounciationsPostRequest;
}

/**
 * 
 */
export class ApiWordPronounciationApi extends runtime.BaseAPI {

    /**
     * index
     */
    async apiWordPronounciationsGetRaw(requestParameters: ApiWordPronounciationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiWordPronounciationsIdGet200Response>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/word_pronounciations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiWordPronounciationsIdGet200ResponseFromJSON));
    }

    /**
     * index
     */
    async apiWordPronounciationsGet(requestParameters: ApiWordPronounciationsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiWordPronounciationsIdGet200Response>> {
        const response = await this.apiWordPronounciationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * destroy
     */
    async apiWordPronounciationsIdDeleteRaw(requestParameters: ApiWordPronounciationsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWordPronounciationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/word_pronounciations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiWordPronounciationsIdDelete(requestParameters: ApiWordPronounciationsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiWordPronounciationsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * show
     */
    async apiWordPronounciationsIdGetRaw(requestParameters: ApiWordPronounciationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiWordPronounciationsIdGet200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWordPronounciationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/word_pronounciations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiWordPronounciationsIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiWordPronounciationsIdGet(requestParameters: ApiWordPronounciationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiWordPronounciationsIdGet200Response> {
        const response = await this.apiWordPronounciationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiWordPronounciationsIdPutRaw(requestParameters: ApiWordPronounciationsIdPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiWordPronounciationsIdPut200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWordPronounciationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/word_pronounciations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiWordPronounciationsIdPutRequestToJSON(requestParameters.apiWordPronounciationsIdPutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiWordPronounciationsIdPut200ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiWordPronounciationsIdPut(requestParameters: ApiWordPronounciationsIdPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiWordPronounciationsIdPut200Response> {
        const response = await this.apiWordPronounciationsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create
     */
    async apiWordPronounciationsPostRaw(requestParameters: ApiWordPronounciationsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiWordPronounciationsIdPut200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/word_pronounciations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiWordPronounciationsPostRequestToJSON(requestParameters.apiWordPronounciationsPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiWordPronounciationsIdPut200ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiWordPronounciationsPost(requestParameters: ApiWordPronounciationsPostOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiWordPronounciationsIdPut200Response> {
        const response = await this.apiWordPronounciationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
