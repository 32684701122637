import { VFC, useState, FormEvent } from "react";
import ReactModal from "react-modal";
import { ApiAcademyCourseApi, Configuration } from "../../generated/api";
import { notify } from "../notification";
import { AcademyCourseIconChooser } from "./AcademyCourseIconChooser";

interface Props {
  csrfToken: string;
  academyCourseCategoryId: number;
}

export const CreateAcademyCourseModal: VFC<Props> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [iconName, setIconName] = useState("");

  const handleCloseButton = () => {
    setModalVisible(false);
    setTitle("");
    setDescription("");
    setIconName("");
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const api = new ApiAcademyCourseApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiAcademyCoursesPost({
        apiAcademyCoursesPostRequest: {
          academyCourse: {
            title,
            description,
            iconName,
            academyCourseCategoryId: props.academyCourseCategoryId,
          },
        },
      })
      .then(() => {
        location.reload();
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  return (
    <>
      <button
        className="c-button--outlined p-academy-edit__create-button"
        onClick={() => setModalVisible(!modalVisible)}
      >
        <i className="material-icons-round c-button__icon">add</i>
        コースを作成
      </button>

      <ReactModal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        onAfterClose={() => handleCloseButton()}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        overlayClassName="c-dialog__overlay vertical-center"
        className="c-dialog__container wide"
      >
        <div className="c-dialog__header">
          <h2 className="c-dialog__header__title">コースを作成</h2>
          <button
            className="material-icons-round c-dialog__header__close-icon"
            onClick={() => handleCloseButton()}
          >
            close
          </button>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="c-dialog__content no-fixed-height">
            <label
              htmlFor="academy-course-title"
              className="c-dialog__text-field-label"
            >
              コース名（必須）
            </label>
            <input
              id="academy-course-title"
              required={true}
              autoFocus={true}
              type="text"
              name="title"
              autoComplete="off"
              maxLength={100}
              className="c-dialog__text-field-input"
              onChange={(e) => setTitle(e.target.value?.trim())}
              value={title}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            &nbsp;
            <br />
            <label
              htmlFor="academy-course-description"
              className="c-dialog__text-field-label"
            >
              コースの説明
            </label>
            <textarea
              id="academy-course-description"
              name="description"
              autoComplete="off"
              maxLength={5000}
              rows={4}
              className="c-dialog__text-field-input"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            &nbsp;
            <br />
            <label className="c-dialog__text-field-label">アイコン</label>
            <AcademyCourseIconChooser
              required={true}
              iconName={iconName}
              onChange={(newIconName) => setIconName(newIconName)}
            />
          </div>
          <div className="c-dialog__footer">
            <input
              className="c-button--primary narrow-padding"
              type="submit"
              value="作成する"
            />
          </div>
        </form>
      </ReactModal>
    </>
  );
};

export default CreateAcademyCourseModal;
