/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiAcademyCourseLessonsIdGet200ResponseVideo } from './ApiAcademyCourseLessonsIdGet200ResponseVideo';
import {
    ApiAcademyCourseLessonsIdGet200ResponseVideoFromJSON,
    ApiAcademyCourseLessonsIdGet200ResponseVideoFromJSONTyped,
    ApiAcademyCourseLessonsIdGet200ResponseVideoToJSON,
} from './ApiAcademyCourseLessonsIdGet200ResponseVideo';

/**
 * 
 * @export
 * @interface ApiAcademyCourseLessonsIdGet200Response
 */
export interface ApiAcademyCourseLessonsIdGet200Response {
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    hashid?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    academyCourseId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    videoId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    rowOrderRank?: number;
    /**
     * 
     * @type {any}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    rowOrderPosition?: any | null;
    /**
     * 
     * @type {ApiAcademyCourseLessonsIdGet200ResponseVideo}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    video?: ApiAcademyCourseLessonsIdGet200ResponseVideo;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    playTimeSec?: number;
    /**
     * 
     * @type {any}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    textHtml?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ApiAcademyCourseLessonsIdGet200Response
     */
    title?: any | null;
}

/**
 * Check if a given object implements the ApiAcademyCourseLessonsIdGet200Response interface.
 */
export function instanceOfApiAcademyCourseLessonsIdGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCourseLessonsIdGet200ResponseFromJSON(json: any): ApiAcademyCourseLessonsIdGet200Response {
    return ApiAcademyCourseLessonsIdGet200ResponseFromJSONTyped(json, false);
}

export function ApiAcademyCourseLessonsIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCourseLessonsIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hashid': !exists(json, 'hashid') ? undefined : json['hashid'],
        'academyCourseId': !exists(json, 'academyCourseId') ? undefined : json['academyCourseId'],
        'videoId': !exists(json, 'videoId') ? undefined : json['videoId'],
        'rowOrderRank': !exists(json, 'rowOrderRank') ? undefined : json['rowOrderRank'],
        'rowOrderPosition': !exists(json, 'rowOrderPosition') ? undefined : json['rowOrderPosition'],
        'video': !exists(json, 'video') ? undefined : ApiAcademyCourseLessonsIdGet200ResponseVideoFromJSON(json['video']),
        'playTimeSec': !exists(json, 'playTimeSec') ? undefined : json['playTimeSec'],
        'textHtml': !exists(json, 'textHtml') ? undefined : json['textHtml'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function ApiAcademyCourseLessonsIdGet200ResponseToJSON(value?: ApiAcademyCourseLessonsIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hashid': value.hashid,
        'academyCourseId': value.academyCourseId,
        'videoId': value.videoId,
        'rowOrderRank': value.rowOrderRank,
        'rowOrderPosition': value.rowOrderPosition,
        'video': ApiAcademyCourseLessonsIdGet200ResponseVideoToJSON(value.video),
        'playTimeSec': value.playTimeSec,
        'textHtml': value.textHtml,
        'title': value.title,
    };
}

