/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAcademyAcademyIdWatchLinkGet200Response,
  ApiAcademyAcademyIdWatchLinkPatchRequest,
  ApiVideoVideoIdWatchLinkPatch422Response,
} from '../models';
import {
    ApiAcademyAcademyIdWatchLinkGet200ResponseFromJSON,
    ApiAcademyAcademyIdWatchLinkGet200ResponseToJSON,
    ApiAcademyAcademyIdWatchLinkPatchRequestFromJSON,
    ApiAcademyAcademyIdWatchLinkPatchRequestToJSON,
    ApiVideoVideoIdWatchLinkPatch422ResponseFromJSON,
    ApiVideoVideoIdWatchLinkPatch422ResponseToJSON,
} from '../models';

export interface ApiAcademyAcademyIdWatchLinkGetRequest {
    academyId: number;
}

export interface ApiAcademyAcademyIdWatchLinkPatchOperationRequest {
    academyId: number;
    apiAcademyAcademyIdWatchLinkPatchRequest?: ApiAcademyAcademyIdWatchLinkPatchRequest;
}

/**
 * 
 */
export class ApiAcademyWatchLinkApi extends runtime.BaseAPI {

    /**
     * show
     */
    async apiAcademyAcademyIdWatchLinkGetRaw(requestParameters: ApiAcademyAcademyIdWatchLinkGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyAcademyIdWatchLinkGet200Response>> {
        if (requestParameters.academyId === null || requestParameters.academyId === undefined) {
            throw new runtime.RequiredError('academyId','Required parameter requestParameters.academyId was null or undefined when calling apiAcademyAcademyIdWatchLinkGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/{academy_id}/watch_link`.replace(`{${"academy_id"}}`, encodeURIComponent(String(requestParameters.academyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyAcademyIdWatchLinkGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiAcademyAcademyIdWatchLinkGet(requestParameters: ApiAcademyAcademyIdWatchLinkGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyAcademyIdWatchLinkGet200Response> {
        const response = await this.apiAcademyAcademyIdWatchLinkGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiAcademyAcademyIdWatchLinkPatchRaw(requestParameters: ApiAcademyAcademyIdWatchLinkPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyAcademyIdWatchLinkGet200Response>> {
        if (requestParameters.academyId === null || requestParameters.academyId === undefined) {
            throw new runtime.RequiredError('academyId','Required parameter requestParameters.academyId was null or undefined when calling apiAcademyAcademyIdWatchLinkPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/academy/{academy_id}/watch_link`.replace(`{${"academy_id"}}`, encodeURIComponent(String(requestParameters.academyId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAcademyAcademyIdWatchLinkPatchRequestToJSON(requestParameters.apiAcademyAcademyIdWatchLinkPatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyAcademyIdWatchLinkGet200ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiAcademyAcademyIdWatchLinkPatch(requestParameters: ApiAcademyAcademyIdWatchLinkPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyAcademyIdWatchLinkGet200Response> {
        const response = await this.apiAcademyAcademyIdWatchLinkPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
