/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData
 */
export interface ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData
     */
    voiceName?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData
     */
    durationMs?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData
     */
    speechRate?: string;
}

/**
 * Check if a given object implements the ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData interface.
 */
export function instanceOfApiVideoVideoIdNarrationsGet200ResponseInnerAudioData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataFromJSON(json: any): ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData {
    return ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataFromJSONTyped(json, false);
}

export function ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'voiceName': !exists(json, 'voiceName') ? undefined : json['voiceName'],
        'durationMs': !exists(json, 'durationMs') ? undefined : json['durationMs'],
        'speechRate': !exists(json, 'speechRate') ? undefined : json['speechRate'],
    };
}

export function ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataToJSON(value?: ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'voiceName': value.voiceName,
        'durationMs': value.durationMs,
        'speechRate': value.speechRate,
    };
}

