/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiScenesIdPutRequestAudioData } from './ApiScenesIdPutRequestAudioData';
import {
    ApiScenesIdPutRequestAudioDataFromJSON,
    ApiScenesIdPutRequestAudioDataFromJSONTyped,
    ApiScenesIdPutRequestAudioDataToJSON,
} from './ApiScenesIdPutRequestAudioData';

/**
 * 
 * @export
 * @interface ApiDesignDesignIdScenesPostRequest
 */
export interface ApiDesignDesignIdScenesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiDesignDesignIdScenesPostRequest
     */
    textHtml?: string;
    /**
     * 
     * @type {ApiScenesIdPutRequestAudioData}
     * @memberof ApiDesignDesignIdScenesPostRequest
     */
    audioData?: ApiScenesIdPutRequestAudioData;
    /**
     * 
     * @type {number}
     * @memberof ApiDesignDesignIdScenesPostRequest
     */
    rowOrderPosition?: number;
}

/**
 * Check if a given object implements the ApiDesignDesignIdScenesPostRequest interface.
 */
export function instanceOfApiDesignDesignIdScenesPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiDesignDesignIdScenesPostRequestFromJSON(json: any): ApiDesignDesignIdScenesPostRequest {
    return ApiDesignDesignIdScenesPostRequestFromJSONTyped(json, false);
}

export function ApiDesignDesignIdScenesPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDesignDesignIdScenesPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textHtml': !exists(json, 'text_html') ? undefined : json['text_html'],
        'audioData': !exists(json, 'audio_data') ? undefined : ApiScenesIdPutRequestAudioDataFromJSON(json['audio_data']),
        'rowOrderPosition': !exists(json, 'row_order_position') ? undefined : json['row_order_position'],
    };
}

export function ApiDesignDesignIdScenesPostRequestToJSON(value?: ApiDesignDesignIdScenesPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text_html': value.textHtml,
        'audio_data': ApiScenesIdPutRequestAudioDataToJSON(value.audioData),
        'row_order_position': value.rowOrderPosition,
    };
}

