import { useMemo } from "react";

interface Props<T extends string> {
  items: { type: T; title: string; icon: string }[];
  value: T;
  onChange: (value: T) => void;
}

export const WatchLinkTypeSelect = <T extends string>(props: Props<T>) => {
  const icon = useMemo(
    () => props.items.find((i) => i.type === props.value)?.icon,
    [props.items, props.value]
  );
  return (
    <div className="c-watch-link-modal__select" data-icon={icon}>
      <select
        value={props.value}
        onChange={(e) => props.onChange(e.target.value as T)}
      >
        {!props.value && <option></option> /* 初期読込中の空表示ダミー */}
        {props.items.map((item) => (
          <option key={item.type} value={item.type}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};
