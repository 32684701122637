import { useEffect } from "react";
import { ApiVideoApi, Configuration } from "../../generated/api";
import type { VFC } from "react";

interface Props {
  videoId: number;
}
export const VideoStateChecker: VFC<Props> = (props) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      const api = new ApiVideoApi(
        new Configuration({
          basePath: "",
          headers: { "x-hopper-api-version": "1.0" },
        })
      );
      api.apiVideoIdGet({ id: props.videoId }).then((video) => {
        if (video.state !== "transforming") {
          clearInterval(intervalId);
          location.reload();
        }
      });
    }, 5_000);
    return () => {
      clearInterval(intervalId);
    };
  }, [props.videoId]);
  return null;
};
export default VideoStateChecker;
