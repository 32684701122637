import { FC, useEffect, useMemo, useRef, useState } from "react";
import { ApiWordPronounciationApi, Configuration } from "../../generated/api";
import { AzureCognitiveService } from "../../lib/AzureCognitiveService";
import { phoneme2Ssml } from "../../lib/ssmlHelper";

interface Props {
  csrfToken: string;
}

const WordPronounciationForm: FC<Props> = (props) => {
  const [phoneme, setPhoneme] = useState("");
  const [literal, setLiteral] = useState("");
  const acsRef = useRef<AzureCognitiveService>();
  const literalRef = useRef<HTMLInputElement>();
  const phonemeRef = useRef<HTMLInputElement>();
  const hasInput = phoneme && literal;

  useEffect(() => {
    return () => acsRef.current?.disposeAudioCaches();
  }, []);

  const api = useMemo(
    () =>
      new ApiWordPronounciationApi(
        new Configuration({
          basePath: "",
          headers: {
            "x-hopper-api-version": "1.0",
            "X-CSRF-Token": props.csrfToken,
          },
        })
      ),
    [props.csrfToken]
  );

  const handleLiteralChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLiteral(e.target.value);
  };
  const handlePhonemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneme(e.target.value);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    api
      .apiWordPronounciationsPost({
        apiWordPronounciationsPostRequest: {
          literal,
          phoneme,
        },
      })
      .then(() => {
        location.reload();
      })
      .catch(async (error) => {
        const { messages } = await error.response.json();
        if (messages.literal) {
          literalRef.current.setCustomValidity(messages.literal);
          literalRef.current.reportValidity();
        }
        if (messages.phoneme) {
          phonemeRef.current.setCustomValidity(messages.phoneme);
          phonemeRef.current.reportValidity();
        }
      });
  };

  const handlePreview = async () => {
    acsRef.current ??= new AzureCognitiveService(props.csrfToken);
    await acsRef.current.ensureToken();
    const ssmlString = phoneme2Ssml({
      phoneme,
      literal,
      voiceName: "ja-JP-NanamiNeural",
      speechRate: "1",
    });

    const audioInfo = await acsRef.current.convertSsml(ssmlString).catch(() => {
      phonemeRef.current.setCustomValidity(
        "音声生成に失敗しました。読み方の指定が正しいか確認してください。"
      );
      phonemeRef.current.reportValidity();
    });
    if (!audioInfo) {
      return;
    }
    const audio = new Audio(audioInfo.blobUrl);
    audio.play();
  };

  return (
    <form className="p-word-pronounciation-table-row">
      <div className="p-word-pronounciation-table-column--literal">
        <input
          className="p-word-pronounciation-input"
          onChange={handleLiteralChange}
          type="text"
          required
          ref={literalRef}
          value={literal}
          placeholder="単語を入力"
        />
      </div>
      <div className="p-word-pronounciation-table-column--phoneme">
        <button
          type="button"
          onClick={handlePreview}
          className="material-icons-round p-word-pronounciation__preview-button"
        >
          volume_up
        </button>
        <input
          className="p-word-pronounciation-input"
          onChange={handlePhonemeChange}
          type="text"
          required
          ref={phonemeRef}
          value={phoneme}
          placeholder="読み方を入力"
        />
        <button
          className="c-button--primary"
          type="submit"
          onClick={handleSubmit}
          disabled={!hasInput}
        >
          追加
        </button>
      </div>
    </form>
  );
};

export default WordPronounciationForm;
