import { Folder } from "../types/folder";

export type TreeFolder = Folder & {
  children?: TreeFolder[];
};

/**
 * ユーザーのフォルダをツリー構造に変換する
 * @param folders ユーザーの全フォルダ
 */
export function toTreeFolderFromUserFolders(folders: Folder[]): TreeFolder {
  const map: Record<string, Folder[]> = {};
  for (const folder of folders) {
    const parentFolderId = folder.ancestry
      ? folder.ancestry.split("/").pop()
      : null;
    map[parentFolderId] ??= [];
    map[parentFolderId].push(folder);
  }
  const rootFolder: Folder = {
    id: null,
    name: "マイライブラリー",
    ancestry: null,
    hashid: null,
  };
  return toTreeFolder(rootFolder, map);
}

/**
 * チームのフォルダをツリー構造に変換する
 * @param folders チームの全フォルダ
 */
export function toTreeFolderFromTeamFolders(folders: Folder[]): TreeFolder {
  const map: Record<string, Folder[]> = {};
  for (const folder of folders) {
    const parentFolderId = folder.ancestry
      ? folder.ancestry.split("/").pop()
      : null;
    map[parentFolderId] ??= [];
    map[parentFolderId].push(folder);
  }
  // チームライブラリでは1件rootのフォルダが用意されている
  const rootFolder = folders.find((f) => f.ancestry == null);
  return toTreeFolder(rootFolder, map);
}

function toTreeFolder(
  folder: Folder,
  map: Record<string, Folder[]>
): TreeFolder {
  if (!map[folder.id]) {
    return { ...folder };
  }
  const children = map[folder.id].map((folder) => toTreeFolder(folder, map));
  return { ...folder, children };
}
