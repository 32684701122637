import { useEffect, useState } from "react";
import {
  ApiVideoApi,
  ApiSubtitleApi,
  Configuration,
} from "../../../generated/api";

type SubtitlesPublished = {
  subtitlesPublished: boolean;
  requestSaveSubtitlesPublished(status: boolean): Promise<void>;
};

/**
 * 字幕の公開設定
 */
export function useSubtitlesPublished(
  videoId: number,
  csrfToken: string
): SubtitlesPublished {
  const [subtitlesPublished, setSubtitlesPublished] = useState<boolean>(null);

  useEffect(() => {
    // 初期取得
    const api = videoApi(csrfToken);
    api.apiVideoIdGet({ id: videoId }).then((video) => {
      setSubtitlesPublished(video.subtitles.isPublished ?? false);
    });
  }, [videoId, csrfToken]);

  return {
    subtitlesPublished,
    requestSaveSubtitlesPublished(status) {
      setSubtitlesPublished(status);
      const api = subtitleApi(csrfToken);
      return api
        .apiVideoVideoIdSubtitlesPublishedPatch({
          videoId,
          apiVideoVideoIdSubtitlesPublishedPatchRequest: { status },
        })
        .then(() => {
          return; // レスポンスは空なので捨てる
        });
    },
  };
}

function videoApi(csrfToken: string) {
  return new ApiVideoApi(
    new Configuration({
      basePath: "",
      headers: {
        Accept: "application/json",
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}

function subtitleApi(csrfToken: string) {
  return new ApiSubtitleApi(
    new Configuration({
      basePath: "",
      headers: {
        Accept: "application/json",
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}
