import { VFC } from "react";

export const WatchLinkTooltipContent: VFC<{
  icon: string;
  title: string;
  description?: string;
}> = (props) => {
  return (
    <div className="c-watch-link-tooltip__container">
      <div className="c-watch-link-tooltip__header">
        <span className="material-icons-round icon">{props.icon}</span>
        {props.title}
      </div>
      {props.description && (
        <p className="c-watch-link-tooltip__desc">{props.description}</p>
      )}
    </div>
  );
};
