import { VFC, useState, useEffect, useRef } from "react";
import { AcademyCourseIcon, ICON_LIST } from "./AcademyCourseIcon";

interface Props {
  iconName?: string;
  required?: boolean;
  onChange?: (iconName: string) => void;
}

export const AcademyCourseIconChooser: VFC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement>();
  const [iconName, setIconName] = useState(props.iconName || "");

  useEffect(() => {
    if (!props.onChange) {
      return;
    }
    if (props.iconName !== iconName) {
      props.onChange(iconName);
    }
  }, [props, iconName]);

  return (
    <div>
      <input
        style={{
          // 完全に隠すとブラウザのバリデーションメッセージが消えるので最小サイズで表示
          width: 30,
          height: 8,
          opacity: 0,
          padding: 0,
          margin: 0,
          border: "none",
          display: "block",
        }}
        ref={inputRef}
        type="text"
        name="icon_name"
        value={iconName}
        required={props.required}
        onChange={(e) => {
          setIconName(e.target.value);
        }}
      />
      <div className="p-academy-edit__icon-chooser">
        {ICON_LIST.map((icon) => (
          <button
            key={icon.name}
            className={`p-academy-edit__icon-chooser-button ${
              iconName === icon.name ? "is-selected" : ""
            }`}
            type="button"
            aria-label={icon.name}
            onClick={() => {
              setIconName(icon.name);
            }}
          >
            <AcademyCourseIcon iconName={icon.name} />
          </button>
        ))}
      </div>
    </div>
  );
};
