/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiWordPronounciationsIdPut200ResponseLastModifiedBy } from './ApiWordPronounciationsIdPut200ResponseLastModifiedBy';
import {
    ApiWordPronounciationsIdPut200ResponseLastModifiedByFromJSON,
    ApiWordPronounciationsIdPut200ResponseLastModifiedByFromJSONTyped,
    ApiWordPronounciationsIdPut200ResponseLastModifiedByToJSON,
} from './ApiWordPronounciationsIdPut200ResponseLastModifiedBy';

/**
 * 
 * @export
 * @interface ApiWordPronounciationsIdPut200Response
 */
export interface ApiWordPronounciationsIdPut200Response {
    /**
     * 
     * @type {number}
     * @memberof ApiWordPronounciationsIdPut200Response
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsIdPut200Response
     */
    literal?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsIdPut200Response
     */
    phoneme?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsIdPut200Response
     */
    lastModifiedAt?: string;
    /**
     * 
     * @type {ApiWordPronounciationsIdPut200ResponseLastModifiedBy}
     * @memberof ApiWordPronounciationsIdPut200Response
     */
    lastModifiedBy?: ApiWordPronounciationsIdPut200ResponseLastModifiedBy;
}

/**
 * Check if a given object implements the ApiWordPronounciationsIdPut200Response interface.
 */
export function instanceOfApiWordPronounciationsIdPut200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiWordPronounciationsIdPut200ResponseFromJSON(json: any): ApiWordPronounciationsIdPut200Response {
    return ApiWordPronounciationsIdPut200ResponseFromJSONTyped(json, false);
}

export function ApiWordPronounciationsIdPut200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWordPronounciationsIdPut200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'literal': !exists(json, 'literal') ? undefined : json['literal'],
        'phoneme': !exists(json, 'phoneme') ? undefined : json['phoneme'],
        'lastModifiedAt': !exists(json, 'lastModifiedAt') ? undefined : json['lastModifiedAt'],
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : ApiWordPronounciationsIdPut200ResponseLastModifiedByFromJSON(json['lastModifiedBy']),
    };
}

export function ApiWordPronounciationsIdPut200ResponseToJSON(value?: ApiWordPronounciationsIdPut200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'literal': value.literal,
        'phoneme': value.phoneme,
        'lastModifiedAt': value.lastModifiedAt,
        'lastModifiedBy': ApiWordPronounciationsIdPut200ResponseLastModifiedByToJSON(value.lastModifiedBy),
    };
}

