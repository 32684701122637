import { VFC } from "react";

import iconCalendar from "images/academy/course_icon/calendar.png";
import iconChat from "images/academy/course_icon/chat.png";
import iconChecklist from "images/academy/course_icon/checklist.png";
import iconComment from "images/academy/course_icon/comment.png";
import iconContents from "images/academy/course_icon/contents.png";
import iconDevice from "images/academy/course_icon/device.png";
import iconDiamond from "images/academy/course_icon/diamond.png";
import iconEye from "images/academy/course_icon/eye.png";
import iconGraph from "images/academy/course_icon/graph.png";
import iconGroup from "images/academy/course_icon/group.png";
import iconHandshake from "images/academy/course_icon/handshake.png";
import iconLightBulb from "images/academy/course_icon/light-bulb.png";
import iconMedal from "images/academy/course_icon/medal.png";
import iconMoney from "images/academy/course_icon/money.png";
import iconNote from "images/academy/course_icon/note.png";
import iconPc from "images/academy/course_icon/pc.png";
import iconSearch from "images/academy/course_icon/search.png";
import iconSetting from "images/academy/course_icon/setting.png";
import iconShare from "images/academy/course_icon/share.png";
import iconShield from "images/academy/course_icon/shield.png";
import iconSpeaker from "images/academy/course_icon/speaker.png";
import iconStar from "images/academy/course_icon/star.png";
import iconSupport from "images/academy/course_icon/support.png";
import iconTime from "images/academy/course_icon/time.png";
import iconVideo from "images/academy/course_icon/video.png";

// ※表示順を保持
export const ICON_LIST = [
  { name: "support", img: iconSupport },
  { name: "calendar", img: iconCalendar },
  { name: "pc", img: iconPc },
  { name: "device", img: iconDevice },
  { name: "setting", img: iconSetting },
  { name: "speaker", img: iconSpeaker },
  { name: "light-bulb", img: iconLightBulb },
  { name: "search", img: iconSearch },
  { name: "chat", img: iconChat },
  { name: "medal", img: iconMedal },
  { name: "note", img: iconNote },
  { name: "diamond", img: iconDiamond },
  { name: "handshake", img: iconHandshake },
  { name: "eye", img: iconEye },
  { name: "group", img: iconGroup },
  { name: "comment", img: iconComment },
  { name: "checklist", img: iconChecklist },
  { name: "shield", img: iconShield },
  { name: "graph", img: iconGraph },
  { name: "money", img: iconMoney },
  { name: "star", img: iconStar },
  { name: "video", img: iconVideo },
  { name: "share", img: iconShare },
  { name: "time", img: iconTime },
  { name: "contents", img: iconContents },
] as const;

const iconMap = ICON_LIST.reduce<Record<string, string>>((ret, item) => {
  ret[item.name] = item.img;
  return ret;
}, {});

export const AcademyCourseIcon: VFC<{
  iconName: string;
  className?: string;
}> = (props) => {
  return (
    <img
      src={iconMap[props.iconName]}
      alt={props.iconName}
      className={props.className}
    />
  );
};
