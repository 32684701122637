/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoPreparePost200Response
 */
export interface ApiVideoPreparePost200Response {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoPreparePost200Response
     */
    assetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoPreparePost200Response
     */
    uploadUrl?: string;
}

/**
 * Check if a given object implements the ApiVideoPreparePost200Response interface.
 */
export function instanceOfApiVideoPreparePost200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoPreparePost200ResponseFromJSON(json: any): ApiVideoPreparePost200Response {
    return ApiVideoPreparePost200ResponseFromJSONTyped(json, false);
}

export function ApiVideoPreparePost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoPreparePost200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetName': !exists(json, 'asset_name') ? undefined : json['asset_name'],
        'uploadUrl': !exists(json, 'upload_url') ? undefined : json['upload_url'],
    };
}

export function ApiVideoPreparePost200ResponseToJSON(value?: ApiVideoPreparePost200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset_name': value.assetName,
        'upload_url': value.uploadUrl,
    };
}

