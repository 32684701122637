import { LoadingContextProvider } from "../../common/loading/LoadingContext";
import { VideoContextProvider } from "../VideoContext";
import { DesignInfoEdit, DesignInfoEditProps } from "./DesignInfoEdit";

function DesignInfoEditContainer(props: DesignInfoEditProps) {
  return (
    <LoadingContextProvider>
      <VideoContextProvider videoId={props.videoId}>
        <DesignInfoEdit {...props} />
      </VideoContextProvider>
    </LoadingContextProvider>
  );
}
export default DesignInfoEditContainer;
