import {
  useState,
  useEffect,
  useCallback,
  MouseEvent,
  startTransition,
} from "react";

export function useClipContextMenuPosition() {
  const [position, setPosition] = useState<{ top: number; left: number }>(null);

  useEffect(() => {
    if (!position) {
      return;
    }
    // ページ中の任意の場所をクリックされたら閉じる
    const handler = () => setPosition(null);
    const option: AddEventListenerOptions = { once: true, passive: true };
    document.body.addEventListener("click", handler, option);
    document.body.addEventListener("contextmenu", handler, option);
    return () => {
      document.body.removeEventListener("click", handler);
      document.body.removeEventListener("contextmenu", handler);
    };
  }, [position]);

  function show(e: MouseEvent<HTMLElement>) {
    const top = e.clientY;
    let left = e.clientX;
    const right = left + 100; // 想定するメニューの右端位置
    if (right > document.body.clientWidth) {
      left -= 100; // メニューが画面の右端から飛び出さないよう調整
    }
    startTransition(() => {
      setPosition({ left, top });
    });
  }

  return {
    clipContextMenuPosition: position,
    showClipContextMenu: useCallback(show, []),
  };
}
