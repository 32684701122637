import { useState, useEffect, useCallback, startTransition } from "react";

export function useSubMenuVisible() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      return;
    }
    // ページ中の任意の場所をクリックされたらメニューを閉じる
    const handler = () => setVisible(false);
    document.body.addEventListener("click", handler, {
      once: true,
      passive: true,
    });
    return () => {
      document.body.removeEventListener("click", handler);
    };
  }, [visible]);

  function toggleVisible(newVisible?: boolean) {
    startTransition(() => {
      setVisible((visible) => newVisible ?? !visible);
    });
  }

  return [visible, useCallback(toggleVisible, [])] as const;
}
