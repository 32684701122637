import { useRef, useCallback, useEffect, useReducer } from "react";
import {
  ApiSubtitleApi,
  ApiVideoApi,
  Configuration,
} from "../../../generated/api";

export type SubtitlesCue = {
  readonly key: string; // 画面上の制御用でユニークならなんでもよい。
  readonly startTimeMs: number;
  readonly endTimeMs: number;
  readonly value: string;

  hasStartTimeError?: boolean;
  hasEndTimeError?: boolean;
};

type ReducerAction =
  | { type: "init"; cues: SubtitlesCue[] }
  | { type: "update"; key: SubtitlesCue["key"]; cue: Omit<SubtitlesCue, "key"> }
  | { type: "delete"; key: SubtitlesCue["key"] }
  | { type: "add-new-after"; key: SubtitlesCue["key"] };

type Video = Awaited<ReturnType<ApiVideoApi["apiVideoIdGet"]>>;

function reduceCues(video: Video, cues: SubtitlesCue[], action: ReducerAction) {
  if (action.type === "init") {
    return action.cues;
  } else if (action.type === "update") {
    const index = cues.findIndex((cue) => cue.key === action.key);
    cues[index] = { ...action.cue, key: action.key };
    return cues.slice();
  } else if (action.type === "delete") {
    const index = cues.findIndex((cue) => cue.key === action.key);
    cues.splice(index, 1);
    return cues.slice();
  } else if (action.type === "add-new-after") {
    const index = cues.findIndex((cue) => cue.key === action.key);
    const startTimeMs = cues[index]?.endTimeMs ?? 0;
    const spanMs = Math.min(
      (cues[index + 1]?.startTimeMs ?? video.playTimeMs) - startTimeMs,
      3_000
    );
    const newCue = {
      key: crypto.randomUUID(),
      startTimeMs,
      endTimeMs: startTimeMs + spanMs,
      value: "",
    };
    cues.splice(index + 1, 0, newCue);
    return cues.slice();
  }
  throw new Error(`unknown action.type: ${JSON.stringify(action)}`);
}

export function validateCues(video: Video, cues: SubtitlesCue[]) {
  cues.forEach((cue, i) => {
    delete cue.hasStartTimeError;
    delete cue.hasEndTimeError;
    if (!Number.isSafeInteger(cue.startTimeMs)) {
      cue.hasStartTimeError = true;
    }
    if (!Number.isSafeInteger(cue.endTimeMs)) {
      cue.hasEndTimeError = true;
    }
    if (cue.endTimeMs - cue.startTimeMs < 100) {
      cue.hasStartTimeError = true;
      cue.hasEndTimeError = true;
    }
    if (cue.endTimeMs > video.playTimeMs) {
      cue.hasEndTimeError = true;
    }
    if (cues[i - 1]?.endTimeMs > cue.startTimeMs) {
      cue.hasStartTimeError = true;
    }
    if (cues[i + 1]?.startTimeMs < cue.endTimeMs) {
      cue.hasEndTimeError = true;
    }
  });
  return cues;
}

export function useSubtitlesCues(videoId: number, csrfToken: string) {
  const videoRef = useRef<Video>();
  const reducer = useCallback((cues: SubtitlesCue[], action: ReducerAction) => {
    const video = videoRef.current;
    return validateCues(video, reduceCues(video, cues, action));
  }, []);
  const [cues, dispatchCues] = useReducer(reducer, []);

  useEffect(() => {
    // 初期取得
    Promise.all([
      videoApi(csrfToken).apiVideoIdGet({ id: videoId }),
      subtitleApi(csrfToken).apiVideoVideoIdSubtitlesGet({ videoId }),
    ]).then(([video, subtitles]) => {
      videoRef.current = video;
      const initialCues = subtitles.map<SubtitlesCue>((s) => ({
        key: crypto.randomUUID(),
        startTimeMs: s.startTimeMs,
        endTimeMs: s.endTimeMs,
        value: s.value,
      }));
      dispatchCues({ type: "init", cues: initialCues });
    });
  }, [videoId, csrfToken]);

  const requestSaveCues = async () => {
    // 値のマッピング漏れがないようパラメータの型を取り出してRequiredに。
    type ReqSubtitleType = Required<
      Parameters<
        typeof api.apiVideoVideoIdSubtitlesPut
      >[0]["apiVideoVideoIdSubtitlesPutRequest"]["subtitles"][0]
    >;
    const subtitles: ReqSubtitleType[] = cues.map((cue) => {
      return {
        startTimeMs: cue.startTimeMs,
        endTimeMs: cue.endTimeMs,
        value: cue.value,
      };
    });
    const api = subtitleApi(csrfToken);
    await api.apiVideoVideoIdSubtitlesPut({
      videoId,
      apiVideoVideoIdSubtitlesPutRequest: { subtitles },
    });
  };

  const requestDestroyCues = async () => {
    const api = subtitleApi(csrfToken);
    await api.apiVideoVideoIdSubtitlesDelete({ videoId });
  };

  return {
    cues,
    dispatchCues,
    requestSaveCues: useCallback(requestSaveCues, [csrfToken, videoId, cues]),
    requestDestroyCues: useCallback(requestDestroyCues, [csrfToken, videoId]),
  };
}

function subtitleApi(csrfToken: string) {
  return new ApiSubtitleApi(
    new Configuration({
      basePath: "",
      headers: {
        Accept: "application/json",
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}

function videoApi(csrfToken: string) {
  return new ApiVideoApi(
    new Configuration({
      basePath: "",
      headers: {
        Accept: "application/json",
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}
