import { VFC, useState, useRef, useEffect } from "react";
import type { ChartData, ChartType, TooltipModel } from "chart.js";
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
} from "chart.js";
import { color as colorHelper } from "chart.js/helpers";

Chart.register(BarController, BarElement, LinearScale, CategoryScale, Tooltip);

type Props = {
  /** 基調色: `#rrggbb`, or HTML Color name */
  color: string;
  periodType: "monthly" | "weekly";
  /** データ種別: 視聴時間 or 視聴回数。ツールチップやY軸ラベルの変更に使う */
  dataType: "watchtime" | "views";
  data: {
    startedAt: string; // ISO8601
    amount: number; // 視聴時間(ミリ秒) or 視聴回数
  }[];
};

const dataTypeUnits: Record<Props["dataType"], string> = {
  watchtime: "分",
  views: "回",
};

export const SummaryBarGraph: VFC<Props> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const [tooltipModel, setTooltipModel] = useState<TooltipModel<ChartType>>();

  useEffect(() => {
    // データの変換をuseEffectの外で行うと、chart.jsでエラーになるので改修時は注意。
    // Reactのライフサイクル毎にuseEffectの依存値が変化する事になり、頻繁にchart.jsの最初期化を行ってしまうことになるため。
    const data = convertGraphData(props.periodType, props.dataType, props.data);
    const hoverColor = colorHelper(props.color).alpha(0.1).rgbString();

    const chart = new Chart(canvasRef.current, {
      type: "bar",
      data,
      options: {
        animation: false,
        responsive: true,
        scales: {
          y: {
            grid: { drawBorder: false },
            ticks: {
              autoSkipPadding: 20, // 余裕を持ってY軸ラベルが表示されるように
              callback(value) {
                if (value === 0) {
                  return `0(${dataTypeUnits[props.dataType]})`;
                }
                return value;
              },
            },
          },
          x: {
            grid: { display: false },
            title: { display: false },
          },
        },
        elements: {
          bar: {
            backgroundColor: props.color,
            hoverBackgroundColor: hoverColor,
          },
        },
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
            enabled: false, // デフォルトのツールチップは消す
            external({ tooltip }) {
              setTooltipModel({ ...tooltip });
            },
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, [props.data, props.color, props.periodType, props.dataType]);

  return (
    <div style={{ position: "relative" }}>
      <canvas ref={canvasRef}></canvas>
      {tooltipModel?.opacity > 0 && (
        <div
          role="tooltip"
          hidden={tooltipModel?.opacity <= 0}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            transition: "transform 0.25s",
            transform: `translateX(calc(${tooltipModel.caretX}px - 50%))
                        translateY(calc(${tooltipModel.caretY}px - 100% - 16px))`,
          }}
          className="c-graph-tooltip text-center"
        >
          <p className="c-graph-tooltip__opt-text">{tooltipModel.title}</p>
          <p
            className="c-graph-tooltip__primary-text"
            style={{ color: props.color }}
          >
            {tooltipModel.dataPoints[0].formattedValue}
            {dataTypeUnits[props.dataType]}
          </p>
        </div>
      )}
    </div>
  );
};
export default SummaryBarGraph;

export function convertGraphData(
  periodType: Props["periodType"],
  dataType: Props["dataType"],
  data: Props["data"]
): ChartData<"bar", number[], string> {
  // e.g. 2021/10 or 2021/10/07
  const dateFormatter = new Intl.DateTimeFormat("ja-JP", {
    year: "numeric",
    month: "2-digit",
    day: periodType === "weekly" ? "2-digit" : undefined,
  });
  return (data || []).reduce<ReturnType<typeof convertGraphData>>(
    (result, data) => {
      const label = dateFormatter.format(Date.parse(data.startedAt));
      const amount =
        dataType === "watchtime"
          ? Math.floor(data.amount / 1000 / 60)
          : data.amount;
      result.labels.push(label);
      result.datasets[0].data.push(amount);
      return result;
    },
    {
      labels: [],
      datasets: [{ data: [] }],
    }
  );
}
