import { VFC, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { ExamQuestion } from "./ExamQuestion";

interface Props {
  isOpen: boolean;
  inputName: boolean;
  userNameCandidate: string;
  isLoggedIn: boolean;
  onClick: (userName: string, answers: ExamAnswer[]) => void;
  onClose: () => void;
  questions: { id: number; text: string; options: { text: string }[] }[];
}

type ExamAnswer = { examQuestionId: number; text: string };

export const CreateExamAnswerModal: VFC<Props> = (props) => {
  const [answers, setAnswers] = useState<ExamAnswer[]>(
    Array.from({ length: props.questions.length })
  );
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (!props.inputName || !props.isOpen || !props.userNameCandidate) {
      return;
    }

    if (props.isLoggedIn) {
      setUserName(props.userNameCandidate);
    } else {
      const retake = confirm(
        `${props.userNameCandidate}さんとしてテストを受けます。よろしいですか？`
      );
      if (retake) {
        setUserName(props.userNameCandidate);
      }
    }
  }, [
    props.inputName,
    props.isLoggedIn,
    props.isOpen,
    props.userNameCandidate,
  ]);

  const resetInputs = () => {
    setAnswers(Array.from({ length: props.questions.length }));
    setUserName("");
  };

  const userNameInput = props.inputName ? (
    <section className="p-exam-answer-username">
      <p className="p-exam-answer-username__heading">
        あなたのお名前を入力してください
      </p>
      <input
        type="text"
        className="p-exam-answer-username__input"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
    </section>
  ) : null;

  const terms = props.inputName ? (
    <p className="p-exam-answer__terms">
      テストを受験するにあたり、氏名をVideoTouchに登録します。 VideoTouchの
      <a
        href="https://videotouch.co.jp/privacy"
        target="_blank"
        rel="noreferrer"
      >
        プライバシーポリシー
      </a>
      をご確認ください。
    </p>
  ) : null;

  return (
    <ReactModal
      isOpen={props.isOpen}
      overlayClassName="c-dialog__overlay vertical-center"
      className="c-dialog__container p-exam-answer-modal"
    >
      <div className="c-dialog__header">
        <h2 className="c-dialog__header__title">テストに回答する</h2>
        <button
          className="material-icons-round c-dialog__header__close-icon"
          onClick={() => {
            resetInputs();
            props.onClose();
          }}
        >
          close
        </button>
      </div>

      <div className="c-dialog__content">
        {userNameInput}
        {props.questions.map((question, index) => (
          <ExamQuestion
            key={question.id}
            questionText={question.text}
            questionNumber={index + 1}
            examQuestionId={question.id}
            options={question.options}
            selectedAnswer={answers[index]}
            onSelect={(answer) => {
              setAnswers(Object.assign([], answers, { [index]: answer }));
            }}
          />
        ))}
      </div>
      <div className="c-dialog__footer p-exam-answer-modal__footer">
        {terms}
        <button
          className="c-button--primary narrow-padding"
          type="submit"
          disabled={
            answers.some((answer) => !answer) || (props.inputName && !userName)
          }
          onClick={() => {
            props.onClick(userName, answers);
            resetInputs();
          }}
        >
          {props.inputName ? "上記に同意し、回答する" : "回答する"}
        </button>
      </div>
    </ReactModal>
  );
};
