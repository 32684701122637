import { useState, Fragment, VFC } from "react";
import ReactModal from "react-modal";
import {
  ApiFolderApi,
  Configuration,
  ApiFoldersPost422Response,
} from "../../generated/api";
import { isResponseError } from "../../lib/isResponseError";
import { notify } from "../notification";

interface Props {
  parentFolderId: number;
  csrfToken: string;
  ownerId: number;
  ownerType: string;
}

const CreateFolderModal: VFC<Props> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [submitable, setSubmitable] = useState(false);
  const [name, setName] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const handleInputValue = (value: string) => {
    const name = value.trim();
    setName(name);
    setSubmitable(name.length > 0);
  };

  const handleCloseButton = () => {
    setModalVisible(false);
    setErrMsg("");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params = {
      parentFolderId: props.parentFolderId,
      folder: {
        name: name,
        ownerId: props.ownerId,
        ownerType: props.ownerType,
      },
    };

    const api = new ApiFolderApi(
      new Configuration({
        basePath: window.location.origin,
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiFoldersPost({ apiFoldersPostRequest: params })
      .then(() => {
        location.reload();
        setModalVisible(false);
      })
      .catch((reason) => {
        if (isResponseError(reason) && reason.response.status === 422) {
          reason.response.json().then((body: ApiFoldersPost422Response) => {
            setErrMsg(body.messages.name.join("\n"));
          });
        } else {
          setModalVisible(false);
          const msg =
            "エラーが発生しました。画面をリロードしてもう一度試してください。";
          notify(msg);
          throw reason;
        }
      });
  };

  return (
    <Fragment>
      <button
        className="p-video-list__header-button"
        onClick={() => setModalVisible(!modalVisible)}
      >
        <i className="material-icons-round p-video-list__header-button-icon">
          create_new_folder
        </i>
        新しいフォルダ
      </button>
      <ReactModal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        overlayClassName="c-dialog__overlay"
        className="c-dialog__container"
      >
        <div className="c-dialog__header">
          <h2 className="c-dialog__header__title">フォルダの作成</h2>
          <button
            className="material-icons-round c-dialog__header__close-icon"
            onClick={() => handleCloseButton()}
          >
            close
          </button>
        </div>
        {errMsg && <p className="c-dialog__err-msgs">{errMsg}</p>}
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="c-dialog__content">
            <input
              type="text"
              name="name"
              className="c-dialog__text-field-input"
              onChange={(e) => handleInputValue(e.target.value)}
              autoFocus={true}
              autoComplete="off"
            />
          </div>
          <div className="c-dialog__footer">
            <button
              className="c-button--outlined narrow-padding"
              type="button"
              onClick={() => handleCloseButton()}
            >
              キャンセル
            </button>
            <input
              className="c-button--primary narrow-padding"
              type="submit"
              value="作成"
              disabled={!submitable}
            />
          </div>
        </form>
      </ReactModal>
    </Fragment>
  );
};

export default CreateFolderModal;
