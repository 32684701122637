/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoIdMovePatchRequest
 */
export interface ApiVideoIdMovePatchRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiVideoIdMovePatchRequest
     */
    folderId?: number;
}

/**
 * Check if a given object implements the ApiVideoIdMovePatchRequest interface.
 */
export function instanceOfApiVideoIdMovePatchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoIdMovePatchRequestFromJSON(json: any): ApiVideoIdMovePatchRequest {
    return ApiVideoIdMovePatchRequestFromJSONTyped(json, false);
}

export function ApiVideoIdMovePatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoIdMovePatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folderId': !exists(json, 'folder_id') ? undefined : json['folder_id'],
    };
}

export function ApiVideoIdMovePatchRequestToJSON(value?: ApiVideoIdMovePatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folder_id': value.folderId,
    };
}

