import { useReducer } from "react";
import { ApiVideoApi, ApiNarrationApi } from "../../generated/api";
import { formatTimeMs } from "../../lib/formatTimeMs";

type Video = Awaited<ReturnType<ApiVideoApi["apiVideoIdGet"]>>;
type VideoNarration = Awaited<
  ReturnType<ApiNarrationApi["apiVideoVideoIdNarrationsGet"]>
>[number];

type ViewState = {
  playing: boolean;
  playingNarrationKey?: NarrationState["key"];
  video: Video;
  voiceName: string;
  speechRate: string;
  narrations: NarrationState[];
};

type NarrationError =
  | "このフィールドを入力してください"
  | "ナレーションを生成できないテキストです"
  | `ビデオの再生時間 ${string} を越えています`
  | `次のナレーションの開始時間 ${string} と重なっています`;

export type NarrationState = {
  key: string;
  startTimeMs: number;
  text: string;
  error?: NarrationError;
  audio?: {
    blobSize: number;
    blobUrl: string;
    durationMs: number;
  };
};

type ReducerAction =
  | { type: "set-voice-name"; voiceName: string }
  | { type: "set-speech-rate"; speechRate: string }
  | {
      type: "set-narrations";
      narrations: (Pick<NarrationState, "startTimeMs" | "text" | "audio"> &
        Partial<Pick<NarrationState, "key">>)[];
    }
  | {
      type: "set-narration-item";
      narration: Pick<NarrationState, "key" | "startTimeMs" | "text" | "audio">;
    }
  | { type: "delete-narration"; key: NarrationState["key"] }
  | { type: "add-new-narration-after"; key: NarrationState["key"] }
  | { type: "set-playing"; playing: boolean }
  | { type: "set-current-time"; currentTimeMs: number };

function reducer(state: ViewState, action: ReducerAction) {
  let result: ViewState;
  if (action.type === "set-voice-name") {
    result = {
      ...state,
      voiceName: action.voiceName,
      narrations: state.narrations.map((n) => ({ ...n, audio: null })), // 音声種別の変更時は生成済み音声もリセット
    };
  } else if (action.type === "set-speech-rate") {
    result = {
      ...state,
      speechRate: action.speechRate,
      narrations: state.narrations.map((n) => ({ ...n, audio: null })), // 音声のパラメータ関連の変更時は生成済み音声もリセット
    };
  } else if (action.type === "set-narrations") {
    result = {
      ...state,
      narrations: action.narrations.map((n) => ({
        key: n.key ?? crypto.randomUUID(),
        startTimeMs: n.startTimeMs,
        text: n.text,
        audio: n.audio,
      })),
    };
  } else if (action.type === "set-narration-item") {
    const index = state.narrations.findIndex(
      (n) => n.key === action.narration.key
    );
    state.narrations[index] = action.narration;
    result = { ...state, narrations: [...state.narrations] };
  } else if (action.type === "delete-narration") {
    const index = state.narrations.findIndex((n) => n.key === action.key);
    state.narrations.splice(index, 1);
    result = { ...state };
  } else if (action.type === "add-new-narration-after") {
    const index = state.narrations.findIndex((n) => n.key === action.key);
    const startTimeMs =
      state.narrations[index]?.startTimeMs != null
        ? state.narrations[index]?.startTimeMs + 3_000
        : 0;
    const newNarration: NarrationState = {
      key: crypto.randomUUID(),
      text: "",
      startTimeMs,
    };
    state.narrations.splice(index + 1, 0, newNarration);
    result = { ...state };
  } else if (action.type === "set-playing") {
    result = { ...state, playing: action.playing };
  } else if (action.type === "set-current-time") {
    const currentTimeMs = action.currentTimeMs;
    const playingNarrationKey = state.narrations
      .filter((n) => n.audio?.durationMs)
      .find(
        (n) =>
          n.startTimeMs <= currentTimeMs &&
          currentTimeMs <= n.startTimeMs + n.audio.durationMs
      )?.key;
    if (playingNarrationKey !== state.playingNarrationKey) {
      result = { ...state, playingNarrationKey };
    } else {
      result = state; // 変更なし
    }
  } else {
    throw new Error(`unknown action.type: ${JSON.stringify(action)}`);
  }
  return validate(result);
}

function validate(state: ViewState): ViewState {
  state.narrations.forEach((narration, index) => {
    delete narration.error;
    if (!narration.text?.trim()) {
      narration.error = "このフィールドを入力してください";
    }
    const nextNarration = state.narrations[index + 1];
    if (narration.startTimeMs > state.video.playTimeMs) {
      narration.error = `ビデオの再生時間 ${formatTimeMs(
        state.video.playTimeMs
      )} を越えています`;
    } else if (narration.audio) {
      if (narration.audio.blobSize === 0) {
        narration.error = "ナレーションを生成できないテキストです";
      }
      // エンコードの都合上、 50ms 程度の余裕が必要。画面上の制御としてはキリ良く 100ms でチェック。
      const endTimeMs =
        narration.startTimeMs + narration.audio.durationMs + 100;
      if (endTimeMs > state.video.playTimeMs) {
        narration.error = `ビデオの再生時間 ${formatTimeMs(
          state.video.playTimeMs
        )} を越えています`;
      } else if (nextNarration) {
        if (endTimeMs > nextNarration.startTimeMs) {
          narration.error = `次のナレーションの開始時間 ${formatTimeMs(
            nextNarration.startTimeMs
          )} と重なっています`;
        }
      }
    }
  });
  return state;
}

export function useNarrationsView(
  video: Video,
  videoNarrations: VideoNarration[],
  defaultVoiceName: string,
  defaultSpeechRate: string
) {
  const [state, dispatch] = useReducer(
    reducer,
    { video, videoNarrations, defaultVoiceName, defaultSpeechRate },
    init
  );
  return [state, dispatch] as const;
}

function init(arg: {
  video: Video;
  videoNarrations: VideoNarration[];
  defaultVoiceName: string;
  defaultSpeechRate: string;
}): ViewState {
  if (!arg.videoNarrations?.length) {
    return {
      playing: false,
      video: arg.video,
      voiceName: arg.defaultVoiceName,
      speechRate: arg.defaultSpeechRate,
      narrations: [],
    };
  }
  return {
    playing: false,
    video: arg.video,
    voiceName:
      arg.videoNarrations?.at(0)?.audioData?.voiceName || arg.defaultVoiceName,
    speechRate:
      arg.videoNarrations?.at(0)?.audioData?.speechRate ||
      arg.defaultSpeechRate,
    narrations: arg.videoNarrations?.map((vn) => ({
      key: crypto.randomUUID(),
      text: vn.text,
      startTimeMs: vn.startTimeMs,
    })),
  };
}
