import type { ReactNode } from "react";
import { useExpandable } from "../hooks";

interface DesignPreviewPaneProps {
  children: ReactNode;
}

export function DesignPreviewPane(props: DesignPreviewPaneProps) {
  const { expanded, canExpand, toggleExpanded } = useExpandable(true, 1280);
  const expandButtonText = expanded ? "エリアを縮小" : "エリアを拡大";

  return (
    <div
      className={`p-design__preview-pane ${
        expanded ? "p-design__preview-pane--expanded" : ""
      }`}
    >
      {props.children}

      <div className="p-design-flex-spacer" />

      <button
        className="p-design-expand-button p-design-expand-button--align-left"
        style={canExpand ? null : { display: "none" }}
        onClick={toggleExpanded}
        title={expandButtonText}
      >
        <i className="material-icons-round p-design-expand-button__icon">
          {expanded ? "chevron_right" : "chevron_left"}
        </i>
        <span className="p-design-expand-button__text">{expandButtonText}</span>
      </button>
    </div>
  );
}
