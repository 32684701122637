import { ApiDesignIdAddCreatomateJobPostRequestCreatomateParam } from "app/javascript/generated/api";
import { loadDefaultJapaneseParser } from "budoux";
import type { getDesignScenes } from "./requests";

export type DesignScene = Awaited<ReturnType<typeof getDesignScenes>>[number];
export type DesignSceneWithAudio = DesignScene & {
  audioUrl: string;
  durationMs: number;
};

export function buildCreatomateParam(scenes: DesignSceneWithAudio[]) {
  let totalDuration = 0;
  const elements = scenes
    .map((scene, index) => {
      const duration = scene.durationMs / 1000 || 0;
      if (duration === 0) {
        return []; // 音声のないシーンはスキップ
      }
      const animationDuration = 0.2; // エフェクトの長さ
      const time = totalDuration; // タイムライン全体の中でのこのシーンの位置
      totalDuration += duration;
      const animations = [
        index > 0
          ? {
              time: "start",
              duration: animationDuration,
              easing: "quadratic-out",
              type: "fade",
            }
          : null,
      ].filter(Boolean);
      return [
        {
          track: null,
          type: "image",
          source: scene.imageUrl,
          fit: "contain",
          duration: duration + animationDuration, // 次シーンとのクロスフェードのため少し長めにする
          time: Math.max(time - animationDuration, 0), // エフェクト時間のため手間にずらす。0未満にはならないように。
          animations,
        },
        {
          track: null,
          type: "audio",
          source: scene.audioUrl,
          duration,
          time,
        },
      ].filter((t) => !!t.source);
    })
    .flat()
    .map((s, i) => ({ ...s, track: i + 1 }));

  const param: ApiDesignIdAddCreatomateJobPostRequestCreatomateParam = {
    source: {
      outputFormat: "mp4",
      duration: totalDuration, // 分割レンダリングのためduration必須
      frameRate: 30, // 指定しないと60fpsとか過剰なエンコードになってしまうことがある
      width: 1920,
      height: 1080,
      elements,
    },
  };

  // 5分こえたら分割レンダリング
  if (totalDuration > 5 * 60) {
    param.multipart = Math.ceil(totalDuration / 5);
  }

  return param;
}

const textParser = loadDefaultJapaneseParser();
export function buildSubtitles(scenes: DesignSceneWithAudio[]) {
  let totalDuration = 0;
  const subtitles = scenes
    .map((scene) => {
      if (!scene.textHtml || !scene.durationMs) {
        return []; // テキスト・音声のないシーンはスキップ
      }
      return splitSubtitle(scene).map((s) => {
        const startTime = totalDuration;
        const endTime = totalDuration + s.duration;
        totalDuration = endTime;
        return { startTime, endTime, text: s.text };
      });
    })
    .flat();
  return subtitles;

  function splitSubtitle(
    scene: DesignSceneWithAudio
  ): { duration: number; text: string }[] {
    const texts = splitText(scene.textHtml);
    const totalLength = texts.reduce((r, t) => r + t.length, 0);
    return texts.map((text) => {
      const duration = ((text.length / totalLength) * scene.durationMs) / 1000;
      return { duration, text };
    });
  }

  function splitText(src: string): string[] {
    // 改行で分離
    const lines = src
      .trim()
      .split(/\n+/)
      .map((s) => s.trim());

    // 文節で区切り、字幕表示に耐えうる文字数にまとめる。
    const MAX_CHARS = 60;
    const result = lines
      .map((line) => {
        const chunks = textParser.parse(line);
        const result = [""];
        for (const chunk of chunks) {
          const currentIndex = result.length - 1;
          if (result[currentIndex].length + chunk.length <= MAX_CHARS) {
            result[currentIndex] += chunk;
          } else {
            result.push(chunk);
          }
        }
        return result;
      })
      .flat();
    return result;
  }
}
