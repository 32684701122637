/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner } from './ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner';
import {
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerFromJSON,
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerFromJSONTyped,
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerToJSON,
} from './ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner';

/**
 * 
 * @export
 * @interface ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource
 */
export interface ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource {
    /**
     * 
     * @type {string}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource
     */
    outputFormat?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource
     */
    frameRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource
     */
    height?: number;
    /**
     * 
     * @type {Array<ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner>}
     * @memberof ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource
     */
    elements?: Array<ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInner>;
}

/**
 * Check if a given object implements the ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource interface.
 */
export function instanceOfApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceFromJSON(json: any): ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource {
    return ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceFromJSONTyped(json, false);
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outputFormat': !exists(json, 'output_format') ? undefined : json['output_format'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'frameRate': !exists(json, 'frame_rate') ? undefined : json['frame_rate'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'elements': !exists(json, 'elements') ? undefined : ((json['elements'] as Array<any>).map(ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerFromJSON)),
    };
}

export function ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceToJSON(value?: ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'output_format': value.outputFormat,
        'duration': value.duration,
        'frame_rate': value.frameRate,
        'width': value.width,
        'height': value.height,
        'elements': value.elements === undefined ? undefined : ((value.elements as Array<any>).map(ApiDesignIdAddCreatomateJobPostRequestCreatomateParamSourceElementsInnerToJSON)),
    };
}

