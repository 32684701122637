import { VFC, useState, MouseEventHandler } from "react";
import { useSubMenuVisible } from "../common/submenus/useSubMenuVisible";
import { ApiAcademyCourseLessonApi, Configuration } from "../../generated/api";
import { notify } from "../notification";
import { VideoSelectorModal } from "../common/VideoSelectorModal";

import type { Folder } from "../../types/folder";
import { newAcademiesCourseLessonPath } from "../../generated/routes";

interface Props {
  csrfToken: string;
  academyCourseId: number;
  academyCourseHashid: string;
  folders: Folder[];
  disabled?: boolean;
}

export const AddAcademyCourseLessonButton: VFC<Props> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [visible, toggleVisible] = useSubMenuVisible();

  const toggleMenu: MouseEventHandler = (event) => {
    event.preventDefault();
    toggleVisible();
  };

  const addVideo: MouseEventHandler = (event) => {
    event.preventDefault();
    setModalVisible(!modalVisible);
  };

  async function submit(videos: { id: number }[]) {
    const videoIds = videos.map((v) => v.id);
    const api = new ApiAcademyCourseLessonApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    const requestFactories = videoIds.map((videoId) => {
      return () =>
        api.apiAcademyCourseLessonsPost({
          apiAcademyCourseLessonsPostRequest: {
            courseLesson: { academyCourseId: props.academyCourseId, videoId },
          },
        });
    });
    for (const requestFactory of requestFactories) {
      try {
        await requestFactory();
      } catch (e) {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw e;
      }
    }
    location.reload();
  }

  return (
    <>
      <button
        className="c-button--outlined p-academy-edit__create-button"
        onClick={toggleMenu}
        disabled={props.disabled}
        title={props.disabled ? "レッスンの登録は100件までです" : null}
      >
        <i className="material-icons-round c-button__icon">add</i>
        レッスンを追加
      </button>
      {visible && (
        <ul role="menu" className="c-submenu__list">
          <li
            role="menuitem"
            className="c-submenu__list-item c-submenu__list-item--medium-size"
          >
            <button onClick={addVideo}>
              <i className="material-icons-round c-submenu__list-item-icon">
                videocam
              </i>
              ビデオ
            </button>
          </li>
          <li
            role="menuitem"
            className="c-submenu__list-item c-submenu__list-item--medium-size"
          >
            <a
              href={newAcademiesCourseLessonPath({
                course_id: props.academyCourseHashid,
              })}
            >
              <i className="material-icons-outlined c-submenu__list-item-icon">
                text_snippet
              </i>
              テキスト
            </a>
          </li>
        </ul>
      )}

      <VideoSelectorModal
        csrfToken={props.csrfToken}
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        onSelect={submit}
        teamFolders={props.folders}
        headerTitle="コースにビデオを追加"
      />
    </>
  );
};
export default AddAcademyCourseLessonButton;
