import { useEffect, useRef } from "react";

/**
 * ページ離脱確認カスタムフック
 */
export function useBeforeUnload(shouldConfirm: boolean) {
  const shouldConfirmRef = useRef(false);
  shouldConfirmRef.current = shouldConfirm;
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      if (shouldConfirmRef.current) {
        event.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
  }, []);
}
