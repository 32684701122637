import { VFC, useState, useEffect, useRef } from "react";
import { academyCsvDownloadExamCsvsPath } from "../../generated/routes";

type AcademyCourseCategory = {
  id: number;
  title: string;
};

type AcademyCourse = {
  id: number;
  title: string;
  rowOrderRank: number;
  academyCourseCategoryId: number;
};

type AcademyCourseLesson = {
  id: number;
  academyCourseId: number;
  rowOrderRank: number;
  videoTitle?: string;
};

type AcademyCourseTree = AcademyCourse & { children: AcademyCourseLesson[] };

type AcademyCourseCategoryTree = AcademyCourseCategory & {
  children: AcademyCourseTree[];
};

interface Props {
  csrfToken: string;
  categories: AcademyCourseCategory[];
  courses: AcademyCourse[];
  lessons: AcademyCourseLesson[];
}

const toAcademyCourseCategoryTree = (
  categories: AcademyCourseCategory[],
  courses: AcademyCourse[],
  lessons: AcademyCourseLesson[]
): AcademyCourseCategoryTree[] => {
  const courseTree = courses.map((course: AcademyCourseTree) => {
    const childLessons = lessons
      .filter((lesson) => lesson.academyCourseId === course.id)
      .sort((a, b) => a.rowOrderRank - b.rowOrderRank);
    course.children = childLessons;
    return course;
  });

  const categoryTree = categories.map((category: AcademyCourseCategoryTree) => {
    const childCourses = courseTree
      .filter((course) => course.academyCourseCategoryId === category.id)
      .sort((a, b) => a.rowOrderRank - b.rowOrderRank);
    category.children = childCourses;
    return category;
  });

  return categoryTree;
};

const LessonItem = (props: {
  lesson: AcademyCourseLesson;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  const [checked, setChecked] = useState(props.checked);
  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);
  const onChange = () => {
    props.onChange(!checked);
    setChecked(!checked);
  };
  return (
    <li className="p-exam-csv-target-selector__tree-item">
      <label className="p-exam-csv-target-selector__label">
        <input
          type="checkbox"
          name="lesson_ids[]"
          value={props.lesson.id}
          checked={checked}
          className="p-exam-csv-target-selector__input"
          onChange={onChange}
        />
        {props.lesson.videoTitle}
      </label>
    </li>
  );
};

const CourseItem = (props: {
  course: AcademyCourseTree;
  checked: boolean;
  onChnage: (checked: boolean) => void;
}) => {
  const [checked, setChecked] = useState(props.checked);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setChecked(props.checked);
    ref.current.indeterminate = false;
  }, [props.checked]);

  const onChange = (chilldChecked) => {
    if (!chilldChecked) {
      ref.current.indeterminate = true;
    }
    props.onChnage(chilldChecked);
  };
  return (
    <li className="p-exam-csv-target-selector__tree-item">
      <label className="p-exam-csv-target-selector__label">
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
          className="p-exam-csv-target-selector__input"
          ref={ref}
        />
        {props.course.title}
      </label>
      <ul>
        {props.course.children.map((lesson) => (
          <LessonItem
            key={lesson.id}
            lesson={lesson}
            checked={checked}
            onChange={onChange}
          />
        ))}
      </ul>
    </li>
  );
};

const CategoryItem = (props: {
  category: AcademyCourseCategoryTree;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  const [checked, setChecked] = useState(props.checked);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setChecked(props.checked);
    ref.current.indeterminate = false;
  }, [props.checked]);

  const onChange = (chilldChecked) => {
    if (!chilldChecked) {
      ref.current.indeterminate = true;
    }
    props.onChange(chilldChecked);
  };
  return (
    <li className="p-exam-csv-target-selector__tree-item">
      <label className="p-exam-csv-target-selector__label">
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
          className="p-exam-csv-target-selector__input"
          ref={ref}
        />
        {props.category.title}
      </label>
      <ul>
        {props.category.children.map((course) => (
          <CourseItem
            key={course.id}
            course={course}
            checked={checked}
            onChnage={onChange}
          />
        ))}
      </ul>
    </li>
  );
};

export const AcademySelector: VFC<Props> = (props) => {
  const courseCategoryTree = toAcademyCourseCategoryTree(
    props.categories || [],
    props.courses || [],
    props.lessons || []
  );
  const ref = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const onChange = (chilldChecked) => {
    if (!chilldChecked) {
      ref.current.indeterminate = true;
    }
  };
  const childrenTree =
    courseCategoryTree.length > 1
      ? courseCategoryTree.map((category) => (
          <CategoryItem
            key={category.id}
            category={category}
            checked={checked}
            onChange={onChange}
          />
        ))
      : courseCategoryTree[0].children.map((course) => (
          <CourseItem
            key={course.id}
            course={course}
            checked={checked}
            onChnage={onChange}
          />
        ));
  return (
    <form
      className="p-exam-csv-target-selector__form"
      action={academyCsvDownloadExamCsvsPath()}
    >
      <h3 className="p-exam-csv-target-selector__title">アカデミー</h3>
      <div className="p-exam-csv-target-selector__checkbox-container">
        <label className="p-exam-csv-target-selector__label">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
            className="p-exam-csv-target-selector__input"
            ref={ref}
          />
          <span className="material-icons-round">school</span>
          VideoTouchアカデミー
        </label>
        <ul>{childrenTree}</ul>
      </div>
      <div className="p-exam-csv-target-selector__control-container">
        <div>
          <input
            type="date"
            name="from"
            className="p-exam-csv__date-input p-exam-csv__date-input--from"
          />
          〜
          <input
            type="date"
            name="to"
            className="p-exam-csv__date-input p-exam-csv__date-input--to"
          />
        </div>
        <button type="submit" className="c-button--primary">
          ダウンロード
        </button>
      </div>
    </form>
  );
};
export default AcademySelector;
