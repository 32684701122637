/* eslint-disable @typescript-eslint/no-empty-function */
import { DesignScene, DesignSceneWithAudio } from "../helpers";
import { DesignSceneItem } from "./DesignSceneItem";

export function DesignSceneItemOverlay(props: {
  activeScene: DesignScene;
  checkedScenesCount: number;
  activeSceneIndex: number;
  scenesWithAudio: DesignSceneWithAudio[];
}) {
  if (props.checkedScenesCount === 0) {
    return null;
  }

  const times = props.checkedScenesCount >= 2 ? 2 : 1;

  return (
    <>
      {props.checkedScenesCount > 1 && (
        <div className="p-design-scene-selected-count-wrapper">
          <div className="p-design-scene-selected-count-label">
            <span className="material-symbols-rounded">
              {props.checkedScenesCount}
            </span>
          </div>
        </div>
      )}
      {Array.from({ length: times }).map((_, i) => (
        <div
          key={i}
          className="p-design-scene-selected-scene-wrapper"
          style={{
            top: `${i * 4}px`,
            left: `${i * 4}px`,
            zIndex: 10 - i,
          }}
        >
          <DesignSceneItem
            csrfToken=""
            index={props.activeSceneIndex}
            scene={props.activeScene}
            beforeScene={false}
            afterScene={false}
            durationMs={
              props.scenesWithAudio?.[props.activeSceneIndex]?.durationMs
            }
            isChecked={true}
            isDragging={false}
            isActiveScene={false}
            isTextareaFocused={false}
            isCompleteUpdateNotify={false}
            onUpdateScene={() => {}}
            onDeleteScene={() => {}}
            onFocus={() => {}}
            onCheckboxSceneChange={() => {}}
          />
        </div>
      ))}
    </>
  );
}
