/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoVideoIdWatchLinkGet200ResponseCondition } from './ApiVideoVideoIdWatchLinkGet200ResponseCondition';
import {
    ApiVideoVideoIdWatchLinkGet200ResponseConditionFromJSON,
    ApiVideoVideoIdWatchLinkGet200ResponseConditionFromJSONTyped,
    ApiVideoVideoIdWatchLinkGet200ResponseConditionToJSON,
} from './ApiVideoVideoIdWatchLinkGet200ResponseCondition';
import type { ApiVideoVideoIdWatchLinkGet200ResponseMembersInner } from './ApiVideoVideoIdWatchLinkGet200ResponseMembersInner';
import {
    ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSON,
    ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSONTyped,
    ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerToJSON,
} from './ApiVideoVideoIdWatchLinkGet200ResponseMembersInner';

/**
 * 
 * @export
 * @interface ApiVideoVideoIdWatchLinkGet200Response
 */
export interface ApiVideoVideoIdWatchLinkGet200Response {
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdWatchLinkGet200Response
     */
    videoId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdWatchLinkGet200Response
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdWatchLinkGet200Response
     */
    uniqKey?: string;
    /**
     * 
     * @type {Array<ApiVideoVideoIdWatchLinkGet200ResponseMembersInner>}
     * @memberof ApiVideoVideoIdWatchLinkGet200Response
     */
    members?: Array<ApiVideoVideoIdWatchLinkGet200ResponseMembersInner>;
    /**
     * 
     * @type {ApiVideoVideoIdWatchLinkGet200ResponseCondition}
     * @memberof ApiVideoVideoIdWatchLinkGet200Response
     */
    condition?: ApiVideoVideoIdWatchLinkGet200ResponseCondition | null;
}

/**
 * Check if a given object implements the ApiVideoVideoIdWatchLinkGet200Response interface.
 */
export function instanceOfApiVideoVideoIdWatchLinkGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdWatchLinkGet200ResponseFromJSON(json: any): ApiVideoVideoIdWatchLinkGet200Response {
    return ApiVideoVideoIdWatchLinkGet200ResponseFromJSONTyped(json, false);
}

export function ApiVideoVideoIdWatchLinkGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdWatchLinkGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'videoId': !exists(json, 'videoId') ? undefined : json['videoId'],
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'uniqKey': !exists(json, 'uniqKey') ? undefined : json['uniqKey'],
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSON)),
        'condition': !exists(json, 'condition') ? undefined : ApiVideoVideoIdWatchLinkGet200ResponseConditionFromJSON(json['condition']),
    };
}

export function ApiVideoVideoIdWatchLinkGet200ResponseToJSON(value?: ApiVideoVideoIdWatchLinkGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'videoId': value.videoId,
        'typeName': value.typeName,
        'uniqKey': value.uniqKey,
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerToJSON)),
        'condition': ApiVideoVideoIdWatchLinkGet200ResponseConditionToJSON(value.condition),
    };
}

