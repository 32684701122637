/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiDesignIdAddCreatomateJobPost200ResponseInner,
  ApiDesignIdAddCreatomateJobPostRequest,
} from '../models';
import {
    ApiDesignIdAddCreatomateJobPost200ResponseInnerFromJSON,
    ApiDesignIdAddCreatomateJobPost200ResponseInnerToJSON,
    ApiDesignIdAddCreatomateJobPostRequestFromJSON,
    ApiDesignIdAddCreatomateJobPostRequestToJSON,
} from '../models';

export interface ApiDesignIdAddCreatomateJobPostOperationRequest {
    id: number;
    apiDesignIdAddCreatomateJobPostRequest?: ApiDesignIdAddCreatomateJobPostRequest;
}

/**
 * 
 */
export class ApiDesignApi extends runtime.BaseAPI {

    /**
     * add_creatomate_job
     */
    async apiDesignIdAddCreatomateJobPostRaw(requestParameters: ApiDesignIdAddCreatomateJobPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiDesignIdAddCreatomateJobPost200ResponseInner>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDesignIdAddCreatomateJobPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/design/{id}/add_creatomate_job`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiDesignIdAddCreatomateJobPostRequestToJSON(requestParameters.apiDesignIdAddCreatomateJobPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiDesignIdAddCreatomateJobPost200ResponseInnerFromJSON));
    }

    /**
     * add_creatomate_job
     */
    async apiDesignIdAddCreatomateJobPost(requestParameters: ApiDesignIdAddCreatomateJobPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiDesignIdAddCreatomateJobPost200ResponseInner>> {
        const response = await this.apiDesignIdAddCreatomateJobPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
