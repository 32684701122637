import { createContext, ReactNode } from "react";

type TreeViewThemeContextType = {
  icon?: ReactNode;
};

/**
 * アイコンなどツリーの一部でだけ差し替えたい表示情報を扱うコンテキスト
 */
export const TreeViewThemeContext = createContext<TreeViewThemeContextType>({});
TreeViewThemeContext.displayName = "TreeViewThemeContext";
