/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoVideoIdSubtitlesDefaultDisplayedPatchRequest,
  ApiVideoVideoIdSubtitlesGet200ResponseInner,
  ApiVideoVideoIdSubtitlesPublishedPatchRequest,
  ApiVideoVideoIdSubtitlesPutRequest,
} from '../models';
import {
    ApiVideoVideoIdSubtitlesDefaultDisplayedPatchRequestFromJSON,
    ApiVideoVideoIdSubtitlesDefaultDisplayedPatchRequestToJSON,
    ApiVideoVideoIdSubtitlesGet200ResponseInnerFromJSON,
    ApiVideoVideoIdSubtitlesGet200ResponseInnerToJSON,
    ApiVideoVideoIdSubtitlesPublishedPatchRequestFromJSON,
    ApiVideoVideoIdSubtitlesPublishedPatchRequestToJSON,
    ApiVideoVideoIdSubtitlesPutRequestFromJSON,
    ApiVideoVideoIdSubtitlesPutRequestToJSON,
} from '../models';

export interface ApiVideoVideoIdSubtitlesAddGenerateJobPatchRequest {
    videoId: number;
}

export interface ApiVideoVideoIdSubtitlesDefaultDisplayedPatchOperationRequest {
    videoId: number;
    apiVideoVideoIdSubtitlesDefaultDisplayedPatchRequest?: ApiVideoVideoIdSubtitlesDefaultDisplayedPatchRequest;
}

export interface ApiVideoVideoIdSubtitlesDeleteRequest {
    videoId: number;
}

export interface ApiVideoVideoIdSubtitlesGetRequest {
    videoId: number;
}

export interface ApiVideoVideoIdSubtitlesPublishedPatchOperationRequest {
    videoId: number;
    apiVideoVideoIdSubtitlesPublishedPatchRequest?: ApiVideoVideoIdSubtitlesPublishedPatchRequest;
}

export interface ApiVideoVideoIdSubtitlesPutOperationRequest {
    videoId: number;
    apiVideoVideoIdSubtitlesPutRequest?: ApiVideoVideoIdSubtitlesPutRequest;
}

/**
 * 
 */
export class ApiSubtitleApi extends runtime.BaseAPI {

    /**
     * add_generate_job
     */
    async apiVideoVideoIdSubtitlesAddGenerateJobPatchRaw(requestParameters: ApiVideoVideoIdSubtitlesAddGenerateJobPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdSubtitlesAddGenerateJobPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/subtitles/add_generate_job`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * add_generate_job
     */
    async apiVideoVideoIdSubtitlesAddGenerateJobPatch(requestParameters: ApiVideoVideoIdSubtitlesAddGenerateJobPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoVideoIdSubtitlesAddGenerateJobPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * default_displayed
     */
    async apiVideoVideoIdSubtitlesDefaultDisplayedPatchRaw(requestParameters: ApiVideoVideoIdSubtitlesDefaultDisplayedPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdSubtitlesDefaultDisplayedPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/subtitles/default_displayed`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdSubtitlesDefaultDisplayedPatchRequestToJSON(requestParameters.apiVideoVideoIdSubtitlesDefaultDisplayedPatchRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * default_displayed
     */
    async apiVideoVideoIdSubtitlesDefaultDisplayedPatch(requestParameters: ApiVideoVideoIdSubtitlesDefaultDisplayedPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoVideoIdSubtitlesDefaultDisplayedPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * destroy
     */
    async apiVideoVideoIdSubtitlesDeleteRaw(requestParameters: ApiVideoVideoIdSubtitlesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdSubtitlesDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/subtitles`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiVideoVideoIdSubtitlesDelete(requestParameters: ApiVideoVideoIdSubtitlesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoVideoIdSubtitlesDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * show
     */
    async apiVideoVideoIdSubtitlesGetRaw(requestParameters: ApiVideoVideoIdSubtitlesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiVideoVideoIdSubtitlesGet200ResponseInner>>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdSubtitlesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/subtitles`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiVideoVideoIdSubtitlesGet200ResponseInnerFromJSON));
    }

    /**
     * show
     */
    async apiVideoVideoIdSubtitlesGet(requestParameters: ApiVideoVideoIdSubtitlesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiVideoVideoIdSubtitlesGet200ResponseInner>> {
        const response = await this.apiVideoVideoIdSubtitlesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * published
     */
    async apiVideoVideoIdSubtitlesPublishedPatchRaw(requestParameters: ApiVideoVideoIdSubtitlesPublishedPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdSubtitlesPublishedPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/subtitles/published`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdSubtitlesPublishedPatchRequestToJSON(requestParameters.apiVideoVideoIdSubtitlesPublishedPatchRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * published
     */
    async apiVideoVideoIdSubtitlesPublishedPatch(requestParameters: ApiVideoVideoIdSubtitlesPublishedPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoVideoIdSubtitlesPublishedPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiVideoVideoIdSubtitlesPutRaw(requestParameters: ApiVideoVideoIdSubtitlesPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiVideoVideoIdSubtitlesGet200ResponseInner>>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdSubtitlesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/subtitles`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdSubtitlesPutRequestToJSON(requestParameters.apiVideoVideoIdSubtitlesPutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiVideoVideoIdSubtitlesGet200ResponseInnerFromJSON));
    }

    /**
     * update
     */
    async apiVideoVideoIdSubtitlesPut(requestParameters: ApiVideoVideoIdSubtitlesPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiVideoVideoIdSubtitlesGet200ResponseInner>> {
        const response = await this.apiVideoVideoIdSubtitlesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
