/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiStatsWatchGetRequest {
    vid?: string;
    uid?: string;
    state?: string;
    startTime?: string;
    endTime?: string;
    player?: string;
    playerVersion?: string;
    mute?: string;
    volume?: string;
    userAgent?: string;
    referrer?: string;
    teamId?: number;
    tenantId?: number;
    ipAddress?: string;
}

/**
 * 
 */
export class ApiStatsWatchApi extends runtime.BaseAPI {

    /**
     * log
     */
    async apiStatsWatchGetRaw(requestParameters: ApiStatsWatchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.vid !== undefined) {
            queryParameters['vid'] = requestParameters.vid;
        }

        if (requestParameters.uid !== undefined) {
            queryParameters['uid'] = requestParameters.uid;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.startTime !== undefined) {
            queryParameters['start_time'] = requestParameters.startTime;
        }

        if (requestParameters.endTime !== undefined) {
            queryParameters['end_time'] = requestParameters.endTime;
        }

        if (requestParameters.player !== undefined) {
            queryParameters['player'] = requestParameters.player;
        }

        if (requestParameters.playerVersion !== undefined) {
            queryParameters['player_version'] = requestParameters.playerVersion;
        }

        if (requestParameters.mute !== undefined) {
            queryParameters['mute'] = requestParameters.mute;
        }

        if (requestParameters.volume !== undefined) {
            queryParameters['volume'] = requestParameters.volume;
        }

        if (requestParameters.userAgent !== undefined) {
            queryParameters['user_agent'] = requestParameters.userAgent;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenant_id'] = requestParameters.tenantId;
        }

        if (requestParameters.ipAddress !== undefined) {
            queryParameters['ip_address'] = requestParameters.ipAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stats/watch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * log
     */
    async apiStatsWatchGet(requestParameters: ApiStatsWatchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiStatsWatchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
