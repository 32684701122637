import { VFC, MouseEventHandler, useState } from "react";
import { useSubMenuVisible } from "./useSubMenuVisible";
import { RenameFolderModal } from "./RenameFolderModal";
import { MoveFolderModal } from "./MoveFolderModal";
import { ApiFolderApi, Configuration } from "../../../generated/api";
import { notify } from "../../notification";
import { Folder } from "app/javascript/types/folder";

async function deleteFolder(folderId: number, csrfToken: string) {
  if (
    !confirm(
      "フォルダとその中のビデオは完全に削除されます。\nこの操作は取り消せません。"
    )
  ) {
    return;
  }
  const api = new ApiFolderApi(
    new Configuration({
      basePath: location.origin,
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  try {
    await api.apiFoldersIdDelete({ id: folderId });
    location.reload();
  } catch (reason) {
    notify("削除に失敗しました。しばらく待ってもう一度お試しください。");
    throw reason;
  }
}

type Props = {
  folderId: number; // folder の id
  folderName: string;
  parentFolderId: number;
  userFolders: Folder[];
  teamFolders: Folder[];
  csrfToken: string;
};

export const FolderSubMenu: VFC<Props> = ({
  folderId,
  folderName,
  csrfToken,
  parentFolderId,
  userFolders,
  teamFolders,
}) => {
  const [visible, toggleVisible] = useSubMenuVisible();
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [moveModalVisible, setMoveModalVisible] = useState(false);

  const toggleMenu: MouseEventHandler = (event) => {
    event.preventDefault();
    toggleVisible();
  };

  const renameFolder: MouseEventHandler = (event) => {
    event.preventDefault();
    setRenameModalVisible(true);
  };

  const moveVideo: MouseEventHandler = (event) => {
    event.preventDefault();
    setMoveModalVisible(true);
  };

  const delFolder: MouseEventHandler = (event) => {
    event.preventDefault();
    deleteFolder(folderId, csrfToken);
  };

  return (
    <>
      <button
        className="material-icons-round c-submenu__button"
        onClick={toggleMenu}
      >
        more_vert
      </button>
      {visible && (
        <ul className="c-submenu__list">
          <li className="c-submenu__list-item">
            <button onClick={renameFolder}>
              <i className="material-icons-round c-submenu__list-item-icon">
                border_color
              </i>
              名称変更
            </button>
          </li>
          <li className="c-submenu__list-item">
            <button onClick={moveVideo}>
              <i className="material-icons-outlined c-submenu__list-item-icon">
                drive_file_move
              </i>
              移動
            </button>
          </li>
          <li className="c-submenu__list-item danger">
            <button onClick={delFolder}>
              <i className="material-icons-outlined c-submenu__list-item-icon">
                delete
              </i>
              削除
            </button>
          </li>
        </ul>
      )}
      {renameModalVisible && (
        <RenameFolderModal
          folderId={folderId}
          folderName={folderName}
          csrfToken={csrfToken}
          onAfterClose={() => setRenameModalVisible(false)}
        />
      )}
      {moveModalVisible && (
        <MoveFolderModal
          folderId={folderId}
          currentParentFolderId={parentFolderId}
          userFolders={userFolders}
          teamFolders={teamFolders}
          csrfToken={csrfToken}
          onAfterClose={() => setMoveModalVisible(false)}
        />
      )}
    </>
  );
};
export default FolderSubMenu;
