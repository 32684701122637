import { useState, useEffect, useCallback } from "react";
import {
  ApiVideoApi,
  ApiSubtitleApi,
  Configuration,
} from "../../generated/api";

type SubtitlesViewState = "initial" | "processing" | "editable" | "error";

type SubtitleView = {
  viewState: SubtitlesViewState;
  isRequesting: boolean;
  requestGenerateJob(): void;
  startEditView(): void;
};

/**
 * SubtitlesView のためのカスタムフック
 */
export function useSubtitleView(
  videoId: number,
  csrfToken: string,
  initialSubtitlesGenerateJobState?: string,
  initialSubtitlesSize?: number
): SubtitleView {
  const [isRequesting, setIsRequesting] = useState(false);
  const [viewState, setViewState] = useState<SubtitlesViewState>(() =>
    toViewState(initialSubtitlesGenerateJobState, initialSubtitlesSize)
  );

  useEffect(() => {
    // 処理待ち中は定期チェック
    if (viewState === "processing") {
      const intervalId = setInterval(() => {
        Promise.all([
          getVideo(videoId, csrfToken),
          getSubtitles(videoId, csrfToken),
        ]).then(([video, subtitles]) => {
          setViewState(
            toViewState(video.subtitles.generateJobState, subtitles.length)
          );
        });
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [viewState, videoId, csrfToken]);

  const requestGenerateJob = useCallback(() => {
    setIsRequesting(true);
    postSubtitlesGenerateJob(videoId, csrfToken)
      .then(() => {
        setViewState(toViewState("running", initialSubtitlesSize));
      })
      .catch((reason) => {
        setViewState("error");
        throw reason;
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }, [csrfToken, initialSubtitlesSize, videoId]);

  const startEditView = useCallback(() => {
    setViewState("editable");
  }, []);

  return {
    viewState,
    isRequesting,
    requestGenerateJob,
    startEditView,
  };
}

function postSubtitlesGenerateJob(videoId: number, csrfToken: string) {
  const api = new ApiSubtitleApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiVideoVideoIdSubtitlesAddGenerateJobPatch({ videoId });
}

function getVideo(videoId: number, csrfToken: string) {
  const api = new ApiVideoApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiVideoIdGet({ id: videoId });
}

function getSubtitles(videoId: number, csrfToken: string) {
  const api = new ApiSubtitleApi(
    new Configuration({
      basePath: "",
      headers: {
        Accept: "application/json",
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiVideoVideoIdSubtitlesGet({ videoId });
}

export function toViewState(
  subtitlesGenerateJobState: string,
  subtitlesSize: number
): SubtitlesViewState {
  const table: Record<string, SubtitlesViewState> = {
    none: "initial",
    running: "processing",
    finished: "editable",
  };
  const state = table[subtitlesGenerateJobState] ?? "initial";
  if (subtitlesSize === 0 && state === "editable") {
    return "initial"; // 一度字幕生成を行った後に初期化したとき
  }
  if (subtitlesSize > 0 && state !== "processing") {
    return "editable"; // 字幕データがあるなら、生成中でなければ字幕編集画面に倒しておく
  }
  return state;
}
