/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoVideoIdTimelineUpdateJobsPostRequest,
} from '../models';
import {
    ApiVideoVideoIdTimelineUpdateJobsPostRequestFromJSON,
    ApiVideoVideoIdTimelineUpdateJobsPostRequestToJSON,
} from '../models';

export interface ApiVideoVideoIdTimelineUpdateJobsPostOperationRequest {
    videoId: string;
    apiVideoVideoIdTimelineUpdateJobsPostRequest?: ApiVideoVideoIdTimelineUpdateJobsPostRequest;
}

/**
 * 
 */
export class ApiTimelineUpdateJobApi extends runtime.BaseAPI {

    /**
     * create
     */
    async apiVideoVideoIdTimelineUpdateJobsPostRaw(requestParameters: ApiVideoVideoIdTimelineUpdateJobsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdTimelineUpdateJobsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/timeline_update_jobs`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdTimelineUpdateJobsPostRequestToJSON(requestParameters.apiVideoVideoIdTimelineUpdateJobsPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * create
     */
    async apiVideoVideoIdTimelineUpdateJobsPost(requestParameters: ApiVideoVideoIdTimelineUpdateJobsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.apiVideoVideoIdTimelineUpdateJobsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
