/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAcademyCoursesIdPatch202Response
 */
export interface ApiAcademyCoursesIdPatch202Response {
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    hashid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    iconName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    rowOrderRank?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    rowOrderPosition?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    playTimeSec?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    lessonsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCoursesIdPatch202Response
     */
    academyCourseCategoryId?: number;
}

/**
 * Check if a given object implements the ApiAcademyCoursesIdPatch202Response interface.
 */
export function instanceOfApiAcademyCoursesIdPatch202Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCoursesIdPatch202ResponseFromJSON(json: any): ApiAcademyCoursesIdPatch202Response {
    return ApiAcademyCoursesIdPatch202ResponseFromJSONTyped(json, false);
}

export function ApiAcademyCoursesIdPatch202ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCoursesIdPatch202Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hashid': !exists(json, 'hashid') ? undefined : json['hashid'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'iconName': !exists(json, 'iconName') ? undefined : json['iconName'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'rowOrderRank': !exists(json, 'rowOrderRank') ? undefined : json['rowOrderRank'],
        'rowOrderPosition': !exists(json, 'rowOrderPosition') ? undefined : json['rowOrderPosition'],
        'playTimeSec': !exists(json, 'playTimeSec') ? undefined : json['playTimeSec'],
        'lessonsCount': !exists(json, 'lessonsCount') ? undefined : json['lessonsCount'],
        'academyCourseCategoryId': !exists(json, 'academyCourseCategoryId') ? undefined : json['academyCourseCategoryId'],
    };
}

export function ApiAcademyCoursesIdPatch202ResponseToJSON(value?: ApiAcademyCoursesIdPatch202Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hashid': value.hashid,
        'state': value.state,
        'iconName': value.iconName,
        'title': value.title,
        'description': value.description,
        'rowOrderRank': value.rowOrderRank,
        'rowOrderPosition': value.rowOrderPosition,
        'playTimeSec': value.playTimeSec,
        'lessonsCount': value.lessonsCount,
        'academyCourseCategoryId': value.academyCourseCategoryId,
    };
}

