/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiFoldersIdRenamePatch202Response,
  ApiFoldersIdRenamePatchRequest,
  ApiFoldersIdVideosGet200ResponseInner,
  ApiFoldersPost201Response,
  ApiFoldersPost422Response,
  ApiFoldersPostRequest,
  ApiVideoIdMovePatchRequest,
} from '../models';
import {
    ApiFoldersIdRenamePatch202ResponseFromJSON,
    ApiFoldersIdRenamePatch202ResponseToJSON,
    ApiFoldersIdRenamePatchRequestFromJSON,
    ApiFoldersIdRenamePatchRequestToJSON,
    ApiFoldersIdVideosGet200ResponseInnerFromJSON,
    ApiFoldersIdVideosGet200ResponseInnerToJSON,
    ApiFoldersPost201ResponseFromJSON,
    ApiFoldersPost201ResponseToJSON,
    ApiFoldersPost422ResponseFromJSON,
    ApiFoldersPost422ResponseToJSON,
    ApiFoldersPostRequestFromJSON,
    ApiFoldersPostRequestToJSON,
    ApiVideoIdMovePatchRequestFromJSON,
    ApiVideoIdMovePatchRequestToJSON,
} from '../models';

export interface ApiFoldersIdDeleteRequest {
    id: number;
}

export interface ApiFoldersIdMovePatchRequest {
    id: number;
    apiVideoIdMovePatchRequest?: ApiVideoIdMovePatchRequest;
}

export interface ApiFoldersIdRenamePatchOperationRequest {
    id: number;
    apiFoldersIdRenamePatchRequest?: ApiFoldersIdRenamePatchRequest;
}

export interface ApiFoldersIdVideosGetRequest {
    id: number;
}

export interface ApiFoldersPostOperationRequest {
    apiFoldersPostRequest?: ApiFoldersPostRequest;
}

/**
 * 
 */
export class ApiFolderApi extends runtime.BaseAPI {

    /**
     * destroy
     */
    async apiFoldersIdDeleteRaw(requestParameters: ApiFoldersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFoldersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/folders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiFoldersIdDelete(requestParameters: ApiFoldersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiFoldersIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * move
     */
    async apiFoldersIdMovePatchRaw(requestParameters: ApiFoldersIdMovePatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiFoldersPost201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFoldersIdMovePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/folders/{id}/move`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoIdMovePatchRequestToJSON(requestParameters.apiVideoIdMovePatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiFoldersPost201ResponseFromJSON(jsonValue));
    }

    /**
     * move
     */
    async apiFoldersIdMovePatch(requestParameters: ApiFoldersIdMovePatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiFoldersPost201Response> {
        const response = await this.apiFoldersIdMovePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * rename
     */
    async apiFoldersIdRenamePatchRaw(requestParameters: ApiFoldersIdRenamePatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiFoldersIdRenamePatch202Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFoldersIdRenamePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/folders/{id}/rename`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiFoldersIdRenamePatchRequestToJSON(requestParameters.apiFoldersIdRenamePatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiFoldersIdRenamePatch202ResponseFromJSON(jsonValue));
    }

    /**
     * rename
     */
    async apiFoldersIdRenamePatch(requestParameters: ApiFoldersIdRenamePatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiFoldersIdRenamePatch202Response> {
        const response = await this.apiFoldersIdRenamePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * videos
     */
    async apiFoldersIdVideosGetRaw(requestParameters: ApiFoldersIdVideosGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiFoldersIdVideosGet200ResponseInner>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFoldersIdVideosGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/folders/{id}/videos`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiFoldersIdVideosGet200ResponseInnerFromJSON));
    }

    /**
     * videos
     */
    async apiFoldersIdVideosGet(requestParameters: ApiFoldersIdVideosGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiFoldersIdVideosGet200ResponseInner>> {
        const response = await this.apiFoldersIdVideosGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create
     */
    async apiFoldersPostRaw(requestParameters: ApiFoldersPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiFoldersPost201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/folders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiFoldersPostRequestToJSON(requestParameters.apiFoldersPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiFoldersPost201ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiFoldersPost(requestParameters: ApiFoldersPostOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiFoldersPost201Response> {
        const response = await this.apiFoldersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
