import { domReady } from "../dom-ready";

domReady(() => {
  // 閉じるボタンイベント
  const elem = document.getElementById("c-notification");
  if (elem) {
    const closeButton = elem.querySelector("button");
    closeButton.addEventListener("click", () => {
      elem.style.display = "none";
    });
  }
});

export function notify(msg: string): void {
  const elem = document.getElementById("c-notification");
  clearTimeout(parseInt(elem.dataset.timeoutId));
  if (elem.style.display) {
    show();
  } else {
    // 表示中の場合、一度消してから再表示させCSSアニメーションを発火する
    elem.style.display = "none";
    setTimeout(() => show(), 100);
  }

  function show() {
    elem.querySelector("p").textContent = msg;
    elem.style.display = "";
    const timeoutId = setTimeout(() => {
      elem.style.display = "none";
    }, 5000); // 5秒
    elem.dataset.timeoutId = `${timeoutId}`;
  }
}
