import { Jodit } from "jodit";
import {
  TimestampLinkBuilder,
  TimestampLinkParser,
} from "../../../lib/timestamp-link";

const Plugin = Jodit.modules.Plugin;

// NOTE: 現状、1つの行に複数対象となる文字列がある場合の動作が適切ではない。
export class TimestampLink extends Plugin {
  private walker = new Jodit.modules.LazyWalker(this.jodit.async);

  afterInit() {
    this.walker
      .on("visit", (node: Node) => this.ensureTimestampLink(node))
      .on("end", (affected: boolean) => {
        if (affected) {
          this.jodit.synchronizeValues();
        }
      });

    this.jodit.events.on("change", () => {
      this.walker.setWork(this.jodit.editor);
    });
  }

  /**
   * @param node
   * @returns boolean 変更したかどうか
   */
  ensureTimestampLink(node: Node): boolean {
    node.normalize();
    const Dom = Jodit.modules.Dom;

    const builder = new TimestampLinkBuilder(node.textContent);
    if (isHTMLAnchorElement(node)) {
      const currentNodeTime = node.href
        ? new TimestampLinkParser(node.href).time()
        : null;
      if (currentNodeTime != null) {
        // 入力が崩れたらリンクをunwrap
        if (!builder.containsTimestampNotation()) {
          this.jodit.selection.save();
          node.normalize();
          Dom.unwrap(node);
          this.jodit.selection.restore();
          return true;
        }

        // <p><a href="?t=1">00:01</a>:12</p> のような入力を救済するため。
        if (currentNodeTime < 3600) {
          const hasPrevTimestampPartNode =
            Dom.isText(node.previousSibling) &&
            /\d?\d:$/.test(node.previousSibling.textContent);
          const hasNextTimestampPartNode =
            Dom.isText(node.nextSibling) &&
            /^:\d\d/.test(node.nextSibling.textContent);
          if (hasPrevTimestampPartNode || hasNextTimestampPartNode) {
            this.jodit.selection.save();
            const parent = node.parentElement;
            Dom.unwrap(node);
            parent.normalize();
            this.jodit.selection.restore();
            return true;
          }
        }
      }
    }

    if (!Dom.isText(node) || !builder.containsTimestampNotation()) {
      return false; // テキストノードを起点に処理を行うため、それ以外の場合はスキップ
    }

    {
      // すでに要素がある場合
      const anchor = Dom.closest(node, "a", this.jodit.editor);
      if (anchor) {
        const href = builder.href();
        if (anchor.getAttribute("href") !== href) {
          anchor.setAttribute("href", href);
          return true;
        } else {
          return false;
        }
      }
    }

    if (!Dom.isOrContains(this.jodit.editor, node)) {
      return false; // 他のプラグインなどの処理によりnodeが改変されていなくなることがある。
    }

    this.jodit.selection.save();

    const match = builder.match();
    try {
      // "foo 12:34 bar" を "foo ", "12:34", " bar" に分割して真ん中だけ取り出す。
      const timestampTextNode = node.splitText(match.index);
      timestampTextNode.splitText(match.captured.length);
      const anchor = Dom.wrap(timestampTextNode, "a", this.jodit.createInside);
      anchor.setAttribute("href", builder.href());
    } catch (reason) {
      // Joditの他の処理とバッティングして失敗することがあるので、諦めて次回の処理サイクルに任せる。
      console.warn(reason);
      return false;
    }

    this.jodit.selection.restore();

    return true;
  }

  beforeDestruct() {
    this.walker.destruct();
  }
}

function isHTMLAnchorElement(node: Node): node is HTMLAnchorElement {
  const Dom = Jodit.modules.Dom;
  return Dom.isHTMLElement(node) && node.tagName === "A";
}
