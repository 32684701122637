import { FC, useState, ReactNode } from "react";
import { TreeViewContext } from "./TreeViewContext";
import { ITEM_ID } from "./TreeViewTypes";

type Props = {
  selectedItemId?: ITEM_ID;
  onItemSelected?: (itemId: ITEM_ID) => void;
  children: ReactNode;
};

export const TreeView: FC<Props> = (props) => {
  const [selectedItemId, setSelectedItemId] = useState(props.selectedItemId);
  return (
    <ul>
      <TreeViewContext.Provider
        value={{
          selectedItemId,
          selectItem(itemId) {
            setSelectedItemId(itemId);
            props.onItemSelected && props.onItemSelected(itemId);
          },
        }}
      >
        {props.children}
      </TreeViewContext.Provider>
    </ul>
  );
};
