import { VFC } from "react";
import { LineGraph, ChartData, TooltipComponent } from "./LineGraph";

type Props = {
  data: {
    /** 秒数 */
    sec: number;
    /** 秒毎の視聴維持率: 0-100 */
    rate: number;
  }[];
};

// e.g. 12:34
const timeFormater = new Intl.DateTimeFormat("ja-JP", {
  minute: "2-digit",
  second: "2-digit",
});

function convert2ChartData(src: Props["data"]): ChartData {
  const labels = src.map((d) => timeFormater.format(d.sec * 1000));
  const data = src.map((d) => d.rate);
  const chartData: ChartData = {
    labels,
    datasets: [{ data }],
  };
  return chartData;
}

const Tooltip: TooltipComponent = (props) => {
  const [data] = props.model.dataPoints;
  const dateLabel = data.label;
  return (
    <div className="c-graph-tooltip text-center">
      <p className="c-graph-tooltip__opt-text">{dateLabel}</p>
      <p className="c-graph-tooltip__primary-text">{data.formattedValue}%</p>
    </div>
  );
};

export const WatchRetensionGraph: VFC<Props> = (props) => {
  const chartData = convert2ChartData(props.data);
  return (
    <LineGraph data={chartData} tooltip={Tooltip} aspectRatio={4} yMax={100} />
  );
};
export default WatchRetensionGraph;
