import { useState, useCallback } from "react";

export function useStack<T>(initialValues: T[] = []) {
  const [stack, setStack] = useState(initialValues);

  function push(item: T) {
    setStack((stack) => {
      return [...stack, item];
    });
  }

  function pop(fn: (item: T) => void) {
    setStack((stack) => {
      const newStack = [...stack];
      fn(newStack.pop());
      return newStack;
    });
  }

  function clear() {
    setStack([]);
  }

  return [
    stack,
    useCallback(push, []),
    useCallback(pop, []),
    useCallback(clear, []),
  ] as const;
}
