import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

type LoadingContextType = {
  isLoading: boolean;
  handleLoading?: <T>(promise: Promise<T>) => Promise<T>;
};

export const LoadingContext = createContext<LoadingContextType>({
  isLoading: false,
});
LoadingContext.displayName = "LoadingContext";

export function LoadingContextProvider(props: { children: ReactNode }) {
  const [loadingKeys, setLoadingKeys] = useState<string[]>([]);
  const isLoading = useMemo(() => loadingKeys.length > 0, [loadingKeys]);
  const handleLoading: LoadingContextType["handleLoading"] = useCallback(
    (promise) => {
      const key = crypto.randomUUID();
      setLoadingKeys((keys) => [...keys, key]);
      return promise.finally(() => {
        setLoadingKeys((keys) => keys.filter((k) => k !== key));
      });
    },
    []
  );
  const contextValue = useMemo(
    () => ({ isLoading, handleLoading }),
    [isLoading, handleLoading]
  );

  return (
    <LoadingContext.Provider value={contextValue}>
      {props.children}
    </LoadingContext.Provider>
  );
}
