export function loadVideoDuration(file: Blob): Promise<number> {
  return new Promise<number>((resolve) => {
    const blobUrl = URL.createObjectURL(file);
    const elem = document.createElement("video");
    elem.preload = "metadata";
    elem.onloadedmetadata = () => {
      // https://developer.mozilla.org/ja/docs/Web/API/HTMLMediaElement/duration
      const duration = elem.duration; // 秒単位
      console.debug("Video duration:", duration);
      elem.remove();
      URL.revokeObjectURL(blobUrl);
      if (Number.isFinite(duration)) {
        resolve(duration);
      } else {
        resolve(null);
      }
    };
    elem.onerror = (error) => {
      // サポートしていないフォーマットの場合などはエラーになる
      console.warn("Failed to load video duration", error, file);
      elem.remove();
      URL.revokeObjectURL(blobUrl);
      resolve(null);
    };
    elem.src = blobUrl;
  });
}
