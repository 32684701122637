import { useState, useCallback } from "react";
import type { RefObject, CSSProperties } from "react";

type ItemContainerStyle = {
  minWidth: number;
  marginLeft: number;
  justifyContent: CSSProperties["justifyContent"];
};

/**
 * クリップの開始位置変更時のタイムラインのコンテナ要素の状態を制御するためのカスタムフック
 */
export function useChangingClipStart(
  tlClipItemContainerRef: RefObject<HTMLDivElement>
) {
  const [itemContainerStyle, setItemContainerStyle] =
    useState<ItemContainerStyle>(null);

  function startChangingClipStart() {
    // 変更開始時の横幅を最小幅に指定することで、リサイズ中にタイムライン全体がずれないようにする。
    setItemContainerStyle({
      minWidth: tlClipItemContainerRef.current.offsetWidth,
      marginLeft: null,
      justifyContent: "flex-end", // 開始位置変更中はタイムラインの末尾側の位置を固定したいため。
    });
  }

  function updateChangingClipStart() {
    if (itemContainerStyle?.minWidth == null) {
      return; // 非同期処理のタイミングによってはstartChangingClipStartのstate変更が反映される前に実行されるため。
    }
    // 横幅が溢れる場合は、コンテナ要素の左にネガティブマージンをつけて表示幅を調整する。
    const marginLeft =
      itemContainerStyle.minWidth - tlClipItemContainerRef.current.offsetWidth;
    if (marginLeft < 0) {
      setItemContainerStyle({ ...itemContainerStyle, marginLeft });
    } else {
      setItemContainerStyle({ ...itemContainerStyle, marginLeft: null });
    }
  }

  function endChangingClipStart() {
    setItemContainerStyle(null);
  }

  return {
    itemContainerStyle,

    startChangingClipStart: useCallback(startChangingClipStart, [
      tlClipItemContainerRef,
    ]),
    updateChangingClipStart: useCallback(updateChangingClipStart, [
      tlClipItemContainerRef,
      itemContainerStyle,
    ]),
    endChangingClipStart: useCallback(endChangingClipStart, []),
  };
}
