import { useState, useEffect } from "react";
import {
  ApiStatsWatchApi,
  Configuration,
  FetchError,
} from "../../../generated/api";

type WatchLog = {
  sec: number;
  playerName: string;
  playerVersion: string;
  paused: boolean;
  mute: boolean;
  volume: number;
};

type PlayerWatchLog = {
  addWatchLog: (log: WatchLog) => void;
};

const cookies = document.cookie.split(/;\s?/);
const uid = cookies.find((c) => c.startsWith("vt_uid="))?.split("=")[1] || "";

const userAgent = navigator.userAgent;
const referrer = location.href;

let avertedSyntaxErrorAlreadySentToRollbar = false;
let avertedFetchErrorAlreadySentToRollbar = false;

function sendLog(
  apiBaseUrl: string,
  videoId: string,
  teamId: number,
  tenantId: number,
  ipAddress: string,
  log: WatchLog
) {
  const api = new ApiStatsWatchApi(
    new Configuration({
      basePath: apiBaseUrl || "",
      headers: { "x-hopper-api-version": "1.0" },
    })
  );
  api
    .apiStatsWatchGet({
      vid: videoId,
      teamId,
      tenantId,
      uid,
      userAgent,
      referrer,
      ipAddress,
      startTime: `${log.sec}`,
      endTime: `${log.sec + 1}`, // 本来は再生した範囲を送信する。今は1秒毎送信なので+1して1秒間という事にしておく
      state: log.paused ? "paused" : "playing",
      mute: `${log.mute}`,
      volume: `${log.volume}`,
      player: log.playerName,
      playerVersion: log.playerVersion,
    })
    .catch((reason) => {
      if (isSyntaxError(reason)) {
        if (avertedSyntaxErrorAlreadySentToRollbar) {
          return; // NOOP
        }
        avertedSyntaxErrorAlreadySentToRollbar = true;
      }
      if (isFetchError(reason)) {
        if (avertedFetchErrorAlreadySentToRollbar) {
          return; // NOOP
        }
        avertedFetchErrorAlreadySentToRollbar = true;
      }
      Rollbar.warn(reason);
    });
}

export function usePlayerWatchLog(
  apiBaseUrl: string,
  videoId: string,
  teamId: number,
  tenantId: number,
  ipAddress: string
): PlayerWatchLog {
  const [lastSec, setLastSec] = useState(NaN);
  const [log, setLog] = useState<WatchLog>(null);

  useEffect(() => {
    // timeupdateイベントを拾うので、1秒毎にログを間引く
    if (!log || Math.floor(log.sec) === Math.floor(lastSec)) {
      return;
    }

    sendLog(apiBaseUrl, videoId, teamId, tenantId, ipAddress, log);
    setLastSec(log.sec);
  }, [apiBaseUrl, videoId, teamId, tenantId, ipAddress, log, lastSec]);

  return {
    addWatchLog: setLog,
  };
}

function isSyntaxError(err: unknown): err is SyntaxError {
  // 互換性の問題でinstanceofが動作しない場合があるため、nameで判定する
  return (err as SyntaxError)?.name === "SyntaxError";
}

function isFetchError(err: unknown): err is FetchError {
  return (err as FetchError)?.name === "FetchError";
}
