/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoVideoIdExamAnswersPost200Response,
  ApiVideoVideoIdExamAnswersPostRequest,
} from '../models';
import {
    ApiVideoVideoIdExamAnswersPost200ResponseFromJSON,
    ApiVideoVideoIdExamAnswersPost200ResponseToJSON,
    ApiVideoVideoIdExamAnswersPostRequestFromJSON,
    ApiVideoVideoIdExamAnswersPostRequestToJSON,
} from '../models';

export interface ApiVideoVideoIdExamAnswersPostOperationRequest {
    videoId: number;
    apiVideoVideoIdExamAnswersPostRequest?: ApiVideoVideoIdExamAnswersPostRequest;
}

/**
 * 
 */
export class ApiExamAnswerApi extends runtime.BaseAPI {

    /**
     * create
     */
    async apiVideoVideoIdExamAnswersPostRaw(requestParameters: ApiVideoVideoIdExamAnswersPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoVideoIdExamAnswersPost200Response>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdExamAnswersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/exam/answers`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdExamAnswersPostRequestToJSON(requestParameters.apiVideoVideoIdExamAnswersPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoVideoIdExamAnswersPost200ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiVideoVideoIdExamAnswersPost(requestParameters: ApiVideoVideoIdExamAnswersPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoVideoIdExamAnswersPost200Response> {
        const response = await this.apiVideoVideoIdExamAnswersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
