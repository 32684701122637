import { useRef, useEffect, useState } from "react";
import type { VFC } from "react";
import type { NarrationState } from "./useNarrationsView";
import { videojs } from "../../lib/videojs";

type Props = {
  src: string;
  type: string;
  subtitlesUrl?: string;
  narrations: NarrationState[];
  playing: boolean;
  onChangePlaying(playing: boolean): void;
  onTimeUpdate?(currentTimeMs: number): void;
  onPlay?(): void;
};

export const NarrationPreviewPlayer: VFC<Props> = (props) => {
  const videoRef = useRef<HTMLVideoElement>();
  const playerRef = useRef<ReturnType<typeof videojs>>();
  const [currentTimeMs, setCurrentTimeMs] = useState<number>();

  useEffect(() => {
    const opt = {
      disablePictureInPicture: true,
      controlBar: {
        pictureInPictureToggle: false,
        remainingTimeDisplay: false,
        volumePanel: false, // ボリュームコントロールを無効にする
      },
      muted: true, // 初期化時にミュートにする
    };
    playerRef.current = videojs(videoRef.current, opt);
    playerRef.current.hlsQualitySelector();
    playerRef.current.ready(() => {
      // https://stackoverflow.com/a/49598388
      const textTrackSettings = playerRef.current.textTrackSettings;
      textTrackSettings.setValues({ backgroundOpacity: "0.5" });
      textTrackSettings.updateDisplay();
    });
    return () => {
      playerRef.current?.dispose();
      playerRef.current = null;
    };
  }, []);

  useEffect(() => {
    const isPlaying = !playerRef.current.paused();
    if (props.playing !== isPlaying) {
      if (props.playing) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [props.playing]);

  useEffect(() => {
    if (!props.onChangePlaying) {
      return;
    }
    const onChangePlaying = props.onChangePlaying;
    const onPlaying = () => onChangePlaying(true);
    const onPause = () => onChangePlaying(false);
    const player = playerRef.current;

    player.on("playing", onPlaying);
    player.on("pause", onPause);

    return () => {
      player.off("playing", onPlaying);
      player.off("pause", onPause);
    };
  }, [props.onChangePlaying]);

  useEffect(() => {
    if (!props.onTimeUpdate) {
      return;
    }
    const onTimeUpdate = props.onTimeUpdate;
    const player = playerRef.current;

    const listener = () => {
      onTimeUpdate(player.currentTime() * 1000);
    };

    player.on("timeupdate", listener);

    return () => {
      player.off("timeupdate", listener);
    };
  }, [props.onTimeUpdate]);

  useEffect(() => {
    if (!props.onPlay) {
      return;
    }
    const onPlay = props.onPlay;
    const listener = () => onPlay();
    const player = playerRef.current;

    player.on("play", listener);

    return () => {
      player.off("play", listener);
    };
  }, [props.onPlay]);

  useEffect(() => {
    // timeupdateイベントより詳細に再生位置に追従するためrequestAnimationFrameを使う。
    let rafId: ReturnType<typeof requestAnimationFrame>;
    const loop = () => {
      const newCurrentTimeMs = playerRef.current.paused()
        ? null
        : Math.floor(playerRef.current.currentTime() * 1000);
      setCurrentTimeMs(newCurrentTimeMs);
      rafId = requestAnimationFrame(loop);
    };

    const playCallback = () => {
      loop();
    };

    const pauseCallback = () => {
      setCurrentTimeMs(null);
      cancelAnimationFrame(rafId);
    };

    const player = playerRef.current;

    player.on("play", playCallback);
    player.on("pause", pauseCallback);

    return () => {
      cancelAnimationFrame(rafId);
      player.off("play", playCallback);
      player.off("pause", pauseCallback);
    };
  }, []);

  return (
    <>
      <video
        ref={videoRef}
        className="video-js"
        controls
        width={640}
        height={360}
        preload="auto"
      >
        <source src={props.src} type={props.type} />
        {props.subtitlesUrl && (
          <track
            src={props.subtitlesUrl}
            srcLang="ja"
            label="日本語"
            kind="subtitles"
            default={true}
          />
        )}
      </video>
      {props.narrations
        .filter((n) => n.audio?.blobSize)
        .map((narration) => (
          <PreviewAudio
            key={narration.key}
            narration={narration}
            // PreviewAudioの副作用フックの処理回数を軽減するため親コンポーネント側でざっくり範囲チェックする
            currentTimeMs={onlyWithin(
              currentTimeMs,
              narration.startTimeMs,
              narration.startTimeMs + narration.audio.durationMs
            )}
          />
        ))}
    </>
  );
};

const PreviewAudio: VFC<{
  narration: NarrationState;
  currentTimeMs?: number;
}> = (props) => {
  const audioRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (props.currentTimeMs == null) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();
      }
      return;
    }

    // そもそも範囲外
    const newCurrentTimeMs = props.currentTimeMs - props.narration.startTimeMs;
    if (
      newCurrentTimeMs < 0 ||
      props.narration.audio.durationMs < newCurrentTimeMs
    ) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();
      }
      return;
    }

    // 範囲内なので、シークが必要かどうかチェック
    const actualTimeMs = Math.floor(audioRef.current.currentTime * 1000);
    if (shouldChangeCurrentTimeMs(actualTimeMs, newCurrentTimeMs)) {
      audioRef.current.currentTime = newCurrentTimeMs / 1000;
      if (audioRef.current.paused) {
        audioRef.current.play().catch((reason) => {
          if (reason instanceof Error && reason.name === "AbortError") {
            // 高頻度に再生と停止を処理するので割り込みエラーが起きるが、許容せざるを得ないので警告として処理する。
            // see: https://goo.gl/LdLk22
            console.warn(reason);
            Rollbar.warn(reason);
          } else {
            throw reason;
          }
        });
      }
    }
  }, [
    props.currentTimeMs,
    props.narration.audio.durationMs,
    props.narration.startTimeMs,
  ]);

  return (
    <audio
      ref={audioRef}
      src={props.narration.audio.blobUrl}
      hidden={true}
      preload="auto"
    />
  );
};

function shouldChangeCurrentTimeMs(actualMs: number, expectMs: number) {
  // ±100ms以上ずれていたらシークする
  return expectMs < actualMs - 100 || actualMs + 100 < expectMs;
}

function onlyWithin(num: number, start: number, end: number): number {
  if (start <= num && num <= end) {
    return num;
  }
  return null;
}
