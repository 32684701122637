import {
  FC,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ApiWordPronounciationApi, Configuration } from "../../generated/api";
import { useSubMenuVisible } from "../common/submenus/useSubMenuVisible";
import { AzureCognitiveService } from "../../lib/AzureCognitiveService";
import { phoneme2Ssml } from "../../lib/ssmlHelper";

interface WordPronounciation {
  id: number;
  literal: string;
  phoneme: string;
  lastModifiedBy?: {
    name?: string;
    imageUrl?: string;
  };
  lastModifiedAt: string;
}
interface Props {
  csrfToken: string;
  wordPronounciation: WordPronounciation;
}

const WordPronounciationEntry: FC<Props> = (props) => {
  const { wordPronounciation } = props;
  const [phoneme, setPhoneme] = useState(wordPronounciation.phoneme);
  const [literal, setLiteral] = useState(wordPronounciation.literal);
  const [subMenuVisible, toggleSubMenuVisible] = useSubMenuVisible();
  const acsRef = useRef<AzureCognitiveService>();
  const phonemeRef = useRef<HTMLInputElement>();
  const literalRef = useRef<HTMLInputElement>();

  useEffect(() => {
    return () => acsRef.current?.disposeAudioCaches();
  }, []);

  const api = useMemo(
    () =>
      new ApiWordPronounciationApi(
        new Configuration({
          basePath: "",
          headers: {
            "x-hopper-api-version": "1.0",
            "X-CSRF-Token": props.csrfToken,
          },
        })
      ),
    [props.csrfToken]
  );

  const formattedLastModifiedAt = useMemo(() => {
    // RubyのI18n.l(_, format: :long) 相当のフォーマット
    const dateTimeFormater = new Intl.DateTimeFormat("ja-JP", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
    return dateTimeFormater.format(new Date(wordPronounciation.lastModifiedAt));
  }, [wordPronounciation.lastModifiedAt]);

  const handleLiteralChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLiteral(e.target.value);
  };
  const handlePhonemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneme(e.target.value);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    api
      .apiWordPronounciationsIdPut({
        id: wordPronounciation.id,
        apiWordPronounciationsIdPutRequest: {
          literal,
          phoneme,
        },
      })
      .then(() => {
        location.reload();
      })
      .catch(async (error) => {
        const { messages } = await error.response.json();
        if (messages.literal) {
          literalRef.current.setCustomValidity(messages.literal.join("\n"));
          literalRef.current.reportValidity();
        }
        if (messages.phoneme) {
          phonemeRef.current.setCustomValidity(messages.phoneme.join("\n"));
          phonemeRef.current.reportValidity();
        }
      });
  };

  const handleDelete = () => {
    api
      .apiWordPronounciationsIdDelete({
        id: wordPronounciation.id,
      })
      .then(() => {
        location.reload();
      });
  };

  const handlePreview = async () => {
    acsRef.current ??= new AzureCognitiveService(props.csrfToken);
    await acsRef.current.ensureToken();
    const ssmlString = phoneme2Ssml({
      phoneme,
      literal,
      voiceName: "ja-JP-NanamiNeural",
      speechRate: "1",
    });

    const audioInfo = await acsRef.current.convertSsml(ssmlString).catch(() => {
      phonemeRef.current.setCustomValidity(
        "音声生成に失敗しました。読み方の指定が正しいか確認してください。"
      );
      phonemeRef.current.reportValidity();
    });
    if (!audioInfo) {
      return;
    }
    const audio = new Audio(audioInfo.blobUrl);
    audio.play();
  };

  const toggleMenu: MouseEventHandler = () => {
    toggleSubMenuVisible();
  };

  const isDirty =
    phoneme !== wordPronounciation.phoneme ||
    literal !== wordPronounciation.literal;

  return (
    <form className="p-word-pronounciation-table-row">
      <div className="p-word-pronounciation-table-column--literal">
        <input
          className="p-word-pronounciation-input"
          onChange={handleLiteralChange}
          type="text"
          required
          value={literal}
          ref={literalRef}
        />
      </div>
      <div className="p-word-pronounciation-table-column--phoneme">
        <button
          type="button"
          onClick={handlePreview}
          className="material-icons-round p-word-pronounciation__preview-button"
        >
          volume_up
        </button>
        <input
          className="p-word-pronounciation-input"
          onChange={handlePhonemeChange}
          type="text"
          required
          value={phoneme}
          ref={phonemeRef}
        />
        <button
          type="button"
          onClick={toggleMenu}
          className="material-icons-round c-submenu__button"
        >
          more_vert
        </button>
        {subMenuVisible && (
          <ul className="c-submenu__list p-word-pronounciation__submenu">
            <li className="c-submenu__list-item danger">
              <button onClick={handleDelete}>
                <i className="material-icons-outlined c-submenu__list-item-icon">
                  delete
                </i>
                削除
              </button>
            </li>
          </ul>
        )}
        <button
          className="c-button--primary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isDirty}
        >
          保存
        </button>
      </div>
      <div className="p-word-pronounciation-table-column--modification">
        <img
          src={wordPronounciation.lastModifiedBy?.imageUrl || ""}
          alt=""
          title={wordPronounciation.lastModifiedBy?.name}
          className="p-word-pronounciation_last-modified-by-icon"
          referrerPolicy="no-referrer"
        />
        <div className="p-word-pronounciation-modification-container">
          <span>
            {wordPronounciation.lastModifiedBy?.name || "削除済みユーザー"}
          </span>
          <span>{formattedLastModifiedAt} 更新</span>
        </div>
      </div>
    </form>
  );
};

export default WordPronounciationEntry;
