import { VFC, useState, useEffect } from "react";
import { videoReviewsPath } from "../../generated/routes";
import bestImage from "images/review/best.svg";
import betterImage from "images/review/better.svg";
import normalImage from "images/review/normal.svg";
import worseImage from "images/review/worse.svg";
import worstImage from "images/review/worst.svg";

interface Props {
  videoID: string;
  csrfToken: string;
}
interface Rate {
  rate: number;
  image: string;
  label: string;
}

const Review: VFC<Props> = (props) => {
  const { videoID, csrfToken } = props;
  const [localStorageVideoID, setLocalStorageVideoID] = useState<string | null>(
    () => {
      try {
        return localStorage.getItem(videoID);
      } catch (reason) {
        Rollbar.info(reason);
        return null;
      }
    }
  );
  const [rate, setRate] = useState<number>(null);
  const [reviewdVideoID, setReviewdVideoID] = useState("");

  const rateArr: Rate[] = [
    { rate: 1, image: worstImage, label: "不満" },
    { rate: 2, image: worseImage, label: "やや不満" },
    { rate: 3, image: normalImage, label: "ふつう" },
    { rate: 4, image: betterImage, label: "やや満足" },
    { rate: 5, image: bestImage, label: "満足" },
  ];

  const handleSelect = (rate: number) => {
    setRate(rate);
  };

  useEffect(() => {
    if (rate !== null) {
      const url = videoReviewsPath(videoID);
      const params = {
        review: {
          rate: rate,
        },
      };
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(params),
      })
        .then(() => {
          try {
            localStorage.setItem(videoID, videoID);
            setLocalStorageVideoID(localStorage.getItem("videoID"));
          } catch (reason) {
            Rollbar.info(reason);
          }
          setReviewdVideoID(videoID);
        })
        .catch((reason) => {
          Rollbar.warn(reason);
        });
    }
  }, [rate, videoID, csrfToken]);

  if (reviewdVideoID === videoID) {
    return (
      <div className="p-video_ratingArea__box">
        <h3>ありがとうございます！</h3>
        <h5>ビデオの改善の参考にさせていただきます。</h5>
      </div>
    );
  }

  if (localStorageVideoID === videoID) {
    return <></>;
  }

  return (
    <>
      <h2>ビデオの評価</h2>
      <div className="p-video_ratingArea__row">
        {rateArr.map((arr, i) => (
          <button className="p-video_ratingArea__button" key={i}>
            <img src={arr.image} onClick={() => handleSelect(arr.rate)} />
            <p>{arr.label}</p>
          </button>
        ))}
      </div>
    </>
  );
};

export default Review;
