/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAcademyCoursesIdGet200Response,
  ApiAcademyCoursesIdPatch202Response,
  ApiAcademyCoursesIdPatchRequest,
  ApiAcademyCoursesPost201Response,
  ApiAcademyCoursesPostRequest,
} from '../models';
import {
    ApiAcademyCoursesIdGet200ResponseFromJSON,
    ApiAcademyCoursesIdGet200ResponseToJSON,
    ApiAcademyCoursesIdPatch202ResponseFromJSON,
    ApiAcademyCoursesIdPatch202ResponseToJSON,
    ApiAcademyCoursesIdPatchRequestFromJSON,
    ApiAcademyCoursesIdPatchRequestToJSON,
    ApiAcademyCoursesPost201ResponseFromJSON,
    ApiAcademyCoursesPost201ResponseToJSON,
    ApiAcademyCoursesPostRequestFromJSON,
    ApiAcademyCoursesPostRequestToJSON,
} from '../models';

export interface ApiAcademyCoursesIdDeleteRequest {
    id: number;
}

export interface ApiAcademyCoursesIdGetRequest {
    id: number;
}

export interface ApiAcademyCoursesIdPatchOperationRequest {
    id: number;
    apiAcademyCoursesIdPatchRequest?: ApiAcademyCoursesIdPatchRequest;
}

export interface ApiAcademyCoursesPostOperationRequest {
    apiAcademyCoursesPostRequest?: ApiAcademyCoursesPostRequest;
}

/**
 * 
 */
export class ApiAcademyCourseApi extends runtime.BaseAPI {

    /**
     * destroy
     */
    async apiAcademyCoursesIdDeleteRaw(requestParameters: ApiAcademyCoursesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCoursesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/courses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiAcademyCoursesIdDelete(requestParameters: ApiAcademyCoursesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiAcademyCoursesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * show
     */
    async apiAcademyCoursesIdGetRaw(requestParameters: ApiAcademyCoursesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCoursesIdGet200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCoursesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/courses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCoursesIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiAcademyCoursesIdGet(requestParameters: ApiAcademyCoursesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCoursesIdGet200Response> {
        const response = await this.apiAcademyCoursesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiAcademyCoursesIdPatchRaw(requestParameters: ApiAcademyCoursesIdPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCoursesIdPatch202Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademyCoursesIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/academy/courses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAcademyCoursesIdPatchRequestToJSON(requestParameters.apiAcademyCoursesIdPatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCoursesIdPatch202ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiAcademyCoursesIdPatch(requestParameters: ApiAcademyCoursesIdPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCoursesIdPatch202Response> {
        const response = await this.apiAcademyCoursesIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create
     */
    async apiAcademyCoursesPostRaw(requestParameters: ApiAcademyCoursesPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyCoursesPost201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/academy/courses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAcademyCoursesPostRequestToJSON(requestParameters.apiAcademyCoursesPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyCoursesPost201ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiAcademyCoursesPost(requestParameters: ApiAcademyCoursesPostOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyCoursesPost201Response> {
        const response = await this.apiAcademyCoursesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
