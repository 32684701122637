import { VFC } from "react";
import { DurationText } from "../../common/DurationText";
import { LineGraph, ChartData, TooltipComponent } from "./LineGraph";

type Props = {
  data: {
    /** YYYY-MM-DD */
    day: string;
    /** 日別の累計視聴時間(ミリ秒) */
    sum: number;
  }[];
};

function convert2ChartData(src: Props["data"]) {
  const labels = src.map((d) => d.day.replace(/-/g, "/"));
  const data = src.map((d) => d.sum / 1000);
  const maxValue = Math.max(...data);
  const chartData: ChartData = {
    labels,
    datasets: [{ data }],
  };
  return { chartData, maxValue };
}

// e.g. 2020年12月22日(火)
const dateTimeFormater = new Intl.DateTimeFormat("ja-JP", {
  weekday: "short",
  year: "numeric",
  month: "long",
  day: "numeric",
});

export const Tooltip: TooltipComponent = (props) => {
  const [data] = props.model.dataPoints;
  const dateLabel = dateTimeFormater.format(new Date(data.label));
  return (
    <div className="c-graph-tooltip text-center">
      <p className="c-graph-tooltip__opt-text">{dateLabel}</p>
      <p className="c-graph-tooltip__primary-text">
        <DurationText
          value={Number(data.raw)}
          format={{ hours: true, minutes: true, seconds: true, separator: " " }}
        />
      </p>
    </div>
  );
};

export const WatchCumulativeGraph: VFC<Props> = (props) => {
  const { chartData, maxValue } = convert2ChartData(props.data);
  // 秒、分、時間でY軸がスケールするようにしておく
  const yScale = maxValue > 3600 ? 3600 : maxValue > 60 ? 60 : undefined;
  return <LineGraph data={chartData} tooltip={Tooltip} yTickScale={yScale} />;
};
export default WatchCumulativeGraph;
