import { VFC } from "react";
import type { User } from "app/javascript/types/user";

type Props = {
  data: {
    user: User;
  }[];
  domainWatchLinkAvailable: boolean;
};

export const WatchedUserList: VFC<Props> = (props) => {
  const { data, domainWatchLinkAvailable } = props;

  if (!data?.length) {
    return (
      <p className="p-video-watched-users__placeholder">
        {domainWatchLinkAvailable
          ? "ビデオの共有が「特定の人」、「特定のドメイン」で視聴したユーザーの一覧が表示されます。"
          : "ビデオの共有が「特定の人」で視聴したユーザーの一覧が表示されます。"}
      </p>
    );
  }

  return (
    <ul className="p-video-watched-users__list">
      {props.data.map((data, index) => {
        const { user } = data;
        return (
          // keyは本来はユニーク値にするべきだが、Userのidを取得してないので一旦index
          <li key={index} className="p-video-watched-users__list-item">
            <img
              className="p-video-watched-users__avatar-icon"
              alt={user.name}
              src={user.imageUrl}
              width={34}
              height={34}
            />
            {user.name}
          </li>
        );
      })}
    </ul>
  );
};
export default WatchedUserList;
