/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoIdMovePatch200ResponseSubtitles
 */
export interface ApiVideoIdMovePatch200ResponseSubtitles {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdMovePatch200ResponseSubtitles
     */
    generateJobState?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoIdMovePatch200ResponseSubtitles
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiVideoIdMovePatch200ResponseSubtitles
     */
    isPublished?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiVideoIdMovePatch200ResponseSubtitles
     */
    isDefaultDisplayed?: boolean;
}

/**
 * Check if a given object implements the ApiVideoIdMovePatch200ResponseSubtitles interface.
 */
export function instanceOfApiVideoIdMovePatch200ResponseSubtitles(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoIdMovePatch200ResponseSubtitlesFromJSON(json: any): ApiVideoIdMovePatch200ResponseSubtitles {
    return ApiVideoIdMovePatch200ResponseSubtitlesFromJSONTyped(json, false);
}

export function ApiVideoIdMovePatch200ResponseSubtitlesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoIdMovePatch200ResponseSubtitles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generateJobState': !exists(json, 'generateJobState') ? undefined : json['generateJobState'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'isPublished': !exists(json, 'isPublished') ? undefined : json['isPublished'],
        'isDefaultDisplayed': !exists(json, 'isDefaultDisplayed') ? undefined : json['isDefaultDisplayed'],
    };
}

export function ApiVideoIdMovePatch200ResponseSubtitlesToJSON(value?: ApiVideoIdMovePatch200ResponseSubtitles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generateJobState': value.generateJobState,
        'size': value.size,
        'isPublished': value.isPublished,
        'isDefaultDisplayed': value.isDefaultDisplayed,
    };
}

