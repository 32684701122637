/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoUploadedPostRequest
 */
export interface ApiVideoUploadedPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoUploadedPostRequest
     */
    assetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoUploadedPostRequest
     */
    title?: string;
    /**
     * 
     * @type {any}
     * @memberof ApiVideoUploadedPostRequest
     */
    folderId?: any | null;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoUploadedPostRequest
     */
    inputVideoDuration?: number;
}

/**
 * Check if a given object implements the ApiVideoUploadedPostRequest interface.
 */
export function instanceOfApiVideoUploadedPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoUploadedPostRequestFromJSON(json: any): ApiVideoUploadedPostRequest {
    return ApiVideoUploadedPostRequestFromJSONTyped(json, false);
}

export function ApiVideoUploadedPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoUploadedPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetName': !exists(json, 'asset_name') ? undefined : json['asset_name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'folderId': !exists(json, 'folder_id') ? undefined : json['folder_id'],
        'inputVideoDuration': !exists(json, 'input_video_duration') ? undefined : json['input_video_duration'],
    };
}

export function ApiVideoUploadedPostRequestToJSON(value?: ApiVideoUploadedPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset_name': value.assetName,
        'title': value.title,
        'folder_id': value.folderId,
        'input_video_duration': value.inputVideoDuration,
    };
}

