/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoIdThumbnailPost200Response,
  ApiVideoIdThumbnailPresignGet201Response,
} from '../models';
import {
    ApiVideoIdThumbnailPost200ResponseFromJSON,
    ApiVideoIdThumbnailPost200ResponseToJSON,
    ApiVideoIdThumbnailPresignGet201ResponseFromJSON,
    ApiVideoIdThumbnailPresignGet201ResponseToJSON,
} from '../models';

export interface ApiVideoIdThumbnailDeleteRequest {
    id: number;
}

export interface ApiVideoIdThumbnailPostRequest {
    id: number;
    assetName?: string;
    assetContainer?: string;
}

export interface ApiVideoIdThumbnailPresignGetRequest {
    id: number;
}

/**
 * 
 */
export class ApiThumbnailApi extends runtime.BaseAPI {

    /**
     * destroy
     */
    async apiVideoIdThumbnailDeleteRaw(requestParameters: ApiVideoIdThumbnailDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdThumbnailDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{id}/thumbnail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiVideoIdThumbnailDelete(requestParameters: ApiVideoIdThumbnailDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoIdThumbnailDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create
     */
    async apiVideoIdThumbnailPostRaw(requestParameters: ApiVideoIdThumbnailPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdThumbnailPost200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdThumbnailPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.assetName !== undefined) {
            formParams.append('asset_name', requestParameters.assetName as any);
        }

        if (requestParameters.assetContainer !== undefined) {
            formParams.append('asset_container', requestParameters.assetContainer as any);
        }

        const response = await this.request({
            path: `/api/video/{id}/thumbnail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdThumbnailPost200ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiVideoIdThumbnailPost(requestParameters: ApiVideoIdThumbnailPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdThumbnailPost200Response> {
        const response = await this.apiVideoIdThumbnailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * presign
     */
    async apiVideoIdThumbnailPresignGetRaw(requestParameters: ApiVideoIdThumbnailPresignGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdThumbnailPresignGet201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdThumbnailPresignGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{id}/thumbnail/presign`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdThumbnailPresignGet201ResponseFromJSON(jsonValue));
    }

    /**
     * presign
     */
    async apiVideoIdThumbnailPresignGet(requestParameters: ApiVideoIdThumbnailPresignGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdThumbnailPresignGet201Response> {
        const response = await this.apiVideoIdThumbnailPresignGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
