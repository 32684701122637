/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner } from './ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner';
import {
    ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerFromJSON,
    ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerFromJSONTyped,
    ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerToJSON,
} from './ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner';

/**
 * 
 * @export
 * @interface ApiVideoVideoIdSubtitlesPutRequest
 */
export interface ApiVideoVideoIdSubtitlesPutRequest {
    /**
     * 
     * @type {Array<ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner>}
     * @memberof ApiVideoVideoIdSubtitlesPutRequest
     */
    subtitles?: Array<ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner>;
}

/**
 * Check if a given object implements the ApiVideoVideoIdSubtitlesPutRequest interface.
 */
export function instanceOfApiVideoVideoIdSubtitlesPutRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdSubtitlesPutRequestFromJSON(json: any): ApiVideoVideoIdSubtitlesPutRequest {
    return ApiVideoVideoIdSubtitlesPutRequestFromJSONTyped(json, false);
}

export function ApiVideoVideoIdSubtitlesPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdSubtitlesPutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subtitles': !exists(json, 'subtitles') ? undefined : ((json['subtitles'] as Array<any>).map(ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerFromJSON)),
    };
}

export function ApiVideoVideoIdSubtitlesPutRequestToJSON(value?: ApiVideoVideoIdSubtitlesPutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subtitles': value.subtitles === undefined ? undefined : ((value.subtitles as Array<any>).map(ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerToJSON)),
    };
}

