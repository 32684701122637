import {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useMemo,
  CSSProperties,
  useCallback,
  MutableRefObject,
} from "react";

import { videojs } from "../../lib/videojs";
import type { Clip } from "./useTimelineClips";

interface Props {
  clips: Clip[];
  onPlayingStateUpdate(playing: boolean): void;
  onCurrentTimeMsUpdate(currentTimeMs: number): void;
}

export interface PreviewPlayerHandler {
  play(): void;
  pause(): void;
  enterFullscreen?(): void;
  seekInTimeline(ms: number): void;
  seekInClip(clip: Clip, positionMsInClip: number): void;
}

export const PreviewPlayer = forwardRef<PreviewPlayerHandler, Props>(
  function PreviewPlayerImpl(props, ref) {
    const { clips, onPlayingStateUpdate, onCurrentTimeMsUpdate } = { ...props };
    const containerRef = useRef<HTMLDivElement>();
    const wrapperRefs = useRef<HTMLDivElement[]>([]);
    const playerRefs = useRef<ReturnType<typeof videojs>[]>([]);
    const isPlayingRef = useRef(false);
    const isSeekingWithinClip = useRef(false);
    const [currentClipIndex, setCurrentClipIndex] = useState(0);

    const containerStyle: CSSProperties = useMemo(() => {
      // 画面全体がスクロールしないよう、ある程度の範囲でプレイヤーの高さを調整する
      const height = Math.min(
        Math.max(320, document.documentElement.clientHeight - 350),
        500
      );
      const width = (height / 9) * 16; // 16:9
      return { width, height };
    }, []);

    const clipsWithOffset = useMemo(() => {
      const result: { clip: Clip; offsetMs: number }[] = [];
      let offsetMs = 0;
      for (const clip of clips) {
        result.push({ clip, offsetMs });
        offsetMs += clip.durationMs;
      }
      return result;
    }, [clips]);

    const play = useCallback(
      (targetClipIndex: number) => {
        playerRefs.current.forEach((player, i) => {
          if (i === targetClipIndex) {
            player.play();
          } else {
            player.pause();
          }
        });
        setCurrentClipIndex(targetClipIndex);
        onPlayingStateUpdate((isPlayingRef.current = true));
      },
      [onPlayingStateUpdate]
    );

    const pause = useCallback(() => {
      playerRefs.current.forEach((p) => p.pause());
      onPlayingStateUpdate((isPlayingRef.current = false));
    }, [onPlayingStateUpdate]);

    useImperativeHandle(
      ref,
      () => ({
        play: () => play(currentClipIndex),
        pause,
        enterFullscreen: resolveRequestFullscreen(containerRef),
        seekInTimeline(ms: number) {
          const index = clipsWithOffset.findIndex((current, i, arr) => {
            const next = arr[i + 1];
            return current.offsetMs <= ms && (!next || ms <= next.offsetMs);
          });
          if (index < 0) {
            return;
          }
          const { clip, offsetMs } = clipsWithOffset[index];
          const positionMsInClip = ms - offsetMs + clip.startMs;
          const player = playerRefs.current[index];
          player.one("seeked", () => {
            if (isPlayingRef.current) {
              player.play(); // 再生中にシークした時、クリップを跨いでも再生を継続する
            }
            setCurrentClipIndex(index);
          });
          player.currentTime(positionMsInClip / 1000);
          playerRefs.current.forEach((player, i) => {
            if (i !== index) {
              player.pause();
            }
          });
        },
        seekInClip(clip: Clip, positionMsInClip: number) {
          pause();

          const index = clips.findIndex((c) => c.id === clip.id);
          setCurrentClipIndex(index);
          isSeekingWithinClip.current = true;
          const player = playerRefs.current[index];

          player.one("seeked", () => {
            isSeekingWithinClip.current = false;
          });
          player.currentTime(positionMsInClip / 1000);
        },
      }),
      [clips, clipsWithOffset, currentClipIndex, pause, play]
    );

    useEffect(() => {
      isSeekingWithinClip.current = false;
      onPlayingStateUpdate((isPlayingRef.current = false));

      // 古い参照が残ってしまうので取り除く
      // e.g. ビデオが3つから2つに減った時、3つ目divへの参照が残るので2つ目までだけ残す。
      wrapperRefs.current = wrapperRefs.current.slice(
        0,
        clipsWithOffset.length
      );
      playerRefs.current = playerRefs.current.slice(0, clipsWithOffset.length);

      const players: ReturnType<typeof videojs>[] = [];
      clipsWithOffset.forEach(({ clip, offsetMs }, index) => {
        // videojsのエントリポイントになるvideo要素はvideojsによって破壊的に変更されReactのrenderとバッティングする。
        // 自前でappendしてしまうことで回避する。
        const videoEl = document.createElement("video");
        videoEl.className = "video-js";
        videoEl.preload = "auto";
        videoEl.style.width = "100%";
        videoEl.style.height = "100%";
        wrapperRefs.current[index].append(videoEl);

        const opt = {
          controls: false, //コントローラーを非表示
          autoplay: false,
          preload: "auto",
        };
        const player = videojs(videoEl, opt);
        player.on("loadedmetadata", () => {
          forceUseHighestQualityLevel(player);
        });
        player.src({
          src: clip.video.streamingLocatorUrl,
          type: "application/x-mpegURL",
        });
        // ビデオ読み込み時にクリップの開始位置に移動しておく
        player.on("loadeddata", () => {
          player.currentTime(clip.startMs / 1000);
        });

        // 再生位置の同期
        player.on("timeupdate", () => {
          if (player.paused()) {
            return; // 再生中のみ同期
          }
          const positionMsInClip = Math.floor(player.currentTime() * 10) * 100; // 100ms未満を切り捨てる
          const newCurrentTimeMs = offsetMs + positionMsInClip - clip.startMs;
          onCurrentTimeMsUpdate(newCurrentTimeMs);
        });

        // クリップの範囲のみ再生する
        player.on("timeupdate", () => {
          if (isSeekingWithinClip.current) {
            return; // クリップの開始・終了位置の変更中は、クリップの再生範囲の限定をしない
          }

          const positionMsInClip = player.currentTime() * 1000;
          if (positionMsInClip < clip.startMs) {
            player.currentTime(clip.startMs / 1000);
          } else if (clip.endMs <= positionMsInClip) {
            player.pause();

            const nextIndex = index + 1;
            const nextClip = clipsWithOffset[nextIndex]?.clip;
            if (nextClip) {
              if (isPlayingRef.current) {
                playerRefs.current[nextIndex].play();
                setCurrentClipIndex(nextIndex);
              }
            } else {
              onPlayingStateUpdate((isPlayingRef.current = false));
            }
          }
        });

        playerRefs.current[index] = player;
        players.push(player);
      });
      return () => {
        players.forEach((p) => p.dispose());
      };
    }, [clipsWithOffset, onPlayingStateUpdate, onCurrentTimeMsUpdate]);

    useEffect(() => {
      clipsWithOffset.forEach(({ clip }, index) => {
        if (index === currentClipIndex) {
          return;
        }
        // 次の再生に備えて非表示になったクリップの再生位置をリセットしておく
        const player = playerRefs.current[index];
        player.currentTime(clip.startMs / 1000);
      });

      // 末尾クリップを表示中そのクリップが削除されたら真っ白になるのを回避するため、手前のクリップに切替
      if (currentClipIndex >= clipsWithOffset.length) {
        setCurrentClipIndex(clipsWithOffset.length - 1);
      }
    }, [clipsWithOffset, currentClipIndex]);

    // videojsのコントロールを使わない代わりにクリックでの再生・停止を自前で制御
    const togglePlayPause = () => {
      const player = playerRefs.current[currentClipIndex];
      if (player.paused()) {
        play(currentClipIndex);
      } else {
        pause();
      }
    };

    return (
      <div ref={containerRef} style={containerStyle} onClick={togglePlayPause}>
        {clips.map((clip, i) => (
          <div
            key={clip.id}
            id={`tl-clip-${clip.id}`}
            ref={(elem) => (wrapperRefs.current[i] = elem)}
            hidden={currentClipIndex !== i}
            style={currentClipIndex === i ? { display: "contents" } : null}
          />
        ))}
      </div>
    );
  }
);

function resolveRequestFullscreen(ref: MutableRefObject<Element>) {
  // 2023年3月現在はまだモバイルSafariではベンダプレフィックスが必要なので、ここでクッションする。
  function getRequestFullscreenFunc(ref: MutableRefObject<Element>) {
    return (
      ref.current.requestFullscreen ??
      (ref.current as unknown as { webkitRequestFullscreen(): void })
        .webkitRequestFullscreen
    );
  }
  if (getRequestFullscreenFunc(ref)) {
    return () => {
      getRequestFullscreenFunc(ref).call(ref.current);
    };
  }
  return null;
}

function forceUseHighestQualityLevel(player: ReturnType<typeof videojs>) {
  // 最高画質以外を無効化して、最高画質だけ使うよう強制する。
  const qualityLevels = player.qualityLevels();
  const bitrateList = Array.from(qualityLevels).map((level) => level.bitrate);
  const highestBitrateIndex = bitrateList.indexOf(Math.max(...bitrateList));
  Array.from(qualityLevels).forEach((level, index) => {
    level.enabled = index === highestBitrateIndex;
  });
  // 強制的に画質を変更するイベント発火する公式ハック
  // https://github.com/videojs/videojs-contrib-quality-levels/tree/v4.1.0?tab=readme-ov-file#triggering-the-change-event
  qualityLevels.selectedIndex_ = highestBitrateIndex;
  qualityLevels.trigger({ type: "change", selectedIndex: highestBitrateIndex });
}
