import { ReactNode, VFC, useState } from "react";
import ReactModal from "react-modal";

import { toTreeFolderFromTeamFolders, TreeFolder } from "../../lib/tree-folder";
import type { Folder } from "../../types/folder";
import { TreeView, TreeItem, TreeViewThemeContext } from "./treeview";

interface Props {
  isOpen: boolean;
  onClick: (folder: Folder) => void;
  onClose: () => void;
  teamFolders: Folder[];
  selectedFolder?: Folder;
  headerTitle: string;
}

export const FolderSelectorModal: VFC<Props> = (props) => {
  const rootTeamFolder = toTreeFolderFromTeamFolders(props.teamFolders);
  const [folderHashid, setFolderHashid] = useState<string | undefined>(
    props.selectedFolder?.hashid
  );
  const onClick = () => {
    const folder = props.teamFolders.find((f) => f.hashid === folderHashid);
    if (folder) {
      props.onClick(folder);
    }
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      overlayClassName="c-dialog__overlay vertical-center"
      className="c-dialog__container c-folder-selector-modal__container"
    >
      <div className="c-dialog__header">
        <h2 className="c-dialog__header__title">{props.headerTitle}</h2>
        <button
          className="material-icons-round c-dialog__header__close-icon"
          onClick={() => {
            props.onClose();
            // 再度開いたときにクリックしたが選択ボタンを押下せずに閉じたものはリセットする
            if (props.selectedFolder) {
              setFolderHashid(props.selectedFolder.hashid);
            }
          }}
        >
          close
        </button>
      </div>

      <div className="c-dialog__content c-folder-selector-modal__body">
        <div className="c-folder-selector-modal__scroll-list">
          <TreeView
            selectedItemId={folderHashid}
            onItemSelected={(itemId: string) => setFolderHashid(itemId)}
          >
            <TreeViewThemeContext.Provider
              value={{
                icon: <span className="material-icons-round">folder</span>,
              }}
            >
              <TreeItem
                itemId={rootTeamFolder.hashid}
                label={rootTeamFolder.name}
                icon={<span className="material-icons-round">business</span>}
              >
                {buildTreeView(rootTeamFolder.children)}
              </TreeItem>
            </TreeViewThemeContext.Provider>
          </TreeView>
        </div>
      </div>
      <div className="c-dialog__footer">
        <button
          className="c-button--primary narrow-padding"
          type="submit"
          disabled={!folderHashid}
          onClick={onClick}
        >
          選択する
        </button>
      </div>
    </ReactModal>
  );
};

function buildTreeView(folders: TreeFolder[] | undefined): ReactNode {
  if (folders) {
    return folders.map((folder) => (
      <TreeItem key={folder.hashid} itemId={folder.hashid} label={folder.name}>
        {buildTreeView(folder.children)}
      </TreeItem>
    ));
  }
}
