/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategory } from './ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategory';
import {
    ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategoryFromJSON,
    ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategoryFromJSONTyped,
    ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategoryToJSON,
} from './ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategory';

/**
 * 
 * @export
 * @interface ApiAcademyCourseCategoriesIdPatchRequest
 */
export interface ApiAcademyCourseCategoriesIdPatchRequest {
    /**
     * 
     * @type {ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategory}
     * @memberof ApiAcademyCourseCategoriesIdPatchRequest
     */
    academyCourseCategory?: ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategory;
}

/**
 * Check if a given object implements the ApiAcademyCourseCategoriesIdPatchRequest interface.
 */
export function instanceOfApiAcademyCourseCategoriesIdPatchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCourseCategoriesIdPatchRequestFromJSON(json: any): ApiAcademyCourseCategoriesIdPatchRequest {
    return ApiAcademyCourseCategoriesIdPatchRequestFromJSONTyped(json, false);
}

export function ApiAcademyCourseCategoriesIdPatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCourseCategoriesIdPatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'academyCourseCategory': !exists(json, 'academy_course_category') ? undefined : ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategoryFromJSON(json['academy_course_category']),
    };
}

export function ApiAcademyCourseCategoriesIdPatchRequestToJSON(value?: ApiAcademyCourseCategoriesIdPatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'academy_course_category': ApiAcademyCourseCategoriesIdPatchRequestAcademyCourseCategoryToJSON(value.academyCourseCategory),
    };
}

