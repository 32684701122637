import { useState, useCallback, useMemo } from "react";
import type { RefObject, PointerEvent } from "react";
import type { Clip } from "./useTimelineClips";

export function useClipItemDraggingPosition(
  fixedClipItemWidth: number,
  tlScrollContainerRef: RefObject<Element>,
  tlClipItemContainerRef: RefObject<Element>
) {
  const [draggingClip, setDraggingClip] = useState<Clip>(null);
  const [overlayClipItemAdjustX, setOverlayClipItemAdjustX] = useState(0);
  const [draggingClipItemX, setDraggingClipItemX] = useState(0);
  const [prevClipItemsWidth, setPrevClipItemsWidth] = useState(0);

  function retainPos(event: PointerEvent) {
    // dndkitがドラッグ開始前後で対象の要素の大きさが変わることをサポートしていないので、
    // 自前で位置調整するためにポインタのイベントを監視する。
    const rect = event.currentTarget.getBoundingClientRect();
    setOverlayClipItemAdjustX(event.clientX - rect.left);

    // ドラッグ開始時のマウスカーソル位置がドラッグするクリップの中央になるようにしたいので、座標を保持しておく。
    // TL上でドラッグ中にクリップを表示する位置 = 画面上のクリック位置 - タイムラインの左位置 - スクロール量 - クリップ表示幅の半分
    const xOnTl =
      event.clientX -
      tlClipItemContainerRef.current.getBoundingClientRect().left -
      tlScrollContainerRef.current.scrollLeft -
      fixedClipItemWidth / 2;
    setDraggingClipItemX(xOnTl);
  }

  function show(clips: Clip[], id: Clip["id"]) {
    const index = clips.findIndex((c) => c.id === id);
    setDraggingClip(clips[index]);
    setPrevClipItemsWidth(fixedClipItemWidth * index); // ドラッグするクリップより前にあるクリップの表示幅
  }

  function hide() {
    setDraggingClip(null);
    setOverlayClipItemAdjustX(0);
    setDraggingClipItemX(0);
    setPrevClipItemsWidth(0);
  }

  return {
    draggingClipData: useMemo(() => {
      if (!draggingClip) {
        return null;
      }
      return {
        clip: draggingClip,
        overlayClipItemAdjustX,
        tlMarginLeft: draggingClipItemX - prevClipItemsWidth,
      };
    }, [
      draggingClip,
      overlayClipItemAdjustX,
      draggingClipItemX,
      prevClipItemsWidth,
    ]),
    retainPointerPosition: useCallback(retainPos, [
      fixedClipItemWidth,
      tlScrollContainerRef,
      tlClipItemContainerRef,
    ]),
    showDraggingOverlayClip: useCallback(show, [fixedClipItemWidth]),
    hideDraggingOverlayClip: useCallback(hide, []),
  };
}
