import { VFC, useState, useRef, useEffect } from "react";
import type {
  InteractionMode,
  TooltipModel,
  ChartType,
  ChartData,
} from "chart.js";
import {
  Chart,
  LineController,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from "chart.js";

Chart.register(
  LineController,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
);

type ChartTooltipModel = TooltipModel<ChartType>;

export type { ChartData };
export type TooltipComponent = VFC<{ model: ChartTooltipModel }>;

type Props = {
  data: ChartData;
  aspectRatio?: number;
  /**
   * Y軸の単位を可変にするスケール値
   *
   * 例えば、値の単位が秒の場合に`60`を指定するとY軸の表示は分単位になる。
   */
  yTickScale?: number;
  yMax?: number;
  // ツールチップの制御を親コンポーネントに移譲する実装にすると、ReactのレンダリングとChart.jsのDOM操作がカニバる。
  // そのため、ツールチップのコンポーネントを親から受け取り処理する形にしている。
  tooltip?: TooltipComponent;
};

const primaryColor = "#0032F0";
const primaryColorBackground = "#0032F022";
const white = "#FFFFFF";

export const LineGraph: VFC<Props> = (props) => {
  const { data, aspectRatio, yTickScale, yMax, tooltip: Tooltip } = props;
  const [tooltipModel, setTooltipModel] = useState<ChartTooltipModel>(null);
  const canvasRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    const tooltipMode: InteractionMode = "index";
    const chart = new Chart(canvasRef.current, {
      type: "line",
      data: data,
      options: {
        animation: false,
        aspectRatio,
        scales: {
          y: {
            position: "right",
            beginAtZero: true,
            max: yMax,
            ticks: {
              autoSkipPadding: 14,
              stepSize: yTickScale,
              callback(value) {
                if (yTickScale && typeof value === "number") {
                  return value / yTickScale;
                }
                return value;
              },
            },
          },
          x: {
            grid: { display: false },
            ticks: { font: { size: 10 }, maxRotation: 0, autoSkipPadding: 14 },
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            mode: tooltipMode,
            intersect: false,
            enabled: false, // デフォルトのツールチップは消す
            external({ tooltip }) {
              setTooltipModel({ ...tooltip });
            },
          },
        },
        hover: { mode: tooltipMode, intersect: false },
        elements: {
          line: {
            fill: true,
            backgroundColor: primaryColorBackground,
            borderColor: primaryColor,
            tension: 0,
          },
          point: {
            radius: 0,
            hoverRadius: 8,
            borderWidth: 3,
            backgroundColor: primaryColor,
            borderColor: white, // なんかきれいに白にならないけど諦める
            pointStyle: "circle",
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, [data, aspectRatio, yTickScale, yMax]);

  // ツールチップの位置制御まわりはcssに切り出し辛いのでここでやる
  return (
    <div style={{ position: "relative" }}>
      <canvas ref={canvasRef}></canvas>
      {Tooltip && tooltipModel?.opacity > 0 && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            transition: "transform 0.25s",
            transform: `translateX(calc(${tooltipModel.caretX}px - 50%))
                        translateY(calc(${tooltipModel.caretY}px - 100% - 16px))`,
          }}
        >
          <Tooltip model={tooltipModel} />
        </div>
      )}
    </div>
  );
};
