/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoIdThumbnailPresignGet201Response
 */
export interface ApiVideoIdThumbnailPresignGet201Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiVideoIdThumbnailPresignGet201Response
     */
    method?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdThumbnailPresignGet201Response
     */
    endpoint?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiVideoIdThumbnailPresignGet201Response
     */
    params?: object;
    /**
     * 
     * @type {object}
     * @memberof ApiVideoIdThumbnailPresignGet201Response
     */
    headers?: object;
}

/**
 * Check if a given object implements the ApiVideoIdThumbnailPresignGet201Response interface.
 */
export function instanceOfApiVideoIdThumbnailPresignGet201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoIdThumbnailPresignGet201ResponseFromJSON(json: any): ApiVideoIdThumbnailPresignGet201Response {
    return ApiVideoIdThumbnailPresignGet201ResponseFromJSONTyped(json, false);
}

export function ApiVideoIdThumbnailPresignGet201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoIdThumbnailPresignGet201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'method': !exists(json, 'method') ? undefined : json['method'],
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'params': !exists(json, 'params') ? undefined : json['params'],
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
    };
}

export function ApiVideoIdThumbnailPresignGet201ResponseToJSON(value?: ApiVideoIdThumbnailPresignGet201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': value.method,
        'endpoint': value.endpoint,
        'params': value.params,
        'headers': value.headers,
    };
}

