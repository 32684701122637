import { createContext } from "react";
import { ITEM_ID } from "./TreeViewTypes";

type TreeViewContextType = {
  selectedItemId?: ITEM_ID;
  selectItem?: (itemId: ITEM_ID) => void;
};

/**
 * ツリー全体で扱う情報を管理するコンテキスト
 */
export const TreeViewContext = createContext<TreeViewContextType>({});
TreeViewContext.displayName = "TreeViewContext";
