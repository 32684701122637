import type { MouseEventHandler, ButtonHTMLAttributes } from "react";
import { useState, forwardRef, useImperativeHandle } from "react";
import loadingImg from "images/loading.svg";

type Props = {
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

type SavingState = "default" | "saving" | "done";

export interface SaveButtonHandler {
  startSaving(): void;
  finishSaving(): Promise<void>;
  reset(): void;
}

export const SaveButton = forwardRef<SaveButtonHandler, Props>(
  function SaveButtonImpl(props: Props, ref) {
    const [state, setState] = useState<SavingState>("default");

    useImperativeHandle(
      ref,
      () => ({
        startSaving() {
          setState("saving");
        },
        async finishSaving() {
          setState("done");
          await new Promise((resolve) => setTimeout(resolve, 3_000)); // 少し待ってから初期状態に戻す。
          setState("default");
        },
        reset() {
          setState("default");
        },
      }),
      []
    );

    const className =
      "c-button--text-link-primary narrow-padding c-save-button__button";
    if (state === "saving") {
      return (
        <p className={className}>
          <img
            className="c-button__icon c-save-button__icon"
            src={loadingImg}
          />
          保存中
        </p>
      );
    }
    if (state === "done") {
      return (
        <p className={className}>
          <span className="material-icons-round c-button__icon c-save-button__icon">
            done
          </span>
          保存しました
        </p>
      );
    }
    return (
      <button
        className={className}
        type={props.type}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        保存
      </button>
    );
  }
);
