import { VFC, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ApiAcademyCourseApi, Configuration } from "../../generated/api";
import { notify } from "../notification";
import { useSubMenuVisible } from "../common/submenus/useSubMenuVisible";
import { AcademyCourseIcon } from "./AcademyCourseIcon";
import { AcademyCourseIconChooser } from "./AcademyCourseIconChooser";

type AcademyCourse = Awaited<
  ReturnType<ApiAcademyCourseApi["apiAcademyCoursesIdGet"]>
>;

interface Props {
  csrfToken: string;
  course: AcademyCourse;
}

export const AcademyCourseEdit: VFC<Props> = (props) => {
  const [title, setTitle] = useState(props.course.title ?? "");
  const [description, setDescription] = useState(
    props.course.description ?? ""
  );
  const [iconName, setIconName] = useState(props.course.iconName ?? "");
  const [visibleIconChooser, setVisibleIconChooser] = useSubMenuVisible();

  const patchCourse = (
    course: Pick<AcademyCourse, "title" | "description" | "iconName">
  ) => {
    const api = new ApiAcademyCourseApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiAcademyCoursesIdPatch({
        id: props.course.id,
        apiAcademyCoursesIdPatchRequest: { academyCourse: { ...course } },
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  return (
    <div>
      <label
        className="p-academy-edit__input-field-label"
        htmlFor="course_title"
      >
        コース名
      </label>
      <TextareaAutosize
        id="course_title"
        className="p-academy-edit__input-field-textarea"
        rows={1}
        value={title}
        required={true}
        maxLength={100}
        placeholder="コース名を入力してください"
        onChange={(e) => setTitle(e.target.value)}
        onBlur={() => {
          if (title) {
            patchCourse({ title });
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.currentTarget.blur();
          }
        }}
      />
      {/*  */}
      <label
        className="p-academy-edit__input-field-label"
        htmlFor="course_description"
      >
        コースの説明
      </label>
      <TextareaAutosize
        id="course_description"
        className="p-academy-edit__input-field-textarea"
        value={description}
        maxLength={5000}
        placeholder="説明を追加"
        onChange={(e) => setDescription(e.target.value)}
        onBlur={() => {
          patchCourse({ description });
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.currentTarget.blur();
          }
        }}
      />
      {/*  */}
      <label
        className="p-academy-edit__input-field-label"
        htmlFor="course_icon"
      >
        アイコン
      </label>
      <button
        className="p-academy-edit__icon-edit-button"
        onClick={() => setVisibleIconChooser(true)}
      >
        <AcademyCourseIcon iconName={iconName} />
      </button>
      {visibleIconChooser && (
        <div className="p-academy-edit__icon-chooser-tooltip">
          <AcademyCourseIconChooser
            iconName={iconName}
            onChange={(newIconName) => {
              setIconName(newIconName);
              setVisibleIconChooser(false);
              patchCourse({ iconName: newIconName });
            }}
          />
        </div>
      )}
    </div>
  );
};
export default AcademyCourseEdit;
