import { VFC, ReactChild } from "react";

interface Props {
  /** 秒 */
  value: number;
  format?: {
    hours?: boolean;
    minutes?: boolean;
    seconds?: boolean;
    separator?: ReactChild;
  };
}

export const DurationText: VFC<Props> = (props) => {
  const format = props.format ?? { hours: true, minutes: true, seconds: true };
  let value = props.value ?? 0;
  let hours = NaN;
  let minutes = NaN;
  let seconds = NaN;

  if (format.hours) {
    hours = Math.floor(value / 3600);
    if (format.minutes || format.seconds) {
      hours ||= NaN; // より小さい桁を表示する指定があるのなら、値が0の桁は表示しない。
    }
    value = value % 3600;
  }
  if (format.minutes) {
    minutes = Math.floor(value / 60);
    if (format.seconds) {
      minutes ||= NaN;
    }
    value = value % 60;
  }
  if (format.seconds) {
    seconds = Math.floor(value);
  }
  const separator = format.separator ?? "";

  return (
    <>
      {!isNaN(hours) && `${hours}時間`}
      {separator}
      {!isNaN(minutes) && `${minutes}分`}
      {separator}
      {!isNaN(seconds) && `${seconds}秒`}
    </>
  );
};
