import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useContext, useRef, useState } from "react";
import { LoadingContext } from "../../common/loading/LoadingContext";
import { useBeforeUnload } from "../../../lib/useBeforeUnload";
import { notify } from "../../notification";
import {
  CreatomatePreviewPlayer,
  CreatomatePreviewPlayerHandler,
} from "./CreatomatePreviewPlayer";
import { DesignHeader } from "../DesignHeader";
import { DesignPreviewPane } from "./DesignPreviewPane";
import { DesignSceneItem } from "./DesignSceneItem";
import { DesignSideNav } from "../DesignSideNav";
import { buildCreatomateParam, buildSubtitles } from "../helpers";
import { useDesignScenesWithAudio, useInitialDesignScenes } from "../hooks";
import {
  addCreatomateJob,
  publishVideoSubtitles,
  updateVideoSubtitlesDefaultDisplayed,
  updateDesignScene,
} from "../requests";
import type { DesignScene } from "../helpers";
import { VideoContext } from "../VideoContext";
import { FileUploadDropzone } from "./FileUploadDropzone";
import { DesignSceneItemOverlay } from "./DesignSceneItemOverlay";

export interface DesignSceneEditProps {
  csrfToken: string;
  designHashId: string;
  designId: number;
  videoId: number;
  folderHashId: string;
  creatomatePublicToken: string;
  shareUrl: string;
  embedHtml: string;
  domainWatchLinkAvailable: boolean;
}

// https://learn.microsoft.com/ja-jp/azure/cognitive-services/speech-service/language-support?tabs=stt-tts#supported-languages
const VOICE_NAME_LIST = [
  { label: "女性", value: "ja-JP-NanamiNeural" },
  { label: "男性", value: "ja-JP-KeitaNeural" },
];
const SPEECH_RATE_LIST = [
  { label: "少しゆっくり", value: "1" },
  { label: "ふつう", value: "1.05" },
  { label: "少し速い", value: "1.25" },
  { label: "速い", value: "1.5" },
];

export function DesignScenesEdit(props: DesignSceneEditProps) {
  const dndSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [video, setVideo] = useContext(VideoContext);
  const [scenes, setScenes] = useInitialDesignScenes(props.designId);
  const voiceName =
    scenes?.at(0)?.audioData?.voiceName ?? VOICE_NAME_LIST[0].value;
  const speechRate =
    scenes?.at(0)?.audioData?.speechRate ?? SPEECH_RATE_LIST[1].value;
  const [scenesWithAudio, isGeneratingAudio] = useDesignScenesWithAudio(
    props.csrfToken,
    scenes
  );

  const playerRef = useRef<CreatomatePreviewPlayerHandler>();

  const { isLoading, handleLoading } = useContext(LoadingContext);
  useBeforeUnload(isLoading);

  const [isDragging, setIsDragging] = useState(false);
  const [activeScene, setActiveScene] = useState<DesignScene | null>(null);
  const handleSubtitlesDefaultDisplayedChange = (isDisplayed) => {
    updateVideoSubtitlesDefaultDisplayed(
      props.csrfToken,
      props.videoId,
      isDisplayed
    );
    setVideo({
      ...video,
      subtitles: {
        ...video.subtitles,
        isDefaultDisplayed: isDisplayed,
      },
    });
  };
  const [checkedScenes, setCheckedScenes] = useState([]);
  const [completeUpdateScene, setCompleteUpdateScene] = useState<number | null>(
    null
  );
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  function updateAudioData(arg: Partial<DesignScene["audioData"]>) {
    const newList = scenes.map((scene) => ({
      ...scene,
      audioData: { ...scene.audioData, ...arg },
    }));
    setScenes(newList);
    newList.forEach((scene) =>
      handleLoading(updateDesignScene(props.csrfToken, scene.id, scene))
    );
  }

  // rowOrderを更新する際に更新後でないとrowOrderが全て同じ値になるため順番に更新する
  const updateScenesSequentially = async (
    scenes: typeof checkedScenes,
    newRowOrder: number
  ) => {
    return scenes.reduce(async (previousPromise, scene) => {
      await previousPromise;
      return updateDesignScene(props.csrfToken, scene.id, {
        rowOrderPosition: newRowOrder,
      });
    }, Promise.resolve());
  };

  const handleDragStart = (event: DragStartEvent) => {
    const draggedSceneId = event.active.id;
    const draggedScene = scenes.find(
      (scene) => scene.id === Number(draggedSceneId)
    );
    setActiveScene(draggedScene);
    setIsDragging(true);

    if (
      draggedScene &&
      !checkedScenes.some((scene) => scene.id === draggedScene.id)
    ) {
      setCheckedScenes([draggedScene]);
    }
  };

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    const overId = over?.id;
    const activeId = active?.id;

    if (active.id !== overId) {
      const { newScenes, updateCheckedScenes, newIndex } = updateSceneOrder(
        scenes,
        checkedScenes,
        overId,
        activeId,
        activeScene
      );
      handleLoading(
        updateScenesSequentially(updateCheckedScenes, newIndex).catch(
          (reason) => {
            notify(
              "エラーが発生しました。画面をリロードしてもう一度試してください。"
            );
            throw reason;
          }
        )
      );
      setScenes(newScenes);
    }
    setActiveScene(null);
    setIsDragging(false);
  }

  async function startGenerateVideo() {
    // ビデオの生成と字幕の保存・公開
    const param = buildCreatomateParam(scenesWithAudio);
    const subtitles = buildSubtitles(scenesWithAudio).map((s) => ({
      startTimeMs: Math.floor(s.startTime * 1000),
      endTimeMs: Math.floor(s.endTime * 1000),
      value: s.text,
    }));
    await addCreatomateJob(props.csrfToken, props.designId, param);
    await publishVideoSubtitles(props.csrfToken, props.videoId, subtitles);
  }

  const handleCheckboxChange = (id: number) => {
    setCheckedScenes((prevCheckedScenes) => {
      const matchedScene = scenes.find((scene) => scene.id === id);
      if (!matchedScene) return prevCheckedScenes;

      const isAlreadyChecked = prevCheckedScenes.some(
        (scene) => scene.id === id
      );
      if (isAlreadyChecked) {
        // 既に存在する場合は削除
        return prevCheckedScenes.filter((scene) => scene.id !== id);
      } else {
        // 存在しない場合は追加
        return [...prevCheckedScenes, matchedScene];
      }
    });
  };

  const handleSelectAllChange = () => {
    setCheckedScenes([]);
  };

  const checkedSceneIds = new Set(checkedScenes.map((scene) => scene.id));
  const activeSceneIndex = scenes?.findIndex(
    (scene) => scene.id === activeScene?.id
  );

  return (
    <div className="p-design">
      <DesignHeader
        csrfToken={props.csrfToken}
        videoId={props.videoId}
        designId={props.designId}
        folderHashId={props.folderHashId}
        onRequestGenerateVideo={
          scenesWithAudio?.some((s) => s.imageUrl && s.audioUrl)
            ? startGenerateVideo
            : null
        }
        videoShareUrl={props.shareUrl}
        videoEmbedHtml={props.embedHtml}
        domainWatchLinkAvailable={props.domainWatchLinkAvailable}
      />

      {/* メインエリア */}
      <div className="p-design__main">
        <DesignSideNav designHashId={props.designHashId} mode="edit" />
        <div className="p-design__center">
          {checkedScenes?.length > 0 && (
            <div
              className={`p-design-scene-count-wrapper ${
                checkedScenes?.length > 0 ? "visible" : ""
              }`}
            >
              <label className="p-checkbox-wrapper">
                <input
                  className="p-checkbox"
                  type="checkbox"
                  checked={checkedScenes?.length > 0}
                  onChange={handleSelectAllChange}
                />
                <span className="p-checkbox-label"></span>
              </label>
              <span className="p-design-scene-count">
                {checkedScenes?.length}/{scenes?.length}シーン選択中
              </span>
              <span className="p-design-scene-count-wrapper-indicator">
                シーンの移動は
                <i className="material-icons-round p-design-scene-count-wrapper-indicator__drag-handle">
                  drag_indicator
                </i>
                をドラッグ
              </span>
            </div>
          )}

          <div className="p-design__center-description">
            <span className="p-design__center-description__title">シーン</span>
            <span className="p-design__center-description__text">
              スクリプトをAIナレーションが読み上げます
            </span>
          </div>
          <div className="p-design__center-pane p-design-scrollbar">
            {scenes && (
              <DndContext
                sensors={dndSensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={filterCheckedScene(
                    scenes,
                    checkedScenes,
                    activeScene
                  ).map((c) => c.id.toString())}
                  strategy={verticalListSortingStrategy}
                >
                  {scenes.map((scene, index) => (
                    <DesignSceneItem
                      key={scene.id}
                      csrfToken={props.csrfToken}
                      index={index}
                      beforeScene={
                        checkedSceneIds.has(scene.id) &&
                        index > 0 &&
                        checkedSceneIds.has(scenes[index - 1].id)
                      }
                      afterScene={
                        checkedSceneIds.has(scene.id) &&
                        index < scenes.length - 1 &&
                        checkedSceneIds.has(scenes[index + 1].id)
                      }
                      scene={scene}
                      durationMs={scenesWithAudio?.[index]?.durationMs}
                      isChecked={checkedSceneIds.has(scene.id)}
                      isDragging={isDragging}
                      isActiveScene={scene.id === activeScene?.id}
                      isTextareaFocused={isTextareaFocused}
                      isCompleteUpdateNotify={
                        completeUpdateScene && index === scenes.length - 1
                      }
                      onUpdateScene={(newScene) => {
                        const newList = [...scenes];
                        newList[index] = newScene;
                        setScenes(newList);
                        setIsTextareaFocused(false);
                      }}
                      onDeleteScene={() => {
                        const newList = [...scenes];
                        newList.splice(index, 1);
                        setScenes(newList);
                        if (checkedSceneIds.has(scene.id)) {
                          handleCheckboxChange(scene.id);
                        }
                      }}
                      onFocus={() => {
                        playerRef.current?.seekToScene({ id: scene.id });
                        setIsTextareaFocused(true);
                      }}
                      onCheckboxSceneChange={(sceneId) => {
                        handleCheckboxChange(sceneId);
                        setIsTextareaFocused(false);
                      }}
                    />
                  ))}
                </SortableContext>
                <DragOverlay>
                  <DesignSceneItemOverlay
                    activeScene={activeScene}
                    activeSceneIndex={activeSceneIndex}
                    checkedScenesCount={checkedScenes.length}
                    scenesWithAudio={scenesWithAudio}
                  />
                </DragOverlay>
              </DndContext>
            )}
          </div>
          <FileUploadDropzone
            csrfToken={props.csrfToken}
            designId={props.designId}
            index={scenes?.length + 1}
            audioData={{ voiceName, speechRate }}
            onUpdateScenes={(newScenes) => {
              const newList = [...scenes];
              newList.splice(scenes?.length + 1, 1, ...newScenes);
              setScenes(newList);
              setCheckedScenes(newScenes);
              setCompleteUpdateScene(newList.length - 1);
              setTimeout(() => setCompleteUpdateScene(null), 5000);
            }}
          />
        </div>
        <DesignPreviewPane>
          <CreatomatePreviewPlayer
            ref={playerRef}
            scenes={scenesWithAudio}
            publicToken={props.creatomatePublicToken}
            isPreviewGenerating={isGeneratingAudio}
            subtitlesDisplayed={video?.subtitles?.isDefaultDisplayed}
          />

          <div className="p-design-narration">
            <p className="p-design-narration__title">
              <i className="material-icons-round p-design-narration__title-icon">
                mic
              </i>
              &nbsp;
              <span>AIナレーション</span>
              &nbsp;
              <span className="p-design-narration__title-subtext">
                スクリプトを読み上げます
              </span>
            </p>

            <div className="p-design-narration__select">
              <span className="p-design-narration__select-title">声</span>
              {VOICE_NAME_LIST.map((item) => (
                <label
                  className="p-design-narration__select-label"
                  key={item.value}
                >
                  <input
                    className="p-design-narration__select-check"
                    type="radio"
                    name="design-narration-voice-name"
                    value={item.value}
                    checked={item.value === voiceName}
                    onChange={(e) => {
                      updateAudioData({ voiceName: e.target.value });
                    }}
                  />
                  {item.label}
                </label>
              ))}
            </div>
            <div className="p-design-narration__select">
              <span className="p-design-narration__select-title">速度</span>
              {SPEECH_RATE_LIST.map((item) => (
                <label
                  className="p-design-narration__select-label"
                  key={item.value}
                >
                  <input
                    className="p-design-narration__select-check"
                    type="radio"
                    name="design-narration-speech-rate"
                    value={item.value}
                    checked={item.value === speechRate}
                    onChange={(e) => {
                      updateAudioData({ speechRate: e.target.value });
                    }}
                  />
                  {item.label}
                </label>
              ))}
            </div>
          </div>

          <div className="p-design-subtitles">
            <p className="p-design-subtitles__title">
              <i className="material-icons-round p-design-subtitles__title-icon">
                subtitles
              </i>
              &nbsp;
              <span>字幕</span>
              &nbsp;
              <span className="p-design-subtitles__title-subtext">
                スクリプトを字幕として表示します
              </span>
            </p>

            <div className="p-design-subtitles__select">
              <span className="p-design-subtitles__select-title">
                字幕表示の初期設定
              </span>
              <label className="p-design-subtitles__select-label">
                {video?.subtitles?.isDefaultDisplayed != null && (
                  <input
                    className="p-design-subtitles__select-check"
                    type="radio"
                    name="subtitles-default-displayed"
                    value="visible"
                    checked={video?.subtitles?.isDefaultDisplayed}
                    onChange={() => handleSubtitlesDefaultDisplayedChange(true)}
                  />
                )}
                表示あり
              </label>
              <label className="p-design-subtitles__select-label">
                {video?.subtitles?.isDefaultDisplayed != null && (
                  <input
                    className="p-design-subtitles__select-check"
                    type="radio"
                    name="subtitles-default-displayed"
                    value="hidden"
                    checked={!video?.subtitles?.isDefaultDisplayed}
                    onChange={() =>
                      handleSubtitlesDefaultDisplayedChange(false)
                    }
                  />
                )}
                表示なし
              </label>
            </div>
          </div>
        </DesignPreviewPane>
      </div>
    </div>
  );
}

function filterCheckedScene(
  scenes: DesignScene[],
  checkedScenes: DesignScene[],
  activeScene: DesignScene
) {
  if (!scenes || !checkedScenes || !activeScene) return [];
  const checkedSceneIds = new Set(checkedScenes.map((scene) => scene.id));
  return scenes.filter(
    (scene) => scene.id === activeScene.id || !checkedSceneIds.has(scene.id)
  );
}

export function updateSceneOrder(
  scenes: DesignScene[],
  checkedScenes: DesignScene[],
  overId: string,
  activeId: string,
  activeScene: DesignScene
) {
  const overScene = filterCheckedScene(scenes, checkedScenes, activeScene);
  const overIndex = overScene.findIndex(
    (scene) => scene.id.toString() === overId
  );
  const activeIndex = overScene.findIndex(
    (scene) => scene.id.toString() === activeId
  );
  const newScene = arrayMove(overScene, activeIndex, overIndex);
  const newActiveIndex = newScene.findIndex(
    (scene) => scene.id.toString() === activeId
  );

  const sortedCheckedScenes = [...checkedScenes].sort(
    (a, b) => a.rowOrder - b.rowOrder
  );

  const newScenes = [
    ...newScene.slice(0, newActiveIndex),
    ...sortedCheckedScenes,
    ...newScene.slice(newActiveIndex + 1),
  ];

  const newIndex =
    activeIndex < overIndex
      ? overIndex + checkedScenes.length - 1
      : overIndex === 0
      ? 0
      : overIndex;

  // 上方向か下方向かの並び替えに応じた更新順番に並び替えて更新する
  const updateCheckedScenes =
    activeIndex < overIndex
      ? sortedCheckedScenes
      : sortedCheckedScenes.reverse();

  return { newScenes, updateCheckedScenes, newIndex };
}
