/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoVideoIdWatchLinkGet200ResponseMembersInner
 */
export interface ApiVideoVideoIdWatchLinkGet200ResponseMembersInner {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdWatchLinkGet200ResponseMembersInner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdWatchLinkGet200ResponseMembersInner
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdWatchLinkGet200ResponseMembersInner
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiVideoVideoIdWatchLinkGet200ResponseMembersInner
     */
    isJoined?: boolean;
}

/**
 * Check if a given object implements the ApiVideoVideoIdWatchLinkGet200ResponseMembersInner interface.
 */
export function instanceOfApiVideoVideoIdWatchLinkGet200ResponseMembersInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSON(json: any): ApiVideoVideoIdWatchLinkGet200ResponseMembersInner {
    return ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSONTyped(json, false);
}

export function ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdWatchLinkGet200ResponseMembersInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'isJoined': !exists(json, 'isJoined') ? undefined : json['isJoined'],
    };
}

export function ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerToJSON(value?: ApiVideoVideoIdWatchLinkGet200ResponseMembersInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'imageUrl': value.imageUrl,
        'isJoined': value.isJoined,
    };
}

