import { VFC, useState } from "react";
import ReactModal from "react-modal";
import { ApiAcademyWatchLinkApi, Configuration } from "../../generated/api";
import { isResponseError } from "../../lib/isResponseError";
import LinkCopyButton from "../common/LinkCopyButton";
import { notify } from "../notification";
import { WatchLinkInvitationForm } from "../common/watch_link/WatchLinkInvitationForm";
import { WatchLinkItem } from "../common/watch_link/WatchLinkItem";
import { WatchLinkTypeSelect } from "../common/watch_link/WatchLinkTypeSelect";
import { AcademyWatchLinkType } from "../../types/academyWatchLink";
import { AcademyWatchLinkIconMap } from "./AcademyShareMenu";

type InviteMember = Awaited<
  ReturnType<ApiAcademyWatchLinkApi["apiAcademyAcademyIdWatchLinkGet"]>
>["members"][number];

interface Props {
  isOpen: boolean;
  requestClose(): void;
  onUpdated(type: AcademyWatchLinkType, inviteMembersCount: number): void;
  type: AcademyWatchLinkType;
  csrfToken: string;
  academyId: number;
  shareUrl: string;
  domainWatchLinkAvailable: boolean;
  tenantWatchLinkAvailable?: boolean;
}

export const AcademyShareModal: VFC<Props> = (props) => {
  const [type, setType] = useState(props.type);
  const [members, setMembers] = useState<InviteMember[]>([]);
  const [errMsg, setErrMsg] = useState("");

  const typeItems: WatchLinkItem<AcademyWatchLinkType>[] = [
    {
      type: "team_only",
      title: "非公開",
      icon: "lock",
      copyDesc: "チームに所属するユーザーだけが閲覧できます。",
    },
    {
      type: "member_only",
      title: "特定の人",
      icon: "people",
      copyDesc: "指定したメールアドレスでログインした場合に閲覧できます。",
    },
    {
      type: "no_limit",
      title: "リンクを知っている人全員",
      icon: "language",
      copyDesc: "リンクを知っているインターネット上の全員が閲覧できます。",
    },
  ];

  if (props.domainWatchLinkAvailable) {
    typeItems.splice(2, 0, {
      type: "domain_only",
      title: "特定のドメイン",
      icon: AcademyWatchLinkIconMap["domain_only"],
      copyDesc: "指定したドメインでログインした場合に閲覧できます。",
    });
  }
  if (props.tenantWatchLinkAvailable) {
    typeItems.splice(2, 0, {
      type: "tenant_only",
      title: "自社サービスのユーザー（SSO）",
      icon: "corporate_fare",
      copyDesc: "SSO連携した自社サービスのユーザーのみが閲覧できます。",
    });
  }

  const copyDesc = typeItems.find((i) => i.type === type)?.copyDesc;

  const closeModal = () => {
    props.requestClose();

    // 初期値に戻す
    setType(undefined);
    setMembers([]);
    setErrMsg("");
  };

  // 初期データ取得
  const initialize = () => {
    watchLinkApi(props.csrfToken)
      .apiAcademyAcademyIdWatchLinkGet({
        academyId: props.academyId,
      })
      .then((data) => {
        const typeName = data.typeName as AcademyWatchLinkType;
        setType(typeName);
        setMembers(data.members ?? []);
        props.onUpdated(typeName, data.members?.length);
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        Rollbar.warn(reason);
      });
  };

  const updateShareSettings = () => {
    setErrMsg("");
    const emails = type === "member_only" ? members.map((m) => m.email) : [];
    const api = watchLinkApi(props.csrfToken);
    api
      .apiAcademyAcademyIdWatchLinkPatch({
        academyId: props.academyId,
        apiAcademyAcademyIdWatchLinkPatchRequest: { type, emails },
      })
      .then(() => {
        props.onUpdated(type, members?.length);
        closeModal();
      })
      .catch((reason) => {
        if (isResponseError(reason) && reason.response.status === 422) {
          reason.response
            .json()
            .then((body) => Object.values(body?.messages).flat().join("\n"))
            .then((msg) => {
              setErrMsg(msg);
            });
        } else {
          notify(
            "エラーが発生しました。画面をリロードしてもう一度試してください。"
          );
          Rollbar.warn(reason);
        }
      });
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      onAfterOpen={initialize}
      onRequestClose={closeModal}
      overlayClassName="c-dialog__overlay c-watch-link-modal__overlay"
      className={{ base: "", afterOpen: "", beforeClose: "" }}
    >
      <div className="c-dialog__container c-watch-link-modal__container">
        <div className="c-dialog__header">
          <h2 className="c-dialog__header__title">共有リンクを設定</h2>
          <button
            className="material-icons-round c-dialog__header__close-icon"
            onClick={closeModal}
          >
            close
          </button>
        </div>
        {errMsg && <p className="c-dialog__err-msgs">{errMsg}</p>}
        <div className="c-dialog__content no-scroll flex-column">
          <WatchLinkTypeSelect
            items={typeItems}
            value={type}
            onChange={(value) => setType(value)}
          />

          {type === "member_only" && (
            <WatchLinkInvitationForm members={members} onUpdate={setMembers} />
          )}
        </div>
        <div className="c-dialog__footer">
          <button
            className="c-button--outlined narrow-padding"
            type="button"
            onClick={closeModal}
          >
            キャンセル
          </button>
          <button
            className="c-button--primary narrow-padding"
            type="submit"
            onClick={updateShareSettings}
            disabled={!type}
          >
            完了
          </button>
        </div>
      </div>

      <div className="c-dialog__container c-watch-link-modal__container c-watch-link-modal__dialog-spacer">
        <div className="c-dialog__header">
          <h2 className="c-dialog__header__title">リンクを取得</h2>
        </div>
        <div className="c-dialog__content">
          <p>{copyDesc}</p>
          <div className="c-watch-link-modal__button-container">
            <LinkCopyButton url={props.shareUrl} className="no-padding" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

function watchLinkApi(csrfToken: string) {
  return new ApiAcademyWatchLinkApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}
