import { useState, useRef, useEffect, VFC } from "react";
import { useDebounceState } from "../../../lib/useDebounce";
import { videojs } from "../../../lib/videojs";

type Props = {
  src: string;
  type: string;
  cues: {
    startTimeMs: number;
    endTimeMs: number;
    value: string;
  }[];
  onTimeUpdate(currentTimeMs: number): void;
};

export const SubtitlesPreviewPlayer: VFC<Props> = (props) => {
  const videoRef = useRef<HTMLVideoElement>();
  const playerRef = useRef<ReturnType<typeof videojs>>();
  const [playerInitialized, setPlayerInitialized] = useState(false);
  const [debouncedCues] = useDebounceState(props.cues, 50); // 字幕の更新はDOM操作なのでdebounceする

  useEffect(() => {
    const opt = {
      disablePictureInPicture: true,
      controlBar: {
        pictureInPictureToggle: false,
        remainingTimeDisplay: false,
      },
    };
    playerRef.current = videojs(videoRef.current, opt);
    playerRef.current.hlsQualitySelector();
    playerRef.current.ready(() => {
      // https://stackoverflow.com/a/49598388
      const textTrackSettings = playerRef.current.textTrackSettings;
      textTrackSettings.setValues({ backgroundOpacity: "0.5" });
      textTrackSettings.updateDisplay();
    });

    playerRef.current.on("loadeddata", () => {
      // loadeddataを待たないとダミーの字幕データも読み込まれていない。
      setPlayerInitialized(true);
    });
    return () => {
      setPlayerInitialized(false);
      playerRef.current?.dispose();
      playerRef.current = null;
    };
  }, []);

  useEffect(() => {
    const onTimeUpdate = props.onTimeUpdate;
    const listener = () => {
      onTimeUpdate(playerRef.current.currentTime() * 1000);
    };
    const player = playerRef.current;
    player.on("timeupdate", listener);
    return () => {
      player.off("timeupdate", listener);
    };
  }, [props.onTimeUpdate]);

  useEffect(() => {
    if (!playerInitialized) {
      return;
    }
    const track = Array.from(playerRef.current.textTracks()).find(
      (track) => track.mode === "showing"
    );
    if (!track) {
      return; // 字幕トラックオブジェクトが初期化前の場合があるため
    }
    for (const cue of Array.from(track.cues)) {
      track.removeCue(cue);
    }
    for (const cue of debouncedCues) {
      if (
        cue.value &&
        Number.isSafeInteger(cue.startTimeMs) &&
        Number.isSafeInteger(cue.endTimeMs)
      ) {
        track.addCue(
          new VTTCue(
            cue.startTimeMs / 1000,
            cue.endTimeMs / 1000,
            escapeCueText(cue.value.trim())
          )
        );
      }
    }
    playerRef.current.textTrackDisplay.updateDisplay();
  }, [playerInitialized, debouncedCues]);

  return (
    <video
      ref={videoRef}
      className="video-js"
      controls
      width={640}
      height={360}
      preload="auto"
    >
      <source src={props.src} type={props.type} />
      <track
        src="data:text/text,WEBVTT"
        kind="subtitles"
        label="字幕プレビュー"
        srcLang="ja"
        default={true}
      />
    </video>
  );
};

function escapeCueText(str: string): string {
  // AMPの字幕キューに入れるとエラーになる文字をエスケープする。
  return str.replace(
    /[&><]/g,
    (match) =>
      ({
        "&": "&amp;",
        ">": "&gt;",
        "<": "&lt;",
      }[match])
  );
}
