/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner
 */
export interface ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner
     */
    value?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner
     */
    startTimeMs?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner
     */
    endTimeMs?: number;
}

/**
 * Check if a given object implements the ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner interface.
 */
export function instanceOfApiVideoVideoIdSubtitlesPutRequestSubtitlesInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerFromJSON(json: any): ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner {
    return ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerFromJSONTyped(json, false);
}

export function ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'startTimeMs': !exists(json, 'start_time_ms') ? undefined : json['start_time_ms'],
        'endTimeMs': !exists(json, 'end_time_ms') ? undefined : json['end_time_ms'],
    };
}

export function ApiVideoVideoIdSubtitlesPutRequestSubtitlesInnerToJSON(value?: ApiVideoVideoIdSubtitlesPutRequestSubtitlesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'start_time_ms': value.startTimeMs,
        'end_time_ms': value.endTimeMs,
    };
}

