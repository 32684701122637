import { Preview } from "@creatomate/preview";
import {
  CSSProperties,
  forwardRef,
  useDeferredValue,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  buildCreatomateParam,
  buildSubtitles,
  DesignSceneWithAudio,
} from "../helpers";
import { formatTimeMs } from "../../../lib/formatTimeMs";
import loadingImg from "images/loading-white.svg";

export interface CreatomatePreviewPlayerHandler {
  seekToScene(targetScene: { id: number }): void;
}

interface Props {
  scenes: DesignSceneWithAudio[];
  publicToken: string;
  isPreviewGenerating: boolean;
  subtitlesDisplayed: boolean;
}

export const CreatomatePreviewPlayer = forwardRef<
  CreatomatePreviewPlayerHandler,
  Props
>(function CreatomatePreviewPlayerImpl(props, ref) {
  const playerContainerRef = useRef<HTMLDivElement>();
  const previewRef = useRef<Preview>();
  const [ready, setReady] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useIsPreviewLoading(
    props.scenes
  );
  const [currentTime, setCurrentTime] = useState(0);
  const [subtitleFontSize, setSubtitleFontSize] =
    useState<CSSProperties["fontSize"]>();
  const debouncedScenes = useDeferredValue(props.scenes);
  const creatomateParam = useMemo(
    () => (debouncedScenes ? buildCreatomateParam(debouncedScenes) : null),
    [debouncedScenes]
  );
  const subtitles = useMemo(
    () =>
      props.subtitlesDisplayed && debouncedScenes
        ? buildSubtitles(debouncedScenes)
        : null,
    [debouncedScenes, props.subtitlesDisplayed]
  );
  const currentSubtitle = subtitles?.find(
    (s) => s.startTime <= currentTime && currentTime <= s.endTime
  );

  useEffect(() => {
    // https://github.com/creatomate/creatomate-preview
    previewRef.current = new Preview(
      playerContainerRef.current,
      "player",
      props.publicToken
    );
    previewRef.current.onReady = () => {
      previewRef.current.setLoop(false);
      setReady(true);
    };
    previewRef.current.onTimeChange = (time) => {
      setCurrentTime(time);
    };
    previewRef.current.onScaleChange = (scale) => {
      setSubtitleFontSize(calcSubtitleFontSize(scale));
    };
    previewRef.current.onLoadComplete = () => {
      setIsPreviewLoading(false);
    };
    return () => {
      previewRef.current.dispose();
      previewRef.current = null;
    };
  }, [props.publicToken, setIsPreviewLoading]);

  useEffect(() => {
    if (ready && creatomateParam) {
      // CreatomateのAPIはsnake_caseを期待するので読み替える。
      const { outputFormat, duration, frameRate, width, height, elements } =
        creatomateParam.source;
      const source = {
        output_format: outputFormat,
        duration,
        frame_rate: frameRate,
        width,
        height,
        elements,
      };
      previewRef.current.setSource(source);
    }
  }, [ready, creatomateParam]);

  useImperativeHandle(
    ref,
    () => ({
      seekToScene(targetScene) {
        if (!debouncedScenes) {
          return;
        }
        const targetIndex = debouncedScenes.findIndex(
          (ds) => ds.id === targetScene.id
        );
        if (targetIndex < 0) {
          return;
        }
        const durationMs = debouncedScenes
          .slice(0, targetIndex)
          .reduce((result, current) => (result += current.durationMs), 0);
        // 計算誤差で若干手前にズレるので、100msずらして確実にシーンの先頭に移動する
        previewRef.current.setTime((durationMs + 100) / 1000);
      },
    }),
    [debouncedScenes]
  );

  return (
    <div className="p-design-preview">
      <div className="p-design-preview__player" ref={playerContainerRef}></div>
      <time className="p-design-preview__time">
        {formatTimeMs(creatomateParam?.source?.duration * 1000)}
      </time>
      {currentSubtitle && (
        <p className="p-design-preview__subtitles">
          <span
            className="p-design-preview__subtitles-text"
            style={subtitleFontSize ? { fontSize: subtitleFontSize } : null}
          >
            {currentSubtitle.text}
          </span>
        </p>
      )}
      {(props.isPreviewGenerating || isPreviewLoading) && (
        <div className="p-design-preview__loading">
          <img src={loadingImg} width={16} />
          プレビューを生成中
        </div>
      )}
    </div>
  );
});

function calcSubtitleFontSize(previewScale: number): CSSProperties["fontSize"] {
  // プレビュー表示サイズに合わせて字幕のフォントサイズを変更する
  const REAL_VIDEO_WIDTH = 1920;
  const ACTUALLY_PREVIEW_WIDTH = 600;
  const isSmallPreviewWidth =
    (REAL_VIDEO_WIDTH * previewScale) / ACTUALLY_PREVIEW_WIDTH < 1;
  return isSmallPreviewWidth ? "smaller" : null;
}

/** シーン情報の変更を起点として、プレビューのローディング状態を管理する。 */
function useIsPreviewLoading(scenes: DesignSceneWithAudio[]) {
  const [isLoading, setIsLoading] = useState(false);
  const [prevValue, setPrevValue] = useState(scenes);
  // 過剰なuseEffectを使わずに、値が変更されたときだけ処理を実行する公式テクニック。
  // https://ja.react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  if (prevValue !== scenes) {
    setPrevValue(scenes);
    if (scenes.some((s) => !!s.durationMs)) {
      setIsLoading(true);
    }
  }
  return [isLoading, setIsLoading] as const;
}
