/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAcademyLogoDelete422Response,
  ApiAcademyLogoGet200Response,
  ApiAcademyLogoPost201Response,
  ApiAcademyLogoPost422Response,
  ApiVideoIdThumbnailPresignGet201Response,
} from '../models';
import {
    ApiAcademyLogoDelete422ResponseFromJSON,
    ApiAcademyLogoDelete422ResponseToJSON,
    ApiAcademyLogoGet200ResponseFromJSON,
    ApiAcademyLogoGet200ResponseToJSON,
    ApiAcademyLogoPost201ResponseFromJSON,
    ApiAcademyLogoPost201ResponseToJSON,
    ApiAcademyLogoPost422ResponseFromJSON,
    ApiAcademyLogoPost422ResponseToJSON,
    ApiVideoIdThumbnailPresignGet201ResponseFromJSON,
    ApiVideoIdThumbnailPresignGet201ResponseToJSON,
} from '../models';

export interface ApiAcademyLogoPostRequest {
    assetName?: string;
    assetContainer?: string;
}

/**
 * 
 */
export class ApiAcademyLogoApi extends runtime.BaseAPI {

    /**
     * destroy
     */
    async apiAcademyLogoDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/logo`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiAcademyLogoDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiAcademyLogoDeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     * show
     */
    async apiAcademyLogoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyLogoGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/logo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyLogoGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiAcademyLogoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyLogoGet200Response> {
        const response = await this.apiAcademyLogoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * create
     */
    async apiAcademyLogoPostRaw(requestParameters: ApiAcademyLogoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAcademyLogoPost201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.assetName !== undefined) {
            formParams.append('asset_name', requestParameters.assetName as any);
        }

        if (requestParameters.assetContainer !== undefined) {
            formParams.append('asset_container', requestParameters.assetContainer as any);
        }

        const response = await this.request({
            path: `/api/academy/logo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAcademyLogoPost201ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiAcademyLogoPost(requestParameters: ApiAcademyLogoPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAcademyLogoPost201Response> {
        const response = await this.apiAcademyLogoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * presign
     */
    async apiAcademyLogoPresignGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdThumbnailPresignGet201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/academy/logo/presign`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdThumbnailPresignGet201ResponseFromJSON(jsonValue));
    }

    /**
     * presign
     */
    async apiAcademyLogoPresignGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdThumbnailPresignGet201Response> {
        const response = await this.apiAcademyLogoPresignGetRaw(initOverrides);
        return await response.value();
    }

}
