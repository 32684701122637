import { useContext, useEffect, useState } from "react";
import { folderPath } from "../../generated/routes";
import { VideoShareModal } from "../video/VideoShareModal";
import { useVideoWatchLinkTooltipValue } from "./hooks";
import { getVideo, updateVideo } from "./requests";
import ReactTooltip from "react-tooltip";
import { VideoWatchLinkTooltipContent } from "../video/VideoShareMenu";
import { notify } from "../notification";
import { LoadingContext } from "../common/loading/LoadingContext";
import loadingImg from "images/loading.svg";
import { VideoContext } from "./VideoContext";

interface DesignHeaderProps {
  csrfToken: string;
  videoId: number;
  designId: number;
  folderHashId: string;
  onRequestGenerateVideo?: () => void;
  videoShareUrl: string;
  videoEmbedHtml: string;
  domainWatchLinkAvailable: boolean;
}

export function DesignHeader(props: DesignHeaderProps) {
  const [video, setVideo] = useContext(VideoContext);
  const [watchLinkTooltipValue, setWatchLinkTooltipValue] =
    useVideoWatchLinkTooltipValue(props.videoId);
  const [modalVisible, setModalVisible] = useState(false);
  const { isLoading, handleLoading } = useContext(LoadingContext);

  useEffect(() => {
    // ビデオ生成ステータスのポーリング
    if (video?.state === "transforming") {
      const intervalId = setInterval(() => {
        getVideo(props.videoId).then((video) => {
          if (video.state !== "transforming") {
            clearInterval(intervalId);
            setVideo(video);
            if (video.state === "playable") {
              notify("ビデオの生成が完了しました");
            }
          }
        });
      }, 5_000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [props.videoId, video?.state, setVideo]);

  async function updateTitle(title: string) {
    const newVideo = await handleLoading(
      updateVideo(props.csrfToken, props.videoId, {
        title,
      })
    );
    setVideo(newVideo);
  }

  const requestGenerateVideo =
    props.onRequestGenerateVideo && video?.state !== "transforming"
      ? () => {
          if (!confirm("ビデオを生成しますか？")) {
            return;
          }
          setVideo((video) => ({ ...video, state: "transforming" }));
          props.onRequestGenerateVideo();
        }
      : null;

  return (
    <>
      <div className="p-design__header">
        <a
          href={folderPath(props.folderHashId)}
          title="フォルダ画面に戻る"
          className="material-icons-round p-design__header-back-button"
        >
          arrow_back
        </a>

        <input
          required
          pattern=".*\S+.*"
          className="p-design__title-field"
          title={video?.title}
          defaultValue={video?.title}
          onBlur={(e) => {
            if (e.target.reportValidity()) {
              updateTitle(e.target.value);
            }
          }}
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing) {
              return; // 日本語入力中は無視
            }
            if (e.altKey || e.ctrlKey || e.shiftKey || e.metaKey) {
              return; // 修飾キー付きはブラウザのホットキーなどの場合があるので無視
            }
            if (e.key === "Enter") {
              e.currentTarget.blur();
            }
          }}
        />
        <i className="material-icons-round p-design__title-field-icon">edit</i>

        {/* spacer */}
        <div className="p-design-flex-spacer" />

        {/* 保存中のインジケーター表示 */}
        {isLoading && (
          <>
            <img src={loadingImg} width={12} />
            保存中
          </>
        )}
        <div
          data-tip={
            video?.state !== "playable" ? "ビデオを生成後に共有できます" : null
          }
        >
          <button
            className="c-button--outlined narrow-padding"
            onClick={() => setModalVisible(true)}
            disabled={video?.state !== "playable"}
            data-tip-disable={video?.state !== "playable"}
            data-tip=""
            data-for="design-video-share-tooltip"
          >
            共有
          </button>
        </div>

        <button
          className="c-button--primary narrow-padding"
          disabled={!requestGenerateVideo}
          onClick={requestGenerateVideo}
          data-tip="生成中です。完了後に操作できます"
          data-tip-disable={video?.state !== "transforming"}
        >
          ビデオを生成
        </button>
      </div>

      {/* eslint-disable-next-line react/forbid-elements */}
      <ReactTooltip
        id="design-video-share-tooltip"
        effect="solid"
        place="bottom"
        globalEventOff="click"
      >
        <VideoWatchLinkTooltipContent {...watchLinkTooltipValue} />
      </ReactTooltip>
      {watchLinkTooltipValue.type && (
        <VideoShareModal
          isOpen={modalVisible}
          requestClose={() => setModalVisible(false)}
          onUpdated={(type, inviteMembersCount) => {
            setWatchLinkTooltipValue({ type, inviteMembersCount });
          }}
          type={watchLinkTooltipValue.type}
          csrfToken={props.csrfToken}
          videoId={props.videoId}
          shareUrl={props.videoShareUrl}
          domainWatchLinkAvailable={props.domainWatchLinkAvailable}
        />
      )}
    </>
  );
}
