import { useState, useEffect } from "react";
import type { Clip, Video } from "./useTimelineClips";

type StoredData = {
  lastModifiedAt: string;
  clips: Clip[];
};

/**
 * 編集状態の保持と取り出しを扱う。
 * @param video 編集対象のビデオ
 * @param lastTransformedAt 対象ビデオの最終変換(エンコード)日時, ISO8601形式
 */
export function useRestoreClips(video: Video, lastTransformedAt: string) {
  const storingKey = `video-timeline-edit-${video.hashid}`;
  const [restoredClips] = useState<Clip[]>(restoreClips);
  const [storingClips, setStoringClips] = useState<Clip[]>();

  useEffect(() => {
    if (storingClips === undefined) {
      return;
    }

    if (storingClips == null) {
      setStoredData(storingKey, null);
      return;
    }

    // localStorageへの書き込みをdebounceする。
    const timeoutId = setTimeout(() => {
      const clips = storingClips.map((clip) => {
        const video = { ...clip.video };
        // ブラウザ側に保持したくない項目を削除
        delete video.watchLink;
        delete video.subtitles;
        delete video.descriptionHtml;
        return { ...clip, video };
      });
      const data: StoredData = {
        clips,
        lastModifiedAt: new Date().toISOString(),
      };
      setStoredData(storingKey, data);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [storingKey, storingClips]);

  function restoreClips() {
    const data = getStoredData(storingKey);
    if (
      data &&
      lastTransformedAt &&
      new Date(data.lastModifiedAt).getTime() <
        new Date(lastTransformedAt).getTime()
    ) {
      setStoredData(storingKey, null);
      return null;
    }
    return data?.clips;
  }

  return [restoredClips, setStoringClips] as const;
}

function getStoredData(key: string): StoredData {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
}

function setStoredData(key: string, data: StoredData) {
  if (data) {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.removeItem(key);
  }
}
