/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInner } from './ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInner';
import {
    ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInnerFromJSON,
    ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInnerFromJSONTyped,
    ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInnerToJSON,
} from './ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInner';

/**
 * 
 * @export
 * @interface ApiVideoVideoIdExamQuestionsGet200ResponseInner
 */
export interface ApiVideoVideoIdExamQuestionsGet200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdExamQuestionsGet200ResponseInner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdExamQuestionsGet200ResponseInner
     */
    text?: string;
    /**
     * 
     * @type {Array<ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInner>}
     * @memberof ApiVideoVideoIdExamQuestionsGet200ResponseInner
     */
    options?: Array<ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInner>;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdExamQuestionsGet200ResponseInner
     */
    rowOrder?: number;
}

/**
 * Check if a given object implements the ApiVideoVideoIdExamQuestionsGet200ResponseInner interface.
 */
export function instanceOfApiVideoVideoIdExamQuestionsGet200ResponseInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdExamQuestionsGet200ResponseInnerFromJSON(json: any): ApiVideoVideoIdExamQuestionsGet200ResponseInner {
    return ApiVideoVideoIdExamQuestionsGet200ResponseInnerFromJSONTyped(json, false);
}

export function ApiVideoVideoIdExamQuestionsGet200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdExamQuestionsGet200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInnerFromJSON)),
        'rowOrder': !exists(json, 'rowOrder') ? undefined : json['rowOrder'],
    };
}

export function ApiVideoVideoIdExamQuestionsGet200ResponseInnerToJSON(value?: ApiVideoVideoIdExamQuestionsGet200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(ApiVideoVideoIdExamQuestionsGet200ResponseInnerOptionsInnerToJSON)),
        'rowOrder': value.rowOrder,
    };
}

