/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoVideoIdExamGet200Response,
  ApiVideoVideoIdExamPutRequest,
} from '../models';
import {
    ApiVideoVideoIdExamGet200ResponseFromJSON,
    ApiVideoVideoIdExamGet200ResponseToJSON,
    ApiVideoVideoIdExamPutRequestFromJSON,
    ApiVideoVideoIdExamPutRequestToJSON,
} from '../models';

export interface ApiVideoVideoIdExamGetRequest {
    videoId: number;
}

export interface ApiVideoVideoIdExamPutOperationRequest {
    videoId: number;
    apiVideoVideoIdExamPutRequest?: ApiVideoVideoIdExamPutRequest;
}

/**
 * 
 */
export class ApiExamApi extends runtime.BaseAPI {

    /**
     * show
     */
    async apiVideoVideoIdExamGetRaw(requestParameters: ApiVideoVideoIdExamGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoVideoIdExamGet200Response>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdExamGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/exam`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoVideoIdExamGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiVideoVideoIdExamGet(requestParameters: ApiVideoVideoIdExamGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoVideoIdExamGet200Response> {
        const response = await this.apiVideoVideoIdExamGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiVideoVideoIdExamPutRaw(requestParameters: ApiVideoVideoIdExamPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoVideoIdExamGet200Response>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdExamPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/exam`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdExamPutRequestToJSON(requestParameters.apiVideoVideoIdExamPutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoVideoIdExamGet200ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiVideoVideoIdExamPut(requestParameters: ApiVideoVideoIdExamPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoVideoIdExamGet200Response> {
        const response = await this.apiVideoVideoIdExamPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
