import { VFC, MouseEventHandler, useState, CSSProperties } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useSubMenuVisible } from "../common/submenus/useSubMenuVisible";
import { AcademyCourseIcon } from "./AcademyCourseIcon";
import {
  editAcademiesCoursePath,
  academiesCoursePath,
} from "../../generated/routes";
import { ApiAcademyCourseApi, Configuration } from "../../generated/api";
import { notify } from "../notification";
import { DurationText } from "../common/DurationText";

type AcademyCourse = Awaited<
  ReturnType<ApiAcademyCourseApi["apiAcademyCoursesIdGet"]>
>;

interface Props {
  csrfToken: string;
  academyId: number;
  courses: AcademyCourse[];
}

export const AcademyCourseListEdit: VFC<Props> = (props) => {
  const dndSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [courses, setCourses] = useState(props.courses ?? []);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = courses.findIndex((i) => i.id.toString() === active.id);
      const newIndex = courses.findIndex((i) => i.id.toString() === over.id);
      const target = courses[oldIndex];
      setCourses(arrayMove(courses, oldIndex, newIndex));
      academyCourseApi(props.csrfToken)
        .apiAcademyCoursesIdPatch({
          id: target.id,
          apiAcademyCoursesIdPatchRequest: {
            academyCourse: { rowOrderPosition: newIndex },
          },
        })
        .catch((reason) => {
          notify(
            "エラーが発生しました。画面をリロードしてもう一度試してください。"
          );
          throw reason;
        });
    }
  }

  return (
    <DndContext
      sensors={dndSensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={courses.map((c) => c.id.toString())}
        strategy={verticalListSortingStrategy}
      >
        <table className="p-academy-edit__table">
          <thead>
            <tr>
              <th>{/* drag handle */}</th>
              <th>{/* icon */}</th>
              <th>コース名・説明</th>
              <th>公開</th>
              <th>長さ</th>
              <th>レッスン</th>
              <th>{/* copy link */}</th>
              <th>{/* submenu */}</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <ListItem
                key={course.id}
                {...course}
                csrfToken={props.csrfToken}
              />
            ))}
            {courses.length === 0 && (
              <tr style={{ background: "none" }}>
                <td colSpan={7}>
                  「コースを作成」ボタンでコースを追加してください。
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </SortableContext>
    </DndContext>
  );
};
export default AcademyCourseListEdit;

const ListItem: VFC<AcademyCourse & { csrfToken: string }> = (props) => {
  const linkPath = editAcademiesCoursePath(props.hashid);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id.toString() });
  const [subMenuVisible, toggleSubMenuVisible] = useSubMenuVisible();

  const copyLink: MouseEventHandler = () => {
    const url = new URL(academiesCoursePath(props.hashid), location.href);
    navigator.clipboard.writeText(url.toString());
  };

  const toggleMenu: MouseEventHandler = () => {
    toggleSubMenuVisible();
  };

  const onClickDel: MouseEventHandler = () => {
    if (!confirm(`コース「${props.title}」を削除しますか？`)) {
      return;
    }

    academyCourseApi(props.csrfToken)
      .apiAcademyCoursesIdDelete({
        id: props.id,
      })
      .then(() => {
        location.reload();
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  // tableで行に対してリンクを実装出来ないので、onClickでお茶を濁す。
  // 代わりにテキストのセルには個別にaタグを実装して、ブラウザのインタラクションを損ねないようにする。
  const onClickRow: MouseEventHandler = (event) => {
    if (
      event.target instanceof HTMLElement &&
      (event.target.closest("a") || event.target.closest("button"))
    ) {
      return; // aタグや行内のメニューボタンの操作の場合は無視
    }
    location.assign(linkPath);
  };

  const rowStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr
      className="p-academy-edit__table-cursor-pointer"
      onClick={onClickRow}
      ref={setNodeRef}
      style={rowStyle}
    >
      <td>
        <button
          className="p-academy-edit__table-drag-handle"
          {...attributes}
          {...listeners}
          style={{ cursor: transform ? "grabbing" : "grab" }}
        >
          <i className="material-icons-round">drag_handle</i>
        </button>
      </td>
      <td>
        <a href={linkPath}>
          <AcademyCourseIcon
            className="p-academy-edit__table-icon"
            iconName={props.iconName}
          />
        </a>
      </td>
      <td className="p-academy-edit__table-info" title={props.title}>
        <a href={linkPath}>
          <p className="p-academy-edit__table-info-title">{props.title}</p>
          <p
            className="p-academy-edit__table-info-desc"
            title={props.description}
          >
            {props.description}
          </p>
        </a>
      </td>
      <td>{props.state === "draft" ? "非公開" : "公開"}</td>
      <td>
        <a href={linkPath}>
          <DurationText
            value={props.playTimeSec}
            format={{ hours: true, minutes: true }}
          />
        </a>
      </td>
      <td>
        <a href={linkPath}>{props.lessonsCount}</a>
      </td>
      <td>
        <button
          className="material-icons-round c-submenu__button"
          onClick={copyLink}
        >
          link
        </button>
      </td>
      <td>
        <button
          className="material-icons-round c-submenu__button"
          onClick={toggleMenu}
        >
          more_vert
        </button>
        {subMenuVisible && (
          <ul className="c-submenu__list p-academy-edit__table-submenu">
            <li className="c-submenu__list-item danger">
              <button onClick={onClickDel}>
                <i className="material-icons-outlined c-submenu__list-item-icon">
                  delete
                </i>
                削除
              </button>
            </li>
          </ul>
        )}
      </td>
    </tr>
  );
};

function academyCourseApi(csrfToken: string) {
  return new ApiAcademyCourseApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}
