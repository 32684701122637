/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoIdThumbnailPresignGet201Response,
  ApiVideoVideoIdNarrationsGet200ResponseInner,
  ApiVideoVideoIdNarrationsPutRequest,
} from '../models';
import {
    ApiVideoIdThumbnailPresignGet201ResponseFromJSON,
    ApiVideoIdThumbnailPresignGet201ResponseToJSON,
    ApiVideoVideoIdNarrationsGet200ResponseInnerFromJSON,
    ApiVideoVideoIdNarrationsGet200ResponseInnerToJSON,
    ApiVideoVideoIdNarrationsPutRequestFromJSON,
    ApiVideoVideoIdNarrationsPutRequestToJSON,
} from '../models';

export interface ApiVideoVideoIdNarrationsGetRequest {
    videoId: number;
}

export interface ApiVideoVideoIdNarrationsPresignGetRequest {
    videoId: number;
}

export interface ApiVideoVideoIdNarrationsPutOperationRequest {
    videoId: number;
    apiVideoVideoIdNarrationsPutRequest?: ApiVideoVideoIdNarrationsPutRequest;
}

/**
 * 
 */
export class ApiNarrationApi extends runtime.BaseAPI {

    /**
     * show
     */
    async apiVideoVideoIdNarrationsGetRaw(requestParameters: ApiVideoVideoIdNarrationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiVideoVideoIdNarrationsGet200ResponseInner>>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdNarrationsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/narrations`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiVideoVideoIdNarrationsGet200ResponseInnerFromJSON));
    }

    /**
     * show
     */
    async apiVideoVideoIdNarrationsGet(requestParameters: ApiVideoVideoIdNarrationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiVideoVideoIdNarrationsGet200ResponseInner>> {
        const response = await this.apiVideoVideoIdNarrationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * presign
     */
    async apiVideoVideoIdNarrationsPresignGetRaw(requestParameters: ApiVideoVideoIdNarrationsPresignGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdThumbnailPresignGet201Response>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdNarrationsPresignGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/narrations/presign`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdThumbnailPresignGet201ResponseFromJSON(jsonValue));
    }

    /**
     * presign
     */
    async apiVideoVideoIdNarrationsPresignGet(requestParameters: ApiVideoVideoIdNarrationsPresignGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdThumbnailPresignGet201Response> {
        const response = await this.apiVideoVideoIdNarrationsPresignGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiVideoVideoIdNarrationsPutRaw(requestParameters: ApiVideoVideoIdNarrationsPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiVideoVideoIdNarrationsGet200ResponseInner>>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdNarrationsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/narrations`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdNarrationsPutRequestToJSON(requestParameters.apiVideoVideoIdNarrationsPutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiVideoVideoIdNarrationsGet200ResponseInnerFromJSON));
    }

    /**
     * update
     */
    async apiVideoVideoIdNarrationsPut(requestParameters: ApiVideoVideoIdNarrationsPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiVideoVideoIdNarrationsGet200ResponseInner>> {
        const response = await this.apiVideoVideoIdNarrationsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
