/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoVideoIdWatchLinkGet200Response,
  ApiVideoVideoIdWatchLinkPatch200Response,
  ApiVideoVideoIdWatchLinkPatch422Response,
  ApiVideoVideoIdWatchLinkPatchRequest,
} from '../models';
import {
    ApiVideoVideoIdWatchLinkGet200ResponseFromJSON,
    ApiVideoVideoIdWatchLinkGet200ResponseToJSON,
    ApiVideoVideoIdWatchLinkPatch200ResponseFromJSON,
    ApiVideoVideoIdWatchLinkPatch200ResponseToJSON,
    ApiVideoVideoIdWatchLinkPatch422ResponseFromJSON,
    ApiVideoVideoIdWatchLinkPatch422ResponseToJSON,
    ApiVideoVideoIdWatchLinkPatchRequestFromJSON,
    ApiVideoVideoIdWatchLinkPatchRequestToJSON,
} from '../models';

export interface ApiVideoVideoIdWatchLinkGetRequest {
    videoId: number;
}

export interface ApiVideoVideoIdWatchLinkPatchOperationRequest {
    videoId: number;
    apiVideoVideoIdWatchLinkPatchRequest?: ApiVideoVideoIdWatchLinkPatchRequest;
}

/**
 * 
 */
export class ApiWatchLinkApi extends runtime.BaseAPI {

    /**
     * show
     */
    async apiVideoVideoIdWatchLinkGetRaw(requestParameters: ApiVideoVideoIdWatchLinkGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoVideoIdWatchLinkGet200Response>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdWatchLinkGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/watch_link`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoVideoIdWatchLinkGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiVideoVideoIdWatchLinkGet(requestParameters: ApiVideoVideoIdWatchLinkGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoVideoIdWatchLinkGet200Response> {
        const response = await this.apiVideoVideoIdWatchLinkGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiVideoVideoIdWatchLinkPatchRaw(requestParameters: ApiVideoVideoIdWatchLinkPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoVideoIdWatchLinkPatch200Response>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdWatchLinkPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/watch_link`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdWatchLinkPatchRequestToJSON(requestParameters.apiVideoVideoIdWatchLinkPatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoVideoIdWatchLinkPatch200ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiVideoVideoIdWatchLinkPatch(requestParameters: ApiVideoVideoIdWatchLinkPatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoVideoIdWatchLinkPatch200Response> {
        const response = await this.apiVideoVideoIdWatchLinkPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
