import {
  VFC,
  useRef,
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  MouseEventHandler,
} from "react";
import { useSubtitlesCues, SubtitlesCue } from "./useSubtitlesCues";
import { useCurrentSubtitlesCue } from "./useCurrentSubtitlesCue";
import { useSubMenuVisible } from "../../common/submenus/useSubMenuVisible";
import { SaveButton, SaveButtonHandler } from "../../common/SaveButton";
import { useSubtitlesPublished } from "./useSubtitlesPublished";
import { notify } from "../../notification";
import { SubtitlesPreviewPlayer } from "./SubtitlesPreviewPlayer";
import { TimeMsInput } from "../../common/TimeMsInput";

export interface SubtitlesEditViewProps {
  csrfToken: string;
  videoId: number;
  src: string;
  type: string;
}

export const SubtitlesEditView: VFC<SubtitlesEditViewProps> = (props) => {
  const saveButtonRef = useRef<SaveButtonHandler>();
  const [savable, setSavable] = useState(false);
  const { cues, dispatchCues, requestSaveCues, requestDestroyCues } =
    useSubtitlesCues(props.videoId, props.csrfToken);
  const { subtitlesPublished, requestSaveSubtitlesPublished } =
    useSubtitlesPublished(props.videoId, props.csrfToken);
  const { currentCue, setCurrentTimeMs } = useCurrentSubtitlesCue(cues);

  const hasError = cues.some((c) => c.hasStartTimeError || c.hasEndTimeError);

  useEffect(() => {
    // 保存前、保存中は画面離脱確認をする。
    if (savable) {
      const onBeforeUnload = (event: BeforeUnloadEvent) => {
        event.returnValue = true;
      };
      window.addEventListener("beforeunload", onBeforeUnload);
      return () => window.removeEventListener("beforeunload", onBeforeUnload);
    }
  }, [savable]);

  const save = async () => {
    if (savable) {
      saveButtonRef.current.startSaving();
      try {
        await requestSaveCues();
        await saveButtonRef.current.finishSaving();
        setSavable(false);
      } catch (reason) {
        saveButtonRef.current.reset();
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      }
    }
  };

  const destroy = async () => {
    if (confirm("字幕を削除し初期状態に戻します。この操作は取り消せません。")) {
      setSavable(false); // 画面離脱確認が表示されないように
      try {
        await Promise.all([
          requestDestroyCues(),
          requestSaveSubtitlesPublished(false),
        ]);
        location.reload();
      } catch {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
      }
    }
  };

  return (
    <>
      <div className="p-subtitles__header">
        <SaveButton
          ref={saveButtonRef}
          disabled={!savable || hasError}
          onClick={save}
        />

        <SubtitlesEditPublishSwitch
          checked={subtitlesPublished}
          onChange={(event) => {
            requestSaveSubtitlesPublished(event.currentTarget.checked);
          }}
        />

        <SubtitlesEditSubmenu onClickDestroy={destroy} />
      </div>
      <div className="p-subtitles__main">
        <div className="p-subtitles__edit-content-container">
          {cues.length === 0 && (
            <button
              className="c-button--text-link-primary no-decoration"
              onClick={() => dispatchCues({ type: "add-new-after", key: null })}
              title="字幕を追加"
            >
              <span className="material-icons-round c-button__icon">add</span>
              字幕を追加
            </button>
          )}
          {cues.map((cue) => (
            <SubtitlesEditCueItem
              key={cue.key}
              cue={cue}
              isCurrent={currentCue?.key === cue.key}
              dispatchCues={(action) => {
                setSavable(true); // 厳密な変更検知は諦めて、何かしら更新したら保存可能扱い
                return dispatchCues(action);
              }}
            />
          ))}
        </div>
        <div className="p-subtitles__player-container">
          <SubtitlesPreviewPlayer
            src={props.src}
            type={props.type}
            cues={cues}
            onTimeUpdate={setCurrentTimeMs}
          />
        </div>
      </div>
    </>
  );
};

const SubtitlesEditCueItem: VFC<{
  cue: SubtitlesCue;
  isCurrent: boolean;
  dispatchCues: ReturnType<typeof useSubtitlesCues>["dispatchCues"];
}> = ({ cue, isCurrent, dispatchCues }) => {
  const currentCueItemRef = useCallback((elem: HTMLDivElement) => {
    elem?.scrollIntoView?.({ behavior: "smooth", block: "center" });
  }, []);

  const updateCue = (newCue: SubtitlesCue) => {
    dispatchCues({ type: "update", key: cue.key, cue: newCue });
  };

  const changeCueText: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    // WebVTTの仕様で空行は扱えないので入力時点で制御する。
    const value = event.target.value.trimStart().replace(/\n\n/g, "\n");
    updateCue({ ...cue, value });
  };

  return (
    <div
      className={`p-subtitles__cue-item p-subtitles__show-on-hover-container ${
        isCurrent ? "is-current" : ""
      }`}
      ref={isCurrent ? currentCueItemRef : undefined}
    >
      <div className="p-subtitles__cue-times">
        <TimeMsInput
          required
          className={`p-subtitles__cue-time-input ${
            cue.hasStartTimeError ? "has-error" : ""
          }`}
          value={cue.startTimeMs}
          onChange={(startTimeMs) => updateCue({ ...cue, startTimeMs })}
        />
        <TimeMsInput
          required
          className={`p-subtitles__cue-time-input ${
            cue.hasEndTimeError ? "has-error" : ""
          }`}
          value={cue.endTimeMs}
          onChange={(endTimeMs) => updateCue({ ...cue, endTimeMs })}
        />
      </div>
      <div className="p-subtitles__cue-controls p-subtitles__show-on-hover-item">
        <button
          className="material-icons-round p-subtitles__cue-controls-middle-button"
          title="この字幕を削除"
          onClick={() => dispatchCues({ type: "delete", key: cue.key })}
        >
          delete_outline
        </button>

        <button
          className="material-icons-round p-subtitles__cue-controls-bottom-button"
          title="後に字幕を追加"
          onClick={() => dispatchCues({ type: "add-new-after", key: cue.key })}
        >
          add_circle
        </button>
      </div>
      <textarea
        className="p-subtitles__cue-textarea"
        value={cue.value}
        onChange={changeCueText}
      ></textarea>
    </div>
  );
};

const SubtitlesEditPublishSwitch: VFC<{
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}> = ({ checked, onChange }) => {
  return (
    <>
      <input
        id="publish_switch"
        type="checkbox"
        className="c-toggle-switch__input"
        disabled={checked == null} // 読込中はdisabledにしておく
        checked={checked ?? false}
        onChange={onChange}
      />
      <label
        htmlFor="publish_switch"
        className="c-toggle-switch__label"
        data-switch-on="公開"
        data-switch-off="非公開"
      />
    </>
  );
};

const SubtitlesEditSubmenu: VFC<{
  onClickDestroy: MouseEventHandler<HTMLButtonElement>;
}> = ({ onClickDestroy }) => {
  const [submenu, toggleSubmenu] = useSubMenuVisible();
  return (
    <>
      <button
        className="material-icons-round c-submenu__button"
        onClick={() => toggleSubmenu()}
      >
        more_vert
      </button>
      <ul
        className="c-submenu__list p-subtitles__header-submenu"
        hidden={!submenu}
      >
        <li className="c-submenu__list-item danger">
          <button onClick={onClickDestroy}>
            <i className="material-icons-round c-submenu__list-item-icon">
              restart_alt
            </i>
            初期化
          </button>
        </li>
      </ul>
    </>
  );
};
