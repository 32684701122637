import { useMemo, useState, VFC } from "react";
import { formatTimeMs } from "../../lib/formatTimeMs";

type Prop = {
  required?: boolean;
  className?: string;
  value?: number;
  onChange?(value: number): void;
};

/** `12:34.5` or `12:34:56.7` */
const TIME_MS_REGEXP = /^(?:(\d+):)?([0-5]\d):([0-5]\d)(?:\.(\d))$/;

function parse(text: string): number {
  const match = (text ?? "").trim().match(TIME_MS_REGEXP);
  if (match) {
    const [, hour, min, sec, tenthOfSec] = [...match];
    const timeMs =
      Number(hour || 0) * 3600_000 +
      Number(min) * 60_000 +
      Number(sec) * 1000 +
      Number(tenthOfSec) * 100;
    if (Number.isSafeInteger(timeMs)) {
      return timeMs;
    }
  }
  return null;
}

/**
 * ミリ秒を「hh:mm:ss.z」の書式のテキストで扱うテキストフィールド。
 *
 * 適切な入力の時はミリ秒の値をonChangeで親コンポーネントに戻し、propsと同期する。
 * 不適切な入力の時はnullを返し、適切な入力になるまではpropsと同期せず、このコンポーネント内だけでinputを制御する。
 */
export const TimeMsInput: VFC<Prop> = (props) => {
  const [text, setText] = useState(() => formatTimeMs(props.value));
  const propValue = useMemo(() => formatTimeMs(props.value), [props.value]);
  return (
    <input
      type="text"
      required={props.required}
      pattern={TIME_MS_REGEXP.source}
      className={props.className}
      value={propValue ?? text}
      onChange={(event) => {
        setText(event.target.value);
        const timeMs = parse(event.target.value);
        if (props.value !== timeMs) {
          props.onChange(timeMs);
        }
      }}
    />
  );
};
