import { VFC } from "react";

interface Props {
  feedback: {
    correctAnswerCount: number;
    totalQuestionCount: number;
  };
}

export const AnswerFeedback: VFC<Props> = ({ feedback }) => {
  if (!feedback) {
    return null;
  }
  const rate = Math.round(
    (feedback.correctAnswerCount / feedback.totalQuestionCount) * 100
  );
  return (
    <div className="p-exam-answer-feedback">
      <p className="p-exam-answer-feedback__title">
        テスト結果は
        <span className="p-exam-answer-feedback__score">
          {feedback.correctAnswerCount}/{feedback.totalQuestionCount}問正解
        </span>
        <span className="p-exam-answer-feedback__rate">(正解率 {rate}%)</span>
        です。
      </p>
    </div>
  );
};

export default AnswerFeedback;
