import type { ReactNode } from "react";
import { editDesignPath, infoDesignPath } from "../../generated/routes";
import { useExpandable } from "./hooks";

interface DesignSideNavProps {
  designHashId: string;
  mode: "edit" | "info";
}

export function DesignSideNav(props: DesignSideNavProps) {
  const { expanded, canExpand, toggleExpanded } = useExpandable(true, 1280);
  const expandButtonText = expanded ? "エリアを縮小" : "エリアを拡大";

  return (
    <div
      className={`p-design__sidenav ${
        expanded ? "p-design__sidenav--expanded" : ""
      }`}
    >
      <Anchor
        href={editDesignPath(props.designHashId)}
        isCurrent={props.mode === "edit"}
        icon="movie"
      >
        ビデオ
      </Anchor>
      <Anchor
        href={infoDesignPath(props.designHashId)}
        isCurrent={props.mode === "info"}
        icon="article"
      >
        情報
      </Anchor>

      <div className="p-design-flex-spacer" />

      <button
        className={`p-design-expand-button p-design-expand-button--align-${
          expanded ? "right" : "center"
        }`}
        style={canExpand ? null : { display: "none" }}
        onClick={toggleExpanded}
        title={expandButtonText}
      >
        <i className="material-icons-round p-design-expand-button__icon">
          {expanded ? "chevron_left" : "chevron_right"}
        </i>
        {expanded && (
          <span className="p-design-expand-button__text">
            {expandButtonText}
          </span>
        )}
      </button>
    </div>
  );
}

function Anchor(props: {
  href: string;
  isCurrent: boolean;
  icon: string;
  children: ReactNode;
}) {
  return (
    <a
      href={props.isCurrent ? null : props.href}
      className={`p-design__sidenav-link ${
        props.isCurrent ? "is-current" : ""
      }`}
    >
      <i className="material-icons-round p-design__sidenav-link-icon">
        {props.icon}
      </i>
      {props.children}
    </a>
  );
}
