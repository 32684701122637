/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData } from './ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData';
import {
    ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataFromJSON,
    ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataFromJSONTyped,
    ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataToJSON,
} from './ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData';

/**
 * 
 * @export
 * @interface ApiVideoVideoIdNarrationsGet200ResponseInner
 */
export interface ApiVideoVideoIdNarrationsGet200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdNarrationsGet200ResponseInner
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdNarrationsGet200ResponseInner
     */
    startTimeMs?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdNarrationsGet200ResponseInner
     */
    audioBlobName?: string;
    /**
     * 
     * @type {ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData}
     * @memberof ApiVideoVideoIdNarrationsGet200ResponseInner
     */
    audioData?: ApiVideoVideoIdNarrationsGet200ResponseInnerAudioData;
}

/**
 * Check if a given object implements the ApiVideoVideoIdNarrationsGet200ResponseInner interface.
 */
export function instanceOfApiVideoVideoIdNarrationsGet200ResponseInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdNarrationsGet200ResponseInnerFromJSON(json: any): ApiVideoVideoIdNarrationsGet200ResponseInner {
    return ApiVideoVideoIdNarrationsGet200ResponseInnerFromJSONTyped(json, false);
}

export function ApiVideoVideoIdNarrationsGet200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdNarrationsGet200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'startTimeMs': !exists(json, 'startTimeMs') ? undefined : json['startTimeMs'],
        'audioBlobName': !exists(json, 'audioBlobName') ? undefined : json['audioBlobName'],
        'audioData': !exists(json, 'audioData') ? undefined : ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataFromJSON(json['audioData']),
    };
}

export function ApiVideoVideoIdNarrationsGet200ResponseInnerToJSON(value?: ApiVideoVideoIdNarrationsGet200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'startTimeMs': value.startTimeMs,
        'audioBlobName': value.audioBlobName,
        'audioData': ApiVideoVideoIdNarrationsGet200ResponseInnerAudioDataToJSON(value.audioData),
    };
}

