import { VFC, useState, useEffect } from "react";
import ReactModal from "react-modal";
import { TreeView, TreeItem, TreeViewThemeContext } from "./treeview";
import { stripTags } from "../../lib/stripTags";
import {
  toTreeFolderFromUserFolders,
  toTreeFolderFromTeamFolders,
} from "../../lib/tree-folder";
import { ApiFolderApi, Configuration } from "../../generated/api";
import loadingImg from "images/loading.svg";

import type { Folder } from "../../types/folder";
import type { ReactNode } from "react";
import type { TreeFolder } from "../../lib/tree-folder";

type VideoSummary = Awaited<
  ReturnType<ApiFolderApi["apiFoldersIdVideosGet"]>
>[number];
interface Props {
  isOpen: boolean;
  onClose: () => void;
  csrfToken: string;
  userFolders?: Folder[];
  teamFolders?: Folder[];
  onSelect: (videos: VideoSummary[]) => void;
  headerTitle?: string;
  filter?: (
    value: VideoSummary,
    index: number,
    array: VideoSummary[]
  ) => boolean;
}

export const VideoSelectorModal: VFC<Props> = (props) => {
  const rootUserFolder = props.userFolders
    ? toTreeFolderFromUserFolders(props.userFolders)
    : null;
  const rootTeamFolder = props.teamFolders
    ? toTreeFolderFromTeamFolders(props.teamFolders)
    : null;
  const [folderId, setFolderId] = useState<number>(
    rootUserFolder ? rootUserFolder.id : rootTeamFolder.id
  );
  const [selectedVideos, setSelectedVideos] = useState<VideoSummary[]>([]);

  return (
    <ReactModal
      isOpen={props.isOpen}
      overlayClassName="c-dialog__overlay vertical-center"
      className="c-dialog__container c-video-selector-modal__container"
    >
      <div className="c-dialog__header">
        <h2 className="c-dialog__header__title">
          {props.headerTitle ?? "ビデオを追加"}
        </h2>
        <button
          className="material-icons-round c-dialog__header__close-icon"
          onClick={props.onClose}
        >
          close
        </button>
      </div>

      <div className="c-dialog__content c-video-selector-modal__body">
        <div className="c-video-selector-modal__scroll-list">
          <TreeView
            selectedItemId={folderId}
            onItemSelected={(itemId: number) => setFolderId(itemId)}
          >
            <TreeViewThemeContext.Provider
              value={{
                icon: <span className="material-icons-round">folder</span>,
              }}
            >
              {rootUserFolder && (
                <TreeItem
                  itemId={rootUserFolder.id}
                  label={rootUserFolder.name}
                  icon={
                    <span className="material-icons-round">perm_identity</span>
                  }
                >
                  {buildTreeView(rootUserFolder.children)}
                </TreeItem>
              )}
              {rootTeamFolder && (
                <TreeItem
                  itemId={rootTeamFolder.id}
                  label={rootTeamFolder.name}
                  icon={<span className="material-icons-round">business</span>}
                >
                  {buildTreeView(rootTeamFolder.children)}
                </TreeItem>
              )}
            </TreeViewThemeContext.Provider>
          </TreeView>
        </div>

        {props.isOpen && (
          <VideosChooser
            csrfToken={props.csrfToken}
            folderId={folderId}
            onChangeSelectedVideos={setSelectedVideos}
            filter={props.filter}
          />
        )}
      </div>
      <div className="c-dialog__footer">
        <button
          className="c-button--primary narrow-padding"
          type="submit"
          disabled={selectedVideos.length === 0}
          onClick={() => props.onSelect(selectedVideos)}
        >
          追加する
        </button>
      </div>
    </ReactModal>
  );
};

type VideosChooserProps = {
  csrfToken: string;
  folderId: number;
  onChangeSelectedVideos: (videos: VideoSummary[]) => void;
  filter?: Props["filter"];
};

const VideosChooser: VFC<VideosChooserProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState<VideoSummary[]>([]);
  const [selectedVideos, setSelectedVideos] = useState<VideoSummary[]>([]);

  useEffect(() => {
    // UI上フォルダをまたいだビデオ選択を表現できておらず、フォルダを切り替えたら選択状態をリセットする。
    setSelectedVideos([]);
  }, [props.folderId]);

  useEffect(() => {
    // ビデオの選択状態をモーダル本体に同期
    props.onChangeSelectedVideos(selectedVideos);
  }, [props, selectedVideos]);

  useEffect(() => {
    setLoading(true);
    let cancelled = false;
    const api = new ApiFolderApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    const folderId = props.folderId ? props.folderId : -1;
    api
      .apiFoldersIdVideosGet({ id: folderId })
      .then((videos) => {
        if (cancelled) {
          return;
        }
        if (props.filter) {
          setVideos(videos.filter(props.filter));
        } else {
          setVideos(videos);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      cancelled = true;
    };
  }, [props.folderId, props.csrfToken, props.filter]);

  if (loading) {
    return (
      <div className="c-video-selector-modal__loading">
        <img src={loadingImg} alt="読み込み中" />
      </div>
    );
  }
  if (videos.length === 0) {
    if (props.folderId) {
      return <>フォルダにビデオがありません</>;
    }
    return <>フォルダを選択してください</>;
  }

  function selectVideo(video: VideoSummary) {
    if (selectedVideos.some((v) => v.id === video.id)) {
      setSelectedVideos(selectedVideos.filter((v) => v.id !== video.id));
    } else {
      setSelectedVideos([...selectedVideos, video]);
    }
  }

  return (
    <div className="c-video-selector-modal__scroll-list c-video-selector-modal__video-list">
      {videos.map((video) => {
        const isSelected = !!selectedVideos.find((v) => v.id === video.id);
        const description = stripTags(video.descriptionHtml);
        return (
          <button
            className={`c-video-selector-modal__video-item ${
              isSelected ? "is-selected" : ""
            }`}
            key={video.id}
            type="button"
            onClick={() => selectVideo(video)}
            title={video.title}
          >
            <img
              className="c-video-selector-modal__video-thumbnail"
              src={video.thumbnailUrl}
              alt={video.title}
            />
            <div className="c-video-selector-modal__video-info">
              <p className="c-video-selector-modal__video-title">
                {video.title}
              </p>
              <p
                className="c-video-selector-modal__video-desc"
                title={description}
              >
                {description}
              </p>
            </div>
          </button>
        );
      })}
    </div>
  );
};

function buildTreeView(folders: TreeFolder[]): ReactNode {
  if (folders) {
    return folders.map((folder) => (
      <TreeItem key={folder.id} itemId={folder.id} label={folder.name}>
        {buildTreeView(folder.children)}
      </TreeItem>
    ));
  }
}
