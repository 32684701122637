import { VFC, useState } from "react";

import { CreateExamAnswerModal } from "./CreateExamAnswerModal";
import { ApiExamAnswerApi, Configuration } from "../../../generated/api";
import AnswerFeedback from "./AnswerFeedback";

interface Props {
  csrfToken: string;
  inputName: boolean;
  userNameCandidate: string;
  isLoggedIn: boolean;
  videoId: number;
  questions: { id: number; text: string; options: { text: string }[] }[];
}

type AnswerFeedback = {
  correctAnswerCount: number;
  totalQuestionCount: number;
};

export const CreateExamAnswer: VFC<Props> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [feedback, setFeedback] = useState<AnswerFeedback>();

  const onClose = () => setModalVisible(false);
  const onClick = (
    userName: string,
    answers: { examQuestionId: number; text: string }[]
  ) => {
    const api = answerApi(props.csrfToken);
    api
      .apiVideoVideoIdExamAnswersPost({
        videoId: props.videoId,
        apiVideoVideoIdExamAnswersPostRequest: { answers, userName },
      })
      .then((res) => {
        setFeedback({
          correctAnswerCount: res.correctAnswerCount,
          totalQuestionCount: res.totalQuestionCount,
        });
      })
      .finally(onClose);
  };

  return (
    <div className="p-exam-answer">
      <button
        className="c-button--outlined p-exam-answer__button"
        onClick={() => setModalVisible(true)}
      >
        <i className="material-icons-round c-button__icon">edit</i>
        テストを受ける
      </button>

      <AnswerFeedback feedback={feedback} />

      <CreateExamAnswerModal
        isOpen={modalVisible}
        inputName={props.inputName}
        isLoggedIn={props.isLoggedIn}
        userNameCandidate={props.userNameCandidate}
        onClick={onClick}
        onClose={onClose}
        questions={props.questions}
      />
    </div>
  );
};

function answerApi(csrfToken: string) {
  return new ApiExamAnswerApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}

export default CreateExamAnswer;
