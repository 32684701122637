/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiDesignDesignIdScenesPostRequest,
  ApiScenesIdGet200Response,
  ApiScenesIdPutRequest,
  ApiScenesIdUploadedAudioPost200Response,
  ApiScenesIdUploadedAudioPostRequest,
  ApiScenesIdUploadedImagePost200Response,
  ApiScenesIdUploadedImagePostRequest,
  ApiVideoIdThumbnailPresignGet201Response,
} from '../models';
import {
    ApiDesignDesignIdScenesPostRequestFromJSON,
    ApiDesignDesignIdScenesPostRequestToJSON,
    ApiScenesIdGet200ResponseFromJSON,
    ApiScenesIdGet200ResponseToJSON,
    ApiScenesIdPutRequestFromJSON,
    ApiScenesIdPutRequestToJSON,
    ApiScenesIdUploadedAudioPost200ResponseFromJSON,
    ApiScenesIdUploadedAudioPost200ResponseToJSON,
    ApiScenesIdUploadedAudioPostRequestFromJSON,
    ApiScenesIdUploadedAudioPostRequestToJSON,
    ApiScenesIdUploadedImagePost200ResponseFromJSON,
    ApiScenesIdUploadedImagePost200ResponseToJSON,
    ApiScenesIdUploadedImagePostRequestFromJSON,
    ApiScenesIdUploadedImagePostRequestToJSON,
    ApiVideoIdThumbnailPresignGet201ResponseFromJSON,
    ApiVideoIdThumbnailPresignGet201ResponseToJSON,
} from '../models';

export interface ApiDesignDesignIdScenesGetRequest {
    designId: number;
}

export interface ApiDesignDesignIdScenesPostOperationRequest {
    designId: number;
    apiDesignDesignIdScenesPostRequest?: ApiDesignDesignIdScenesPostRequest;
}

export interface ApiScenesIdDeleteRequest {
    id: number;
}

export interface ApiScenesIdGetRequest {
    id: number;
}

export interface ApiScenesIdPresignAudioGetRequest {
    id: number;
}

export interface ApiScenesIdPresignImageGetRequest {
    id: number;
}

export interface ApiScenesIdPutOperationRequest {
    id: number;
    apiScenesIdPutRequest?: ApiScenesIdPutRequest;
}

export interface ApiScenesIdUploadedAudioPostOperationRequest {
    id: number;
    apiScenesIdUploadedAudioPostRequest?: ApiScenesIdUploadedAudioPostRequest;
}

export interface ApiScenesIdUploadedImagePostOperationRequest {
    id: number;
    apiScenesIdUploadedImagePostRequest?: ApiScenesIdUploadedImagePostRequest;
}

/**
 * 
 */
export class ApiSceneApi extends runtime.BaseAPI {

    /**
     * index
     */
    async apiDesignDesignIdScenesGetRaw(requestParameters: ApiDesignDesignIdScenesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiScenesIdGet200Response>>> {
        if (requestParameters.designId === null || requestParameters.designId === undefined) {
            throw new runtime.RequiredError('designId','Required parameter requestParameters.designId was null or undefined when calling apiDesignDesignIdScenesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/design/{design_id}/scenes`.replace(`{${"design_id"}}`, encodeURIComponent(String(requestParameters.designId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiScenesIdGet200ResponseFromJSON));
    }

    /**
     * index
     */
    async apiDesignDesignIdScenesGet(requestParameters: ApiDesignDesignIdScenesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiScenesIdGet200Response>> {
        const response = await this.apiDesignDesignIdScenesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create
     */
    async apiDesignDesignIdScenesPostRaw(requestParameters: ApiDesignDesignIdScenesPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiScenesIdGet200Response>> {
        if (requestParameters.designId === null || requestParameters.designId === undefined) {
            throw new runtime.RequiredError('designId','Required parameter requestParameters.designId was null or undefined when calling apiDesignDesignIdScenesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/design/{design_id}/scenes`.replace(`{${"design_id"}}`, encodeURIComponent(String(requestParameters.designId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiDesignDesignIdScenesPostRequestToJSON(requestParameters.apiDesignDesignIdScenesPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiScenesIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * create
     */
    async apiDesignDesignIdScenesPost(requestParameters: ApiDesignDesignIdScenesPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiScenesIdGet200Response> {
        const response = await this.apiDesignDesignIdScenesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * destroy
     */
    async apiScenesIdDeleteRaw(requestParameters: ApiScenesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scenes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiScenesIdDelete(requestParameters: ApiScenesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiScenesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * show
     */
    async apiScenesIdGetRaw(requestParameters: ApiScenesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiScenesIdGet200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scenes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiScenesIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiScenesIdGet(requestParameters: ApiScenesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiScenesIdGet200Response> {
        const response = await this.apiScenesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * presign_audio
     */
    async apiScenesIdPresignAudioGetRaw(requestParameters: ApiScenesIdPresignAudioGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdThumbnailPresignGet201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenesIdPresignAudioGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scenes/{id}/presign_audio`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdThumbnailPresignGet201ResponseFromJSON(jsonValue));
    }

    /**
     * presign_audio
     */
    async apiScenesIdPresignAudioGet(requestParameters: ApiScenesIdPresignAudioGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdThumbnailPresignGet201Response> {
        const response = await this.apiScenesIdPresignAudioGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * presign_image
     */
    async apiScenesIdPresignImageGetRaw(requestParameters: ApiScenesIdPresignImageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdThumbnailPresignGet201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenesIdPresignImageGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scenes/{id}/presign_image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdThumbnailPresignGet201ResponseFromJSON(jsonValue));
    }

    /**
     * presign_image
     */
    async apiScenesIdPresignImageGet(requestParameters: ApiScenesIdPresignImageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdThumbnailPresignGet201Response> {
        const response = await this.apiScenesIdPresignImageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiScenesIdPutRaw(requestParameters: ApiScenesIdPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiScenesIdGet200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/scenes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiScenesIdPutRequestToJSON(requestParameters.apiScenesIdPutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiScenesIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiScenesIdPut(requestParameters: ApiScenesIdPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiScenesIdGet200Response> {
        const response = await this.apiScenesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * uploaded_audio
     */
    async apiScenesIdUploadedAudioPostRaw(requestParameters: ApiScenesIdUploadedAudioPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiScenesIdUploadedAudioPost200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenesIdUploadedAudioPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/scenes/{id}/uploaded_audio`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiScenesIdUploadedAudioPostRequestToJSON(requestParameters.apiScenesIdUploadedAudioPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiScenesIdUploadedAudioPost200ResponseFromJSON(jsonValue));
    }

    /**
     * uploaded_audio
     */
    async apiScenesIdUploadedAudioPost(requestParameters: ApiScenesIdUploadedAudioPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiScenesIdUploadedAudioPost200Response> {
        const response = await this.apiScenesIdUploadedAudioPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * uploaded_image
     */
    async apiScenesIdUploadedImagePostRaw(requestParameters: ApiScenesIdUploadedImagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiScenesIdUploadedImagePost200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenesIdUploadedImagePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/scenes/{id}/uploaded_image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiScenesIdUploadedImagePostRequestToJSON(requestParameters.apiScenesIdUploadedImagePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiScenesIdUploadedImagePost200ResponseFromJSON(jsonValue));
    }

    /**
     * uploaded_image
     */
    async apiScenesIdUploadedImagePost(requestParameters: ApiScenesIdUploadedImagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiScenesIdUploadedImagePost200Response> {
        const response = await this.apiScenesIdUploadedImagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
