import { VFC, useState } from "react";

import type { Folder } from "../../types/folder";
import { FolderSelectorModal } from "../common/FolderSelectorModal";
import { summaryFolderPath } from "../../generated/routes";

interface Props {
  teamFolders: Folder[];
  disabled?: boolean;
  selectedFolder?: Folder;
}

export const SelectFolderButton: VFC<Props> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const onClose = () => setModalVisible(false);
  const onClick = (folder: Folder) => {
    location.assign(summaryFolderPath(folder.hashid));
    onClose();
  };

  return (
    <div className="p-summary__folder-button__container">
      <button
        className="c-button--outlined p-summary__folder-button"
        onClick={() => setModalVisible(true)}
        disabled={props.disabled}
      >
        {props.selectedFolder ? (
          <>
            <span className="p-summary__folder-button__icon">
              <span className="material-icons-round">folder</span>
            </span>
            <span
              className="p-summary__folder-button__label"
              title={props.selectedFolder.name}
            >
              {props.selectedFolder.name}
            </span>
          </>
        ) : (
          "フォルダを選択"
        )}
      </button>

      <FolderSelectorModal
        isOpen={modalVisible}
        onClick={onClick}
        onClose={onClose}
        selectedFolder={props.selectedFolder}
        teamFolders={props.teamFolders}
        headerTitle="対象のフォルダを選択"
      />
    </div>
  );
};
export default SelectFolderButton;
