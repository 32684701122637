/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoVideoIdNarrationsPutRequestNarrationsInner } from './ApiVideoVideoIdNarrationsPutRequestNarrationsInner';
import {
    ApiVideoVideoIdNarrationsPutRequestNarrationsInnerFromJSON,
    ApiVideoVideoIdNarrationsPutRequestNarrationsInnerFromJSONTyped,
    ApiVideoVideoIdNarrationsPutRequestNarrationsInnerToJSON,
} from './ApiVideoVideoIdNarrationsPutRequestNarrationsInner';

/**
 * 
 * @export
 * @interface ApiVideoVideoIdNarrationsPutRequest
 */
export interface ApiVideoVideoIdNarrationsPutRequest {
    /**
     * 
     * @type {Array<ApiVideoVideoIdNarrationsPutRequestNarrationsInner>}
     * @memberof ApiVideoVideoIdNarrationsPutRequest
     */
    narrations?: Array<ApiVideoVideoIdNarrationsPutRequestNarrationsInner>;
}

/**
 * Check if a given object implements the ApiVideoVideoIdNarrationsPutRequest interface.
 */
export function instanceOfApiVideoVideoIdNarrationsPutRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdNarrationsPutRequestFromJSON(json: any): ApiVideoVideoIdNarrationsPutRequest {
    return ApiVideoVideoIdNarrationsPutRequestFromJSONTyped(json, false);
}

export function ApiVideoVideoIdNarrationsPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdNarrationsPutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'narrations': !exists(json, 'narrations') ? undefined : ((json['narrations'] as Array<any>).map(ApiVideoVideoIdNarrationsPutRequestNarrationsInnerFromJSON)),
    };
}

export function ApiVideoVideoIdNarrationsPutRequestToJSON(value?: ApiVideoVideoIdNarrationsPutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'narrations': value.narrations === undefined ? undefined : ((value.narrations as Array<any>).map(ApiVideoVideoIdNarrationsPutRequestNarrationsInnerToJSON)),
    };
}

