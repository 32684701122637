import { createContext, ReactNode, useMemo } from "react";
import { useInitialVideo } from "./hooks";

type VideoContextType = ReturnType<typeof useInitialVideo>;

export const VideoContext = createContext<VideoContextType>([
  null,
  () => void 0,
]);
VideoContext.displayName = "VideoContext";

export function VideoContextProvider(props: {
  videoId: number;
  children: ReactNode;
}) {
  const [video, setVideo] = useInitialVideo(props.videoId);
  const contextValue = useMemo(
    () => [video, setVideo] as const,
    [video, setVideo]
  );
  return (
    <VideoContext.Provider value={contextValue}>
      {props.children}
    </VideoContext.Provider>
  );
}
