/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiAcademyCourseLessonsPost201ResponseVideo } from './ApiAcademyCourseLessonsPost201ResponseVideo';
import {
    ApiAcademyCourseLessonsPost201ResponseVideoFromJSON,
    ApiAcademyCourseLessonsPost201ResponseVideoFromJSONTyped,
    ApiAcademyCourseLessonsPost201ResponseVideoToJSON,
} from './ApiAcademyCourseLessonsPost201ResponseVideo';

/**
 * 
 * @export
 * @interface ApiAcademyCourseLessonsIdPatch202Response
 */
export interface ApiAcademyCourseLessonsIdPatch202Response {
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    hashid?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    academyCourseId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    videoId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    rowOrderRank?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    rowOrderPosition?: string | null;
    /**
     * 
     * @type {ApiAcademyCourseLessonsPost201ResponseVideo}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    video?: ApiAcademyCourseLessonsPost201ResponseVideo | null;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    playTimeSec?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    textHtml?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdPatch202Response
     */
    title?: string | null;
}

/**
 * Check if a given object implements the ApiAcademyCourseLessonsIdPatch202Response interface.
 */
export function instanceOfApiAcademyCourseLessonsIdPatch202Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCourseLessonsIdPatch202ResponseFromJSON(json: any): ApiAcademyCourseLessonsIdPatch202Response {
    return ApiAcademyCourseLessonsIdPatch202ResponseFromJSONTyped(json, false);
}

export function ApiAcademyCourseLessonsIdPatch202ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCourseLessonsIdPatch202Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hashid': !exists(json, 'hashid') ? undefined : json['hashid'],
        'academyCourseId': !exists(json, 'academyCourseId') ? undefined : json['academyCourseId'],
        'videoId': !exists(json, 'videoId') ? undefined : json['videoId'],
        'rowOrderRank': !exists(json, 'rowOrderRank') ? undefined : json['rowOrderRank'],
        'rowOrderPosition': !exists(json, 'rowOrderPosition') ? undefined : json['rowOrderPosition'],
        'video': !exists(json, 'video') ? undefined : ApiAcademyCourseLessonsPost201ResponseVideoFromJSON(json['video']),
        'playTimeSec': !exists(json, 'playTimeSec') ? undefined : json['playTimeSec'],
        'textHtml': !exists(json, 'textHtml') ? undefined : json['textHtml'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function ApiAcademyCourseLessonsIdPatch202ResponseToJSON(value?: ApiAcademyCourseLessonsIdPatch202Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hashid': value.hashid,
        'academyCourseId': value.academyCourseId,
        'videoId': value.videoId,
        'rowOrderRank': value.rowOrderRank,
        'rowOrderPosition': value.rowOrderPosition,
        'video': ApiAcademyCourseLessonsPost201ResponseVideoToJSON(value.video),
        'playTimeSec': value.playTimeSec,
        'textHtml': value.textHtml,
        'title': value.title,
    };
}

