/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAcademyCourseLessonsPostRequestCourseLesson
 */
export interface ApiAcademyCourseLessonsPostRequestCourseLesson {
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsPostRequestCourseLesson
     */
    academyCourseId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsPostRequestCourseLesson
     */
    videoId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsPostRequestCourseLesson
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsPostRequestCourseLesson
     */
    textHtml?: string;
}

/**
 * Check if a given object implements the ApiAcademyCourseLessonsPostRequestCourseLesson interface.
 */
export function instanceOfApiAcademyCourseLessonsPostRequestCourseLesson(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCourseLessonsPostRequestCourseLessonFromJSON(json: any): ApiAcademyCourseLessonsPostRequestCourseLesson {
    return ApiAcademyCourseLessonsPostRequestCourseLessonFromJSONTyped(json, false);
}

export function ApiAcademyCourseLessonsPostRequestCourseLessonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCourseLessonsPostRequestCourseLesson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'academyCourseId': !exists(json, 'academy_course_id') ? undefined : json['academy_course_id'],
        'videoId': !exists(json, 'video_id') ? undefined : json['video_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'textHtml': !exists(json, 'text_html') ? undefined : json['text_html'],
    };
}

export function ApiAcademyCourseLessonsPostRequestCourseLessonToJSON(value?: ApiAcademyCourseLessonsPostRequestCourseLesson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'academy_course_id': value.academyCourseId,
        'video_id': value.videoId,
        'title': value.title,
        'text_html': value.textHtml,
    };
}

