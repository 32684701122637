import { createContext } from "react";

type DescendantsContextType = {
  /**
   * HTML構造とデザイン上表現したい構造が異なるのを解決するために深さの情報を持つ。
   * 親から伝搬されたdepthの値を使って左paddingを動的に調整する。
   */
  depth: number;

  /**
   * 子が選択状態のときに、親に通知してツリーを展開させる。
   * TreeItemの実装側で中身は定義する。
   *
   * NOTE: 子から先祖を展開させる実装にしたため、ツリーの全コンポーネントをrenderさせる必要がある構造になってしまった。
   * パフォーマンス問題が起きたら、初期表示しないコンポーネントはrenderしない構造にできないか検討したい。
   */
  expandIfChildSelected: () => void;
};

/**
 * ツリーの親子間で連携したい情報を扱うコンテキスト。
 */
export const DescendantsContext = createContext<DescendantsContextType>({
  depth: 0,
  expandIfChildSelected() {
    // NOOP
  },
});
DescendantsContext.displayName = "DescendantsContext";
