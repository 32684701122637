import { useState, useEffect } from "react";

// 参考: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export function useDebounceState<T>(state: T, delay: number): [T] {
  const [debouncedState, setDebouncedState] = useState(state);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedState(state);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [state, delay]);
  return [debouncedState];
}
