/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoVideoIdWatchLinkConditionResponsePostRequest,
} from '../models';
import {
    ApiVideoVideoIdWatchLinkConditionResponsePostRequestFromJSON,
    ApiVideoVideoIdWatchLinkConditionResponsePostRequestToJSON,
} from '../models';

export interface ApiVideoVideoIdWatchLinkConditionResponsePostOperationRequest {
    videoId: number;
    apiVideoVideoIdWatchLinkConditionResponsePostRequest?: ApiVideoVideoIdWatchLinkConditionResponsePostRequest;
}

/**
 * 
 */
export class ApiWatchLinkConditionResponseApi extends runtime.BaseAPI {

    /**
     * create
     */
    async apiVideoVideoIdWatchLinkConditionResponsePostRaw(requestParameters: ApiVideoVideoIdWatchLinkConditionResponsePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdWatchLinkConditionResponsePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{video_id}/watch_link/condition/response`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoVideoIdWatchLinkConditionResponsePostRequestToJSON(requestParameters.apiVideoVideoIdWatchLinkConditionResponsePostRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * create
     */
    async apiVideoVideoIdWatchLinkConditionResponsePost(requestParameters: ApiVideoVideoIdWatchLinkConditionResponsePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoVideoIdWatchLinkConditionResponsePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
