/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoIdMovePatch200ResponseWatchLink
 */
export interface ApiVideoIdMovePatch200ResponseWatchLink {
    /**
     * 
     * @type {number}
     * @memberof ApiVideoIdMovePatch200ResponseWatchLink
     */
    videoId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdMovePatch200ResponseWatchLink
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdMovePatch200ResponseWatchLink
     */
    uniqKey?: string;
    /**
     * 
     * @type {any}
     * @memberof ApiVideoIdMovePatch200ResponseWatchLink
     */
    condition?: any | null;
}

/**
 * Check if a given object implements the ApiVideoIdMovePatch200ResponseWatchLink interface.
 */
export function instanceOfApiVideoIdMovePatch200ResponseWatchLink(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoIdMovePatch200ResponseWatchLinkFromJSON(json: any): ApiVideoIdMovePatch200ResponseWatchLink {
    return ApiVideoIdMovePatch200ResponseWatchLinkFromJSONTyped(json, false);
}

export function ApiVideoIdMovePatch200ResponseWatchLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoIdMovePatch200ResponseWatchLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'videoId': !exists(json, 'videoId') ? undefined : json['videoId'],
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'uniqKey': !exists(json, 'uniqKey') ? undefined : json['uniqKey'],
        'condition': !exists(json, 'condition') ? undefined : json['condition'],
    };
}

export function ApiVideoIdMovePatch200ResponseWatchLinkToJSON(value?: ApiVideoIdMovePatch200ResponseWatchLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'videoId': value.videoId,
        'typeName': value.typeName,
        'uniqKey': value.uniqKey,
        'condition': value.condition,
    };
}

