/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoVideoIdWatchLinkGet200ResponseMembersInner } from './ApiVideoVideoIdWatchLinkGet200ResponseMembersInner';
import {
    ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSON,
    ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSONTyped,
    ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerToJSON,
} from './ApiVideoVideoIdWatchLinkGet200ResponseMembersInner';
import type { ApiVideoVideoIdWatchLinkPatch200ResponseCondition } from './ApiVideoVideoIdWatchLinkPatch200ResponseCondition';
import {
    ApiVideoVideoIdWatchLinkPatch200ResponseConditionFromJSON,
    ApiVideoVideoIdWatchLinkPatch200ResponseConditionFromJSONTyped,
    ApiVideoVideoIdWatchLinkPatch200ResponseConditionToJSON,
} from './ApiVideoVideoIdWatchLinkPatch200ResponseCondition';

/**
 * 
 * @export
 * @interface ApiVideoVideoIdWatchLinkPatch200Response
 */
export interface ApiVideoVideoIdWatchLinkPatch200Response {
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdWatchLinkPatch200Response
     */
    videoId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdWatchLinkPatch200Response
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdWatchLinkPatch200Response
     */
    uniqKey?: string;
    /**
     * 
     * @type {Array<ApiVideoVideoIdWatchLinkGet200ResponseMembersInner>}
     * @memberof ApiVideoVideoIdWatchLinkPatch200Response
     */
    members?: Array<ApiVideoVideoIdWatchLinkGet200ResponseMembersInner>;
    /**
     * 
     * @type {ApiVideoVideoIdWatchLinkPatch200ResponseCondition}
     * @memberof ApiVideoVideoIdWatchLinkPatch200Response
     */
    condition?: ApiVideoVideoIdWatchLinkPatch200ResponseCondition;
}

/**
 * Check if a given object implements the ApiVideoVideoIdWatchLinkPatch200Response interface.
 */
export function instanceOfApiVideoVideoIdWatchLinkPatch200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdWatchLinkPatch200ResponseFromJSON(json: any): ApiVideoVideoIdWatchLinkPatch200Response {
    return ApiVideoVideoIdWatchLinkPatch200ResponseFromJSONTyped(json, false);
}

export function ApiVideoVideoIdWatchLinkPatch200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdWatchLinkPatch200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'videoId': !exists(json, 'videoId') ? undefined : json['videoId'],
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'uniqKey': !exists(json, 'uniqKey') ? undefined : json['uniqKey'],
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerFromJSON)),
        'condition': !exists(json, 'condition') ? undefined : ApiVideoVideoIdWatchLinkPatch200ResponseConditionFromJSON(json['condition']),
    };
}

export function ApiVideoVideoIdWatchLinkPatch200ResponseToJSON(value?: ApiVideoVideoIdWatchLinkPatch200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'videoId': value.videoId,
        'typeName': value.typeName,
        'uniqKey': value.uniqKey,
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(ApiVideoVideoIdWatchLinkGet200ResponseMembersInnerToJSON)),
        'condition': ApiVideoVideoIdWatchLinkPatch200ResponseConditionToJSON(value.condition),
    };
}

