import { VFC, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  ApiAcademyCourseCategoryApi,
  Configuration,
} from "../../generated/api";
import { notify } from "../notification";

type AcademyCourseCategory = Awaited<
  ReturnType<ApiAcademyCourseCategoryApi["apiAcademyCourseCategoriesIdGet"]>
>;

interface Props {
  csrfToken: string;
  courseCategory: AcademyCourseCategory;
}

export const AcademyCourseCategoryEdit: VFC<Props> = (props) => {
  const [title, setTitle] = useState(props.courseCategory.title ?? "");

  const patchCourse = (
    courseCategory: Pick<AcademyCourseCategory, "title">
  ) => {
    const api = new ApiAcademyCourseCategoryApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiAcademyCourseCategoriesIdPatch({
        id: props.courseCategory.id,
        apiAcademyCourseCategoriesIdPatchRequest: {
          academyCourseCategory: { ...courseCategory },
        },
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  return (
    <div>
      <label
        className="p-academy-edit__input-field-label"
        htmlFor="course_category_title"
      >
        カテゴリー名
      </label>
      <TextareaAutosize
        id="course_category_title"
        className="p-academy-edit__input-field-textarea"
        rows={1}
        value={title}
        required={true}
        maxLength={100}
        placeholder="カテゴリー名を入力してください"
        onChange={(e) => setTitle(e.target.value)}
        onBlur={() => {
          if (title) {
            patchCourse({ title });
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.currentTarget.blur();
          }
        }}
      />
    </div>
  );
};
export default AcademyCourseCategoryEdit;
