/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVideoVideoIdSubtitlesGet200ResponseInner
 */
export interface ApiVideoVideoIdSubtitlesGet200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof ApiVideoVideoIdSubtitlesGet200ResponseInner
     */
    value?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdSubtitlesGet200ResponseInner
     */
    startTimeMs?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoVideoIdSubtitlesGet200ResponseInner
     */
    endTimeMs?: number;
}

/**
 * Check if a given object implements the ApiVideoVideoIdSubtitlesGet200ResponseInner interface.
 */
export function instanceOfApiVideoVideoIdSubtitlesGet200ResponseInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoVideoIdSubtitlesGet200ResponseInnerFromJSON(json: any): ApiVideoVideoIdSubtitlesGet200ResponseInner {
    return ApiVideoVideoIdSubtitlesGet200ResponseInnerFromJSONTyped(json, false);
}

export function ApiVideoVideoIdSubtitlesGet200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoVideoIdSubtitlesGet200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'startTimeMs': !exists(json, 'startTimeMs') ? undefined : json['startTimeMs'],
        'endTimeMs': !exists(json, 'endTimeMs') ? undefined : json['endTimeMs'],
    };
}

export function ApiVideoVideoIdSubtitlesGet200ResponseInnerToJSON(value?: ApiVideoVideoIdSubtitlesGet200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'startTimeMs': value.startTimeMs,
        'endTimeMs': value.endTimeMs,
    };
}

