import { VFC, useState } from "react";
import { ApiVideoApi, Configuration } from "../../generated/api";
import { notify } from "../notification";
import { RichTextEditor } from "../common/editor/RichTextEditor";

interface Props {
  csrfToken: string;
  videoId: number;
  descriptionHtml: string;
}

export const VideoDescriptionEdit: VFC<Props> = (props) => {
  const [descriptionHtml, setDescriptionHtml] = useState(
    props.descriptionHtml ?? ""
  );

  const submit = () => {
    if (descriptionHtml.length > 20000) {
      notify(
        "データ量が多すぎるため保存できません。説明テキストの内容を減らしてください。"
      );
      return;
    }
    const api = new ApiVideoApi(
      new Configuration({
        basePath: location.origin,
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    api
      .apiVideoIdPut({
        id: props.videoId,
        apiVideoIdPutRequest: { descriptionHtml },
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  return (
    <RichTextEditor
      className="p-video__description-edit"
      placeholder="説明を追加"
      toolbarStickyOffset={56}
      value={descriptionHtml}
      onChangeValue={setDescriptionHtml}
      onBlur={submit}
      enableTimestampLink={true}
    />
  );
};

export default VideoDescriptionEdit;
