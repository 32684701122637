import { VFC, FormEvent, useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import userImageDefault from "images/user-image-default.svg";

export interface WatchLinkInviteMember {
  email?: string; // 本来はrequiredだが自動生成コードとバッティングするので仕方なくoptional
  name?: string;
  imageUrl?: string;
  isJoined?: boolean;
}

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const WatchLinkInvitationForm: VFC<{
  members: WatchLinkInviteMember[];
  onUpdate: (updated: WatchLinkInviteMember[]) => void;
}> = (props) => {
  const textfield = useRef<HTMLTextAreaElement>();
  const [emails, setEmails] = useState("");

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const unique = (arr: string[]) => Array.from(new Set(arr));
    const emailList = unique(emails.trim().split(/[,\s]+/));
    if (emailList.length > 600) {
      textfield.current.setCustomValidity(
        "一度に追加できるメールアドレスは600件までです。"
      );
      event.currentTarget.reportValidity();
      return;
    }

    let isInvalidPattern = false;
    let isInvitedEmail = false;
    const ok: string[] = [];
    const ng: string[] = [];
    const alreadyInvitedEmails = props.members.map((item) => item.email);
    emailList.forEach((email) => {
      if (!EMAIL_REGEXP.test(email)) {
        isInvalidPattern = true;
        ng.push(email);
      } else if (alreadyInvitedEmails.includes(email)) {
        isInvitedEmail = true;
        ng.push(email);
      } else {
        ok.push(email);
      }
    });

    setEmails(ng.join("\n"));
    textfield.current.setCustomValidity(
      isInvalidPattern && isInvitedEmail
        ? "招待済みまたは不正なメールアドレスです。"
        : isInvalidPattern
        ? "不正なメールアドレスです。"
        : isInvitedEmail
        ? "招待済みです。"
        : ""
    );
    if (isInvalidPattern || isInvitedEmail) {
      event.currentTarget.reportValidity();
    }

    const newMembers = ok.map((e) => ({ email: e.trim() }));
    props.onUpdate([...newMembers, ...props.members]);
  };

  const delMember = (member: WatchLinkInviteMember) => {
    props.onUpdate(props.members.filter((m) => m.email !== member.email));
  };

  return (
    <>
      <form className="c-watch-link-modal__margin-top" onSubmit={handleSubmit}>
        <div className="c-watch-link-modal__input">
          <TextareaAutosize
            ref={textfield}
            className="c-watch-link-modal__input-text-field"
            maxRows={9}
            placeholder="メールアドレス"
            required
            value={emails}
            onChange={(event) => {
              event.target.setCustomValidity("");
              setEmails(event.target.value);
            }}
          />
          <button className="c-watch-link-modal__input-button" type="submit">
            追加
          </button>
          <p className="c-watch-link-modal__input-desc">
            改行、またはカンマかスペースで区切ると一括追加できます。
          </p>
        </div>
      </form>
      <ul className="c-watch-link-modal__list">
        {props.members.map((member) => (
          <li
            className="c-watch-link-modal__list-item"
            key={member.email}
            title={member.email}
          >
            <img
              className="c-watch-link-modal__list-item-img"
              src={member.imageUrl || userImageDefault}
            />
            <span className="c-watch-link-modal__list-item-text">
              {member.name || member.email}
            </span>
            <button
              className="material-icons-round c-watch-link-modal__list-item-del"
              onClick={() => delMember(member)}
            >
              clear
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};
