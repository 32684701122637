import { useBeforeUnload } from "../../../lib/useBeforeUnload";
import { useContext, useState } from "react";
import { RichTextEditor } from "../../common/editor/RichTextEditor";
import { LoadingContext } from "../../common/loading/LoadingContext";
import { DesignHeader } from "../DesignHeader";
import { DesignSideNav } from "../DesignSideNav";
import { updateVideo } from "../requests";
import { VideoContext } from "../VideoContext";
import VideoThumbnail from "../../video/VideoThumbnail";
import { notify } from "../../notification";

export interface DesignInfoEditProps {
  csrfToken: string;
  designHashId: string;
  designId: number;
  videoId: number;
  folderHashId: string;
  shareUrl: string;
  embedHtml: string;
  thumbnailResettable: boolean;
  domainWatchLinkAvailable: boolean;
}

export function DesignInfoEdit(props: DesignInfoEditProps) {
  const [video, setVideo] = useContext(VideoContext);
  const [shouldSaveDescription, setShouldSaveDescription] = useState(false);
  const { isLoading, handleLoading } = useContext(LoadingContext);
  useBeforeUnload(isLoading || shouldSaveDescription);

  const onSubmitDescription = async () => {
    if (!video) {
      return;
    }
    if (video?.descriptionHtml?.length > 20000) {
      notify(
        "データ量が多すぎるため保存できません。ノートの内容を減らしてください。"
      );
      return;
    }
    if (!shouldSaveDescription) {
      return;
    }
    const newVideo = await handleLoading(
      updateVideo(props.csrfToken, props.videoId, {
        descriptionHtml: video.descriptionHtml ?? "",
      })
    ).finally(() => setShouldSaveDescription(false));
    setVideo(newVideo);
  };

  return (
    <div className="p-design">
      <DesignHeader
        csrfToken={props.csrfToken}
        videoId={props.videoId}
        designId={props.designId}
        folderHashId={props.folderHashId}
        videoShareUrl={props.shareUrl}
        videoEmbedHtml={props.embedHtml}
        domainWatchLinkAvailable={props.domainWatchLinkAvailable}
      />

      {/* メインエリア */}
      <div className="p-design__main">
        <DesignSideNav designHashId={props.designHashId} mode="info" />

        <div className="p-design__center-pane p-design-scrollbar">
          <div className="p-design__center-pane-inner">
            <h2 className="p-design-title">サムネイル</h2>
            <div className="p-design-thumbnail">
              <div className="p-design-thumbnail__items">
                <div>
                  <VideoThumbnail
                    csrfToken={props.csrfToken}
                    videoId={props.videoId}
                    thumbnailResettable={props.thumbnailResettable}
                  />
                </div>
                {props.thumbnailResettable && video?.thumbnailUrl && (
                  <div>
                    <img
                      className="p-design-thumbnail__img"
                      width={240}
                      height={(240 * 9) / 16}
                      src={video.thumbnailUrl}
                    />
                  </div>
                )}
              </div>
            </div>

            <h2 className="p-design-title">
              ノート
              <small className="p-design-title__note">
                「1:00」などと入力すると指定時間から再生できるリンクをつけられます
              </small>
            </h2>
            <RichTextEditor
              placeholder="ノートを追加"
              value={video?.descriptionHtml ?? ""}
              toolbarStickyOffset={-8}
              onChangeValue={(newValue) => {
                setShouldSaveDescription(true);
                setVideo({ ...video, descriptionHtml: newValue });
              }}
              onBlur={onSubmitDescription}
              enableTimestampLink={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
