/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAcademyCourseLessonsIdGet200ResponseVideo
 */
export interface ApiAcademyCourseLessonsIdGet200ResponseVideo {
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    hashid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    descriptionHtml?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    playTimeMs?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    timelineEditable?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiAcademyCourseLessonsIdGet200ResponseVideo
     */
    timelineUneditableReasons?: Array<string>;
}

/**
 * Check if a given object implements the ApiAcademyCourseLessonsIdGet200ResponseVideo interface.
 */
export function instanceOfApiAcademyCourseLessonsIdGet200ResponseVideo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiAcademyCourseLessonsIdGet200ResponseVideoFromJSON(json: any): ApiAcademyCourseLessonsIdGet200ResponseVideo {
    return ApiAcademyCourseLessonsIdGet200ResponseVideoFromJSONTyped(json, false);
}

export function ApiAcademyCourseLessonsIdGet200ResponseVideoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAcademyCourseLessonsIdGet200ResponseVideo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hashid': !exists(json, 'hashid') ? undefined : json['hashid'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'descriptionHtml': !exists(json, 'descriptionHtml') ? undefined : json['descriptionHtml'],
        'playTimeMs': !exists(json, 'playTimeMs') ? undefined : json['playTimeMs'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'timelineEditable': !exists(json, 'timelineEditable') ? undefined : json['timelineEditable'],
        'timelineUneditableReasons': !exists(json, 'timelineUneditableReasons') ? undefined : json['timelineUneditableReasons'],
    };
}

export function ApiAcademyCourseLessonsIdGet200ResponseVideoToJSON(value?: ApiAcademyCourseLessonsIdGet200ResponseVideo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hashid': value.hashid,
        'state': value.state,
        'title': value.title,
        'descriptionHtml': value.descriptionHtml,
        'playTimeMs': value.playTimeMs,
        'thumbnailUrl': value.thumbnailUrl,
        'timelineEditable': value.timelineEditable,
        'timelineUneditableReasons': value.timelineUneditableReasons,
    };
}

