/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoIdDuplicatePost422Response,
  ApiVideoIdGet200Response,
  ApiVideoIdMovePatch200Response,
  ApiVideoIdMovePatchRequest,
  ApiVideoIdPut200Response,
  ApiVideoIdPutRequest,
  ApiVideoPreparePost200Response,
  ApiVideoUploadedPostRequest,
} from '../models';
import {
    ApiVideoIdDuplicatePost422ResponseFromJSON,
    ApiVideoIdDuplicatePost422ResponseToJSON,
    ApiVideoIdGet200ResponseFromJSON,
    ApiVideoIdGet200ResponseToJSON,
    ApiVideoIdMovePatch200ResponseFromJSON,
    ApiVideoIdMovePatch200ResponseToJSON,
    ApiVideoIdMovePatchRequestFromJSON,
    ApiVideoIdMovePatchRequestToJSON,
    ApiVideoIdPut200ResponseFromJSON,
    ApiVideoIdPut200ResponseToJSON,
    ApiVideoIdPutRequestFromJSON,
    ApiVideoIdPutRequestToJSON,
    ApiVideoPreparePost200ResponseFromJSON,
    ApiVideoPreparePost200ResponseToJSON,
    ApiVideoUploadedPostRequestFromJSON,
    ApiVideoUploadedPostRequestToJSON,
} from '../models';

export interface ApiVideoIdDeleteRequest {
    id: number;
}

export interface ApiVideoIdDownloadGetRequest {
    id: number;
}

export interface ApiVideoIdDuplicatePostRequest {
    id: number;
}

export interface ApiVideoIdGetRequest {
    id: number;
}

export interface ApiVideoIdMovePatchOperationRequest {
    id: number;
    apiVideoIdMovePatchRequest?: ApiVideoIdMovePatchRequest;
}

export interface ApiVideoIdPutOperationRequest {
    id: number;
    apiVideoIdPutRequest?: ApiVideoIdPutRequest;
}

export interface ApiVideoUploadedPostOperationRequest {
    apiVideoUploadedPostRequest?: ApiVideoUploadedPostRequest;
}

/**
 * 
 */
export class ApiVideoApi extends runtime.BaseAPI {

    /**
     * destroy
     */
    async apiVideoIdDeleteRaw(requestParameters: ApiVideoIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * destroy
     */
    async apiVideoIdDelete(requestParameters: ApiVideoIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * download
     */
    async apiVideoIdDownloadGetRaw(requestParameters: ApiVideoIdDownloadGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdDownloadGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{id}/download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * download
     */
    async apiVideoIdDownloadGet(requestParameters: ApiVideoIdDownloadGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoIdDownloadGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * duplicate
     */
    async apiVideoIdDuplicatePostRaw(requestParameters: ApiVideoIdDuplicatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdDuplicatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{id}/duplicate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * duplicate
     */
    async apiVideoIdDuplicatePost(requestParameters: ApiVideoIdDuplicatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.apiVideoIdDuplicatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * show
     */
    async apiVideoIdGetRaw(requestParameters: ApiVideoIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdGet200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiVideoIdGet(requestParameters: ApiVideoIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdGet200Response> {
        const response = await this.apiVideoIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * move
     */
    async apiVideoIdMovePatchRaw(requestParameters: ApiVideoIdMovePatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdMovePatch200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdMovePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{id}/move`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoIdMovePatchRequestToJSON(requestParameters.apiVideoIdMovePatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdMovePatch200ResponseFromJSON(jsonValue));
    }

    /**
     * move
     */
    async apiVideoIdMovePatch(requestParameters: ApiVideoIdMovePatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdMovePatch200Response> {
        const response = await this.apiVideoIdMovePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async apiVideoIdPutRaw(requestParameters: ApiVideoIdPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoIdPut200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideoIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoIdPutRequestToJSON(requestParameters.apiVideoIdPutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoIdPut200ResponseFromJSON(jsonValue));
    }

    /**
     * update
     */
    async apiVideoIdPut(requestParameters: ApiVideoIdPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoIdPut200Response> {
        const response = await this.apiVideoIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * prepare
     */
    async apiVideoPreparePostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoPreparePost200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/prepare`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoPreparePost200ResponseFromJSON(jsonValue));
    }

    /**
     * prepare
     */
    async apiVideoPreparePost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoPreparePost200Response> {
        const response = await this.apiVideoPreparePostRaw(initOverrides);
        return await response.value();
    }

    /**
     * uploaded
     */
    async apiVideoUploadedPostRaw(requestParameters: ApiVideoUploadedPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/video/uploaded`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVideoUploadedPostRequestToJSON(requestParameters.apiVideoUploadedPostRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * uploaded
     */
    async apiVideoUploadedPost(requestParameters: ApiVideoUploadedPostOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVideoUploadedPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
