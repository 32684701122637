import { useContext, useRef, useState } from "react";

import { DesignScene } from "../helpers";
import { LoadingContext } from "../../common/loading/LoadingContext";
import { createDesignScene, uploadDesignSceneImage } from "../requests";
import loadingImg from "images/loading.svg";
import icnAddMultiFile from "images/icon/icn_add_multi_file.png";

export function FileUploadDropzone(props: {
  csrfToken: string;
  designId: number;
  index: number;
  audioData: { voiceName: string; speechRate: string };
  onUpdateScenes: (scene: DesignScene[]) => void;
}) {
  const { handleLoading } = useContext(LoadingContext);
  const { csrfToken, designId, index, audioData, onUpdateScenes } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const acceptImageMimeTypes = ["image/jpeg", "image/png"];
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  function validateImageFile(file: File) {
    if (!file) {
      setError("ファイルが選択されていません。");
      return false;
    }
    if (file.size > 1024 * 1024) {
      setError("1MB以下のファイルを選択してください。");
      return false;
    }
    if (!acceptImageMimeTypes.includes(file.type)) {
      setError("追加できませんでした。JPEG、PNGをお選びください。");
      return false;
    }
    return true;
  }

  async function addScene(rowIndex: number): Promise<DesignScene> {
    if (rowIndex > 99) {
      setError("シーンは99個までです");
      setIsLoading(false);
      return;
    }

    if (!rowIndex) {
      return;
    }
    const newScene = await handleLoading(
      createDesignScene(csrfToken, designId, {
        rowOrderPosition: rowIndex,
        textHtml: "",
        audioData: audioData,
      })
    );

    return newScene;
  }

  async function uploadImage(
    updateSceneId: number,
    file: File
  ): Promise<DesignScene> {
    const newScene = await handleLoading(
      uploadDesignSceneImage(csrfToken, updateSceneId, file)
    );

    return newScene;
  }

  async function uploadImages(files: File[]) {
    const newScenes: DesignScene[] = [];
    // アップロードを待機
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (!index) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (!files || files.length === 0) {
      return;
    }

    // ファイルバリデーションチェック
    if (!Array.from(files).every((value) => validateImageFile(value))) {
      return;
    }

    setIsLoading(true);

    const filesSortByName = sortFilesByName(Array.from(files));

    // ビデオ編集V2 ファイルアップロードの定量分析
    if ("krt" in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).krt("send", "scene_upload_image", {
        designId: designId,
        fileCount: filesSortByName.length,
      });
    }

    // シーン新規作成後、画像アップロード
    for (let i = 0; i < filesSortByName.length; i++) {
      const file = filesSortByName[i];

      const newScene = await addScene(index + i);
      if (!newScene) {
        return;
      }

      const uploadedScene = await uploadImage(newScene.id, file);
      if (!uploadedScene) {
        return;
      }
      newScenes.push(uploadedScene);
    }

    setIsLoading(false);

    onUpdateScenes(newScenes);
  }

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setError(null);
    const files: File[] = Array.from(e.dataTransfer.files);
    await uploadImages(files);
  };

  // 下記削除するとドラッグしたときにファイルアップロードされない
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError(null);
    if (e.target.files) {
      // 非同期関数の完了を待機
      await uploadImages(Array.from(e.target.files));
      e.target.value = "";
    }
  };

  return (
    <>
      {isLoading && (
        <div className="p-design-scene__file-upload-dropzone__loading">
          <img
            src={loadingImg}
            className="p-design-scene__file-upload-dropzone__loading-icon"
          />
          <span className="p-design-scene__file-upload-dropzone__loading-text">
            読み込み中
          </span>
        </div>
      )}

      <div
        className="p-design-scene__file-upload-dropzone"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
        data-testid="dropzone"
      >
        <div className="p-design-scene__file-upload-dropzone-child--hover">
          <i className="material-icons-round p-design-scene__file-upload-dropzone--dragging-icon">
            file_upload
          </i>
          <span className="p-design-scene__file-upload-dropzone__description--dragging">
            ここにドロップ
          </span>
        </div>
        <div className="p-design-scene__file-upload-dropzone-child">
          <div className="p-design-scene__file-upload-dropzone__title-container">
            <img
              className="p-design-scene__file-upload-dropzone__title-icon"
              src={icnAddMultiFile}
              alt=""
            />
            <span className="p-design-scene__file-upload-dropzone__title">
              シーンを追加
            </span>
          </div>
          <span className="p-design-scene__file-upload-dropzone__description">
            複数の画像をドラッグ可能、または<u>ファイルを選択</u>
          </span>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept={acceptImageMimeTypes.join(",")}
          onChange={handleFileChange}
          multiple
          data-testid="dropzone-input"
        />
      </div>

      <div className="p-design-scene__file-upload-dropzone__error">
        {error && (
          <>
            <span className="p-design-scene__file-upload-dropzone__error-icon material-icons-round">
              error_outline
            </span>
            <span>{error}</span>
          </>
        )}
      </div>
    </>
  );
}

// 0埋めされていない連番に対応するソート
export function sortFilesByName(files: File[]) {
  const collator = new Intl.Collator("ja-JP", { numeric: true });
  return [...files].sort((a, b) => collator.compare(a.name, b.name));
}
