// mm:ss , m:ss , hh:mm:ss のいずれか
const TIMESTAMP_REGEXP = /(^|\s)((?:(\d?\d):)?(\d?\d):(\d\d))(?=\s|$)/;
const PARAM_NAME = "t";

export function queryTimestampAnchorElements(root: ParentNode = document) {
  const selector = `a[href^='?${PARAM_NAME}='],a[href*='&${PARAM_NAME}=']`;
  return Array.from(root.querySelectorAll<HTMLAnchorElement>(selector))
    .map((elem) => ({
      elem,
      time: new TimestampLinkParser(elem.href).time(),
    }))
    .filter((item) => item.time != null);
}

export class TimestampLinkParser {
  private url: URL;
  private isSameSite = false;

  constructor(
    urlStr: string,
    loc: { origin: string; pathname: string } = location
  ) {
    const url = new URL(urlStr);
    this.url = url;
    this.isSameSite =
      url.origin === loc.origin && url.pathname === loc.pathname;
  }

  time() {
    if (this.isSameSite) {
      const paramValue = this.url.searchParams.get(PARAM_NAME);
      const sec = paramValue ? Number(paramValue) : null;
      if (Number.isSafeInteger(sec)) {
        return sec;
      }
    }
    return null;
  }
}

export class TimestampLinkBuilder {
  constructor(private text: string) {}

  containsTimestampNotation(): boolean {
    return TIMESTAMP_REGEXP.test(this.text);
  }

  href() {
    const time = this.match()?.time;
    if (time != null) {
      const params = new URLSearchParams();
      params.append(PARAM_NAME, `${time}`);
      return `?${params}`;
    }
    return null;
  }

  match() {
    const match = (this.text || "").match(TIMESTAMP_REGEXP);
    if (match) {
      const [, lookbehind, captured, hour, min, sec] = [...match];
      const time = Number(hour || 0) * 3600 + Number(min) * 60 + Number(sec);
      return {
        time,
        index: match.index + lookbehind.length,
        captured,
        source: match,
      };
    }
    return null;
  }
}
