import { useState, useEffect, useCallback } from "react";
import type { RefObject } from "react";

/**
 * 次のrender後(useEffect実行時)にscrollIntoViewを実行するカスタムフック
 * @param elemRef scrollIntoViewを実行する要素
 * @param options scrollIntoViewのオプション
 * @returns [requestScrollIntoView]
 */
export function useScrollIntoView(
  elemRef: RefObject<Element>,
  options?: ScrollIntoViewOptions
) {
  const [req, setReq] = useState(false);

  useEffect(() => {
    if (req) {
      elemRef.current?.scrollIntoView?.(options);
      setReq(false);
    }
  }, [req, elemRef, options]);

  return [useCallback(() => setReq(true), [])] as const;
}
