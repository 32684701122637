/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiFoldersPostRequestFolder } from './ApiFoldersPostRequestFolder';
import {
    ApiFoldersPostRequestFolderFromJSON,
    ApiFoldersPostRequestFolderFromJSONTyped,
    ApiFoldersPostRequestFolderToJSON,
} from './ApiFoldersPostRequestFolder';

/**
 * 
 * @export
 * @interface ApiFoldersPostRequest
 */
export interface ApiFoldersPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiFoldersPostRequest
     */
    parentFolderId?: number;
    /**
     * 
     * @type {ApiFoldersPostRequestFolder}
     * @memberof ApiFoldersPostRequest
     */
    folder?: ApiFoldersPostRequestFolder;
}

/**
 * Check if a given object implements the ApiFoldersPostRequest interface.
 */
export function instanceOfApiFoldersPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiFoldersPostRequestFromJSON(json: any): ApiFoldersPostRequest {
    return ApiFoldersPostRequestFromJSONTyped(json, false);
}

export function ApiFoldersPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiFoldersPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentFolderId': !exists(json, 'parent_folder_id') ? undefined : json['parent_folder_id'],
        'folder': !exists(json, 'folder') ? undefined : ApiFoldersPostRequestFolderFromJSON(json['folder']),
    };
}

export function ApiFoldersPostRequestToJSON(value?: ApiFoldersPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parent_folder_id': value.parentFolderId,
        'folder': ApiFoldersPostRequestFolderToJSON(value.folder),
    };
}

