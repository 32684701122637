import { useState, useRef, ChangeEventHandler, MouseEventHandler } from "react";
import loadingImg from "images/loading.svg";
import { ApiThumbnailApi, Configuration } from "../../generated/api";
import { BlockBlobClient } from "@azure/storage-blob";

interface Props {
  csrfToken: string;
  videoId: number;
  thumbnailResettable: boolean;
  enableVideoElemCapture?: boolean;
}

const acceptMimeTypes = ["image/jpeg", "image/png"];
type ErrorType = "fileSize" | "fileType" | "unknown";

async function uploadThumbnail(props: Props, file: File): Promise<void> {
  const api = new ApiThumbnailApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": props.csrfToken,
      },
    })
  );

  if (props.thumbnailResettable) {
    await api.apiVideoIdThumbnailDelete({
      id: props.videoId,
    });
  }

  const res = await api.apiVideoIdThumbnailPresignGet({
    id: props.videoId,
  });

  const blobClient = new BlockBlobClient(res.endpoint);
  await blobClient.uploadData(file, {
    blobHTTPHeaders: { blobContentType: file.type },
  });

  const url = new URL(res.endpoint);
  const [, assetContainerName, assetName] = url.pathname.split("/");
  await api.apiVideoIdThumbnailPost({
    id: props.videoId,
    assetName: assetName,
    assetContainer: assetContainerName,
  });
}

function validateFile(file: File) {
  const errors: ErrorType[] = [];
  if (file.size > 1024 * 1024) {
    errors.push("fileSize");
  }
  if (!acceptMimeTypes.includes(file.type)) {
    errors.push("fileType");
  }
  return errors;
}

async function purgeThumbnail(props: Props): Promise<void> {
  const api = new ApiThumbnailApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": props.csrfToken,
      },
    })
  );

  await api.apiVideoIdThumbnailDelete({
    id: props.videoId,
  });
}

export function VideoThumbnail(props: Props) {
  const resettable = props.thumbnailResettable;
  const inputFileRef = useRef<HTMLInputElement>();
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [uploading, setUploading] = useState(false);

  const fileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setErrors([]);
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setUploading(true);
    const errors = validateFile(file);
    if (errors.length > 0) {
      setErrors(errors);
      setUploading(false);
      return;
    }

    // アップロード
    uploadThumbnail(props, file)
      .then(() => {
        location.reload();
      })
      .catch((reason) => {
        console.error(reason);
        setErrors(["unknown"]);
        setUploading(false);
      });
  };

  const captureVideoElemInThisPage = () => {
    const video = document.querySelector("video");
    if (!video) {
      alert("ビデオを検出できませんでした");
      return;
    }
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0);
    canvas.toBlob((blob) => {
      canvas.remove();
      if (!blob) {
        alert("画像の取得に失敗しました");
        return;
      }
      const file = new File([blob], "thumbnail.jpeg", { type: blob.type });
      setUploading(true);
      uploadThumbnail(props, file)
        .then(() => {
          location.reload();
        })
        .catch((reason) => {
          console.error(reason);
          setErrors(["unknown"]);
          setUploading(false);
        });
    }, "image/jpeg");
  };

  const initializeThumbnail: MouseEventHandler = () => {
    if (!confirm("サムネイルをリセットしますか？")) {
      return;
    }
    setUploading(true);
    purgeThumbnail(props)
      .then(() => {
        location.reload();
      })
      .catch((reason) => {
        console.error(reason);
        setErrors(["unknown"]);
        setUploading(false);
      });
  };
  return (
    <>
      {errors.length > 0 && (
        <div className="p-thumbnail__error">
          アップロードできませんでした。
          <br />
          {errors
            .map((errorType) => {
              const errMsgs: Record<ErrorType, string> = {
                fileSize: "ファイルサイズを1MB以下にして再度お試しください。",
                fileType:
                  "JPEGまたはPNG形式のファイルをアップロードしてください。",
                unknown: "画面をリロードしてもう一度お試しください。",
              };
              return errMsgs[errorType] ?? errMsgs.unknown;
            })
            .join("\n")}
        </div>
      )}
      <div className="p-thumbnail__container">
        <p className="material-icons-round p-thumbnail__icon">insert_photo</p>
        <h3 className="p-thumbnail__title">画像をアップロード</h3>
        <p>
          jpeg, png / 1MB以下
          <br />
          推奨サイズ：1280 x 720px
        </p>

        <button
          className="c-button--outlined p-thumbnail__button"
          disabled={uploading}
          onClick={() => inputFileRef.current.click()}
        >
          ファイルを選択
        </button>
        <input
          ref={inputFileRef}
          type="file"
          accept={acceptMimeTypes.join(",")}
          onChange={fileChange}
          disabled={uploading}
          className="p-thumbnail__input"
        />

        {uploading && (
          <>
            <div className="p-thumbnail__uploading" title="アップロード中…">
              <img
                src={loadingImg}
                className="p-thumbnail__uploading-progress"
              />
            </div>
            <style>{`body { cursor: progress; }`}</style>
          </>
        )}
      </div>
      {props.enableVideoElemCapture && (
        <div className="p-thumbnail__reset">
          <button
            className="c-button--text"
            disabled={uploading}
            onClick={captureVideoElemInThisPage}
          >
            現在のビデオプレビューをサムネイルに設定
          </button>
        </div>
      )}
      {resettable && (
        <div className="p-thumbnail__reset">
          <button
            className="c-button--text"
            disabled={uploading}
            onClick={initializeThumbnail}
          >
            サムネイルをリセット
          </button>
        </div>
      )}
    </>
  );
}
export default VideoThumbnail;
