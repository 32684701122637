import { VFC, useState } from "react";
import ReactModal from "react-modal";
import type { VideoWatchLinkType } from "../../types/videoWatchLink";
import { ApiWatchLinkApi, Configuration } from "../../generated/api";
import { linkIconMap } from "./VideoShareMenu";
import { notify } from "../notification";
import { WatchLinkItem } from "../common/watch_link/WatchLinkItem";
import { WatchLinkTypeSelect } from "../common/watch_link/WatchLinkTypeSelect";
import { WatchLinkInvitationForm } from "../common/watch_link/WatchLinkInvitationForm";
import { LinkCopyButton } from "../common/LinkCopyButton";
import { isResponseError } from "../../lib/isResponseError";

type InviteMember = Awaited<
  ReturnType<ApiWatchLinkApi["apiVideoVideoIdWatchLinkGet"]>
>["members"][number];
type WatchLinkCondition = Awaited<
  ReturnType<ApiWatchLinkApi["apiVideoVideoIdWatchLinkGet"]>
>["condition"];

type Props = {
  isOpen: boolean;
  requestClose: () => void;
  onUpdated: (type: VideoWatchLinkType, inviteMembersCount: number) => void;
  type: VideoWatchLinkType;
  csrfToken: string;
  videoId: number;
  shareUrl: string;
  domainWatchLinkAvailable: boolean;
  tenantWatchLinkAvailable?: boolean;
  watchLinkConditionAvailable?: boolean;
};

function watchLinkApi(csrfToken: string) {
  return new ApiWatchLinkApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}

export const VideoShareModal: VFC<Props> = (props) => {
  const [type, setType] = useState(props.type);
  const [members, setMembers] = useState<InviteMember[]>([]);
  const [condition, setCondition] = useState<WatchLinkCondition>();
  const [errMsg, setErrMsg] = useState("");

  const typeItems: WatchLinkItem<VideoWatchLinkType>[] = [
    {
      type: "video_create_user_only",
      title: "非公開",
      icon: linkIconMap["video_create_user_only"],
      copyDesc: "自分だけが閲覧できます。",
    },
    {
      type: "member_only",
      title: "特定の人",
      icon: linkIconMap["member_only"],
      copyDesc: "指定したメールアドレスでログインした場合に閲覧できます。",
    },
    {
      type: "no_limit",
      title: "リンクを知っている人全員",
      icon: linkIconMap["no_limit"],
      copyDesc: "リンクを知っているインターネット上の全員が閲覧できます。",
    },
  ];
  if (props.domainWatchLinkAvailable) {
    typeItems.splice(2, 0, {
      type: "domain_only",
      title: "特定のドメイン",
      icon: linkIconMap["domain_only"],
      copyDesc: "指定したドメインでログインした場合に閲覧できます。",
    });
  }
  if (props.tenantWatchLinkAvailable) {
    typeItems.splice(2, 0, {
      type: "tenant_only",
      title: "自社サービスのユーザー（SSO）",
      icon: linkIconMap["tenant_only"],
      copyDesc: "SSO連携した自社サービスのユーザーのみが閲覧できます。",
    });
  }

  const copyDesc = typeItems.find((i) => i.type === type)?.copyDesc;

  const closeModal = () => {
    props.requestClose();

    // 初期値に戻す
    setType(undefined);
    setMembers([]);
    setCondition(undefined);
    setErrMsg("");
  };

  // 初期データ取得
  const initialize = () => {
    watchLinkApi(props.csrfToken)
      .apiVideoVideoIdWatchLinkGet({
        videoId: props.videoId,
      })
      .then((data) => {
        const typeName = data.typeName as VideoWatchLinkType;
        setType(typeName);
        setMembers(data.members ?? []);
        setCondition(data.condition ?? undefined);
        props.onUpdated(typeName, data.members?.length);
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        Rollbar.warn(reason);
      });
  };

  const updateShareSettings = () => {
    setErrMsg("");
    const emails = type === "member_only" ? members.map((m) => m.email) : [];
    const api = watchLinkApi(props.csrfToken);
    api
      .apiVideoVideoIdWatchLinkPatch({
        videoId: props.videoId,
        apiVideoVideoIdWatchLinkPatchRequest: { type, emails, condition },
      })
      .then(() => {
        props.onUpdated(type, members?.length);
        closeModal();
      })
      .catch((reason) => {
        if (isResponseError(reason) && reason.response.status === 422) {
          reason.response
            .json()
            .then((body) => Object.values(body?.messages).flat().join("\n"))
            .then((msg) => {
              setErrMsg(msg);
            });
        } else {
          notify(
            "エラーが発生しました。画面をリロードしてもう一度試してください。"
          );
          Rollbar.warn(reason);
        }
      });
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      onAfterOpen={initialize}
      onRequestClose={closeModal}
      overlayClassName="c-dialog__overlay c-watch-link-modal__overlay"
      className={{ base: "", afterOpen: "", beforeClose: "" }}
    >
      <div className="c-dialog__container c-watch-link-modal__container">
        <div className="c-dialog__header">
          <h2 className="c-dialog__header__title">共有リンクを設定</h2>
          <button
            className="material-icons-round c-dialog__header__close-icon"
            onClick={closeModal}
          >
            close
          </button>
        </div>
        {errMsg && <p className="c-dialog__err-msgs">{errMsg}</p>}
        <div className="c-dialog__content no-scroll flex-column">
          <WatchLinkTypeSelect
            items={typeItems}
            value={type}
            onChange={(v) => setType(v)}
          />

          {type === "member_only" && (
            <WatchLinkInvitationForm members={members} onUpdate={setMembers} />
          )}
          {props.watchLinkConditionAvailable && type === "no_limit" && (
            <div className="c-watch-link-modal__margin-top">
              <label className="c-watch-link-modal__checkbox">
                <input
                  className="c-watch-link-modal__checkbox-input"
                  type="checkbox"
                  checked={condition?.inputEmail ?? false}
                  onChange={(e) =>
                    setCondition({ ...condition, inputEmail: e.target.checked })
                  }
                />
                視聴時にメールアドレス入力を求める
              </label>
            </div>
          )}
        </div>
        <div className="c-dialog__footer">
          <button
            className="c-button--outlined narrow-padding"
            type="button"
            onClick={closeModal}
          >
            キャンセル
          </button>
          <button
            className="c-button--primary narrow-padding"
            type="submit"
            onClick={updateShareSettings}
            disabled={!type}
          >
            完了
          </button>
        </div>
      </div>

      <div className="c-dialog__container c-watch-link-modal__container c-watch-link-modal__dialog-spacer">
        <div className="c-dialog__header">
          <h2 className="c-dialog__header__title">リンクを取得</h2>
        </div>
        <div className="c-dialog__content">
          <p>{copyDesc}</p>
          <div className="c-watch-link-modal__button-container">
            <LinkCopyButton url={props.shareUrl} className="no-padding" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
