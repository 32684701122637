/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiDesignIdAddCreatomateJobPostRequestCreatomateParam } from './ApiDesignIdAddCreatomateJobPostRequestCreatomateParam';
import {
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamFromJSON,
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamFromJSONTyped,
    ApiDesignIdAddCreatomateJobPostRequestCreatomateParamToJSON,
} from './ApiDesignIdAddCreatomateJobPostRequestCreatomateParam';

/**
 * 
 * @export
 * @interface ApiDesignIdAddCreatomateJobPostRequest
 */
export interface ApiDesignIdAddCreatomateJobPostRequest {
    /**
     * 
     * @type {ApiDesignIdAddCreatomateJobPostRequestCreatomateParam}
     * @memberof ApiDesignIdAddCreatomateJobPostRequest
     */
    creatomateParam?: ApiDesignIdAddCreatomateJobPostRequestCreatomateParam;
}

/**
 * Check if a given object implements the ApiDesignIdAddCreatomateJobPostRequest interface.
 */
export function instanceOfApiDesignIdAddCreatomateJobPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiDesignIdAddCreatomateJobPostRequestFromJSON(json: any): ApiDesignIdAddCreatomateJobPostRequest {
    return ApiDesignIdAddCreatomateJobPostRequestFromJSONTyped(json, false);
}

export function ApiDesignIdAddCreatomateJobPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDesignIdAddCreatomateJobPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creatomateParam': !exists(json, 'creatomate_param') ? undefined : ApiDesignIdAddCreatomateJobPostRequestCreatomateParamFromJSON(json['creatomate_param']),
    };
}

export function ApiDesignIdAddCreatomateJobPostRequestToJSON(value?: ApiDesignIdAddCreatomateJobPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creatomate_param': ApiDesignIdAddCreatomateJobPostRequestCreatomateParamToJSON(value.creatomateParam),
    };
}

