/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiVideoVideoIdThumbnailSpritesGet200Response,
} from '../models';
import {
    ApiVideoVideoIdThumbnailSpritesGet200ResponseFromJSON,
    ApiVideoVideoIdThumbnailSpritesGet200ResponseToJSON,
} from '../models';

export interface ApiVideoVideoIdThumbnailSpritesGetRequest {
    videoId: number;
}

/**
 * 
 */
export class ApiThumbnailSpriteApi extends runtime.BaseAPI {

    /**
     * show
     */
    async apiVideoVideoIdThumbnailSpritesGetRaw(requestParameters: ApiVideoVideoIdThumbnailSpritesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVideoVideoIdThumbnailSpritesGet200Response>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling apiVideoVideoIdThumbnailSpritesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/video/{video_id}/thumbnail_sprites`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVideoVideoIdThumbnailSpritesGet200ResponseFromJSON(jsonValue));
    }

    /**
     * show
     */
    async apiVideoVideoIdThumbnailSpritesGet(requestParameters: ApiVideoVideoIdThumbnailSpritesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVideoVideoIdThumbnailSpritesGet200Response> {
        const response = await this.apiVideoVideoIdThumbnailSpritesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
