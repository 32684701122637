import {
  VFC,
  useState,
  CSSProperties,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  TimeHTMLAttributes,
  RefObject,
  MouseEvent,
  PointerEventHandler,
} from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  useDraggable,
  DragMoveEvent,
  PointerSensorOptions,
  Modifier,
  DragStartEvent,
  DragOverlay,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToHorizontalAxis,
  restrictToWindowEdges,
  createSnapModifier,
} from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

import {
  ApiTimelineUpdateJobApi,
  ApiThumbnailSpriteApi,
  ApiVideoApi,
  Configuration,
  ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner,
} from "../../generated/api";
import { videoPath } from "../../generated/routes";
import { PreviewPlayer } from "./PreviewPlayer";
import {
  useTimelineClips,
  findClipFromPositionMs,
  Video,
  VideoSummary,
  VideoThumbnailSprite,
  Clip,
} from "./useTimelineClips";
import { useClipContextMenuPosition } from "./useClipContextMenuPosition";
import { useChangingClipStart } from "./useChangingClipStart";
import { VideoSelectorModal } from "../common/VideoSelectorModal";
import { notify } from "../notification";

import type { Folder } from "../../types/folder";
import type { PreviewPlayerHandler } from "./PreviewPlayer";
import { useClipItemDraggingPosition } from "./useClipItemDraggingPosition";
import { useScrollIntoView } from "./useScrollIntoView";
import { useRestoreClips } from "./useRestoreClips";
import { isResponseError } from "../../lib/isResponseError";

interface Props {
  csrfToken: string;
  initialVideo: Video;
  initialVideoThumbnailSprite: VideoThumbnailSprite;
  userFolders: Folder[];
  teamFolders: Folder[];
  lastTransformedAt?: string; // ISO8601
}

const dndSensorOptions: PointerSensorOptions = {
  // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
  activationConstraint: { distance: 5 },
};
const zoomList = [0.1, 0.5, 1, 5, 10];
const CLIP_THUMB_HEIGHT = 60; // タイムラインの高さからborderやpaddingを引いた値
const CLIP_THUMB_WIDTH = (CLIP_THUMB_HEIGHT / 9) * 16;

export const VideoTimelineEdit: VFC<Props> = (props) => {
  const playerRef = useRef<PreviewPlayerHandler>();
  const [playing, setPlaying] = useState(false);
  const tlScrollContainerRef = useRef<HTMLDivElement>();
  const tlClipItemContainerRef = useRef<HTMLDivElement>();
  const playheadIndicatorRef = useRef<HTMLDivElement>();
  const [modalVisible, setModalVisible] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [currentTimeMs, setCurrentTimeMs] = useState(0);
  const [currentClip, setCurrentClip] = useState<Clip>();
  const [submitting, setSubmitting] = useState(false);

  const [requestPlayheadScrollIntoView] = useScrollIntoView(
    playheadIndicatorRef,
    { inline: "center" }
  );
  const {
    draggingClipData,
    retainPointerPosition,
    showDraggingOverlayClip,
    hideDraggingOverlayClip,
  } = useClipItemDraggingPosition(
    CLIP_THUMB_WIDTH,
    tlScrollContainerRef,
    tlClipItemContainerRef
  );
  const { clipContextMenuPosition, showClipContextMenu } =
    useClipContextMenuPosition();
  const {
    itemContainerStyle,
    startChangingClipStart,
    updateChangingClipStart,
    endChangingClipStart,
  } = useChangingClipStart(tlClipItemContainerRef);
  const [restoredClips, storeDraftClips] = useRestoreClips(
    props.initialVideo,
    props.lastTransformedAt
  );
  const {
    clips,
    totalDurationMs,
    updateClips,
    addClips,
    splitClip,
    delClip,
    changeClipStart,
    changeClipEnd,
    hasUndoStack,
    hasRedoStack,
    undo,
    redo,
  } = useTimelineClips(
    props.initialVideo,
    props.initialVideoThumbnailSprite,
    // 編集状態を復元する場合、サムネイルスプライトを解決してタイムラインを更新するので、負荷回避のため初期化時はタイムラインを空にする。
    restoredClips ? [] : undefined
  );

  const sensors = useSensors(useSensor(PointerSensor, dndSensorOptions));

  useEffect(() => {
    if (clips?.length > 0 && restoredClips != clips) {
      storeDraftClips(clips);
    }
  }, [clips, restoredClips, storeDraftClips]);

  useEffect(() => {
    if (!restoredClips) {
      return;
    }
    // 過去の編集状態を復元した場合、個々のクリップのビデオとサムネイルスプライトが古いので、再取得する。
    const unique = (arr: number[]) => Array.from(new Set(arr));
    const videoIds = unique(restoredClips.map((c) => c.video.id));
    const promises = videoIds.map((videoId) =>
      Promise.all(
        videoId === props.initialVideo.id
          ? [props.initialVideo, props.initialVideoThumbnailSprite]
          : [
              requestVideo(videoId, props.csrfToken),
              requestThumbnailSprites({ id: videoId }, props.csrfToken),
            ]
      ).then(([video, thumbnailSprite]) => ({
        videoId,
        video,
        thumbnailSprite,
      }))
    );
    Promise.all(promises)
      .then((videoAndThumbs) => {
        const newClips = restoredClips.map((clip) => {
          const resolved = videoAndThumbs.find(
            (v) => v.videoId === clip.video.id
          );
          return {
            ...clip,
            video: resolved.video,
            thumbnailSpritesUrl: resolved.thumbnailSprite.signedUrl,
          };
        });
        updateClips(newClips);
      })
      .catch((reason) => {
        console.warn(reason);
        Rollbar.warn(reason);
        if (isResponseError(reason) && reason.response.status === 404) {
          // 使っていたビデオが削除されている場合
          alert(
            "クリップとして挿入していたビデオが削除されています。\n" +
              "\n" +
              "「キャンセル」を押して編集内容を破棄してやり直してください。"
          );
        } else {
          // 一時的なネットワークエラー、または不測の状態
          alert(
            "編集内容の再開に失敗しました。\n" +
              "\n" +
              "画面をリロードしても繰り返しこのメッセージが表示される場合、\n" +
              "「キャンセル」を押して編集内容を破棄してやり直してください。"
          );
        }
      });
  }, [
    restoredClips,
    updateClips,
    props.csrfToken,
    props.initialVideo,
    props.initialVideoThumbnailSprite,
  ]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.code === "Space") {
        event.preventDefault();
        if (playing) {
          playerRef.current.pause();
        } else {
          playerRef.current.play();
        }
      }
    };
    document.body.addEventListener("keydown", handler);
    return () => document.body.removeEventListener("keydown", handler);
  }, [playing]);

  useEffect(() => {
    // タイムラインの横スクロールをマウスホイールでできるようにする
    // ※ReactのonWheelでアタッチすると、passiveイベントとして扱われpreventDefaultできない。
    const elem = tlScrollContainerRef.current;
    const handleWheel = (event: WheelEvent) => {
      if (event.deltaY) {
        event.preventDefault();
        elem.scrollBy({ left: event.deltaY });
      }
    };
    elem.addEventListener("wheel", handleWheel);
    return () => elem.removeEventListener("wheel", handleWheel);
  }, []);

  useEffect(() => {
    // 再生中、タイムラインのスクロールを現在再生位置に追従する
    if (playing) {
      requestPlayheadScrollIntoView();
    }
  }, [playing, currentTimeMs, requestPlayheadScrollIntoView]);

  useEffect(() => {
    // 拡大率の変更時、現在再生位置を見失わないようタイムラインのスクロールを追従する。
    requestPlayheadScrollIntoView();
  }, [zoom, requestPlayheadScrollIntoView]);

  useEffect(() => {
    // undoなどで全体の尺が短くなったら現在再生位置をあわせる
    if (totalDurationMs < currentTimeMs) {
      setCurrentTimeMs(totalDurationMs);
    }
  }, [totalDurationMs, currentTimeMs]);

  useEffect(() => {
    setCurrentClip(findClipFromPositionMs(clips, currentTimeMs)?.clip);
  }, [clips, currentTimeMs]);

  useEffect(() => {
    // クリップの分割時などでプレイヤーが再構築されても、同じ位置からプレビュー再生できるようシークし直す。
    // ただし、厳密に同じ位置では分割後の1つ目のクリップの終端にいることになり、1つ目のクリップの最初から再生されてしまうので少しずらす。
    playerRef.current.seekInTimeline(currentTimeMs + 1);
    // クリップの変更時のみ行いたいので、currentTimeMsはdepsになくてもよい。
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clips]);

  const handleClipDragStart = (event: DragStartEvent) => {
    updateCurrentTimeMs(calcClipStartMsOfTimeline(clips, event.active.id));
    showDraggingOverlayClip(clips, event.active.id);
  };

  const handleClipDragEnd = (event: DragEndEvent) => {
    hideDraggingOverlayClip();
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = clips.findIndex((c) => c.id === active.id);
      const newIndex = clips.findIndex((c) => c.id === over.id);
      const newClips = arrayMove(clips, oldIndex, newIndex);
      updateClips(newClips);
      updateCurrentTimeMs(calcClipStartMsOfTimeline(newClips, active.id));
    }
    requestPlayheadScrollIntoView();
  };

  const openVideoSelector = () => {
    playerRef.current.pause();
    setModalVisible(true);
  };

  const handleVideoSelect = (selectedVideos: VideoSummary[]) => {
    setModalVisible(false);
    const targetVideos = selectedVideos.filter((v) => v.playTimeMs);

    const promiseResolveVideos = targetVideos.map(async (video) => {
      const promiseSprites = requestThumbnailSprites(
        video,
        props.csrfToken
      ).catch((reason) => {
        console.warn(reason);
        Rollbar.warn(reason); // ここでエラーになるのはそもそもサムネイルスプライトのデータが存在しない場合
        notify("クリップのサムネイルを取得できませんでした。");
        return null as VideoThumbnailSprite;
      });
      const videoWithStreamLocatorUrl = await requestVideo(
        video.id,
        props.csrfToken
      );
      const thumbnailSprite = await promiseSprites;
      return {
        video: videoWithStreamLocatorUrl,
        thumbnailSprite,
      };
    });
    Promise.all(promiseResolveVideos).then((videoAndThumbs) => {
      const filtered = videoAndThumbs.filter((item) => {
        if (item.thumbnailSprite?.signedUrl == null) {
          return false; // サムネイル取得失敗したら除外
        }
        return true;
      });
      if (filtered.length > 0) {
        addClips(filtered, currentTimeMs);
        updateCurrentTimeMs(
          calcClipEndMsOfTimeline(clips, currentClip.id) + 100
        );
        requestPlayheadScrollIntoView();
      }

      if (videoAndThumbs.some((item) => !item.thumbnailSprite?.signedUrl)) {
        notify("選択したビデオのクリップのサムネイルがありません。");
      }
    });

    if (selectedVideos.length - targetVideos.length > 0) {
      notify(
        "挿入できないビデオをスキップしました。\nビデオの状態を確認してください。"
      );
    }
  };

  const handleVideoSave = () => {
    playerRef.current.pause();

    if (!confirm("ビデオの編集を保存しますか？\n※元に戻せません。")) {
      return;
    }
    setSubmitting(true);

    const api = new ApiTimelineUpdateJobApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": props.csrfToken,
        },
      })
    );
    const timelines: ApiVideoVideoIdTimelineUpdateJobsPostRequestTimelinesInner[] =
      clips.map((c) => ({
        videoId: c.video.id,
        startMs: c.startMs,
        durationMs: c.durationMs,
      }));
    api
      .apiVideoVideoIdTimelineUpdateJobsPost({
        videoId: `${props.initialVideo.id}`,
        apiVideoVideoIdTimelineUpdateJobsPostRequest: { timelines },
      })
      .then(() => {
        storeDraftClips(null);
        location.assign(videoPath(props.initialVideo.hashid));
      })
      .catch((reason) => {
        setSubmitting(false);
        notify("保存に失敗しました。しばらく待ってもう一度お試しください。");
        throw reason;
      });
  };

  const updateCurrentTimeMs = (ms: number) => {
    playerRef.current.pause();
    playerRef.current.seekInTimeline(ms);
    setCurrentTimeMs(ms);
  };

  const handleTimelineClick = (e: MouseEvent<HTMLDivElement>) => {
    const offset = e.currentTarget.getBoundingClientRect().left;
    const ms = px2ms(e.clientX - offset, zoom);
    if (0 <= ms && ms <= totalDurationMs) {
      updateCurrentTimeMs(Math.round(ms / 100) * 100); // 100ms未満を四捨五入
    }
  };

  return (
    <div className="p-video-timeline__container">
      {submitting && <style>{`body { cursor: progress; }`}</style>}
      <div className="p-video-timeline__header">
        <button
          className="c-button--text"
          disabled={submitting}
          onClick={() => {
            if (confirm("編集内容を破棄します。よろしいですか？")) {
              storeDraftClips(null);
              location.assign(videoPath(props.initialVideo.hashid));
            }
          }}
        >
          キャンセル
        </button>
        <button
          className="c-button--primary"
          onClick={handleVideoSave}
          disabled={submitting}
        >
          保存
        </button>
      </div>

      <div className="p-video-timeline__player-container">
        <PreviewPlayer
          ref={playerRef}
          clips={clips}
          onPlayingStateUpdate={setPlaying}
          onCurrentTimeMsUpdate={useCallback((ms) => {
            setCurrentTimeMs((prev) => {
              // クリップの切り替り時に直前のクリップのtimeupdateイベントが遅れて伝搬する為に再生位置が前後してしまうのを回避する。
              if (ms < prev) {
                return prev;
              }
              return ms;
            });
          }, [])}
        />
      </div>

      <div
        className="p-video-timeline__timeline-container"
        ref={tlScrollContainerRef}
      >
        {/* タイムライン */}
        <button
          className="p-video-timeline__play-button"
          disabled={submitting}
          onClick={() => {
            playing ? playerRef.current.pause() : playerRef.current.play();
          }}
        >
          <span className="material-icons-round">
            {playing ? "pause" : "play_arrow"}
          </span>
        </button>
        <div className="p-video-timeline__timeline">
          {!draggingClipData && (
            <input
              type="range"
              className="p-video-timeline__timeline-playhead"
              min={0}
              step={100} // 100ms単位
              value={currentTimeMs}
              max={totalDurationMs}
              onChange={(e) => updateCurrentTimeMs(e.target.valueAsNumber)}
              style={{
                width: `${calcClipItemWidth(totalDurationMs, zoom)}px`,
              }}
            />
          )}
          <div
            className="p-video-timeline__timeline-item-container"
            ref={tlClipItemContainerRef}
            style={{
              marginLeft: draggingClipData?.tlMarginLeft,
              ...itemContainerStyle,
            }}
            onClick={handleTimelineClick}
            onContextMenu={(e) => {
              handleTimelineClick(e);
              showClipContextMenu(e);
              e.preventDefault();
            }}
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleClipDragStart}
              onDragEnd={handleClipDragEnd}
            >
              <SortableContext
                items={clips}
                strategy={horizontalListSortingStrategy}
              >
                {clips.map((clip, index) => (
                  <ClipItem
                    key={clip.id}
                    clip={clip}
                    zoom={zoom}
                    scrollContainerRef={tlScrollContainerRef}
                    changeStart={(clip, startMs) => {
                      changeClipStart(clip, startMs);
                      const offsetMs = clips
                        .slice(0, index)
                        .reduce((sum, c) => sum + c.durationMs, 0);
                      updateCurrentTimeMs(offsetMs + 1);
                    }}
                    changeEnd={(clip, endMs) => {
                      changeClipEnd(clip, endMs);
                      const offsetMs = clips
                        .slice(0, index)
                        .reduce((sum, c) => sum + c.durationMs, 0);
                      updateCurrentTimeMs(offsetMs + (endMs - clip.startMs));
                    }}
                    isCurrent={clip.id === currentClip?.id}
                    seekWithinClip={(positionMsInClip) => {
                      playerRef.current.seekInClip(clip, positionMsInClip);
                    }}
                    onStartChangingClipStart={startChangingClipStart}
                    onUpdateChangingClipStart={updateChangingClipStart}
                    onEndChangingClipStart={endChangingClipStart}
                    onPointerDown={retainPointerPosition}
                  />
                ))}
              </SortableContext>

              <DragOverlay>
                {draggingClipData && (
                  <OverlayClipItem
                    clip={draggingClipData.clip}
                    adjustX={draggingClipData.overlayClipItemAdjustX}
                    zoom={zoom}
                  />
                )}
              </DragOverlay>
            </DndContext>
          </div>
          {!draggingClipData && (
            <div
              ref={playheadIndicatorRef}
              className="p-video-timeline__timeline-playhead-indicator"
              style={{
                transform: `translateX(${calcClipItemWidth(
                  currentTimeMs,
                  zoom
                )}px)`,
              }}
            >
              <DurationTimeElem
                durationMs={currentTimeMs}
                maxDurationMs={totalDurationMs}
                className="p-video-timeline__timeline-playhead-indicator-time"
              />
              <div className="p-video-timeline__timeline-playhead-indicator-bar"></div>
            </div>
          )}
        </div>
      </div>

      <div className="p-video-timeline__controls-container">
        {/* 拡大率 */}
        <div className="p-video-timeline__controls-zoom">
          <span className="material-icons-round p-video-timeline__controls-zoom-icon">
            search
          </span>
          <input
            id="timeline-zoom"
            className="p-video-timeline__controls-zoom-input"
            type="range"
            autoComplete="off"
            disabled={submitting}
            min={0}
            step={1}
            max={zoomList.length - 1}
            value={zoomList.indexOf(zoom)}
            onChange={(e) => setZoom(zoomList[e.target.valueAsNumber])}
            style={{
              backgroundSize: `${
                (zoomList.indexOf(zoom) / (zoomList.length - 1)) * 100
              }% 100%`,
            }}
          />
          <label htmlFor="timeline-zoom">{zoom * 100}%</label>
        </div>

        {/* 再生位置コントロール */}
        <div className="p-video-timeline__controls-current-time">
          <label className="p-video-timeline__controls-current-time-inputfield">
            <DurationTimeElem
              durationMs={currentTimeMs}
              maxDurationMs={totalDurationMs}
            />
            <input
              aria-hidden="true"
              id="current-time-ms"
              type="number"
              disabled={submitting}
              min={0}
              step={100} // 100ms単位
              value={currentTimeMs}
              max={totalDurationMs}
              inputMode="none"
              onChange={(e) => {
                // 数値でないものを入力される回避
                let value = e.target.valueAsNumber;
                if (isNaN(value)) {
                  value = currentTimeMs;
                }
                updateCurrentTimeMs(value);
              }}
            />
          </label>
          &ensp;/&ensp;
          <DurationTimeElem durationMs={totalDurationMs} />
        </div>

        {/* クリップの操作メニューなど */}
        <div className="p-video-timeline__controls-buttons-container">
          <button
            className="material-icons-round p-video-timeline__icon-button color-gray"
            title="元に戻す"
            disabled={!hasUndoStack || submitting}
            onClick={undo}
          >
            undo
          </button>
          <button
            className="material-icons-round p-video-timeline__icon-button color-gray"
            title="やり直す"
            disabled={!hasRedoStack || submitting}
            onClick={redo}
          >
            redo
          </button>
          <button
            className="material-icons-round p-video-timeline__icon-button color-gray"
            title="クリップを削除する"
            onClick={() => delClip(currentTimeMs)}
            disabled={clips.length <= 1 || submitting}
          >
            delete_outline
          </button>
          <button
            className="c-button--outlined narrow-padding"
            disabled={submitting}
            onClick={() => splitClip(currentTimeMs)}
          >
            <span
              className="material-icons-round c-button__icon"
              aria-hidden="true"
            >
              flip
            </span>
            クリップを分割
          </button>
          <button
            className="c-button--primary narrow-padding"
            onClick={openVideoSelector}
            disabled={submitting}
          >
            ビデオを挿入
          </button>
          {playerRef.current?.enterFullscreen && (
            <button
              className="material-icons-round p-video-timeline__icon-button"
              title="全画面"
              disabled={submitting}
              onClick={() => playerRef.current.enterFullscreen()}
            >
              open_in_full
            </button>
          )}
        </div>
      </div>

      <VideoSelectorModal
        csrfToken={props.csrfToken}
        userFolders={props.userFolders}
        teamFolders={props.teamFolders}
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        onSelect={handleVideoSelect}
        filter={(video) => video.timelineEditable && video.playTimeMs != null}
      />

      {clipContextMenuPosition && (
        <ul
          className="c-submenu__list"
          style={{ ...clipContextMenuPosition, transform: "translateY(-100%)" }}
        >
          <li className="c-submenu__list-item">
            <button onClick={openVideoSelector} disabled={submitting}>
              ビデオを挿入
            </button>
          </li>
          <li className="c-submenu__list-item">
            <button onClick={() => splitClip(currentTimeMs)}>
              クリップを分割
            </button>
          </li>
          {clips.length > 1 && (
            <li className="c-submenu__list-item">
              <button
                title="クリップを削除する"
                disabled={submitting}
                onClick={() => delClip(currentTimeMs)}
              >
                削除
              </button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
export default VideoTimelineEdit;

interface ClipItemProps {
  clip: Clip;
  zoom: number;
  isCurrent: boolean;
  scrollContainerRef: RefObject<HTMLDivElement>;
  changeStart: ReturnType<typeof useTimelineClips>["changeClipStart"];
  changeEnd: ReturnType<typeof useTimelineClips>["changeClipEnd"];
  seekWithinClip: (positionMsInClip: number) => void;
  onStartChangingClipStart: () => void;
  onUpdateChangingClipStart: () => void;
  onEndChangingClipStart: () => void;
  onPointerDown: PointerEventHandler;
}

const ClipItem: VFC<ClipItemProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
    isDragging,
  } = useSortable({ id: props.clip.id, attributes: { tabIndex: null } });
  const [slidingDurationMs, setSlidingDurationMs] = useState<number>(null);
  const [slidingStartMs, setSlidingStartMs] = useState<number>(null);

  const clipWidth = useMemo(
    () =>
      isSorting
        ? CLIP_THUMB_WIDTH
        : slidingDurationMs // クリップのサイズ変更中の仮のサイズ変更State
        ? calcClipItemWidth(slidingDurationMs, props.zoom)
        : calcClipItemWidth(props.clip.durationMs, props.zoom),
    [isSorting, slidingDurationMs, props.clip.durationMs, props.zoom]
  );
  const style: CSSProperties = {
    width: clipWidth,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isSorting ? "grabbing" : "pointer",
    opacity: isDragging ? 0.4 : null,
  };
  const thumbPositions = useMemo(() => {
    let clip = props.clip;
    if (slidingStartMs != null) {
      clip = { ...clip, startMs: slidingStartMs };
    }
    return calcClipThumbPositions(props.zoom, clip, clipWidth);
  }, [props.zoom, props.clip, clipWidth, slidingStartMs]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onPointerDown={(e) => {
        props.onPointerDown(e);
        return listeners.onPointerDown?.(e);
      }}
      className={`p-video-timeline__timeline-item ${
        props.isCurrent ? "is-current" : ""
      }`}
      title={props.clip.video.title}
    >
      <ClipItemThumbs clip={props.clip} thumbPositions={thumbPositions} />
      <div className="p-video-timeline__timeline-item-slider-outer">
        <ClipEdgeSlider
          clip={props.clip}
          zoom={props.zoom}
          restrictDeltaX={({ transform }) => {
            const deltaMs = px2ms(transform.x, props.zoom);
            const { startMs, endMs } = props.clip;
            let x = transform.x;
            const endMsWithMinDuration = endMs - 100;
            if (startMs + deltaMs < 0) {
              // 0秒未満でないこと
              x = 0 - ms2px(startMs, props.zoom);
            } else if (endMsWithMinDuration < startMs + deltaMs) {
              // クリップの最小サイズを考慮した終了位置より手前であること
              x = ms2px(endMsWithMinDuration - startMs, props.zoom);
            }
            return x;
          }}
          onDragStart={() => {
            props.onStartChangingClipStart();
          }}
          onDragMove={(event) => {
            props.onUpdateChangingClipStart();

            const { startMs, endMs } = props.clip;
            const deltaMs = px2ms(
              event.delta.x - props.scrollContainerRef.current.scrollLeft,
              props.zoom
            );
            const durationMs = endMs - (startMs + deltaMs);
            setSlidingDurationMs(durationMs);
            setSlidingStartMs(startMs + deltaMs);
            props.seekWithinClip(startMs + deltaMs);
          }}
          onDragEnd={(event) => {
            props.onEndChangingClipStart();

            const deltaMs = px2ms(
              event.delta.x - props.scrollContainerRef.current.scrollLeft,
              props.zoom
            );
            props.changeStart(props.clip, props.clip.startMs + deltaMs);
            setSlidingDurationMs(null);
            setSlidingStartMs(null);
          }}
        />
        <ClipEdgeSlider
          clip={props.clip}
          zoom={props.zoom}
          restrictDeltaX={({ transform }) => {
            const deltaMs = px2ms(transform.x, props.zoom);
            const { startMs, endMs, videoDurationMs } = props.clip;
            let x = transform.x;
            const startMsWithMinDuration = startMs + 100;
            if (endMs + deltaMs < startMsWithMinDuration) {
              // クリップの最小サイズを考慮した開始位置より小さくないこと
              x =
                ms2px(startMsWithMinDuration, props.zoom) -
                ms2px(endMs, props.zoom);
            } else if (videoDurationMs < endMs + deltaMs) {
              // ビデオの再生時間を超えないこと
              x = ms2px(videoDurationMs, props.zoom) - ms2px(endMs, props.zoom);
            }
            return x;
          }}
          onDragMove={(event) => {
            const { startMs, endMs } = props.clip;
            const deltaMs = px2ms(
              event.delta.x - props.scrollContainerRef.current.scrollLeft,
              props.zoom
            );
            const durationMs = endMs + deltaMs - startMs;
            setSlidingDurationMs(durationMs);
            props.seekWithinClip(endMs + deltaMs);
          }}
          onDragEnd={(event) => {
            const deltaMs = px2ms(
              event.delta.x - props.scrollContainerRef.current.scrollLeft,
              props.zoom
            );
            props.changeEnd(props.clip, props.clip.endMs + deltaMs);
            setSlidingDurationMs(null);
          }}
        />
      </div>
    </div>
  );
};

interface OverlayClipItemProps extends Pick<ClipItemProps, "clip" | "zoom"> {
  adjustX: number;
}
const OverlayClipItem: VFC<OverlayClipItemProps> = (props) => {
  const style: CSSProperties = {
    width: CLIP_THUMB_WIDTH,
    cursor: "grabbing",
    transform: `translateX(calc(${props.adjustX}px - 50%))`,
  };
  const thumbPositions = useMemo(
    () => [calcClipThumbPositions(props.zoom, props.clip, CLIP_THUMB_WIDTH)[0]],
    [props.zoom, props.clip]
  );

  return (
    <div
      style={style}
      className="p-video-timeline__timeline-item"
      title={props.clip.video.title}
    >
      <ClipItemThumbs clip={props.clip} thumbPositions={thumbPositions} />
    </div>
  );
};

interface ClipItemThumbsProps {
  clip: Clip;
  thumbPositions: ReturnType<typeof calcClipThumbPositions>;
}
const ClipItemThumbs: VFC<ClipItemThumbsProps> = (props) => {
  const style: CSSProperties = {
    width: CLIP_THUMB_WIDTH,
    height: CLIP_THUMB_HEIGHT,
    backgroundImage: `url(${props.clip.thumbnailSpritesUrl})`,
    backgroundSize: 6400 * (CLIP_THUMB_WIDTH / 128), // 実画像サイズと表示上サイズの辻褄合わせ
    backgroundRepeat: "no-repeat",
  };
  return (
    <div className="p-video-timeline__timeline-item-thumbs-outer">
      {props.thumbPositions.map((position) => (
        <div
          key={`${position.x},${position.y}`}
          data-sec={position.sec}
          style={{
            ...style,
            backgroundPosition: `${position.x}px ${position.y}px`,
          }}
        />
      ))}
    </div>
  );
};

interface ClipEdgeSliderProps {
  clip: Clip;
  zoom: number;
  /** 移動量を計算して制限する関数(不可能な位置に移動できなくするため) */
  restrictDeltaX(args: Parameters<Modifier>[0]): number;
  onDragStart?(event: DragStartEvent): void;
  onDragMove(event: DragMoveEvent): void;
  onDragEnd(event: DragEndEvent): void;
}

const ClipEdgeSlider: VFC<ClipEdgeSliderProps> = (props) => {
  const sensors = useSensors(useSensor(PointerSensor, dndSensorOptions));
  const zoomSizeSnapModifier = createSnapModifier(props.zoom);

  const restrictDeltaXModifier: Modifier = (args) => {
    const x = props.restrictDeltaX(args);
    return { ...args.transform, x };
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={props.onDragStart}
      onDragMove={props.onDragMove}
      onDragEnd={props.onDragEnd}
      modifiers={[
        restrictToHorizontalAxis,
        restrictToWindowEdges,
        zoomSizeSnapModifier,
        restrictDeltaXModifier,
      ]}
    >
      <ClipEdgeSliderItem {...props} />
    </DndContext>
  );
};

const ClipEdgeSliderItem: VFC<ClipEdgeSliderProps> = (props) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.clip.id,
    attributes: { role: "slider" },
  });

  return (
    <button
      className="p-video-timeline__timeline-slider"
      ref={setNodeRef}
      style={{ cursor: "ew-resize" }}
      {...attributes}
      {...listeners}
    >
      |
    </button>
  );
};

const durationFormatOptions: Intl.DateTimeFormatOptions = {
  minute: "2-digit",
  second: "2-digit",
  fractionalSecondDigits: 1,
  timeZone: "UTC", // 時刻ではなく再生時間のフォーマットに使いたいのでタイムゾーン調整しないように。
};
const durationFormatter = Intl.DateTimeFormat("ja-JP", durationFormatOptions);
const durationFormatterWithHour = Intl.DateTimeFormat("ja-JP", {
  ...durationFormatOptions,
  hour: "numeric",
});

export const DurationTimeElem: VFC<
  {
    durationMs: number;
    maxDurationMs?: number;
  } & TimeHTMLAttributes<HTMLTimeElement>
> = (props) => {
  const { durationMs, maxDurationMs, ...attrs } = { ...props };
  const formatter =
    (maxDurationMs ?? durationMs) >= 3600_000
      ? durationFormatterWithHour
      : durationFormatter;
  const text = formatter.format(durationMs);
  const iso8601 = `T${durationMs / 1000}S`; // ISO 8601
  return (
    <time {...attrs} dateTime={iso8601}>
      {text}
    </time>
  );
};

function calcClipItemWidth(durationMs: number, zoom: number) {
  return Math.floor(durationMs / 100) * zoom;
}

export function px2ms(px: number, zoom: number): number {
  // zoom=1 のとき、100msが1pxに相当
  return (px * 100) / zoom;
}

export function ms2px(ms: number, zoom: number): number {
  // zoom=1 のとき、100msが1pxに相当
  return (ms / 100) * zoom;
}

function calcClipThumbPositions(
  zoom: number,
  clip: Clip,
  clipWidth: number
): { x: number; y: number; sec: number }[] {
  clipWidth -= 10; // クリップの左右のborder分引く
  const columnSize = 50;
  const thumbCount = Math.floor(clipWidth / CLIP_THUMB_WIDTH);
  const startMs = clip.startMs + px2ms(5, zoom); // クリップの頭のborder分ずらす
  const stepMs = px2ms(CLIP_THUMB_WIDTH, zoom);
  const result: ReturnType<typeof calcClipThumbPositions> = [];
  let positionMs = startMs;
  for (let i = 0; i <= thumbCount; i++) {
    const targetMs = Math.floor(Math.round(positionMs / 1000) * 1000);
    // 1秒 = 1コマ前提
    const line = Math.floor(targetMs / 1000 / columnSize);
    const positionMsInLine = targetMs - line * (columnSize * 1000);
    const column = (positionMsInLine / 1000) * CLIP_THUMB_WIDTH;
    result.push({
      x: -column,
      y: -(line * CLIP_THUMB_HEIGHT),
      sec: targetMs / 1000,
    });
    positionMs += stepMs;
  }
  return result;
}

async function requestVideo(videoId: Video["id"], csrfToken: string) {
  const api = new ApiVideoApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  const video = await api.apiVideoIdGet({ id: videoId });

  // 配信URLの有効性の確認
  let retryCount = 30;
  do {
    const res = await fetch(video.streamingLocatorUrl).catch((err) => {
      console.warn(err);
      return { ok: false }; // ネットワークエラー時はthrowせずリトライさせる
    });
    if (res.ok) {
      break;
    }
    await new Promise((resolve) => setTimeout(resolve, 1000)); // 1秒待機
  } while (--retryCount > 0);

  return video;
}

function requestThumbnailSprites(
  video: Video,
  csrfToken: string
): Promise<VideoThumbnailSprite> {
  const api = new ApiThumbnailSpriteApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiVideoVideoIdThumbnailSpritesGet({ videoId: video.id });
}

function calcClipStartMsOfTimeline(clips: Clip[], id: Clip["id"]): number {
  let endMsOfPrevClip = 0;
  for (const clip of clips) {
    if (clip.id === id) {
      break;
    }
    endMsOfPrevClip += clip.durationMs;
  }
  if (endMsOfPrevClip === 0) {
    return 0; // 1つ目のクリップの場合
  }
  return endMsOfPrevClip + 100; // 手前のクリップの終端 + 100ms = 現在のクリップの先頭
}

function calcClipEndMsOfTimeline(clips: Clip[], id: Clip["id"]): number {
  let endMs = 0;
  for (const clip of clips) {
    endMs += clip.durationMs;
    if (clip.id === id) {
      break;
    }
  }
  return endMs;
}
