import { VFC, useState, MouseEventHandler } from "react";
import { useSubMenuVisible } from "./useSubMenuVisible";
import { MoveVideoModal } from "./MoveVideoModal";
import { ApiVideoApi, Configuration } from "../../../generated/api";
import { notify } from "../../notification";
import { Folder } from "app/javascript/types/folder";
import { isResponseError } from "../../../lib/isResponseError";

async function deleteVideo(
  videoId: number,
  csrfToken: string,
  parentFolderPath: string
) {
  if (!confirm("ビデオは完全に削除されます。この操作は取り消せません。")) {
    return;
  }
  const api = new ApiVideoApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  try {
    await api.apiVideoIdDelete({ id: videoId });
    if (parentFolderPath) {
      location.assign(parentFolderPath);
    } else {
      location.reload();
    }
  } catch (reason) {
    notify("削除に失敗しました。しばらく待ってもう一度お試しください。");
    throw reason;
  }
}

type Props = {
  videoId: number; // video の id
  parentFolderId: number;
  userFolders: Folder[];
  teamFolders: Folder[];
  csrfToken: string;
  pageReloadAfterDuplicateVideo?: boolean;
  parentFolderPath?: string;
  canDuplicate: boolean;
  canDownload: boolean;
};

export const VideoSubMenu: VFC<Props> = ({
  videoId,
  parentFolderId,
  userFolders,
  teamFolders,
  csrfToken,
  pageReloadAfterDuplicateVideo,
  parentFolderPath,
  canDuplicate,
  canDownload,
}) => {
  const [visible, toggleVisible] = useSubMenuVisible();
  const [moveModalVisible, setMoveModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const toggleMenu: MouseEventHandler = (event) => {
    event.preventDefault();
    toggleVisible();
  };

  const moveVideo: MouseEventHandler = (event) => {
    event.preventDefault();
    setMoveModalVisible(true);
  };

  const duplicateVideo: MouseEventHandler = (event) => {
    event.preventDefault();
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const api = new ApiVideoApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": csrfToken,
        },
      })
    );
    api
      .apiVideoIdDuplicatePostRaw({ id: videoId })
      .then(({ raw: response }) => {
        if (pageReloadAfterDuplicateVideo) {
          location.reload();
        } else {
          const newVideoPath = response.headers.get("Location");
          location.assign(newVideoPath);
        }
      })
      .catch((reason) => {
        if (isResponseError(reason) && reason.response.status === 422) {
          alert("ビデオがエンコードまたは字幕生成中のため複製できません。");
        } else {
          notify(
            "エラーが発生しました。画面をリロードしてもう一度試してください。"
          );
          throw reason;
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const downloadVideo: MouseEventHandler = (event) => {
    event.preventDefault();
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const api = new ApiVideoApi(
      new Configuration({
        basePath: "",
        headers: {
          "x-hopper-api-version": "1.0",
          "X-CSRF-Token": csrfToken,
        },
      })
    );
    api
      .apiVideoIdDownloadGetRaw({ id: videoId })
      .then(({ raw: response }) => {
        const downloadPath = response.headers.get("Location");
        location.assign(downloadPath);
      })
      .catch((reason) => {
        if (isResponseError(reason) && reason.response.status === 422) {
          alert("ダウンロードする権限がありません");
        } else {
          notify(
            "エラーが発生しました。画面をリロードしてもう一度試してください。"
          );
          throw reason;
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const delVideo: MouseEventHandler = (event) => {
    event.preventDefault();
    deleteVideo(videoId, csrfToken, parentFolderPath);
  };

  return (
    <>
      <button
        className="material-icons-round c-submenu__button"
        onClick={toggleMenu}
      >
        more_vert
      </button>
      {visible && (
        <ul role="menu" className="c-submenu__list">
          <li role="menuitem" className="c-submenu__list-item">
            <button onClick={moveVideo}>
              <i className="material-icons-outlined c-submenu__list-item-icon">
                drive_file_move
              </i>
              移動
            </button>
          </li>
          {canDuplicate && (
            <li role="menuitem" className="c-submenu__list-item">
              <button onClick={duplicateVideo}>
                <i className="material-icons-outlined c-submenu__list-item-icon">
                  content_copy
                </i>
                複製
              </button>
            </li>
          )}
          {canDownload && (
            <li role="menuitem" className="c-submenu__list-item">
              <button onClick={downloadVideo}>
                <i className="material-icons-outlined c-submenu__list-item-icon">
                  download
                </i>
                ダウンロード
              </button>
            </li>
          )}
          <li role="menuitem" className="c-submenu__list-item danger">
            <button onClick={delVideo}>
              <i className="material-icons-outlined c-submenu__list-item-icon">
                delete
              </i>
              削除
            </button>
          </li>
        </ul>
      )}
      {submitting && <style>{`* { cursor: progress; }`}</style>}
      {moveModalVisible && (
        <MoveVideoModal
          videoId={videoId}
          currentParentFolderId={parentFolderId}
          userFolders={userFolders}
          teamFolders={teamFolders}
          csrfToken={csrfToken}
          onAfterClose={() => setMoveModalVisible(false)}
        />
      )}
    </>
  );
};
export default VideoSubMenu;
