/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiVideoIdMovePatch200ResponseSubtitles } from './ApiVideoIdMovePatch200ResponseSubtitles';
import {
    ApiVideoIdMovePatch200ResponseSubtitlesFromJSON,
    ApiVideoIdMovePatch200ResponseSubtitlesFromJSONTyped,
    ApiVideoIdMovePatch200ResponseSubtitlesToJSON,
} from './ApiVideoIdMovePatch200ResponseSubtitles';
import type { ApiVideoIdMovePatch200ResponseWatchLink } from './ApiVideoIdMovePatch200ResponseWatchLink';
import {
    ApiVideoIdMovePatch200ResponseWatchLinkFromJSON,
    ApiVideoIdMovePatch200ResponseWatchLinkFromJSONTyped,
    ApiVideoIdMovePatch200ResponseWatchLinkToJSON,
} from './ApiVideoIdMovePatch200ResponseWatchLink';

/**
 * 
 * @export
 * @interface ApiVideoIdPut200Response
 */
export interface ApiVideoIdPut200Response {
    /**
     * 
     * @type {number}
     * @memberof ApiVideoIdPut200Response
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdPut200Response
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdPut200Response
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdPut200Response
     */
    descriptionHtml?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVideoIdPut200Response
     */
    playTimeMs?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdPut200Response
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {ApiVideoIdMovePatch200ResponseSubtitles}
     * @memberof ApiVideoIdPut200Response
     */
    subtitles?: ApiVideoIdMovePatch200ResponseSubtitles;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdPut200Response
     */
    hashid?: string;
    /**
     * 
     * @type {ApiVideoIdMovePatch200ResponseWatchLink}
     * @memberof ApiVideoIdPut200Response
     */
    watchLink?: ApiVideoIdMovePatch200ResponseWatchLink;
    /**
     * 
     * @type {boolean}
     * @memberof ApiVideoIdPut200Response
     */
    timelineEditable?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiVideoIdPut200Response
     */
    timelineUneditableReasons?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiVideoIdPut200Response
     */
    streamingLocatorUrl?: string;
}

/**
 * Check if a given object implements the ApiVideoIdPut200Response interface.
 */
export function instanceOfApiVideoIdPut200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiVideoIdPut200ResponseFromJSON(json: any): ApiVideoIdPut200Response {
    return ApiVideoIdPut200ResponseFromJSONTyped(json, false);
}

export function ApiVideoIdPut200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideoIdPut200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'descriptionHtml': !exists(json, 'descriptionHtml') ? undefined : json['descriptionHtml'],
        'playTimeMs': !exists(json, 'playTimeMs') ? undefined : json['playTimeMs'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'subtitles': !exists(json, 'subtitles') ? undefined : ApiVideoIdMovePatch200ResponseSubtitlesFromJSON(json['subtitles']),
        'hashid': !exists(json, 'hashid') ? undefined : json['hashid'],
        'watchLink': !exists(json, 'watchLink') ? undefined : ApiVideoIdMovePatch200ResponseWatchLinkFromJSON(json['watchLink']),
        'timelineEditable': !exists(json, 'timelineEditable') ? undefined : json['timelineEditable'],
        'timelineUneditableReasons': !exists(json, 'timelineUneditableReasons') ? undefined : json['timelineUneditableReasons'],
        'streamingLocatorUrl': !exists(json, 'streamingLocatorUrl') ? undefined : json['streamingLocatorUrl'],
    };
}

export function ApiVideoIdPut200ResponseToJSON(value?: ApiVideoIdPut200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state': value.state,
        'title': value.title,
        'descriptionHtml': value.descriptionHtml,
        'playTimeMs': value.playTimeMs,
        'thumbnailUrl': value.thumbnailUrl,
        'subtitles': ApiVideoIdMovePatch200ResponseSubtitlesToJSON(value.subtitles),
        'hashid': value.hashid,
        'watchLink': ApiVideoIdMovePatch200ResponseWatchLinkToJSON(value.watchLink),
        'timelineEditable': value.timelineEditable,
        'timelineUneditableReasons': value.timelineUneditableReasons,
        'streamingLocatorUrl': value.streamingLocatorUrl,
    };
}

